<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Horas extra</div>
      <div>
        <button
          class="uk-button uk-border-rounded uk-button-primary"
          href="#import-export-modal"
          uk-toggle
        >Importar / Exportar</button>
        <button
          dusk="add-overtime"
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-overtime-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-overtime')"
        >Añadir horas extra</button>
      </div>
    </div>

    <div class="uk-flex uk-flex-center">
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          id="start-date"
          class="uk-input uk-border-rounded uk-text-center"
          v-model="overtimesFilterFrom"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          id="end-date"
          class="uk-input uk-border-rounded uk-text-center"
          v-model="overtimesFilterTo"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input"
            type="search"
            placeholder="Buscar horas extra..."
            v-model="overtimeFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="overtimes.prev_page_url"
          :is-fetching="isFetching"
          :current-page="overtimes.current_page"
          :last-page="overtimes.last_page"
          :next-page-url="overtimes.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th width="20%">Colaborador</th>
            <th width="20%">Razón</th>
            <th width="20%">Notas</th>
            <th width="5%">Constancia</th>
            <th width="10%">Fecha de aplicación</th>
            <th width="5%">Multiplicador de pago</th>
            <th width="10%">Horas</th>
            <th width="10%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(overtime, index) in overtimes.data" :key="overtime.id">
            <td>
              <a
                :id="'overtime-'+ overtime.id"
                :href="'/directory/general/' + overtime.insured_business.insured.id.toString()"
                class="uk-text-bold uk-link-reset"
              >{{ overtime.insured_business.insured.first_name }} {{ overtime.insured_business.insured.last_name }}</a>
            </td>
            <td :id="'overtime-name-' + overtime.id ">{{ overtime.reason.name }}</td>
            <td :id="'overtime-notes-' + overtime.id ">{{ overtime.notes }}</td>
            <td :id="'overtime-file-' + overtime.id " class="uk-text-center">
              <a
                v-if="overtime.file"
                uk-icon="cloud-download"
                :href="overtime.file.path"
                target="_blank"
              ></a>
            </td>
            <td
              :id="'overtime-application-date-' + overtime.id "
            >{{ formatDate(overtime.application_date) }}</td>
            <td
              :id="'overtime-payment-' + overtime.id "
              class="uk-text-center"
            >{{ overtime.payment_multiplier }}</td>
            <td :id="'overtime-hours-' + overtime.id " class="uk-text-center">{{ overtime.hours }}</td>
            <td class="uk-text-right">
              <div
                class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
                v-if="overtime.paid === 0"
              >
                <button
                  :dusk="'edit-' + overtime.id"
                  class="uk-button uk-width-1-2 uk-button-primary"
                  href="#edit-overtime-modal"
                  uk-toggle
                  @click="setEditMode(overtime, index)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-overtime')"
                ></button>
                <button
                  :dusk="'delete-' + overtime.id"
                  class="uk-button uk-width-1-2 uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(overtime, index)"
                  uk-icon="trash"
                  v-if="checkPermission('delete-overtime')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="bottom-divider uk-hidden@s"
        v-for="(overtime, index) in overtimes.data"
        :key="overtime.id"
      >
        <div class="uk-flex uk-flex-between">
          <div>
            <a
              :id="'overtime-'+ overtime.id"
              :href="'/directory/general/' + overtime.insured_business.insured.id.toString()"
              class="uk-text-bold uk-link-reset"
            >{{ overtime.insured_business.insured.first_name }} {{ overtime.insured_business.insured.last_name }}</a>
          </div>
          <div class="text-light">{{ overtime.hours }} hora{{ overtime.hours === 1 ? '' : 's'}}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div class="uk-text-bold">{{ overtime.reason.name }}</div>
          <div>{{ formatDate(overtime.application_date) }}</div>
        </div>
        <div class="text-light">
          <span>{{ overtime.notes }}</span>
        </div>
        <div>
          <span class="uk-text-bold">Multiplicador de pago:</span>
          <span>{{ overtime.payment_multiplier }}</span>
        </div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
          v-if="overtime.paid === 0"
        >
          <button
            :dusk="'responsive-edit-' + overtime.id"
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-overtime-modal"
            uk-toggle
            @click="setEditMode(overtime, index)"
            uk-icon="pencil"
            v-if="checkPermission('edit-overtime')"
          ></button>
          <a
            v-if="overtime.file"
            class="uk-button uk-width-1-2 uk-button-primary"
            :href="overtime.file.path"
            target="_blank"
            uk-icon="cloud-download"
          ></a>
          <button
            :dusk="'responsive-delete-' + overtime.id"
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(overtime, index)"
            uk-icon="trash"
            v-if="checkPermission('delete-overtime')"
          ></button>
        </div>
      </div>

      <div
        v-if="overtimes.data && overtimes.data.length === 0"
        class="uk-text-center uk-margin-top"
      >No se han encontrado resultados con los filtros seleccionados.</div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span>¿Está seguro que desea eliminar la horas extra {{ this.model.name }}?</span>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            dusk="delete-confirm"
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteOvertime"
            :disabled="isLoading"
          >Eliminar horas extra</button>
        </div>
      </div>
    </div>

    <div id="edit-overtime-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          horas extra
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Colaborador*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <v-select
                id="insured-overtime-select"
                v-model="model.insured_business_id"
                class="uk-input uk-border-rounded"
                :options="payload.insureds"
                :reduce="insured => insured.id"
                :getOptionLabel="option => option.insured.dpi + ' - ' + option.insured.first_name + ' ' + option.insured.last_name"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Razón*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select
                name="overtime-reason"
                class="uk-select uk-border-rounded"
                v-model="model.overtime_reason_id"
              >
                <option
                  v-for="reason in payload.reasons"
                  :key="reason.id"
                  :value="reason.id"
                >{{ reason.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Constancia</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1" uk-form-custom="target: true">
              <input
                name="overtime-file"
                type="file"
                @change="handleFileChange($event.target.files[0])"
              />
              <input
                class="uk-input uk-border-rounded"
                type="text"
                disabled
                v-model="model.filename"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Notas</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <textarea
                name="overtime-notes"
                v-model="model.notes"
                rows="5"
                class="uk-textarea uk-border-rounded"
                type="text"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Fecha de aplicación*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <flat-pickr
                id="overtime-application-date"
                class="uk-input uk-border-rounded"
                v-model="model.application_date"
                :config="datepickerConfig"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Multiplicador de pago*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select
                name="overtime-payment"
                class="uk-select uk-border-rounded"
                v-model="model.payment_multiplier"
              >
                <option>1</option>
                <option selected>1.5</option>
                <option>2</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label
            class="uk-form-label"
            for="form-stacked-text"
          >Duración en horas (puedes ingresar decimales)*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                name="overtime-hours"
                v-model="model.hours"
                class="uk-input uk-border-rounded"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label
            class="uk-form-label"
            for="form-stacked-text"
          >Duración en minutos (puedes ingresar decimales)*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                name="overtime-minutes"
                v-model="model.minutes"
                class="uk-input uk-border-rounded"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            dusk="create"
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !overtimeIsValid"
            v-if="mode === 'new'"
          >
            <span v-if="isLoading" uk-spinner="ratio: 1"></span>
            <span v-else>Crear horas extra</span>
          </button>
          <button
            dusk="update"
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading"
            v-else
          >
            <span v-if="isLoading" uk-spinner="ratio: 1"></span>
            <span v-else>Actualizar horas extra</span>
          </button>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <a
            class="uk-button uk-border-rounded uk-button-secondary"
            href="/directory/overtimes/template"
            target="”_blank”"
          >Descargar plantilla de importación</a>
        </div>
        <div class="uk-margin uk-text-center" v-if="checkPermission('import-overtimes')">
          <div uk-form-custom>
            <form autocomplete="off">
              <input
                id="import-file"
                autocomplete="off"
                name="file"
                type="file"
                @change="handleFile($event)"
              />
            </form>
            <button
              class="uk-button uk-border-rounded uk-button-secondary"
              :disabled="isFetching || isLoading"
            >
              <span v-if="isFetching || isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Importar</span>
            </button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center" v-if="checkPermission('export-overtimes')">
          <a
            href="/directory/overtimes/bulk"
            class="uk-button uk-border-rounded uk-button-secondary"
            target="_blank"
          >Exportar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import xlsx from "xlsx";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import moment from "moment";

export default {
  name: "DirectoryOvertime",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        insured_business_id: "",
        overtime_reason_id: "",
        notes: "",
        file: "",
        filename: "",
        application_date: "",
        payment_multiplier: "",
        hours: "",
        minutes: ""
      },
      overtimesFilterFrom: this.payload.overtimesFilterFrom,
      overtimesFilterTo: this.payload.overtimesFilterTo,
      itemsPerPage: 20,
      activeOvertimeId: "",
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      mode: "new",
      overtimeFilter: "",
      overtimes: {},
      overtimeFilterTimer: null,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  computed: {
    insuredIsValid() {
      return this.model.insured_business_id !== "";
    },
    reasonIsValid() {
      return this.model.overtime_reason_id !== "";
    },
    applicationDateIsValid() {
      return this.model.application_date !== "";
    },
    paymentMultiplierIsValid() {
      return this.model.payment_multiplier !== "";
    },
    hoursIsValid() {
      return this.model.hours !== "";
    },
    minutesIsValid() {
      return this.model.minutes !== "";
    },
    durationIsValid() {
      return this.hoursIsValid || this.minutesIsValid;
    },
    overtimeIsValid() {
      const validations = [
        this.insuredIsValid,
        this.reasonIsValid,
        this.applicationDateIsValid,
        this.paymentMultiplierIsValid,
        this.durationIsValid
      ];

      return validations.every(validation => validation);
    }
  },

  mounted() {
    this.overtimes = this.payload.overtimes;
  },

  methods: {
    handleFileChange(file) {
      this.model.file = file;
      this.model.filename = file.name;
    },
    setNewMode() {
      this.mode = "new";
      this.model.insured_business_id = "";
      this.model.overtime_reason_id = "";
      this.model.notes = "";
      this.model.application_date = "";
      this.model.payment_multiplier = 1.5;
      this.model.hours = "";
      this.model.minutes = "";
    },
    setEditMode(overtime, index) {
      this.mode = "edit";
      this.activeOvertimeId = overtime.id;
      this.payloadPosition = index;
      this.model.insured_business_id = overtime.insured_business_id;
      this.model.overtime_reason_id = overtime.overtime_reason_id;
      this.model.notes = overtime.notes;
      this.model.application_date = overtime.application_date;
      this.model.payment_multiplier = overtime.payment_multiplier;
      this.model.hours = overtime.hours;
      this.model.minutes = "";
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.overtimeFilter && !url.includes("&query")) {
        url = url + "&query=" + this.overtimeFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      url = url + "&from=" + this.overtimesFilterFrom;
      url = url + "&to=" + this.overtimesFilterTo;
      axios
        .get(url)
        .then(({ data: { overtimes } }) => {
          this.overtimes = overtimes;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.overtimeFilterTimer) {
        clearTimeout(this.overtimeFilterTimer);
      }
      this.overtimeFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage +
            "&from=" +
            this.overtimesFilterFrom +
            "&to=" +
            this.overtimesFilterTo
        );
        this.overtimeFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.overtimes.current_page.toString() +
            "&items=" +
            this.itemsPerPage +
            "&from=" +
            this.overtimesFilterFrom +
            "&to=" +
            this.overtimesFilterTo
        )
        .then(({ data: { overtimes } }) => {
          this.overtimes = overtimes;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando horas extra...",
        { status: "primary", timeout: 0 }
      );
      let formData = new FormData();
      let { minutes, ...transformedModel } = this.model;
      transformedModel = {
        ...transformedModel,
        hours:
          (this.model.hours !== "" ? parseFloat(this.model.hours) : 0) +
          (this.model.minutes !== "" ? parseFloat(this.model.minutes) : 0) / 60
      };
      for (const key in transformedModel) {
        if (transformedModel[key] !== "") {
          if (typeof transformedModel[key] === "boolean") {
            formData.append(key, transformedModel[key] === true ? 1 : 0);
          } else {
            formData.append(key, transformedModel[key]);
          }
        }
      }
      axios
        .post("/directory/overtimes", formData)
        .then(({ data: { model } }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-overtime-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='success' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Horas extras asignadas</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando horas extra...",
        { status: "primary", timeout: 0 }
      );
      let formData = new FormData();
      let { minutes, ...transformedModel } = this.model;
      transformedModel = {
        ...transformedModel,
        hours:
          (this.model.hours !== "" ? parseFloat(this.model.hours) : 0) +
          (this.model.minutes !== "" ? parseFloat(this.model.minutes) : 0) / 60
      };
      for (const key in transformedModel) {
        if (transformedModel[key] !== "") {
          if (typeof transformedModel[key] === "boolean") {
            formData.append(key, transformedModel[key] === true ? 1 : 0);
          } else {
            formData.append(key, transformedModel[key]);
          }
        }
      }
      formData.append("_method", "PUT");
      axios
        .post(
          "/directory/overtimes/" + this.activeOvertimeId.toString(),
          formData
        )
        .then(({ data: { model } }) => {
          // this.overtimes.data[this.payloadPosition] = model
          this.refetchPage(() => {
            UIkit.modal("#edit-overtime-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='updated' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Horas extra actualizadas</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteOvertime() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando horas extra...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/directory/overtimes/" + this.activeOvertimeId.toString(),
          this.model
        )
        .then(({ response }) => {
          // this.overtimes.data.splice(this.payloadPosition, 1)
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Horas extra eliminadas</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleFile(e) {
      this.isLoading = true;
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        let newOvertimes = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .slice(2);

        newOvertimes = newOvertimes.map(overtime => {
          overtime.application_date = moment(overtime.application_date).format(
            "YYYY-MM-DD"
          );
          return overtime;
        });

        axios
          .post("/directory/overtimes/bulk", newOvertimes)
          .then(({ data }) => {
            this.refetchPage(() => {
              UIkit.modal("#import-export-modal").hide();
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  data.createdCount +
                  " Horas extra creadas, " +
                  data.updatedCount +
                  " actualizadas </div>",
                { status: "success", timeout: 3000 }
              );
              this.isLoading = false;
            });
          })
          .catch(error => {
            console.log("error", error);
            this.isLoading = false;
          })
          .finally(() => {
            let importFileInput = document.getElementById("import-file");
            if (importFileInput) {
              importFileInput.value = "";
            }
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        insured_business_id: "",
        overtime_reason_id: "",
        notes: "",
        file: "",
        filename: "",
        application_date: "",
        payment_multiplier: "",
        hours: "",
        minutes: ""
      };
    }
  },

  watch: {
    overtimeFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    overtimesFilterFrom(value) {
      this.refetchPage(() => {});
    },
    overtimesFilterTo(value) {
      this.refetchPage(() => {});
    }
  }
};
</script>

<style>
.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}
</style>