<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Beneficios</div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input search-input-animation"
            type="search"
            placeholder="Buscar colaborador..."
            v-model="insuredFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="insureds.prev_page_url"
          :is-fetching="isFetching"
          :current-page="insureds.current_page"
          :last-page="insureds.last_page"
          :next-page-url="insureds.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th></th>
            <th>Nombre completo</th>
            <th>Planes</th>
            <th>Fecha de inicio</th>
            <th>Otros beneficios</th>
            <th>Detalle</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="insured in insureds.data" :key="insured.id">
            <td width="10%">
              <a :href="'/directory/general/' + insured.id.toString()" class="uk-link-reset">
                <div
                  v-if="insured.avatar"
                  class="rounded-avatar"
                  :style="{ 'background-image': 'url(' + insured.avatar + ')' }"
                ></div>
                <div
                  class="user-logo uk-border-circle uk-padding-small uk-text-center"
                  v-else
                >{{ insured.first_name.substr(0, 1) }} {{ insured.last_name.substr(0, 1) }}</div>
              </a>
            </td>
            <td>
              <a
                :href="'/directory/general/' + insured.id.toString()"
                class="uk-text-bold uk-link-reset"
              >{{ insured.first_name }} {{ insured.last_name }}</a>
            </td>
            <td>
              <div
                class="uk-card uk-card-primary uk-border-rounded uk-padding-small uk-margin"
                v-for="plan in insured.business_relation.business_plans"
                :key="plan.id"
              >
                <div v-if="plan.pending_changes.length !== 0">Cambios solicitados en revisión</div>
                <div class="uk-flex uk-flex-between">
                  <div>
                    <div class="uk-text-bold">{{ plan.plan.name }}</div>
                    <div v-if="plan.status === 0">Póliza pendiente de validación</div>
                    <div v-if="plan.status === 1">Póliza en proceso</div>
                    <div v-if="plan.status === 2">Póliza {{ plan.policy_number }}</div>
                  </div>
                  <div>
                    <img :data-src="plan.plan.insurer.logo" class="insurer-logo" uk-img />
                  </div>
                </div>
                <div v-if="plan.coverage_options.length !== 0">
                  Coberturas:
                  <ul class="uk-list uk-list-bullet">
                    <li
                      v-for="option in plan.coverage_options"
                      class="uk-margin-remove"
                      :key="option.id"
                    >
                      <span
                        v-if="option.coverage_option.coverage"
                      >{{ option.coverage_option.coverage.name }}:</span>
                      {{ option.coverage_option.description }}
                    </li>
                  </ul>
                </div>
                <div v-if="plan.dependents.length !== 0">
                  Dependientes:
                  <ul class="uk-list uk-list-bullet">
                    <li
                      v-for="(dependent, index) in plan.dependents"
                      :key="index"
                      class="uk-margin-remove"
                    >{{ dependent.first_name }} {{ dependent.last_name }} ({{ dependent.relationship }})</li>
                  </ul>
                </div>
                <div>Precio: {{ planPrice(plan) }}</div>
              </div>
            </td>
            <td>
              <span
                v-if="insured.business_relation"
              >{{ formatDate(insured.business_relation.start_date) }}</span>
            </td>
            <td></td>
            <td class="uk-text-right">
              <button
                class="uk-button uk-border-rounded uk-button-primary"
                href="#edit-insured-modal"
                uk-toggle
                @click="setEditMode(insured)"
                uk-icon="info"
              ></button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="bottom-divider uk-hidden@s" v-for="insured in insureds.data" :key="insured.id">
        <div class="uk-flex uk-flex-between">
          <div>
            <a :href="'/directory/general/' + insured.id.toString()" class="link-underline">
              <span class="uk-text-bold">{{ insured.first_name }} {{ insured.last_name }}</span>
            </a>
          </div>
          <div>
            <button
              class="uk-button uk-border-rounded uk-button-primary"
              href="#edit-insured-modal"
              uk-toggle
              @click="setEditMode(insured)"
              uk-icon="info"
            ></button>
          </div>
        </div>
        <div
          class="uk-card uk-card-primary uk-border-rounded uk-padding-small uk-margin"
          v-for="plan in insured.business_relation.business_plans"
          :key="plan.id"
        >
          <div class="uk-text-bold">{{ plan.plan.name }}</div>
          <div v-if="plan.pending_changes.length !== 0">Cambios solicitados en revisión</div>
          <div v-if="plan.status === 0">Póliza pendiente de validación</div>
          <div v-if="plan.status === 1">Póliza en proceso</div>
          <div v-if="plan.status === 2">Póliza {{ plan.policy_number }}</div>
          <div v-if="plan.coverage_options.length !== 0">
            Coberturas:
            <ul class="uk-list uk-list-bullet">
              <li v-for="option in plan.coverage_options" :key="option.id">
                <span
                  v-if="option.coverage_option.coverage"
                >{{ option.coverage_option.coverage.name }}:</span>
                {{ option.coverage_option.description }}
              </li>
            </ul>
          </div>
          <div v-if="plan.dependents.length !== 0">
            Dependientes:
            <ul class="uk-list uk-list-bullet">
              <li
                v-for="(dependent, index) in plan.dependents"
                :key="index"
              >{{ dependent.first_name }} {{ dependent.last_name }} ({{ dependent.relationship }})</li>
            </ul>
          </div>
          <div>Precio: {{ planPrice(plan) }}</div>
        </div>
      </div>
    </div>

    <div id="edit-insured-modal" class="uk-flex-top uk-modal-container" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <!-- This is the nav containing the toggling elements -->
        <ul
          id="insurances-modal-switcher"
          uk-switcher
          class="uk-hidden"
          swiping="false"
          animation="uk-animation-slide-right, uk-animation-slide-left"
        >
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
        </ul>

        <!-- This is the container of the content items -->
        <ul class="uk-switcher">
          <li>
            <div
              class="uk-text-large"
            >Seguros de {{ activeInsured.first_name }} {{ activeInsured.last_name }}</div>
            <div class="uk-flex uk-flex-wrap uk-flex-around">
              <div
                class="uk-card uk-card-default uk-border-rounded uk-padding-small uk-margin-top uk-width-2-5@s padding-for-buttons"
                v-for="plan in model.plans"
                :key="plan.id"
              >
                <!-- <div class="uk-text-right">
                <button type="button" uk-close></button>
                </div>-->
                <div class="uk-text-bold">{{ plan.plan.name }}</div>
                <div v-if="plan.status === 0">Póliza pendiente de validación</div>
                <div v-if="plan.status === 1">Póliza en proceso</div>
                <div v-if="plan.status === 2">Póliza {{ plan.policy_number }}</div>
                <div v-if="plan.coverage_options.length !== 0">
                  Coberturas:
                  <ul class="uk-list uk-list-bullet">
                    <li v-for="option in plan.coverage_options" :key="option.id">
                      <span
                        v-if="option.coverage_option.coverage"
                      >{{ option.coverage_option.coverage.name }}:</span>
                      {{ option.coverage_option.description }}
                    </li>
                  </ul>
                </div>
                <div v-if="plan.dependents.length !== 0">
                  Dependientes:
                  <ul class="uk-list uk-list-bullet">
                    <li
                      v-for="(dependent, index) in plan.dependents"
                      :key="index"
                    >{{ dependent.first_name }} {{ dependent.last_name }}</li>
                  </ul>
                </div>
                <div>Precio: {{ planPrice(plan) }}</div>
                <div
                  class="uk-card uk-card-primary uk-border-rounded uk-padding-small uk-margin-top"
                  v-for="(form, index) in plan.plan.forms"
                  :key="index"
                >
                  <div
                    v-if="form.filled && form.filled.status.id === 1"
                    class="requirement-status uk-border-circle background-gray"
                    uk-icon="icon: clock; ratio: 1.5;"
                    :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                  ></div>
                  <div
                    v-if="form.filled && form.filled.status.id === 2"
                    class="requirement-status uk-border-circle uk-background-secondary"
                    uk-icon="icon: check; ratio: 1.5;"
                    :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                  ></div>
                  <div
                    v-if="form.filled && form.filled.status.id === 3"
                    class="requirement-status uk-border-circle background-red"
                    uk-icon="icon: close; ratio: 1.5;"
                    :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                  ></div>
                  <div class="uk-text-center uk-text-large uk-text-default">{{ form.name }}</div>
                  <div v-if="form.filled">Estado: {{ form.filled.status.name }}</div>
                  <div class="uk-margin uk-text-center" v-if="form.filled">
                    <a class="uk-link-reset" :href="form.filled.file" target="_blank">
                      <button class="uk-button uk-border-rounded uk-button-secondary">
                        <span uk-icon="search"></span> Ver documento
                      </button>
                    </a>
                  </div>
                  <div class="uk-margin uk-text-center">
                    <div uk-form-custom>
                      <input
                        name="file"
                        type="file"
                        @change="handleFile($event.target.files[0], plan.id, form.id, index)"
                      />
                      <button
                        class="uk-button uk-border-rounded uk-button-secondary"
                        :disabled="updatingFile === form.id"
                      >
                        <span v-if="updatingFile === form.id" uk-spinner="ratio: 0.6"></span>
                        <span v-else>
                          <span uk-icon="cloud-upload"></span> Cargar documento
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="uk-margin uk-text-center">
                    <a class="uk-link-reset" :href="form.file" target="_blank">
                      <button class="uk-button uk-border-rounded uk-button-secondary">
                        <span uk-icon="cloud-download"></span> Descargar plantilla
                      </button>
                    </a>
                  </div>
                </div>
                <div class="uk-position-bottom-center">
                  <!-- <div class="uk-margin uk-text-center">
                  <a class="uk-link-reset" href="#" uk-switcher-item="2">
                    <button class="uk-button uk-border-rounded uk-button-secondary" @click="editPlan(plan)"><span uk-icon="pencil"></span> Modificar</button>
                  </a>
                  </div>-->
                  <div class="uk-margin uk-text-center">
                    <button
                      class="uk-button uk-border-rounded uk-button-danger"
                      @click="editPlan(plan)"
                      href="#down-confirmation-modal"
                      uk-toggle
                    >
                      <span uk-icon="close"></span> Solicitar baja del seguro
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="next">
                <button class="uk-button uk-border-rounded uk-button-secondary">
                  Agregar seguro
                  <span uk-icon="chevron-right"></span>
                </button>
              </a>
            </div>
          </li>
          <li>
            <div
              class="uk-text-large"
            >Seleccione un seguro para {{ activeInsured.first_name }} {{ activeInsured.last_name }}</div>
            <div class="uk-flex uk-flex-wrap uk-flex-around">
              <a
                class="uk-link-reset uk-margin-top uk-width-2-5@s"
                href="#"
                uk-switcher-item="next"
                v-for="plan in payload.plans"
                :key="plan.id"
                @click="setPlan(plan)"
              >
                <div class="uk-card uk-card-default uk-border-rounded uk-padding-small">
                  <div class="uk-text-bold uk-text-center">{{ plan.name }}</div>
                  <div class="uk-text-center uk-margin-bottom">{{ plan.description }}</div>
                  <div v-if="plan.insurer">Aseguradora: {{ plan.insurer.name }}</div>
                  <div>Precio desde: {{ toCurrency(plan.price) }}</div>
                </div>
              </a>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="previous">
                <button class="uk-button uk-border-rounded uk-button-muted">
                  <span uk-icon="chevron-left"></span>
                  Atrás
                </button>
              </a>
            </div>
          </li>
          <li>
            <div
              class="uk-text-large"
            >Seleccione coberturas para {{ activeInsured.first_name }} {{ activeInsured.last_name }}</div>
            <div class="uk-flex uk-flex-wrap uk-flex-around uk-margin-top">
              <div
                class="uk-card uk-card-default uk-border-rounded uk-padding-small uk-margin-top transition-width"
                :class="{ 'uk-width-1-1': selectingCoverage === coverage.id, 'uk-width-2-5@s': selectingCoverage !== coverage.id }"
                v-for="(coverage, index) in model.plan.coverages"
                :key="index"
              >
                <div
                  v-if="(coverage.selectedOption !== null) && !(coverage.required_coverage_id && model.plan.coverages[model.plan.coverageIds.indexOf(coverage.required_coverage_id)].selectedOption === null)"
                  class="coverage-check uk-border-circle uk-background-secondary"
                  uk-icon="icon: check; ratio: 1.5;"
                ></div>
                <div class="uk-text-center uk-text-large">{{ coverage.name }}</div>
                <div class="uk-margin-small-top">{{ coverage.description }}</div>
                <div
                  class="uk-margin"
                  v-if="coverage.required_coverage_id && model.plan.coverages[model.plan.coverageIds.indexOf(coverage.required_coverage_id)].selectedOption === null"
                >
                  Esta cobertura requiere
                  <span
                    class="uk-text-bold"
                  >{{ model.plan.coverages[model.plan.coverageIds.indexOf(coverage.required_coverage_id)].name }}</span>
                </div>
                <div v-else>
                  <div v-if="coverage.optionsById[model.plan.coverages[index].selectedOption]">
                    <div class="uk-margin">
                      <div
                        class="uk-text-large uk-text-center uk-text-bold"
                      >{{ coverage.optionsById[model.plan.coverages[index].selectedOption].description }}</div>
                      <div
                        class="uk-text-center"
                      >{{ toCurrency(coverage.optionsById[model.plan.coverages[index].selectedOption].price) }}</div>
                    </div>
                    <div
                      v-if="selectingCoverage !== coverage.id && coverage.optionsById[model.plan.coverages[index].selectedOption].addins.length !== 0"
                    >Opciones extras</div>
                    <div class="uk-margin" v-if="selectingCoverage !== coverage.id">
                      <div
                        v-for="addin in coverage.optionsById[model.plan.coverages[index].selectedOption].addins"
                        :key="addin.id"
                      >
                        <label>
                          <input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="coverage.optionsById[model.plan.coverages[index].selectedOption].selectedAddins[addin.id]"
                          />
                          <span class="uk-text-bold">{{ addin.description }}</span>
                          ({{ toCurrency(addin.price) }})
                          <div v-if="addin.detail" class="uk-margin-left">
                            <div
                              v-for="(detail, index) in JSON.parse(addin.detail)"
                              :key="index"
                            >{{ detail[0] }}: {{ detail[1]}}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="uk-text-large uk-text-center uk-text-bold">No agregar</div>
                  </div>
                  <div class="uk-margin uk-text-center">
                    <button
                      class="uk-button uk-border-rounded uk-button-secondary"
                      @click="selectingCoverage = coverage.id"
                      :disabled="selectingCoverage !== ''"
                    >Cambiar</button>
                  </div>
                  <div
                    v-if="selectingCoverage === coverage.id"
                    class="table-container uk-overflow-auto"
                  >
                    <table
                      class="uk-table uk-table-hover uk-table-divider uk-table-middle table-fixed"
                    >
                      <thead class="table-header">
                        <tr>
                          <th class="table-description">
                            <div class="uk-margin uk-text-center" v-if="coverage.mandatory === 0">
                              <button
                                class="uk-button uk-border-rounded uk-button-secondary"
                                @click="model.plan.coverages[index].selectedOption = null; selectingCoverage = ''"
                              >No agregar</button>
                            </div>
                          </th>
                          <th
                            v-for="option in coverage.options"
                            :key="option.id"
                            class="table-value"
                          >
                            <div class="uk-text-center uk-text-bold">{{ option.description }}</div>
                            <div class="uk-text-center">{{ toCurrency(option.price) }}</div>
                            <div class="uk-margin uk-text-center">
                              <button
                                class="uk-button uk-border-rounded uk-button-secondary"
                                @click="model.plan.coverages[index].selectedOption = option.id; selectingCoverage = ''"
                              >Seleccionar</button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="coverage.options[0].detail">
                        <tr
                          v-for="(description, detailIndex) in JSON.parse(coverage.options[0].detail)"
                          :key="detailIndex"
                        >
                          <td class="table-description uk-text-bold">{{ description[0] }}</td>
                          <td
                            class="table-value uk-text-center"
                            v-for="option in coverage.options"
                            :key="option.id"
                            v-if="option.detail"
                          >{{ JSON.parse(option.detail)[detailIndex][1] }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-margin-small-top">
              <div v-for="(document, index) in model.plan.documents" :key="index">
                <a class="uk-link-reset" :href="document.file" target="_blank">{{ document.name }}</a>
              </div>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" :uk-switcher-item="askForDependents ? 'next' : 4">
                <button class="uk-button uk-border-rounded uk-button-secondary">
                  Continuar
                  <span uk-icon="chevron-right"></span>
                </button>
              </a>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" :uk-switcher-item="mode === 'new' ? 'previous' : 0">
                <button class="uk-button uk-border-rounded uk-button-muted">
                  <span uk-icon="chevron-left"></span>
                  Atrás
                </button>
              </a>
            </div>
          </li>
          <li>
            <div
              class="uk-text-large"
            >Agrega dependientes de {{ activeInsured.first_name }} {{ activeInsured.last_name }}</div>
            <div
              v-for="(dependent, index) in model.plan.dependents"
              :key="index"
              class="uk-card uk-card-primary uk-padding-small uk-border-rounded uk-margin"
            >
              <div class="uk-text-right uk-margin-bottom">
                <button type="button" uk-close @click="removeDependent(index)"></button>
              </div>
              <div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  placeholder="Nombres"
                  v-model="dependent.first_name"
                />
              </div>
              <div class="uk-margin-top">
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  placeholder="Apellidos"
                  v-model="dependent.last_name"
                />
              </div>
              <div class="uk-margin-top">
                <flat-pickr
                  class="uk-input uk-border-rounded"
                  v-model="dependent.birthdate"
                  :config="datepickerConfig"
                  placeholder="Fecha de nacimiento"
                ></flat-pickr>
              </div>
              <div class="uk-margin-top">
                <select
                  class="uk-select uk-border-rounded"
                  v-model="dependent.relationship"
                  placeholder="Parentesco"
                  required
                >
                  <option value disabled selected hidden>Parentesco</option>
                  <option v-if="activeInsured.civil_state === 'M'">Cónyuge</option>
                  <option>Hijo/a</option>
                  <option v-if="activeInsured.civil_state === 'S'">Padre</option>
                  <option v-if="activeInsured.civil_state === 'S'">Madre</option>
                </select>
              </div>
            </div>
            <div class="uk-margin uk-text-center">
              <button
                class="uk-button uk-border-rounded uk-button-primary"
                uk-icon="plus"
                @click="addDependent"
              ></button>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="next">
                <button class="uk-button uk-border-rounded uk-button-secondary">
                  Continuar
                  <span uk-icon="chevron-right"></span>
                </button>
              </a>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="previous">
                <button class="uk-button uk-border-rounded uk-button-muted">
                  <span uk-icon="chevron-left"></span>
                  Atrás
                </button>
              </a>
            </div>
          </li>
          <li>
            <div v-if="mode === 'new'">
              <div
                class="uk-text-large"
              >Confirma que deseas agregar a {{ activeInsured.first_name }} {{ activeInsured.last_name }} al plan {{ this.model.plan.name }}</div>
              <div class="uk-margin uk-text-center">
                <button
                  class="uk-button uk-border-rounded uk-button-secondary"
                  @click="requestInsurance"
                  :disabled="isLoading"
                >
                  <span uk-icon="check"></span>
                  Enviar solicitud
                </button>
              </div>
            </div>
            <div v-if="mode === 'edit'">
              <div
                class="uk-text-large"
              >Confirma que deseas modificar el plan {{ this.model.plan.name }} de {{ activeInsured.first_name }} {{ activeInsured.last_name }}</div>
              <div class="uk-margin uk-text-center">
                <button
                  class="uk-button uk-border-rounded uk-button-secondary"
                  @click="updateInsurance"
                  :disabled="isLoading"
                >
                  <span uk-icon="check"></span>
                  Modificar plan
                </button>
              </div>
            </div>
            <div class="uk-margin uk-text-center">
              <a
                class="uk-link-reset"
                href="#"
                :uk-switcher-item="askForDependents ? 'previous' : 2"
              >
                <button class="uk-button uk-border-rounded uk-button-muted">
                  <span uk-icon="chevron-left"></span>
                  Atrás
                </button>
              </a>
            </div>
          </li>
          <li>
            Para finalizar, llena los siguientes documentos. Estos seguirán disponibles en el detalle de planes del colaborador.
            <div class="uk-flex uk-flex-wrap uk-flex-around">
              <div
                class="uk-card uk-card-secondary uk-border-rounded uk-padding-small uk-margin-top uk-width-2-5@s"
                v-for="form in model.plan.forms"
                :key="form.id"
              >
                <div
                  v-if="form.filled && form.filled.status.id === 1"
                  class="requirement-status uk-border-circle background-gray"
                  uk-icon="icon: clock; ratio: 1.5;"
                  :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                ></div>
                <div
                  v-if="form.filled && form.filled.status.id === 2"
                  class="requirement-status uk-border-circle uk-background-secondary"
                  uk-icon="icon: check; ratio: 1.5;"
                  :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                ></div>
                <div
                  v-if="form.filled && form.filled.status.id === 3"
                  class="requirement-status uk-border-circle background-red"
                  uk-icon="icon: close; ratio: 1.5;"
                  :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                ></div>
                <div class="uk-text-center uk-text-large">{{ form.name }}</div>
                <div v-if="form.filled">Estado: {{ form.filled.status.name }}</div>
                <div class="uk-margin uk-text-center" v-if="form.filled">
                  <a class="uk-link-reset" :href="form.filled.file" target="_blank">
                    <button class="uk-button uk-border-rounded uk-button-secondary">
                      <span uk-icon="search"></span> Ver documento
                    </button>
                  </a>
                </div>
                <div class="uk-margin uk-text-center">
                  <div uk-form-custom>
                    <input
                      name="file"
                      type="file"
                      @change="handleFile($event.target.files[0], form.id, index)"
                    />
                    <button
                      class="uk-button uk-border-rounded uk-button-secondary"
                      :disabled="updatingFile === form.id"
                    >
                      <span v-if="updatingFile === form.id" uk-spinner="ratio: 0.6"></span>
                      <span v-else>
                        <span uk-icon="cloud-upload"></span> Cargar documento
                      </span>
                    </button>
                  </div>
                </div>
                <div class="uk-margin uk-text-center">
                  <a class="uk-link-reset" :href="form.file" target="_blank">
                    <button class="uk-button uk-border-rounded uk-button-secondary">
                      <span uk-icon="cloud-download"></span> Descargar plantilla
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div id="down-confirmation-modal" class="uk-flex-top" uk-modal="bg-close: false" stack>
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <div>¿Estás seguro que deseas solicitar la baja del plan {{ model.plan.name }} de {{ this.activeInsured.first_name }} {{ this.activeInsured.last_name }} ?</div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteInsurance(model.plan)"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner="ratio: 1"></span>
            <span v-else>
              <span uk-icon="close"></span> Confirmar baja
            </span>
          </button>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-muted uk-modal-close"
            :disabled="isLoading"
          >Cancelar</button>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-border-rounded uk-button-secondary">Exportar</button>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-border-rounded uk-button-secondary">Importar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default {
  name: "BenefitsGeneral",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      selectingCoverage: "",
      plansById: {},
      model: {
        plans: [],
        plan: ""
      },
      itemsPerPage: 20,
      activeInsured: "",
      updatingFile: null,
      isLoading: false,
      mode: "new",
      insureds: {},
      isFetching: false,
      insuredFilter: "",
      insuredFilterTimer: null,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  computed: {
    askForDependents: function() {
      return this.model.plan.allows_dependents === 1 ||
        this.model.plan.coverages
        ? this.model.plan.coverages
            .map(coverage =>
              coverage.selectedOption
                ? coverage.optionsById[coverage.selectedOption]
                    .allows_dependents
                : 0
            )
            .reduce((accum, value) => accum + value, 0) > 0
        : false;
    }
  },

  methods: {
    moment,
    planPrice(plan) {
      let price = plan.plan.price;
      plan.coverage_options.map(option => {
        price = price + option.coverage_option.price;
        option.coverage_option.addins.map(addin => {
          price = price + addin.price;
        });
      });
      return this.toCurrency(price);
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.insuredFilter && !url.includes("&query")) {
        url = url + "&query=" + this.insuredFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { insureds } }) => {
          this.insureds = insureds;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.insuredFilterTimer) {
        clearTimeout(this.insuredFilterTimer);
      }
      this.insuredFilterTimer = setTimeout(() => {
        let urlSearchString = new URL(window.location.href).search;

        this.fetchPage(
          window.location.pathname +
            urlSearchString +
            (urlSearchString == "" ? "?" : "&") +
            "dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.insuredFilterTimer = null;
      }, 500);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.insureds.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { insureds } }) => {
          this.insureds = insureds;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    handleFile(file, planId, formId, formIndex) {
      this.updatingFile = formId;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("plan_id", planId);
      formData.append("form_id", formId);
      axios
        .post("/benefits/general/form", formData)
        .then(({ data: { model } }) => {
          this.updatingFile = null;
          // this.payload.requirements[formIndex] = model
        })
        .catch(error => {
          console.log("error", error);
          this.updatingFile = null;
        });
    },
    setPlan(plan) {
      this.model.plan = {
        ...plan,
        coverages: plan.related_coverages.map(coverage => ({
          ...coverage,
          selectedOption: coverage.options[0].id,
          optionsById: coverage.options.reduce((accum, option) => {
            accum[option.id] = {
              ...option,
              selectedAddins: option.addins.reduce((accum, addin) => {
                accum[addin.id] = false;
                return accum;
              }, {})
            };
            return accum;
          }, {})
        })),
        coverageIds: plan.related_coverages.map(coverage => coverage.id),
        dependents: []
      };
      this.selectingCoverage = "";
      this.mode = "new";
    },
    editPlan(plan) {
      // const selectedOptions = plan.coverage_options.reduce((accum, coverageOption) => {
      //   console.log('coverageOption', coverageOption.coverage_option.coverage.id)
      //   accum[coverageOption.coverage_option.coverage.id] = coverageOption.coverage_option_id
      //   return accum
      // }, {})
      this.model.plan = {
        ...this.plansById[plan.plan.id],
        // coverages: this.plansById[plan.plan.id].coverages.map(coverage => ({
        //   ...coverage,
        //   selectedOption: coverage.id in selectedOptions ? selectedOptions[coverage.id] : null,
        //   optionsById: coverage.options.reduce((accum, option) => {
        //     accum[option.id] = option
        //     return accum
        //   }, {})
        // })),
        dependents: plan.dependents.map(dependent => dependent),
        insured_plan_id: plan.pivot.insured_plan_id,
        insured_business_id: plan.pivot.insured_business_id
      };
      this.selectingCoverage = "";
      this.mode = "edit";
    },
    addDependent() {
      this.model.plan.dependents.push({
        first_name: "",
        last_name: "",
        birthdate: "",
        relationship: ""
      });
    },
    removeDependent(index) {
      this.model.plan.dependents.splice(index, 1);
    },
    setEditMode(insured) {
      this.resetForm();
      UIkit.switcher("#insurances-modal-switcher").show(0);
      this.activeInsured = insured;
      this.model.plans = insured.business_relation.business_plans;
    },
    prevPage() {
      this.currentPage = this.currentPage - 1;
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
    },
    requestInsurance() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Enviando solicitud...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/benefits/general", {
          plan_id: this.model.plan.id,
          insured_id: this.activeInsured.id,
          dependents: this.model.plan.dependents,
          coverages: [
            ...this.model.plan.coverages.map(
              coverage => coverage.selectedOption
            ),
            ...this.model.plan.coverages.map(coverage => {
              return Object.keys(
                coverage.optionsById[coverage.selectedOption].selectedAddins
              ).filter(
                addinId =>
                  coverage.optionsById[coverage.selectedOption].selectedAddins[
                    addinId
                  ] === true
              );
            })
          ].flat()
        })
        .then(({ data }) => {
          UIkit.switcher("#insurances-modal-switcher").show(5);
          loader.close();
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Solicitud enviada</div>",
            { status: "success", timeout: 3000 }
          );
          this.isLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    updateInsurance() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Enviando solicitud de modificación...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/benefits/general", {
          plan_id: this.model.plan.id,
          insured_id: this.activeInsured.id,
          insured_plan_id: this.model.plan.insured_plan_id,
          insured_business_id: this.model.plan.insured_business_id,
          dependents: this.model.plan.dependents,
          coverages: this.model.plan.coverages.map(
            coverage => coverage.selectedOption
          )
        })
        .then(({ data: { model } }) => {
          UIkit.modal("#edit-insured-modal").hide();
          loader.close();
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Solicitud enviada</div>",
            { status: "success", timeout: 4000 }
          );
          this.isLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteInsurance(plan) {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Enviando solicitud de baja...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete("/benefits/general", {
          data: {
            insured_plan_id: plan.insured_plan_id,
            insured_business_id: plan.insured_business_id
          }
        })
        .then(({ data: { model } }) => {
          loader.close();
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Solicitud enviada</div>",
            { status: "success", timeout: 4000 }
          );
          this.isLoading = false;
          UIkit.modal("#down-confirmation-modal").hide();
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    resetForm() {
      this.model = {
        plans: [],
        plan: ""
      };
    }
  },

  mounted() {
    this.insureds = this.payload.insureds;
    this.plansById = this.payload.plans.reduce((accum, plan) => {
      accum[plan.id] = plan;
      return accum;
    }, {});
  },

  watch: {
    insuredFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.padding-for-buttons {
  padding-bottom: 70px !important;
}

.rounded-avatar {
  display: block;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;
}

.user-logo {
  color: white;
  background: #2c5fa1;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  letter-spacing: -3px;
}

.transition-width {
  transition: width 0.4s;
}

.table-container {
  max-height: 500px;
}

.table-header {
  position: sticky;
  top: 0;
  background: #f8f8f8;
}

.table-fixed {
  table-layout: fixed;
}

.table-description {
  width: 250px;
}

.table-value {
  width: 100px;
}

.avatar {
  width: 80px;
  max-width: 80px;
}

.coverage-check {
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 10px;
}

.bottom-divider {
  border-bottom: solid 1px;
  padding: 10px 0;
}

.insurer-logo {
  height: 40px;
}
</style>
