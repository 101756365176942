<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Series</div>
      <div>
        <!-- <button class="uk-button uk-border-rounded uk-button-primary" href="#import-export-modal" uk-toggle>Importar / Exportar</button> -->
        <button
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-serie-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-series')"
        >
          Añadir serie
        </button>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-padding-small uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input"
            type="search"
            placeholder="Buscar serie..."
            v-model="serieFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="series.prev_page_url"
          :is-fetching="isFetching"
          :current-page="series.current_page"
          :last-page="series.last_page"
          :next-page-url="series.next_page_url"
        ></pagination-buttons>
      </div>
    </div>
    <div class="uk-margin-small-top uk-child-width-1-2@s" uk-grid>
      <div v-for="serie in series.data" :key="serie.id">
        <div class="uk-card uk-card-default uk-padding-small uk-border-rounded">
          <div class="uk-text-large uk-text-center">{{ serie.name }}</div>
          <latex
            :postfix="serie.postfix"
            :tokens="payload.snapshotables"
          ></latex>

          <div class="uk-text-center uk-margin-top">
            <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
              <button
                :id="'edit-insured-' + serie.id"
                :dusk="'edit-serie-' + serie.id"
                class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                href="#edit-serie-modal"
                uk-toggle
                @click="setEditMode(serie)"
                uk-icon="pencil"
                v-if="checkPermission('edit-series')"
              ></button>
              <button
                :id="'delete-serie-' + serie.id"
                :dusk="'delete-serie-' + serie.id"
                class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                href="#confirm-deletion-modal"
                uk-toggle
                @click="setEditMode(serie)"
                uk-icon="minus-circle"
                v-if="checkPermission('delete-series')"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="confirm-deletion-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span
            >¿Está seguro que desea eliminar el serie
            {{ this.model.name }}?</span
          >
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteReportAccount"
            :disabled="isLoading"
          >
            Eliminar serie
          </button>
        </div>
      </div>
    </div>

    <div
      id="edit-serie-modal"
      class="uk-flex-top uk-modal-container"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          serie
        </div>
        <div class="uk-margin">
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text"
              >Nombre de la serie</label
            >
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="model.name"
                />
              </div>
            </div>
          </div>
          <formula-editor
            ref="formula-editor"
            v-model="model.postfix"
            :initialValue="activeSerie ? activeSerie.postfix : null"
            :tokens="payload.snapshotables"
          ></formula-editor>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !serieIsValid"
            v-if="mode === 'new'"
          >
            Crear serie
          </button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !serieIsValid"
            v-else
          >
            Actualizar serie
          </button>
        </div>
      </div>
    </div>

    <div
      id="import-export-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <div uk-form-custom>
            <input name="file" type="file" @change="handleFile($event)" />
            <button class="uk-button uk-border-rounded uk-button-secondary">
              Importar
            </button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-border-rounded uk-button-secondary">
            Exportar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import xlsx from "xlsx";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import moment from "moment";

export default {
  name: "ReportsSeries",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        name: null,
        postfix: null
      },
      itemsPerPage: 20,
      activeSerie: null,
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      mode: "new",
      serieFilter: "",
      series: this.payload.series,
      serieFilterTimer: null,
      rangeDatepickerConfig: {
        mode: "range",
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  computed: {
    serieNameIsValid() {
      return this.model.name && this.model.name.length > 1;
    },
    serieIsValid() {
      const validations = [
        this.serieNameIsValid,
        this.$refs["formula-editor"]
          ? this.$refs["formula-editor"]["formulaIsValid"]
          : false
      ];
      return validations.every(validation => validation);
    }
  },

  methods: {
    updateRange(range, serie) {
      serie.range = range.map(date =>
        moment(date)
          .toISOString()
          .substr(0, 10)
      );
    },
    setNewMode() {
      this.mode = "new";
      this.resetForm();
      this.activeSerie = null;
      this.$nextTick(() => {
        this.$refs["formula-editor"].parseInitialValue();
      });
    },
    setEditMode(serie, index) {
      this.mode = "edit";
      this.activeSerie = serie;
      this.payloadPosition = index;
      this.model.name = serie.name;
      this.model.postfix = serie.postfix;
      this.$nextTick(() => {
        this.$refs["formula-editor"].parseInitialValue();
      });
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.serieFilter && !url.includes("&query")) {
        url = url + "&query=" + this.serieFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { series } }) => {
          this.series = series;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.serieFilterTimer) {
        clearTimeout(this.serieFilterTimer);
      }
      this.serieFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.serieFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.series.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { series } }) => {
          this.series = series;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando serie...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/reports/series", this.model)
        .then(({ data: { model } }) => {
          // this.series.data.push(model)
          this.refetchPage(() => {
            UIkit.modal("#edit-serie-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Serie creada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando serie...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/reports/series/" + this.activeSerie.id.toString(), this.model)
        .then(({ data: { model } }) => {
          // this.series.data[this.payloadPosition] = model
          this.refetchPage(() => {
            UIkit.modal("#edit-serie-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Serie actualizada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteReportAccount() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando serie...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete("/reports/series/" + this.activeSerie.id.toString(), this.model)
        .then(({ response }) => {
          // this.series.data.splice(this.payloadPosition, 1)
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Serie eliminada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleFile(e) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        const newAccounts = xlsx.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 2 }
        );

        axios
          .post("/reports/series/bulk", newAccounts)
          .then(({ data }) => {
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                data.count +
                " Series creadas</div>",
              { status: "success", timeout: 3000 }
            );
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        name: null,
        postfix: []
      };
    }
  },

  watch: {
    serieFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}
</style>