<template>
  <div>
    <div
      id="top"
      uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; show-on-up: true; animation: uk-animation-slide-top"
    >
      <nav class="uk-width-1-1 uk-flex-column uk-box-shadow-medium" uk-navbar>
        <div class="uk-navbar-container uk-flex">
          <div class="uk-navbar-left">
            <a
              id="navigation-menu"
              dusk="navigation-menu"
              class="uk-navbar-toggle"
              uk-navbar-toggle-icon
              href="#offcanvas"
              uk-toggle
            ></a>
          </div>
          <a href="/" class="uk-navbar-item uk-logo vertical-divider">
            <img src="/images/logo.png" width="100px" alt />
          </a>
          <div class="uk-logo uk-text-middle">{{ currentSectionName }}</div>
          <div class="uk-navbar-right uk-visible@s uk-padding-small">
            <a
              class="uk-button uk-padding-remove uk-link-reset"
              href="/auth/select-business"
              v-if="businessesCount !== 1"
            >
              <img
                class="business-avatar uk-border-rounded"
                v-if="business.avatar"
                id="avatar"
                :src="business.avatar"
                alt
                width="50px"
                height="50px"
              />
              <div class="uk-padding-small" v-else>{{ business.commercial_name }}</div>
            </a>
            <div v-else>
              <button disabled class="uk-button uk-padding-remove uk-border-rounded">
                <img
                  class="business-avatar uk-border-rounded"
                  v-if="business.avatar"
                  id="avatar"
                  :src="business.avatar"
                  alt
                  width="50px"
                  height="50px"
                />
                <div class="uk-padding-small" v-else>{{ business.commercial_name }}</div>
              </button>
            </div>
          </div>
        </div>

        <div class="uk-navbar-container">
          <div class="uk-navbar-left">
            <ul class="uk-navbar-nav uk-overflow-auto">
              <li
                :class="{ 'uk-active': currentPath === subsection.url }"
                v-for="subsection in currentSubsections"
                :key="subsection.url"
              >
                <a :dusk="subsection.name" :href="subsection.url">{{ subsection.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="uk-container uk-padding-top uk-position-relative content">
      <slot name="content"></slot>
    </div>

    <div id="offcanvas" uk-offcanvas="mode: push; overlay: true">
      <div class="uk-offcanvas-bar">
        <div class="uk-panel">
          <ul class="uk-nav uk-nav-default tm-nav uk-margin-top">
            <!-- <li class="uk-nav-header">{{category}}</li> -->
            <!-- <li v-for="(p, label) in pages" exact><a :href="'./'+p">{{label}}</a></li> -->
            <div class="uk-text-center">
              <img
                v-if="business.avatar"
                id="avatar"
                :src="business.avatar"
                alt
                width="150px"
                height="150px"
              />
              <div v-else>{{ business.commercial_name }}</div>
            </div>
            <div class="user-separator uk-flex uk-flex-middle uk-margin-small-top">
              <div
                class="user-logo uk-border-circle uk-padding-small uk-text-center"
              >{{ payload.first_name.substr(0, 1) }}</div>
              <div class="uk-margin-small-left">{{ payload.first_name }} {{ payload.last_name }}</div>
            </div>
            <li
              class="sidebar-link-animation cursor-pointer uk-margin-top"
              :class="{ 'sidebar-active-link': currentSectionName === section.name }"
              v-for="(section, index) in permissions"
              :key="index"
              exact
              @click="redirectTo(section.url)"
              uk-toggle
              href="#offcanvas"
              :id="index"
            >{{ section.name }}</li>
            <li
              class="sidebar-link-animation cursor-pointer uk-margin-top"
              exact
              @click="redirectTo('/auth/select-business')"
              uk-toggle
              href="#offcanvas"
              v-if="businessesCount !== 1"
            >Cambiar de empresa</li>
            <li
              class="sidebar-link-animation cursor-pointer uk-margin-top"
              exact
              @click="redirectTo('/logout')"
              uk-toggle
              href="#offcanvas"
            >Cerrar sesión</li>
          </ul>
        </div>
      </div>
    </div>

    <div
      id="terms-modal"
      class="uk-flex-top uk-modal-container"
      uk-modal
      esc-close="false"
      bg-close="false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <div class="uk-text-large">
          Resumen de Términos y Condiciones de Uso y Políticas de Privacidad de
          <span
            class="uk-text-bold"
          >Qüilo</span>
        </div>

        <p>
          Bienvenido a la Aplicación Móvil o Página de Internet de
          <b>Qüilo</b>, en adelante (“El Sitio”, “El Sitio Web”, “El Titular”, “
          <b>Qüilo</b>” o “www.vivequilo.com”), constituido bajo las leyes guatemaltecas, cuya actividad principal es permitir a sus usuarios contratar el servicio de una póliza de seguros.
        </p>
        <p>
          Los términos "Usuario(s)", "Cliente(s)" “usted” y "su" se refieren a usted quien puede ser cualquier persona, jurídica o natural, que ingrese, utilice o se registre en
          <b>Qüilo</b>.
        </p>
        <p>Al ingresar al Sitio, el Cliente admite haber leído, entendido y acepta expresamente los Términos y Condiciones de Uso completos que se encuentran en el enlace al final de este resumen, llamados en adelante (“Términos y Condiciones”), y por consiguiente asumen la obligación de respetar su contenido, política de privacidad y todas las cláusulas de exención de responsabilidad que figuran en el mismo y los cuales están sujetos a cambios sin previo aviso.</p>

        <div class="term-title">A. SERVICIO(S)</div>
        <p>
          <b>Qüilo</b> actúa como un medio electrónico a través de nuestra página de internet, que permite a sus usuarios adquirir productos nuestros, como las pólizas de seguros, reintegros de dinero, cotizaciones, así como cualquier otro servicio/producto brindado o por brindarse.
        </p>
        <p>
          Para poder adquirir los productos dentro de
          <b>Qüilo</b> y poder adquirir productos ofrecidos por Vendedores o Publicitantes, los Usuarios deberán proveer una autorización expresa a
          <b>Qüilo</b>, a través de su página de internet, por cualquier medio que
          <b>Qüilo</b> estime pertinente.
        </p>

        <div class="term-title">B. COMUNICACIONES ELECTRÓNICAS</div>
        <p>
          Al aceptar estos Términos y Condiciones nos da su consentimiento para recibir comunicaciones de parte nuestra a través de cualquier medio que
          <b>Qüilo</b> estime pertinente. Asimismo, acepta recibir electrónicamente una factura por el monto de sus compras hechas en línea a través de
          <b>Qüilo</b>, que enviaremos a la cuenta de correo que registre en nuestro sistema.
        </p>

        <div
          class="term-title"
        >C. CREACIÓN Y VERIFICACIÓN DEL USUARIO PARA ADQUISICIÓN DEL PRODUCTO/ SERVICIO</div>
        <p>En el proceso de inscripción en el Sitio, se recopilará información de contacto para el contratante y el titular y dependientes, incluyendo, pero no limitándose a: nombre, número de identificación, fecha de nacimiento, dirección postal, número de teléfono, compañía que le brinda el servicio de telefonía, correo electrónico, números de tarjetas (crédito o débito), entre otros. El usuario se compromete a otorgar información veraz y queda bajo su responsabilidad el ingresar información incorrecta o falsa.</p>
        <p>
          <b>Qüilo</b> examinará la solicitud presentada por el Cliente y se reservará la facultad de verificar los datos comunicados por cada uno de los Usuarios. De constatarse una inconsistencia en la información del Cliente al momento de la inscripción o si omite notificar algún cambio,
          <b>Qüilo</b> podrá proceder con rechazar la solicitud del usuario y/o la cancelación del servicio y la terminación inmediata de la cuenta del Cliente en cuestión.
        </p>

        <div class="term-title">D. PRECIO Y CONDICIONES DE PAGO POR SERVICIO/PRODUCTOS</div>
        <p>El precio que se deberá pagar por cada producto/servicio adquirido será el precio vigente en la Página de Internet al momento de hacerse la compra y estipulado en cada póliza de seguro a contratar, los cuales podrían estar sujetos a cambios por promociones.</p>
        <p>
          Asimismo, el USUARIO acepta de manera expresa todas las condiciones de la pasarela de pago, así como: el cobro recurrente a su tarjeta de crédito o débito, la cancelación del servicio por falta de fondos (y por lo tanto de pago), y que
          <b>Qüilo</b> no será responsable por fallas o irregularidades de la pasarela de pago. Estas formas de pago están sujetas a cambios y modificaciones que surgirán sus efectos una vez sean comunicadas al USUARIO por cualquier medio que estime pertinente.
        </p>

        <div
          class="term-title"
        >E. POLÍTICAS DE PRIVACIDAD Y PROTECCION DE DATOS PERSONALES DE LOS USUARIOS</div>
        <p>
          <b>Qüilo</b> garantiza al Usuario la total confidencialidad de la información que el USUARIO le suministre, y que haya sido verificada como veraz. Así, como el utilizar la misma únicamente para cuestiones relacionadas con los productos o servicios brindados por
          <b>Qüilo</b> así como para sus procesos internos.
        </p>

        <div class="term-title">F. PROPIEDAD INTELECTUAL</div>
        <p>
          Todos los derechos sobre el Contenido de
          <b>Qüilo</b> y los servicios, incluyendo, sin carácter limitativo, el diseño, banners, formas, textos, cifras, gráficas, mapas, símbolos, marcas, lemas, nombres comerciales, enseñas comerciales, logotipos, íconos, imágenes, archivos de audio y video, y software (en general el Contenido) y demás elementos integradores de
          <b>Qüilo</b>, son de titularidad de
          <b>Qüilo</b> y/o de sus proveedores de Contenido.
        </p>
        <p>
          Todo el software utilizado en
          <b>Qüilo</b> es propiedad de
          <b>Qüilo</b> y/o de sus proveedores de software. El Cliente no podrá utilizar la información contenida en
          <b>Qüilo</b> con propósitos diferentes a los relacionados con el funcionamiento de
          <b>Qüilo</b>. Cualquier otro uso, incluyendo la reproducción, modificación, copia, publicación, distribución y/o transmisión, ya sea total o parcial, del Contenido de
          <b>Qüilo</b> está estrictamente prohibida.
        </p>
        <p>
          De igual forma, también está prohibido hacer directamente o incentivar, ayudar o autorizar a cualquier otra persona a realizar actos de ingeniería inversa, descompilar, desensamblar o de cualquier otra forma manipular el software de
          <b>Qüilo</b>.
        </p>

        <div class="term-title">G. ACUERDO INTEGRO</div>
        <p>
          Estos Términos y Condiciones constituyen el acuerdo íntegro entre
          <b>Qüilo</b> y el Cliente, y sustituye cualquier otro acuerdo o convenio celebrado con anterioridad. Cualquier cláusula o provisión de los Términos y Condiciones legalmente declarada inválida, será eliminada o modificada, a elección de
          <b>Qüilo</b>, a fin de corregir su vicio o defecto. Sin embargo, el resto de las cláusulas o provisiones mantendrán su fuerza, obligatoriedad y validez.
        </p>

        <p class="uk-text-center">
          Lee los
          <a
            class="tos-link"
            target="_blank"
            href="https://s3.us-east-2.amazonaws.com/quilohogar/TC+y+PP/Te%CC%81rminos+y+Condiciones+de+Uso+-+Politicas+de+Privacidad+-+Quilo.pdf"
          >Términos y Condiciones de Uso y Políticas de Privacidad</a> completos.
        </p>

        <div class="uk-text-center uk-margin-top">
          <div>
            <button
              id="accept-terms"
              dusk="accept-terms"
              class="uk-button uk-border-rounded uk-button-secondary"
              @click="acceptTerms"
              :disabled="termsIsLoading"
            >Aceptar los términos de uso</button>
          </div>
          <div>
            <a
              id="reject-terms"
              dusk="reject-terms"
              href="/logout"
              class="uk-button uk-border-rounded uk-button-muted"
            >Rechazar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

const extractPermissions = (modules, path) => {
  return modules.reduce((accum, module) => {
    if (module.url === path) {
      accum.push(...module.actions);
    }
    if (module.children.length !== 0) {
      accum.push(...extractPermissions(module.children, path));
    }
    return accum;
  }, []);
};

export default {
  name: "Navigation",

  props: ["payload", "business", "businesses-count", "permissions"],

  data() {
    return {
      termsIsLoading: false
      // navigation: {
      //   profile: {
      //     name: "Perfil de la empresa",
      //     path: "/profile/general",
      //     subsections: [
      //       {
      //         name: "General",
      //         path: "/profile/general"
      //       },
      //       // {
      //       //   name: 'Plan',
      //       //   path: '/profile/plan'
      //       // },
      //       // {
      //       //   name: 'Billing',
      //       //   path: '/profile/billing'
      //       // },
      //       {
      //         name: "Bancos",
      //         path: "/profile/banks"
      //       },
      //       {
      //         name: "Documentos",
      //         path: "/profile/documents"
      //       },
      //       // {
      //       //   name: 'Administradores',
      //       //   path: '/profile/admins'
      //       // },
      //       // {
      //       //   name: 'Permisos',
      //       //   path: '/profile/permissions'
      //       // },
      //       {
      //         name: "Historial",
      //         path: "/profile/audits"
      //       },
      //       {
      //         name: "Seguridad",
      //         path: "/profile/security"
      //       },
      //       {
      //         name: "Roles",
      //         path: "/profile/roles"
      //       },
      //       {
      //         name: "Usuarios",
      //         path: "/profile/users"
      //       },
      //       {
      //         name: "Sugerencias internas",
      //         path: "/profile/business-suggestions"
      //       }
      //     ]
      //   },
      //   directory: {
      //     name: "Directorio",
      //     path: "/directory/general",
      //     subsections: [
      //       {
      //         name: "General",
      //         path: "/directory/general"
      //       },
      //       // {
      //       //   name: 'Asistencia',
      //       //   path: '/directory/checks'
      //       // },
      //       {
      //         name: "Organigrama",
      //         path: "/directory/organization-chart"
      //       },
      //       {
      //         name: "Divisiones",
      //         path: "/directory/divisions"
      //       },
      //       {
      //         name: "Tags",
      //         path: "/directory/tags"
      //       },
      //       {
      //         name: "Ausencias",
      //         path: "/directory/absences"
      //       },
      //       {
      //         name: "Horas extra",
      //         path: "/directory/overtimes"
      //       },
      //       {
      //         name: "Constancias",
      //         path: "/directory/records"
      //       },
      //       {
      //         name: "Bajas",
      //         path: "/directory/laid-offs"
      //       }
      //       // {
      //       //   name: 'Calendario',
      //       //   path: '/directory/calendar'
      //       // }
      //     ]
      //   },
      //   payroll: {
      //     name: "Pago de planilla",
      //     path: "/payroll/next-payments",
      //     subsections: [
      //       {
      //         name: "Próximos pagos",
      //         path: "/payroll/next-payments"
      //       },
      //       {
      //         name: "Pagos realizados",
      //         path: "/payroll/past-payments"
      //       },
      //       {
      //         name: "Mi planilla",
      //         path: "/payroll/general"
      //       },
      //       {
      //         name: "Bonos variables",
      //         path: "/payroll/bonuses"
      //       },
      //       {
      //         name: "Descuentos",
      //         path: "/payroll/discounts"
      //       },
      //       {
      //         name: "Prestaciones",
      //         path: "/payroll/benefits"
      //       }
      //       // {
      //       //   name: 'Reportes',
      //       //   path: '/payroll/reports'
      //       // }
      //     ]
      //   },
      //   // benefits: {
      //   //   name: 'Administración de beneficios',
      //   //   path: '/benefits/general',
      //   //   subsections: [
      //   //     {
      //   //       name: 'General',
      //   //       path: '/benefits/general'
      //   //     },
      //   //     {
      //   //       name: 'Seguros',
      //   //       path: '/benefits/insurances'
      //   //     },
      //   // {
      //   //   name: 'Otros beneficios',
      //   //   path: '/benefits/other-benefits'
      //   // },
      //   // {
      //   //   name: 'Reclamos',
      //   //   path: '/benefits/claims'
      //   // },
      //   // {
      //   //   name: 'Reportes',
      //   //   path: '/benefits/reports'
      //   // },
      //   // {
      //   //   name: 'Configuración',
      //   //   path: '/benefits/configuration'
      //   // }
      //   //   ]
      //   // },
      //   // services: {
      //   //   name: 'Administración de servicios',
      //   //   path: '/services/explore',
      //   //   subsections: [
      //   //     {
      //   //       name: 'Explorar',
      //   //       path: '/services/explore'
      //   //     }
      //   //   ]
      //   // },
      //   // 'other-benefits': {
      //   //   name: 'Jornadas médicas y otros beneficios',
      //   //   path: '/other-benefits/journeys',
      //   //   subsections: [
      //   //     {
      //   //       name: 'Jornadas médicas',
      //   //       path: '/other-benefits/journeys'
      //   //     },
      //   //     {
      //   //       name: 'Otros beneficios',
      //   //       path: '/other-benefits/benefits'
      //   //     },
      //   //     {
      //   //       name: 'Solicitudes',
      //   //       path: '/other-benefits/requests'
      //   //     },
      //   //     {
      //   //       name: 'Calendario',
      //   //       path: '/other-benefits/calendar'
      //   //     },
      //   //     {
      //   //       name: 'Reportes',
      //   //       path: '/other-benefits/reports'
      //   //     }
      //   //   ]
      //   // },
      //   support: {
      //     name: "Soporte",
      //     path: "/support/contact",
      //     subsections: [
      //       {
      //         name: "Contacto",
      //         path: "/support/contact"
      //       }
      //       // {
      //       //   name: 'Solicitudes',
      //       //   path: '/support/requests'
      //       // }
      //     ]
      //   }
      // }
    };
  },

  computed: {
    currentPath() {
      return window.location.pathname;
    },

    currentSection() {
      return this.permissions.filter(
        permission =>
          permission.url.split("/")[1] ===
          window.location.pathname.split("/")[1]
      )[0];
    },

    currentSubsections() {
      return this.currentSection.children;
    },

    currentSectionName() {
      return this.currentSection.name;
    },

    routePermissions() {
      return extractPermissions(this.permissions, this.currentPath).map(
        permission => permission.slug
      );
    }
  },

  methods: {
    redirectTo(path) {
      window.location.pathname = path;
    },
    acceptTerms() {
      this.termsIsLoading = true;
      axios
        .put("/auth/accept-terms")
        .then(response => {
          UIkit.modal("#terms-modal").hide();
          this.termsIsLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          this.termsIsLoading = false;
        });
    }
  },

  mounted() {
    if (this.payload.accepted_terms === 0) {
      UIkit.modal("#terms-modal").show();
    }
  }
};
</script>

<style>
.sidebar-link-animation {
  transition: all 0.3s;
}

.sidebar-link-animation:hover {
  border-left: solid #2c5fa1 7px;
  padding-left: 7px;
}

.sidebar-active-link {
  border-left: solid #2c5fa1 7px;
  padding-left: 7px;
}

.uk-navbar-container {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.vertical-divider {
  border-right: solid 3px;
  margin-right: 20px;
}

.content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.user-separator {
  border-bottom: solid 1px;
  padding-bottom: 10px;
}
.user-logo {
  color: white;
  background: #2c5fa1;
  width: 35px;
  height: 35px;
  line-height: 38px;
}

.change-business {
  line-height: 67px;
}

.business-avatar {
  max-height: 75px;
  background-color: #f8f8f8;
  height: auto;
  width: auto;
}
</style>