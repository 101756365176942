<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Ausencias</div>
      <div>
        <button
          dusk="import-export-absence"
          class="uk-button uk-border-rounded uk-button-primary"
          href="#import-export-modal"
          uk-toggle
        >Importar / Exportar</button>
        <button
          dusk="add-absence"
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-absence-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-absence')"
        >Añadir ausencia</button>
      </div>
    </div>

    <div class="uk-flex uk-flex-center">
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          id="start-date"
          class="uk-input uk-border-rounded uk-text-center"
          v-model="absencesFilterFrom"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          id="end-date"
          class="uk-input uk-border-rounded uk-text-center"
          v-model="absencesFilterTo"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
    </div>

    <div class="uk-text-center">
      <button
        dusk="all"
        class="uk-button uk-border-rounded uk-margin-small-left uk-margin-small-right uk-margin-small-bottom"
        :class="{ 'uk-button-primary': typeFilter === 'all', 'uk-button-muted': typeFilter !== 'all' }"
        href="#"
        :disabled="isFetching"
        @click="setTypeFilter('all')"
      >Todas</button>
      <button
        v-for="type in payload.types"
        :key="type.id"
        :dusk="type.slug"
        class="uk-button uk-border-rounded uk-margin-small-left uk-margin-small-right uk-margin-small-bottom"
        :class="{ 'uk-button-primary': typeFilter === type.slug, 'uk-button-muted': typeFilter !== type.slug }"
        href="#"
        :disabled="isFetching"
        @click="setTypeFilter(type.slug)"
      >{{ type.name }}</button>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            name="search-absence"
            class="uk-search-input"
            type="search"
            placeholder="Buscar ausencia..."
            v-model="absenceFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="absences.prev_page_url"
          :is-fetching="isFetching"
          :current-page="absences.current_page"
          :last-page="absences.last_page"
          :next-page-url="absences.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th width="15%">Colaborador</th>
            <th width="10%">Tipo de ausencia</th>
            <th width="20%">Razón</th>
            <th width="20%">Notas</th>
            <th width="5%">Constancia</th>
            <th width="10%">Fecha de aplicación</th>
            <th width="10%">Duración en días</th>
            <th width="10%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(absence, index) in absences.data" :key="absence.id">
            <td>
              <a
                :id="'absence-'+ absence.id"
                :dusk="'absence-'+ absence.id"
                :href="'/directory/general/' + absence.insured_business.insured.id.toString()"
                class="uk-text-bold uk-link-reset"
              >{{ absence.insured_business.insured.first_name }} {{ absence.insured_business.insured.last_name }}</a>
            </td>
            <td
              :id="'absence-name-'+ absence.id"
              :dusk="'absence-name-'+ absence.id"
            >{{ absence.type.name }}</td>
            <td
              :id="'absence-reason-'+ absence.id"
              :dusk="'absence-reason-'+ absence.id"
            >{{ absence.reason.name }}</td>
            <td
              :id="'absence-notes-'+ absence.id"
              :dusk="'absence-notes-'+ absence.id"
            >{{ absence.notes }}</td>
            <td class="uk-text-center">
              <a
                :id="'absence-path-'+ absence.id"
                :dusk="'absence-path-'+ absence.id"
                v-if="absence.file"
                uk-icon="cloud-download"
                :href="absence.file.path"
                target="_blank"
              ></a>
            </td>
            <td
              :id="'absence-application-'+ absence.id"
              :dusk="'absence-application-'+ absence.id"
            >{{ formatDate(absence.application_date) }}</td>
            <td
              class="uk-text-center"
              :id="'absence-duration-'+ absence.id"
              :dusk="'absence-duration-'+ absence.id"
            >{{ absence.duration }}</td>
            <td class="uk-text-right">
              <div
                class="uk-button-group uk-border-rounded uk-overflow-hidden"
                v-if="absence.paid === 0"
              >
                <button
                  :id="'absence-edit-'+ absence.id"
                  :dusk="'absence-edit-'+ absence.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                  href="#edit-absence-modal"
                  uk-toggle
                  @click="setEditMode(absence, index)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-absence')"
                ></button>
                <button
                  :id="'absence-delete-'+ absence.id"
                  :dusk="'absence-delete-' + absence.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(absence, index)"
                  uk-icon="trash"
                  v-if="checkPermission('delete-absence')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="bottom-divider uk-hidden@s"
        v-for="(absence, index) in absences.data"
        :key="absence.id"
      >
        <div class="uk-flex uk-flex-between">
          <div>
            <a
              :id="'absence-responsive-'+ absence.id"
              :href="'/directory/general/' + absence.insured_business.insured.id.toString()"
              class="uk-text-bold uk-link-reset"
            >{{ absence.insured_business.insured.first_name }} {{ absence.insured_business.insured.last_name }}</a>
          </div>
          <div class="text-light">{{ absence.duration }} día{{ absence.duration === 1 ? '' : 's'}}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div
            class="uk-text-bold"
            :id="'absence-responsive-name-'+ absence.id"
          >{{ absence.type.name }}</div>
          <div :id="'absence-responsive-reason-'+ absence.id">{{ absence.reason.name }}</div>
        </div>
        <div>{{ formatDate(absence.application_date) }}</div>
        <div :id="'absence-responsive-notes-'+ absence.id">{{ absence.notes }}</div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
          v-if="absence.paid === 0"
        >
          <button
            :id="'absence-responsive-edit-' + absence.id"
            :dusk="'absence-responsive-edit-' + absence.id"
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-absence-modal"
            uk-toggle
            @click="setEditMode(absence, index)"
            uk-icon="pencil"
            v-if="checkPermission('edit-absence')"
          ></button>
          <a
            v-if="absence.file"
            class="uk-button uk-width-1-2 uk-button-primary"
            :href="absence.file.path"
            target="_blank"
            uk-icon="cloud-download"
          ></a>
          <button
            :id="'absence-responsive-delete-' + absence.id"
            :dusk="'absence-responsive-delete-' + absence.id"
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(absence, index)"
            uk-icon="trash"
            v-if="checkPermission('delete-absence')"
          ></button>
        </div>
      </div>

      <div
        v-if="absences.data && absences.data.length === 0"
        class="uk-text-center uk-margin-top"
      >No se han encontrado resultados con los filtros seleccionados.</div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span>¿Está seguro que desea eliminar la ausencia {{ this.model.name }}?</span>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            id="absence-delete-confirm"
            dusk="absence-delete-confirm"
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteAbsence"
            :disabled="isLoading"
          >Eliminar ausencia</button>
        </div>
      </div>
    </div>

    <div id="edit-absence-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button dusk="absence-close-modal" class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          ausencia
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Colaborador*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <v-select
                id="absence-insured-select"
                v-model="model.insured_business_id"
                class="uk-input uk-border-rounded"
                :options="payload.insureds"
                :reduce="insured => insured.id"
                :getOptionLabel="option => option.insured.first_name + ' ' + option.insured.last_name"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Tipo de ausencia*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select
                name="absence-type-select"
                class="uk-select uk-border-rounded"
                v-model="model.absence_type_id"
              >
                <option
                  v-for="type in payload.types"
                  :key="type.id"
                  :value="type.id"
                >{{ type.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Razón*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select
                name="absence-reason-select"
                class="uk-select uk-border-rounded"
                v-model="model.absence_reason_id"
              >
                <option
                  v-for="reason in payload.reasons"
                  :key="reason.id"
                  :value="reason.id"
                >{{ reason.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Constancia</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1" uk-form-custom="target: true">
              <input
                name="absence-file-holder"
                type="file"
                @change="handleFileChange($event.target.files[0])"
              />
              <input
                name="absence-file-model"
                class="uk-input uk-border-rounded"
                type="text"
                disabled
                v-model="model.filename"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Notas</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <textarea
                name="absence-notes-text"
                v-model="model.notes"
                rows="5"
                class="uk-textarea uk-border-rounded"
                type="text"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Fecha de aplicación*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <flat-pickr
                id="absence-apllication-date-input"
                class="uk-input uk-border-rounded"
                v-model="model.application_date"
                :config="datepickerConfig"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label
            class="uk-form-label"
            for="form-stacked-text"
          >Duración en días (puedes ingresar medios días)*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                name="application-date-duration"
                v-model="model.duration"
                class="uk-input uk-border-rounded"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label
            class="uk-form-label"
            for="form-stacked-text"
          >Duración en horas (puedes ingresar decimales)*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input v-model="model.hours" class="uk-input uk-border-rounded" type="text" />
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <div v-if="askForForce">
            <div
              class="uk-margin-bottom"
            >Este colaborador ya tiene asignada una ausencia que coincide con el período seleccionado, desea continuar?</div>
            <button
              dusk="create-absence"
              class="uk-button uk-border-rounded uk-button-danger"
              @click="model.force = true; store();"
              :disabled="isLoading || !absenceIsValid"
              v-if="mode === 'new'"
            >
              <span v-if="isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Forzar creación de ausencia</span>
            </button>
            <button
              dusk="update-absence"
              class="uk-button uk-border-rounded uk-button-danger"
              @click="model.force = true; update()"
              :disabled="isLoading || !absenceIsValid"
              v-else
            >
              <span v-if="isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Forzar actualizar ausencia</span>
            </button>
            <button
              dusk="update-absence"
              class="uk-button uk-border-rounded uk-button-secondary"
              @click="askForForce = false"
              :disabled="isLoading || !absenceIsValid"
            >Cancelar</button>
          </div>
          <div v-else>
            <button
              dusk="create-absence"
              class="uk-button uk-border-rounded uk-button-secondary"
              @click="store"
              :disabled="isLoading || !absenceIsValid"
              v-if="mode === 'new'"
            >
              <span v-if="isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Crear ausencia</span>
            </button>
            <button
              dusk="update-absence"
              class="uk-button uk-border-rounded uk-button-secondary"
              @click="update"
              :disabled="isLoading || !absenceIsValid"
              v-else
            >
              <span v-if="isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Actualizar ausencia</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <a
            class="uk-button uk-border-rounded uk-button-secondary"
            href="/directory/absences/template"
            target="”_blank”"
          >Descargar plantilla de importación</a>
        </div>
        <div class="uk-margin uk-text-center" v-if="checkPermission('import-absences')">
          <div uk-form-custom>
            <form autocomplete="off">
              <input
                id="import-file"
                autocomplete="off"
                name="file"
                type="file"
                @change="handleFile($event)"
              />
            </form>
            <button
              class="uk-button uk-border-rounded uk-button-secondary"
              :disabled="isFetching || isLoading"
            >
              <span v-if="isFetching || isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Importar</span>
            </button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center" v-if="checkPermission('export-absences')">
          <a
            href="/directory/absences/bulk"
            class="uk-button uk-border-rounded uk-button-secondary"
            target="_blank"
          >Exportar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import xlsx from "xlsx";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import moment from "moment";

export default {
  name: "DirectoryAbsence",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        insured_business_id: "",
        absence_type_id: "",
        absence_reason_id: "",
        notes: "",
        file: "",
        filename: "",
        application_date: "",
        duration: "",
        hours: "",
        force: false
      },
      askForForce: false,
      absencesFilterFrom: this.payload.absencesFilterFrom,
      absencesFilterTo: this.payload.absencesFilterTo,
      itemsPerPage: 20,
      activeAbsenceId: "",
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      mode: "new",
      absenceFilter: "",
      typeFilter: "all",
      absences: {},
      absenceFilterTimer: null,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  computed: {
    insuredIsValid() {
      return this.model.insured_business_id !== "";
    },
    typeIsValid() {
      return this.model.absence_type_id !== "";
    },
    reasonIsValid() {
      return this.model.absence_reason_id !== "";
    },
    applicationDateIsValid() {
      return this.model.application_date !== "";
    },
    durationIsValid() {
      return this.model.duration !== "";
    },
    hoursIsValid() {
      return this.model.hours !== "";
    },
    timeIsValid() {
      return this.durationIsValid || this.hoursIsValid;
    },
    absenceIsValid() {
      const validations = [
        this.insuredIsValid,
        this.typeIsValid,
        this.reasonIsValid,
        this.applicationDateIsValid,
        this.timeIsValid
      ];

      return validations.every(validation => validation);
    }
  },

  mounted() {
    this.absences = this.payload.absences;
  },

  methods: {
    setTypeFilter(type) {
      this.typeFilter = type;
    },
    handleFileChange(file) {
      this.model.file = file;
      this.model.filename = file.name;
    },
    setNewMode() {
      this.mode = "new";
      this.model.insured_business_id = "";
      this.model.absence_type_id = "";
      this.model.absence_reason_id = "";
      this.model.notes = "";
      this.model.application_date = "";
      this.model.duration = "";
      this.model.hours = "";
      this.model.force = false;
      this.askForForce = false;
    },
    setEditMode(absence, index) {
      this.mode = "edit";
      this.activeAbsenceId = absence.id;
      this.payloadPosition = index;
      this.model.insured_business_id = absence.insured_business_id;
      this.model.absence_type_id = absence.absence_type_id;
      this.model.absence_reason_id = absence.absence_reason_id;
      this.model.notes = absence.notes;
      this.model.application_date = absence.application_date;
      this.model.duration = absence.duration;
      this.model.hours = "";
      this.model.force = false;
      this.askForForce = false;
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.absenceFilter && !url.includes("&query")) {
        url = url + "&query=" + this.absenceFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      url = url + "&type=" + this.typeFilter;
      url = url + "&from=" + this.absencesFilterFrom;
      url = url + "&to=" + this.absencesFilterTo;
      axios
        .get(url)
        .then(({ data: { absences } }) => {
          this.absences = absences;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.absenceFilterTimer) {
        clearTimeout(this.absenceFilterTimer);
      }
      this.absenceFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.absenceFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.absences.current_page.toString() +
            "&items=" +
            this.itemsPerPage +
            "&type=" +
            this.typeFilter +
            "&from=" +
            this.absencesFilterFrom +
            "&to=" +
            this.absencesFilterTo
        )
        .then(({ data: { absences } }) => {
          this.absences = absences;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando ausencia...",
        { status: "primary", timeout: 0 }
      );
      let formData = new FormData();
      let { hours, ...transformedModel } = this.model;
      transformedModel = {
        ...transformedModel,
        duration:
          (this.model.duration !== "" ? parseFloat(this.model.duration) : 0) +
          (this.model.hours !== "" ? parseFloat(this.model.hours) : 0) / 8
      };
      for (const key in transformedModel) {
        if (transformedModel[key] !== "") {
          if (typeof transformedModel[key] === "boolean") {
            formData.append(key, transformedModel[key] === true ? 1 : 0);
          } else {
            formData.append(key, transformedModel[key]);
          }
        }
      }
      axios
        .post("/directory/absences", formData)
        .then(({ data: { model } }) => {
          // this.absences.data.push(model)
          this.refetchPage(() => {
            UIkit.modal("#edit-absence-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='success' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Ausencia creada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          if (
            error.response.data &&
            error.response.data.message === "range_occupied"
          ) {
            this.askForForce = true;
          }
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando ausencia...",
        { status: "primary", timeout: 0 }
      );
      let formData = new FormData();
      let { hours, ...transformedModel } = this.model;
      transformedModel = {
        ...transformedModel,
        duration:
          (this.model.duration !== "" ? parseFloat(this.model.duration) : 0) +
          (this.model.hours !== "" ? parseFloat(this.model.hours) : 0) / 8
      };
      for (const key in transformedModel) {
        if (transformedModel[key] !== "") {
          if (typeof transformedModel[key] === "boolean") {
            formData.append(key, transformedModel[key] === true ? 1 : 0);
          } else {
            formData.append(key, transformedModel[key]);
          }
        }
      }
      formData.append("_method", "PUT");
      axios
        .post(
          "/directory/absences/" + this.activeAbsenceId.toString(),
          formData
        )
        .then(({ data: { model } }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-absence-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='updated' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Ausencia actualizada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          if (
            error.response.data &&
            error.response.data.message === "range_occupied"
          ) {
            this.askForForce = true;
          }
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteAbsence() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando ausencia...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/directory/absences/" + this.activeAbsenceId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='deleted' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Ausencia eliminada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleFile(e) {
      this.isLoading = true;
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        let newAbsences = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .slice(2);

        newAbsences = newAbsences.map(overtime => {
          overtime.application_date = moment(overtime.application_date).format(
            "YYYY-MM-DD"
          );
          return overtime;
        });

        axios
          .post("/directory/absences/bulk", newAbsences)
          .then(({ data }) => {
            this.refetchPage(() => {
              UIkit.modal("#import-export-modal").hide();
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  data.createdCount +
                  " Ausencias creadas, " +
                  data.updatedCount +
                  " actualizadas </div>",
                { status: "success", timeout: 3000 }
              );
              this.isLoading = false;
            });
          })
          .catch(error => {
            console.log("error", error);
            this.isLoading = false;
          })
          .finally(() => {
            let importFileInput = document.getElementById("import-file");
            if (importFileInput) {
              importFileInput.value = "";
            }
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        insured_business_id: "",
        absence_type_id: "",
        absence_reason_id: "",
        notes: "",
        application_date: "",
        duration: "",
        force: false
      };
      this.askForForce = false;
    }
  },

  watch: {
    absenceFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    typeFilter: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    absencesFilterFrom(value) {
      this.refetchPage(() => {});
    },
    absencesFilterTo(value) {
      this.refetchPage(() => {});
    }
  }
};
</script>

<style>
.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}
</style>