<template>
  <div>
    <canvas :id="'chartCanvas-' + chartId"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import { v4 as uuid } from "uuid";

const colors = [
  "#235499",
  "#72D6B2",
  "#00C8FA",
  "#9A88DF",
  "#FFD700",
  "#B1C3FA",
  "#FA5D64",
  "#533A7B",
  "#FE938C",
  "#4281A4"
];

export default {
  name: "LineChart",

  props: ["payload", "configuration"],

  data() {
    return {
      chartId: uuid(),
      chart: null
    };
  },

  mounted() {
    var ctx = "chartCanvas-" + this.chartId;
    if (this.configuration) {
      this.chart = new Chart(ctx, this.configuration);
    } else {
      const labels = this.payload.reduce((accum, dataset) => {
        dataset.data.map(snapshot => {
          if (!accum.includes(snapshot.date.date)) {
            accum.push(snapshot.date.date);
          }
        });
        return accum;
      }, []);
      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels,
          datasets: this.payload.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data.map(snapshot => snapshot.value),
            backgroundColor: "rgba(224, 237, 255, 0)",
            borderColor: colors[index % colors.length],
            borderWidth: 3
          }))
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          tooltips: {
            mode: "index"
          }
        }
      });
    }
  },

  watch: {
    payload: {
      deep: true,
      handler() {
        this.chart.data.labels = this.payload.reduce((accum, dataset) => {
          dataset.data.map(snapshot => {
            if (!accum.includes(snapshot.date.date)) {
              accum.push(snapshot.date.date);
            }
          });
          return accum;
        }, []);
        this.chart.data.datasets = this.payload.map((dataset, index) => ({
          label: dataset.label,
          data: dataset.data.map(snapshot => snapshot.value),
          backgroundColor: "rgba(224, 237, 255, 0)",
          // borderColor: "#2c5fa1",
          borderColor: colors[index % colors.length],
          borderWidth: 3
        }));
        this.chart.update();
      }
    }
  }
};
</script>