<template>
  <div>
    <div class="uk-text-large">Documentos</div>
    <div class="uk-flex uk-flex-wrap uk-flex-around">
      <div
        class="uk-padding-small uk-width-2-5@s uk-width-1-4@m"
        v-for="(requirement, index) in payload.requirements"
        :key="index"
      >
        <div class="uk-card uk-card-default uk-border-rounded uk-padding-small uk-margin-top">
          <div
            v-if="requirement.business_requirement !== null && requirement.business_requirement.status.id === 1"
            class="requirement-status uk-border-circle background-gray"
            uk-icon="icon: clock; ratio: 1.5;"
            :uk-tooltip="'title:' + requirement.business_requirement.status.name + ' ; pos: left'"
          ></div>
          <div
            v-if="requirement.business_requirement !== null && requirement.business_requirement.status.id === 2"
            class="requirement-status uk-border-circle uk-background-secondary"
            uk-icon="icon: check; ratio: 1.5;"
            :uk-tooltip="'title:' + requirement.business_requirement.status.name + ' ; pos: left'"
          ></div>
          <div
            v-if="requirement.business_requirement !== null && requirement.business_requirement.status.id === 3"
            class="requirement-status uk-border-circle background-red"
            uk-icon="icon: close; ratio: 1.5;"
            :uk-tooltip="'title:' + requirement.business_requirement.status.name + ' ; pos: left'"
          ></div>
          <div class="uk-text-center uk-text-large">{{ requirement.name }}</div>
          <div class="uk-margin-top">{{ requirement.description }}</div>
          <div
            v-if="requirement.business_requirement"
          >Estado: {{ requirement.business_requirement.status.name }}</div>
          <div
            class="uk-margin uk-text-center"
            v-if="requirement.business_requirement && checkPermission('download-document')"
          >
            <a class="uk-link-reset" :href="requirement.business_requirement.file" target="_blank">
              <button class="uk-button uk-border-rounded uk-button-secondary">Ver documento</button>
            </a>
          </div>
          <div class="uk-margin uk-text-center" v-if="checkPermission('upload-document')">
            <div uk-form-custom>
              <input
                name="file"
                type="file"
                @change="handleFile($event.target.files[0], requirement.id, index)"
              />
              <button
                class="uk-button uk-border-rounded uk-button-secondary"
                :disabled="updatingFile === requirement.id"
              >
                <span v-if="updatingFile === requirement.id" uk-spinner="ratio: 0.6"></span>
                <span v-else>Cargar documento</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileDocuments",

  props: ["payload"],

  data() {
    return {
      updatingFile: null
    };
  },

  methods: {
    handleFile(file, requirementId, requirementIndex) {
      this.updatingFile = requirementId;
      let formData = new FormData();
      formData.append("file", file);
      axios
        .post("/profile/documents/" + requirementId.toString(), formData)
        .then(({ data: { model } }) => {
          this.updatingFile = null;
          this.payload.requirements[requirementIndex] = model;
        })
        .catch(error => {
          console.log("error", error);
          this.updatingFile = null;
        });
    }
  }
};
</script>

<style>
.requirement-status {
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 10px;
}

.background-gray {
  background-color: gray;
}

.background-red {
  background-color: red;
}
</style>
