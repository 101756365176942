<template>
    <div class="uk-flex uk-flex-right uk-width-1-2@s">
        <select :id="itemsPerPageId" class="uk-select uk-width-1-6 uk-margin-small-right" v-model="$parent[itemsPerPage]" :disabled="isFetching">
            <option disabled>Items por página</option>
            <option :value="10">10</option>
            <option :value="20">20</option>
            <option :value="50">50</option>
        </select>
        <div class="uk-button-group uk-border-rounded uk-overflow-hidden uk-width-2-3">
            <button :id="prevItemsBtnId" :dusk="prevItemsBtnId"  class="uk-button uk-button-primary uk-width-1-5" :disabled="prevPageUrl === null || isFetching" uk-icon="icon: chevron-left; ratio: 2" @click="fetchPage(prevPageUrl)"></button>
            <button class="uk-button uk-background-default uk-width-3-5" disabled ><span uk-spinner="ratio: 0.7" v-if="isFetching"></span> <span v-else>Página {{ currentPage }} de {{ lastPage }}</span></button>
            <button :id="nextItemsBtnId" :dusk="nextItemsBtnId" class="uk-button uk-button-primary uk-width-1-5" :disabled="nextPageUrl === null || isFetching"  uk-icon="icon: chevron-right; ratio: 2" @click="fetchPage(nextPageUrl)"></button>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            itemsPerPageId: {
                type: String,
                default: "itemsPerPageId"
            },
            prevItemsBtnId: {
                type: String,
                default: "prevItemsBtnId"
            },
            nextItemsBtnId: {
                type: String,
                default: "nextItemsBtnId"
            },
            prevPageUrl: String,
            isFetching: Boolean,
            currentPage: [String, Number],
            lastPage: [String, Number],
            nextPageUrl: String,
            methodName: {
                type: String,
                default: 'fetchPage'
            },
            itemsPerPage: {
                type: String,
                default: 'itemsPerPage'
            }
        },
        methods: {
            fetchPage: function(pageUrl) {
                this.$parent[this.methodName](pageUrl)
            }
        }
    }
</script>