<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Tags</div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input"
            type="search"
            placeholder="Buscar tag..."
            v-model="tagFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="tags.prev_page_url"
          :is-fetching="isFetching"
          :current-page="tags.current_page"
          :last-page="tags.last_page"
          :next-page-url="tags.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th width="100%">Nombre</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tag in tags.data" :key="tag.id">
            <td>
              <a :href="'/directory/general?filtertags[0]=' + tag.name.es">
                <span class="uk-badge uk-padding-small">#{{ tag.name.es }}</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="bottom-divider uk-hidden@s" v-for="tag in tags.data" :key="tag.id">
        <div class="uk-text-center">
          <a :href="'/directory/general?filtertags[0]=' + tag.name.es">
            <span class="uk-badge uk-padding-small">#{{ tag.name.es }}</span>
          </a>
        </div>
      </div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">¿Está seguro que desea eliminar el tag {{ this.model.name }}?</div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteTag"
            :disabled="isLoading"
          >Eliminar tag</button>
        </div>
      </div>
    </div>

    <div id="edit-tag-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">Editar tag</div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre</label>
          <div class="uk-form-controls">
            <input
              class="uk-input uk-border-rounded"
              
              type="text"
              v-model="model.name"
            />
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading"
          >Actualizar tag</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

export default {
  name: "DirectoryTag",

  props: ["payload"],

  data() {
    return {
      model: {
        name: ""
      },
      itemsPerPage: 20,
      activeTagId: "",
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      mode: "new",
      tagFilter: "",
      tags: {},
      tagFilterTimer: null
    };
  },

  mounted() {
    this.tags = this.payload.tags;
  },

  methods: {
    setNewMode() {
      this.mode = "new";
      this.model.name = "";
    },
    setEditMode(tag, index) {
      this.mode = "edit";
      this.activeTagId = tag.id;
      this.payloadPosition = index;
      this.model.name = tag.name.es;
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.tagFilter && !url.includes("&query")) {
        url = url + "&query=" + this.tagFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { tags } }) => {
          this.tags = tags;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.tagFilterTimer) {
        clearTimeout(this.tagFilterTimer);
      }
      this.tagFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.tagFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.tags.current_page.toString() +
            query +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { tags } }) => {
          this.tags = tags;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando tag...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/directory/tags/" + this.activeTagId.toString(), this.model)
        .then(({ data: { model } }) => {
          // this.tags.data[this.payloadPosition] = model
          this.refetchPage(() => {
            UIkit.modal("#edit-tag-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>División actualizada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteTag() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando tag...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete("/directory/tags/" + this.activeTagId.toString(), this.model)
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>División eliminada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    resetForm() {
      this.model = {
        name: ""
      };
    }
  },

  watch: {
    tagFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>
