<template>
  <div>
    <canvas :id="'chartCanvas-' + chartId"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import { v4 as uuid } from "uuid";

const colors = [
  "#235499",
  "#72D6B2",
  "#00C8FA",
  "#9A88DF",
  "#FFD700",
  "#B1C3FA",
  "#FA5D64",
  "#533A7B",
  "#FE938C",
  "#4281A4"
];

export default {
  name: "PieChart",

  props: ["payload", "configuration"],

  data() {
    return {
      chartId: uuid(),
      chart: null
    };
  },

  mounted() {
    var ctx = "chartCanvas-" + this.chartId;
    if (this.configuration) {
      this.chart = new Chart(ctx, this.configuration);
    } else {
      this.chart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.payload.map(dataset => dataset.label),
          datasets: [
            {
              data: this.payload.map(
                dataset => dataset.data[dataset.data.length - 1].value
              ),
              backgroundColor: this.payload.map(
                (dataset, index) => colors[index % colors.length]
              ),
              borderWidth: 3
            }
          ]
        }
      });
    }
  },

  watch: {
    payload: {
      deep: true,
      handler() {
        this.chart.data.labels = this.payload.map(dataset => dataset.label);
        this.chart.data.datasets[0].data = this.payload.map(
          dataset => dataset.data[dataset.data.length - 1].value
        );
        this.chart.update();
      }
    }
  }
};
</script>