<template>
  <div>
    <div class="uk-text-large">Seguros</div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
      v-for="(plan, index) in currentPlans"
      :key="index"
    >
      <div class="uk-text-large uk-text-center uk-text-bold">{{ plan.name }}</div>
      <ul class="uk-flex-center" uk-tab swiping="false">
        <li class="custom-tab">
          <a href="#">Información general</a>
        </li>
        <li class="custom-tab">
          <a href="#">Colaboradores asegurados ({{ plan.insureds.length }})</a>
        </li>
        <li class="custom-tab">
          <a href="#">Ayuda</a>
        </li>
      </ul>

      <ul class="uk-switcher uk-margin">
        <li>
          <div class="uk-flex uk-flex-wrap uk-flex-center">
            <div class="uk-width-1-4@s uk-text-right@s uk-margin-right">Descripción:</div>
            <div class="uk-width-1-2@s">{{ plan.description }}</div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-flex-center uk-margin-top">
            <div class="uk-width-1-4@s uk-text-right@s uk-margin-right">Proveedor:</div>
            <div class="uk-width-1-2@s">
              <div>
                <img :data-src="plan.insurer.logo" class="insurer-logo" uk-img />
              </div>
              <div>{{ plan.insurer.name }}</div>
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-flex-center uk-margin-top">
            <div class="uk-width-1-4@s uk-text-right@s uk-margin-right">Costo total:</div>
            <div class="uk-width-1-2@s">{{ totalPlanPrice(plan) }}</div>
          </div>
        </li>
        <li>
          <div class="uk-margin-top">
            <div class="uk-flex uk-flex-wrap uk-flex-between">
              <form class="uk-search uk-search-default uk-width-1-2@s">
                <span uk-search-icon></span>
                <input class="uk-search-input" type="search" placeholder="Buscar colaborador..." />
              </form>
              <!-- <div class="uk-button-group uk-border-rounded uk-overflow-hidden uk-width-1-2@s">
              <button class="uk-button uk-button-primary uk-width-1-5" :disabled="prevPageAvailable" @click="prevPage" uk-icon="icon: chevron-left; ratio: 2"></button>
              <button class="uk-button uk-width-3-5" disabled >Página {{ currentPage + 1 }} de {{ totalPages + 1 }}</button>
              <button class="uk-button uk-button-primary uk-width-1-5" :disabled="nextPageAvailable" @click="nextPage" uk-icon="icon: chevron-right; ratio: 2"></button>
              </div>-->
            </div>
            <div class="uk-text-center uk-margin">
              <button
                class="uk-button uk-border-rounded uk-button-primary"
                href="#add-insureds-modal"
                uk-toggle
                @click="setCurrentPlan(plan, index)"
              >Añadir colaboradores a este plan</button>
            </div>
            <div class="uk-overflow-auto">
              <table class="uk-table uk-table-hover uk-table-divider">
                <thead>
                  <tr>
                    <th>Nombre completo</th>
                    <th>Título</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>División</th>
                    <th>Prima</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="insured in plan.insureds" :key="insured.id">
                    <td>{{ insured.first_name }} {{ insured.last_name }}</td>
                    <td>{{ insured.business_relation.title }}</td>
                    <td>{{ insured.business_relation.type }}</td>
                    <td>{{ insured.business_relation.status }}</td>
                    <td>{{ insured.division.name }}</td>
                    <td>{{ toCurrency(planPrice(insured.business_relation.business_plans.filter(businessPlan => businessPlan.plan_id === plan.id)[0])) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
        <li>
          <div class="uk-text-center">Videos educativos</div>
          <div class="uk-text-center">Links a archivos</div>
          <div class="uk-text-center">Documentos requeridos</div>
          <div class="uk-flex uk-flex-wrap uk-flex-around">
            <div
              class="uk-card uk-card-primary uk-border-rounded uk-padding-small uk-margin-top uk-width-2-5@s uk-width-1-4@m"
              v-for="(requirement, index) in plan.requirements"
              :key="index"
            >
              <div
                v-if="requirement.business_requirement !== null && requirement.business_requirement.status.id === 1"
                class="requirement-status uk-border-circle background-gray"
                uk-icon="icon: clock; ratio: 1.5;"
                :uk-tooltip="'title:' + requirement.business_requirement.status.name + ' ; pos: left'"
              ></div>
              <div
                v-if="requirement.business_requirement !== null && requirement.business_requirement.status.id === 2"
                class="requirement-status uk-border-circle uk-background-secondary"
                uk-icon="icon: check; ratio: 1.5;"
                :uk-tooltip="'title:' + requirement.business_requirement.status.name + ' ; pos: left'"
              ></div>
              <div
                v-if="requirement.business_requirement !== null && requirement.business_requirement.status.id === 3"
                class="requirement-status uk-border-circle background-red"
                uk-icon="icon: close; ratio: 1.5;"
                :uk-tooltip="'title:' + requirement.business_requirement.status.name + ' ; pos: left'"
              ></div>
              <div class="uk-text-center uk-text-large">{{ requirement.name }}</div>
              <div class="uk-margin-top">{{ requirement.description }}</div>
              <div
                v-if="requirement.business_requirement"
              >Estado: {{ requirement.business_requirement.status.name }}</div>
            </div>
          </div>
          <div class="uk-text-center uk-margin-top">
            <a href="/profile/documents" class="uk-link-reset">
              <button class="uk-button uk-border-rounded uk-button-secondary">Administrar documentos</button>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <div class="uk-text-center uk-margin">
      <button
        class="uk-button uk-border-rounded uk-button-primary"
        href="#edit-insurance-modal"
        uk-toggle
      >Explorar seguros</button>
    </div>

    <div
      id="edit-insurance-modal"
      class="uk-flex-top uk-modal-container"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <!-- This is the nav containing the toggling elements -->
        <ul
          id="insurances-modal-switcher"
          uk-switcher
          class="uk-hidden"
          swiping="false"
          animation="uk-animation-slide-right, uk-animation-slide-left"
        >
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
        </ul>

        <!-- This is the container of the content items -->
        <ul class="uk-switcher">
          <li>
            <div class="uk-text-large">Seleccione un seguro</div>
            <div class="uk-flex uk-flex-wrap uk-flex-around">
              <a
                class="uk-link-reset uk-margin-top uk-width-2-5@s"
                href="#"
                uk-switcher-item="next"
                v-for="plan in payload.plans"
                :key="plan.id"
                @click="setPlan(plan)"
              >
                <div class="uk-card uk-card-primary uk-border-rounded uk-padding-small">
                  <div class="uk-text-bold">{{ plan.name }}</div>
                  <div>{{ plan.description }}</div>
                  <div>Precio: {{ toCurrency(plan.price) }}</div>
                </div>
              </a>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="previous">
                <button class="uk-button uk-border-rounded uk-button-muted">
                  <span uk-icon="chevron-left"></span>
                  Atrás
                </button>
              </a>
            </div>
          </li>
          <li>
            <div class="uk-text-large">
              Seleccione coberturas de
              <span class="uk-text-bold">{{ model.plan.name }}</span>
            </div>
            <div class="uk-flex uk-flex-wrap uk-flex-around uk-margin-top">
              <div
                class="uk-card uk-card-default uk-border-rounded uk-padding-small uk-margin-top transition-width"
                :class="{ 'uk-width-1-1': selectingCoverage === coverage.id, 'uk-width-2-5@s': selectingCoverage !== coverage.id }"
                v-for="(coverage, index) in model.plan.coverages"
                :key="index"
              >
                <div
                  v-if="(coverage.selectedOption !== null) && !(coverage.required_coverage_id && model.plan.coverages[model.plan.coverageIds.indexOf(coverage.required_coverage_id)].selectedOption === null)"
                  class="coverage-check uk-border-circle uk-background-secondary"
                  uk-icon="icon: check; ratio: 1.5;"
                ></div>
                <div class="uk-text-center uk-text-large">{{ coverage.name }}</div>
                <div class="uk-margin-small-top">{{ coverage.description }}</div>
                <div
                  class="uk-margin"
                  v-if="coverage.required_coverage_id && model.plan.coverages[model.plan.coverageIds.indexOf(coverage.required_coverage_id)].selectedOption === null"
                >
                  Esta cobertura requiere
                  <span
                    class="uk-text-bold"
                  >{{ model.plan.coverages[model.plan.coverageIds.indexOf(coverage.required_coverage_id)].name }}</span>
                </div>
                <div v-else>
                  <div v-if="coverage.optionsById[model.plan.coverages[index].selectedOption]">
                    <div class="uk-margin">
                      <div
                        class="uk-text-large uk-text-center uk-text-bold"
                      >{{ coverage.optionsById[model.plan.coverages[index].selectedOption].description }}</div>
                      <div
                        class="uk-text-center"
                      >{{ toCurrency(coverage.optionsById[model.plan.coverages[index].selectedOption].price) }}</div>
                    </div>
                    <div
                      v-if="selectingCoverage !== coverage.id && coverage.optionsById[model.plan.coverages[index].selectedOption].addins.length !== 0"
                    >Opciones extras</div>
                    <div
                      class="uk-margin"
                      v-for="addin in coverage.optionsById[model.plan.coverages[index].selectedOption].addins"
                      :key="addin.id"
                      v-if="selectingCoverage !== coverage.id"
                    >
                      <label>
                        <input
                          class="uk-checkbox"
                          type="checkbox"
                          v-model="coverage.optionsById[model.plan.coverages[index].selectedOption].selectedAddins[addin.id]"
                        />
                        <span class="uk-text-bold">{{ addin.description }}</span>
                        ({{ toCurrency(addin.price) }})
                        <div v-if="addin.detail" class="uk-margin-left">
                          <div
                            v-for="(detail, index) in JSON.parse(addin.detail)"
                            :key="index"
                          >{{ detail[0] }}: {{ detail[1]}}</div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div v-else>
                    <div class="uk-text-large uk-text-center uk-text-bold">No agregar</div>
                  </div>
                  <div class="uk-margin uk-text-center">
                    <button
                      class="uk-button uk-border-rounded uk-button-secondary"
                      @click="selectingCoverage = coverage.id"
                      :disabled="selectingCoverage !== ''"
                    >Cambiar</button>
                  </div>
                  <div
                    v-if="selectingCoverage === coverage.id"
                    class="table-container uk-overflow-auto"
                  >
                    <table
                      class="uk-table uk-table-hover uk-table-divider uk-table-middle table-fixed"
                    >
                      <thead class="table-header">
                        <tr>
                          <th class="table-description">
                            <div class="uk-margin uk-text-center" v-if="coverage.mandatory === 0">
                              <button
                                class="uk-button uk-border-rounded uk-button-secondary"
                                @click="model.plan.coverages[index].selectedOption = null; selectingCoverage = ''"
                              >No agregar</button>
                            </div>
                          </th>
                          <th
                            v-for="option in coverage.options"
                            class="table-value"
                            :key="option.id"
                          >
                            <div class="uk-text-center uk-text-bold">{{ option.description }}</div>
                            <div class="uk-text-center">{{ toCurrency(option.price) }}</div>
                            <div class="uk-margin uk-text-center">
                              <button
                                class="uk-button uk-border-rounded uk-button-secondary"
                                @click="model.plan.coverages[index].selectedOption = option.id; selectingCoverage = ''"
                              >Seleccionar</button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="coverage.options[0].detail">
                        <tr
                          v-for="(description, detailIndex) in JSON.parse(coverage.options[0].detail)"
                          :key="detailIndex"
                        >
                          <td class="table-description uk-text-bold">{{ description[0] }}</td>
                          <td
                            class="table-value uk-text-center"
                            v-for="option in coverage.options"
                            :key="option.id"
                            v-if="option.detail"
                          >{{ JSON.parse(option.detail)[detailIndex][1] }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="next">
                <button class="uk-button uk-border-rounded uk-button-secondary">
                  Continuar
                  <span uk-icon="chevron-right"></span>
                </button>
              </a>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" :uk-switcher-item="mode === 'new' ? 'previous' : 0">
                <button class="uk-button uk-border-rounded uk-button-muted">
                  <span uk-icon="chevron-left"></span>
                  Atrás
                </button>
              </a>
            </div>
          </li>
          <li>
            <div v-if="mode === 'new'">
              <div
                class="uk-text-large"
              >Confirma que deseas agregar el plan {{ this.model.plan.name }}</div>
              <div class="uk-margin uk-text-center">
                <button
                  class="uk-button uk-border-rounded uk-button-secondary"
                  @click="requestInsurance"
                  :disabled="isLoading"
                >
                  <span uk-icon="check"></span>
                  Enviar solicitud
                </button>
              </div>
            </div>
            <div v-if="mode === 'edit'">
              <div
                class="uk-text-large"
              >Confirma que deseas modificar el plan {{ this.model.plan.name }}</div>
              <div class="uk-margin uk-text-center">
                <button
                  class="uk-button uk-border-rounded uk-button-secondary"
                  @click="updateInsurance"
                  :disabled="isLoading"
                >
                  <span uk-icon="check"></span>
                  Modificar plan
                </button>
              </div>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="previous">
                <button class="uk-button uk-border-rounded uk-button-muted">
                  <span uk-icon="chevron-left"></span>
                  Atrás
                </button>
              </a>
            </div>
          </li>
          <li>
            Para finalizar, llena los siguientes documentos. Estos seguirán disponibles en el detalle de planes del colaborador.
            <div class="uk-flex uk-flex-wrap uk-flex-around">
              <div
                class="uk-card uk-card-secondary uk-border-rounded uk-padding-small uk-margin-top uk-width-2-5@s"
                v-for="form in model.plan.forms"
                :key="form.id"
              >
                <div
                  v-if="form.filled && form.filled.status.id === 1"
                  class="requirement-status uk-border-circle background-gray"
                  uk-icon="icon: clock; ratio: 1.5;"
                  :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                ></div>
                <div
                  v-if="form.filled && form.filled.status.id === 2"
                  class="requirement-status uk-border-circle uk-background-secondary"
                  uk-icon="icon: check; ratio: 1.5;"
                  :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                ></div>
                <div
                  v-if="form.filled && form.filled.status.id === 3"
                  class="requirement-status uk-border-circle background-red"
                  uk-icon="icon: close; ratio: 1.5;"
                  :uk-tooltip="'title:' + form.filled.status.name + ' ; pos: left'"
                ></div>
                <div class="uk-text-center uk-text-large">{{ form.name }}</div>
                <div v-if="form.filled">Estado: {{ form.filled.status.name }}</div>
                <div class="uk-margin uk-text-center" v-if="form.filled">
                  <a class="uk-link-reset" :href="form.filled.file" target="_blank">
                    <button class="uk-button uk-border-rounded uk-button-secondary">
                      <span uk-icon="search"></span> Ver documento
                    </button>
                  </a>
                </div>
                <div class="uk-margin uk-text-center">
                  <div uk-form-custom>
                    <input
                      name="file"
                      type="file"
                      @change="handleFile($event.target.files[0], form.id, index)"
                    />
                    <button
                      class="uk-button uk-border-rounded uk-button-secondary"
                      :disabled="updatingFile === form.id"
                    >
                      <span v-if="updatingFile === form.id" uk-spinner="ratio: 0.6"></span>
                      <span v-else>
                        <span uk-icon="cloud-upload"></span> Cargar documento
                      </span>
                    </button>
                  </div>
                </div>
                <div class="uk-margin uk-text-center">
                  <a class="uk-link-reset" :href="form.file" target="_blank">
                    <button class="uk-button uk-border-rounded uk-button-secondary">
                      <span uk-icon="cloud-download"></span> Descargar plantilla
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div id="add-insureds-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large uk-text-center">Añadir colaboradores al plan</div>
        <div class="uk-margin uk-height-large uk-overflow-auto">
          <ul class="uk-list uk-list-large uk-list-divider" v-if="model.plan">
            <li
              v-for="insured in payload.allInsureds"
              :key="insured.id"
              v-if="!model.plan.insureds.map(insured => insured.id).includes(insured.id)"
            >
              <label>
                <input class="uk-checkbox" type="checkbox" v-model="model.insureds[insured.id]" />
                {{ insured.first_name }} {{ insured.last_name }}
              </label>
            </li>
          </ul>
        </div>

        <div class="uk-text-center uk-margin">
          <button
            class="uk-button uk-border-rounded uk-button-primary"
            @click="addInsureds"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner="ratio: 1"></span>
            <span v-else>Añadir {{ newCount }} colaborador{{ newCount === 1 ? '' : 'es'}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

export default {
  name: "BenefitsInsurances",

  props: ["payload"],

  data() {
    return {
      selectingCoverage: "",
      isLoading: false,
      plansById: {},
      currentPlanId: "",
      currentPlans: [],
      model: {
        plans: [],
        plan: "",
        insureds: {}
      },
      mode: "new"
    };
  },

  computed: {
    newCount() {
      return Object.keys(this.model.insureds)
        .map(insuredId => this.model.insureds[insuredId])
        .filter(insured => insured).length;
    }
  },

  methods: {
    planPrice(plan) {
      let price = plan.plan.price;
      plan.coverage_options.map(option => {
        price = price + option.coverage_option.price;
        option.coverage_option.addins.map(addin => {
          price = price + addin.price;
        });
      });
      return price;
    },
    totalPlanPrice(plan) {
      const price = plan.insureds.reduce((accum, insured) => {
        accum =
          accum +
          this.planPrice(
            insured.business_relation.business_plans.filter(
              businessPlan => businessPlan.plan_id === plan.id
            )[0]
          );
        return accum;
      }, 0);
      return this.toCurrency(price);
    },
    setCurrentPlan(plan, index) {
      this.currentPlanId = plan.id;
      this.currentPlanIndex = index;
      this.model.insureds = this.payload.allInsureds.reduce(
        (accum, insured) => {
          accum[insured.id] = false;
          return accum;
        },
        {}
      );
      this.model.plan = plan;
    },
    setPlan(plan) {
      this.model.plan = {
        ...plan,
        coverages: plan.related_coverages.map(coverage => ({
          ...coverage,
          selectedOption: coverage.options[0].id,
          optionsById: coverage.options.reduce((accum, option) => {
            accum[option.id] = {
              ...option,
              selectedAddins: option.addins.reduce((accum, addin) => {
                accum[addin.id] = false;
                return accum;
              }, {})
            };
            return accum;
          }, {})
        })),
        coverageIds: plan.related_coverages.map(coverage => coverage.id),
        dependents: [],
        insureds: []
      };
      this.selectingCoverage = "";
      this.mode = "new";
    },
    editPlan(plan) {
      const selectedOptions = plan.coverage_options.reduce(
        (accum, coverageOption) => {
          accum[coverageOption.coverage_option.coverage.id] =
            coverageOption.coverage_option_id;
          return accum;
        },
        {}
      );
      this.model.plan = {
        ...this.plansById[plan.plan.id],
        coverages: this.plansById[plan.plan.id].coverages.map(coverage => ({
          ...coverage,
          selectedOption:
            coverage.id in selectedOptions
              ? selectedOptions[coverage.id]
              : null,
          optionsById: coverage.options.reduce((accum, option) => {
            accum[option.id] = option;
            return accum;
          }, {})
        })),
        dependents: plan.dependents.map(dependent => dependent),
        insured_plan_id: plan.pivot.insured_plan_id,
        insured_business_id: plan.pivot.insured_business_id
      };
      this.selectingCoverage = "";
      this.mode = "edit";
    },
    addInsureds() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Enviando solicitud...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/benefits/insurances/insureds", {
          plan_id: this.model.plan.id,
          insureds: Object.keys(this.model.insureds).reduce(
            (accum, insured) => {
              if (this.model.insureds[insured]) {
                accum.push(insured);
              }
              return accum;
            },
            []
          )
        })
        .then(({ data: { plan_insureds } }) => {
          UIkit.modal("#add-insureds-modal").hide();
          this.currentPlans[this.currentPlanIndex] = plan_insureds;
          loader.close();
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Solicitud enviada</div>",
            { status: "success", timeout: 3000 }
          );
          this.isLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    requestInsurance() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Enviando solicitud...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/benefits/insurances", {
          plan_id: this.model.plan.id,
          coverages: [
            ...this.model.plan.coverages.map(
              coverage => coverage.selectedOption
            ),
            ...this.model.plan.coverages.map(coverage => {
              return Object.keys(
                coverage.optionsById[coverage.selectedOption].selectedAddins
              ).filter(
                addinId =>
                  coverage.optionsById[coverage.selectedOption].selectedAddins[
                    addinId
                  ] === true
              );
            })
          ].flat()
        })
        .then(({ data }) => {
          UIkit.switcher("#insurances-modal-switcher").show(3);
          loader.close();
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Solicitud enviada</div>",
            { status: "success", timeout: 3000 }
          );
          this.isLoading = false;
          UIkit.modal("#add-insureds-modal").hide();
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    updateInsurance() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Enviando solicitud de modificación...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/benefits/insurances", {
          plan_id: this.model.plan.id,
          insured_id: this.activeInsured.id,
          insured_plan_id: this.model.plan.insured_plan_id,
          insured_business_id: this.model.plan.insured_business_id,
          dependents: this.model.plan.dependents,
          coverages: this.model.plan.coverages.map(
            coverage => coverage.selectedOption
          )
        })
        .then(({ data: { model } }) => {
          UIkit.modal("#edit-insured-modal").hide();
          loader.close();
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Solicitud enviada</div>",
            { status: "success", timeout: 4000 }
          );
          this.isLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    resetForm() {
      this.model = {
        plans: [],
        plan: ""
      };
    }
  },

  mounted() {
    this.plansById = this.payload.plans.reduce((accum, plan) => {
      accum[plan.id] = plan;
      return accum;
    }, {});
    this.currentPlans = this.payload.currentPlans;
  }
};
</script>

<style scoped>
.custom-tab.uk-active {
  text-decoration: underline;
}

.insurer-logo {
  height: 50px;
}
</style>
