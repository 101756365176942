<template>
  <div class="uk-width-xlarge@s uk-margin-auto">
    <div class="uk-text-center">Si deseas contactarnos solo llena este formulario</div>
    <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top">
      <div>Asunto</div>
      <select class="uk-select uk-border-rounded line-border" v-model="model.subject">
        <option>Perfil de la empresa</option>
        <option>Directorio</option>
        <option>Pago de planilla</option>
        <option>Planes de beneficios</option>
        <option>Pagos y facturación</option>
        <option>Necesito ayuda</option>
        <option>Otro</option>
      </select>
      <div class="uk-margin-top">Mensaje</div>
      <textarea
        class="uk-textarea uk-border-rounded line-border"
        rows="5"
        placeholder="Escribe tu mensaje..."
        v-model="model.message"
      ></textarea>
      <div class="uk-text-center uk-margin-top">
        <button
          class="uk-button uk-border-rounded uk-button-primary"
          :disabled="model.subject === '' || model.message === '' || isLoading"
          @click="store"
        >
          <span v-if="isLoading" uk-spinner="ratio: 1"></span>
          <span v-else>Enviar</span>
        </button>
      </div>

      <div class="uk-text-center uk-margin-large-top">O escríbenos a nuestras redes sociales</div>
      <div class="uk-flex uk-flex-center uk-margin-top">
        <a target="_blank" href="http://m.me/vivequilo">
          <img class="social-icon" src="/images/facebook.png" />
        </a>
        <a target="_blank" href="https://instagram.com/vivequilo">
          <img class="social-icon" src="/images/instagram.png" />
        </a>
        <a target="_blank" href="https://wa.me/50259090909">
          <img class="social-icon" src="/images/whatsapp.png" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

export default {
  name: "SupportContact",

  data() {
    return {
      model: {
        subject: "",
        message: ""
      },
      isLoading: false
    };
  },

  methods: {
    store() {
      this.isLoading = true;
      axios
        .post("/support/contact", this.model)
        .then(response => {
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Mensaje enviado</div>",
            { status: "success", timeout: 3000 }
          );
          this.isLoading = false;
          this.model.subject = "";
          this.model.message = "";
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.line-border {
  border: solid 1px;
}

.social-icon {
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

.social-svg {
  fill: #2c5fa1;
}
</style>