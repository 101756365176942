<template>
  <div v-if="payment">
    <div>Desglose de pago del período {{ formatDate(payment.start_date) }} - {{ formatDate(payment.end_date) }}</div>
    <div>
      <span id="status" class="uk-badge" v-if="payment.paid === 1">
        <span uk-icon="icon: check; ratio: 1;"></span>Pagado
      </span>
    </div>
    <div class="uk-flex uk-flex-between">
      <div
        class="uk-width-expand"
        uk-leader
      >{{ payment.payroll.payroll_type_id === 1 ? 'Base' : 'Honorarios'}}</div>
      <div :id="'detail-base-' + payment.id">{{ toCurrency(payment.base) }}</div>
    </div>
    <div class="uk-flex uk-flex-between">
      <div class="uk-width-expand" uk-leader>+ Bono de productividad</div>
      <div :id="'detail-fixed-bonus-' + payment.id">{{ toCurrency(payment.bonuses) }}</div>
    </div>
    <div
      class="uk-flex uk-flex-between"
      v-if="JSON.parse(payment.adjustments).filter(adjustment => adjustment.type === 'incentive').length === 1"
    >
      <div class="uk-width-expand" uk-leader>+ Bono incentivo</div>
      <div
        :id="'detail-incentive-bonus-' + payment.id"
      >{{ toCurrency(JSON.parse(payment.adjustments).filter(adjustment => adjustment.type === 'incentive')[0].amount) }}</div>
    </div>
    <div class="uk-flex uk-flex-between" v-if="payment.adjustments">
      <div class="uk-width-expand">Prestaciones</div>
      <div></div>
    </div>
    <!-- BENEFIT BONUSES -->
    <div v-if="payment.benefit_bonuses">
      <div
        class="uk-flex uk-flex-between"
        v-for="benefit in payment.benefit_bonuses"
        :key="benefit.id"
      >
        <div
          :id="'benefit-'+ benefit.id +'-reason-' + payment.id"
          class="uk-width-expand tab1"
          uk-leader
        >{{ benefit.reason }}</div>
        <div
          :id="'benefit-'+ benefit.id +'-bonus-' + payment.id"
          :class="{ 'uk-text-danger': benefit.amount < 0, 'uk-text-success': benefit.amount >= 0 }"
        >{{ toCurrency(benefit.amount) }}</div>
      </div>
    </div>

    <!-- ADJUSTMENTS -->
    <div v-if="payment.adjustments">
      <div
        class="uk-flex uk-flex-between"
        v-for="(adjustment, index) in JSON.parse(payment.adjustments).filter(adjustment => adjustment.type !== 'incentive')"
        :key="index"
      >
        <div
          :id="'detail-adjustment-name-' + adjustment.type + '-' + payment.id"
          class="uk-width-expand tab1"
          uk-leader
        >
          {{ adjustment.name }}
          <span
            :id="'detail-adjustment-percentage-' + adjustment.type + '-' + payment.id"
            v-if="adjustment.type === 'percentage'"
          >({{ adjustment.amount }}% sobre base)</span>
        </div>
        <div
          :id="'detail-adjustment-percentage-amount-' + adjustment.type + '-' + payment.id"
          :class="{ 'uk-text-danger': adjustment.amount < 0, 'uk-text-success': adjustment.amount >= 0 }"
          v-if="adjustment.type === 'percentage'"
        >{{ toCurrency(adjustment.amount / 100 * payment.base) }}</div>
        <div
          :id="'detail-adjustment-isr-amount-' + adjustment.type + '-' + payment.id"
          class="uk-text-danger"
          v-if="adjustment.type === 'isr'"
        >{{ toCurrency(payment.isrAmount) }}</div>
        <div
          :id="'detail-adjustment-amount-' + adjustment.type + '-' + payment.id"
          :class="{ 'uk-text-danger': adjustment.amount < 0, 'uk-text-success': adjustment.amount >= 0 }"
          v-if="adjustment.type === 'net'"
        >{{ toCurrency(adjustment.amount) }}</div>
      </div>
    </div>

    <!-- OTHER BONUSES -->
    <div v-if="payment.other_bonuses.length !== 0">
      <div class="uk-flex uk-flex-between">
        <div class="uk-width-expand">Otros bonos / descuentos</div>
      </div>
      <div v-for="other_bonus in payment.other_bonuses" :key="other_bonus.id">
        <div class="uk-flex uk-flex-between">
          <div
            :id="'detail-other-bonus-detail-' + other_bonus.id + '-' + payment.id"
            class="uk-width-expand tab1"
            uk-leader
          >{{ other_bonus.type.operation }} {{ other_bonus.reason }} ({{ formatDate(other_bonus.application_date) }})</div>
          <div
            :id="'detail-other-bonus-amount-' + other_bonus.id + '-' + payment.id"
            :class="{ 'uk-text-danger': other_bonus.type.operation === '-' }"
          >{{ toCurrency(other_bonus.amount) }}</div>
        </div>
        <div
          class="uk-flex uk-flex-between"
          v-if="other_bonus.type.benefits.map(benefit => benefit.slug).includes('igss')"
        >
          <div class="uk-width-expand tab2" uk-leader>- Ajuste IGSS (-4.83%)</div>
          <div
            :id="'detail-other-bonus-iggs-' + other_bonus.id + '-' + payment.id"
            class="uk-text-danger"
          >{{ toCurrency(other_bonus.amount / 100 * -4.83) }}</div>
        </div>
      </div>
    </div>

    <!-- ABSENCES -->
    <div v-if="payment.absences.length !== 0">
      <div class="uk-flex uk-flex-between">
        <div class="uk-width-expand">Ausencias</div>
      </div>

      <div v-for="absence in payment.absences" :key="absence.id">
        <div class="uk-flex uk-flex-between">
          <div
            :id="'detail-absence-detail-' + absence.id + '-' + payment.id"
            class="uk-width-expand tab1"
            uk-leader
          >{{ formatDate(absence.application_date) }} - {{ absence.duration }} día{{ absence.duration === 1 ? '' : 's' }} - {{ absence.type.name }}, {{ absence.reason.name }}</div>
          <div
            :id="'detail-absence-amount-' + absence.id + '-' + payment.id"
            class="uk-text-danger"
          >{{ toCurrency(absence.amount) }}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div class="uk-width-expand tab2" uk-leader>+ Ajuste IGSS (4.83%)</div>
          <div
            :id="'detail-absence-iggs-' + absence.id + '-' + payment.id"
          >{{ toCurrency(absence.igssAdjustment) }}</div>
        </div>
      </div>
    </div>

    <!-- OVERTIMES -->
    <div v-if="payment.overtimes.length !== 0">
      <div class="uk-flex uk-flex-between">
        <div class="uk-width-expand">Horas extras</div>
        <div></div>
      </div>
      <div v-for="overtime in payment.overtimes" :key="overtime.id">
        <div class="uk-flex uk-flex-between">
          <div
            :id="'detail-overtime-detail-' + overtime.id + '-' + payment.id"
            class="uk-width-expand tab1"
            uk-leader
          >{{ formatDate(overtime.application_date) }} - {{ overtime.hours }} horas</div>
          <div
            :id="'detail-overtime-amount-' + overtime.id + '-' + payment.id"
          >{{ toCurrency(overtime.amount) }}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div class="uk-width-expand tab2" uk-leader>- Ajuste IGSS (4.83%)</div>
          <div
            :id="'detail-overtime-iggs-' + overtime.id + '-' + payment.id"
            class="uk-text-danger"
          >{{ toCurrency(overtime.igssAdjustment) }}</div>
        </div>
      </div>
    </div>

    <!-- TOTAL -->
    <div class="uk-flex uk-flex-between">
      <div class="uk-width-expand" uk-leader>Total</div>
      <div :id="'detail-total-' + payment.id">{{ toCurrency(payment.totalAmount) }}</div>
    </div>

    <div v-if="!payment.paid">
      <div v-if="!editCardOpen" class="uk-text-center uk-margin-top">
        <button
          :dusk="'open-edit-payment-' + payment.id"
          class="uk-button uk-border-rounded uk-button-primary"
          @click="openEditCard"
          v-if="editPermission"
        >Editar pago</button>
      </div>
      <!-- BONO VARIABLE MODAL -->
      <div
        class="uk-card uk-card-primary uk-border-rounded uk-margin-auto uk-margin-small-top uk-width-1-2@s"
        v-else
      >
        <div class="uk-card-body uk-padding-small">
          <div class="uk-flex uk-flex-between">
            <div class="uk-text-large">Editar pago</div>
            <button dusk="close-edit-payment" type="button" uk-close @click="closeEditCard"></button>
          </div>

          <div class="uk-text-large">Configuración de pago</div>

          <div class="uk-margin">
            <label
              class="uk-form-label"
              for="form-stacked-text"
            >{{ payment.payroll.payroll_type_id === 1 ? 'Base' : 'Honorarios'}}</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input
                  name="payment-edit-base"
                  v-model="model.base"
                  class="uk-input uk-border-rounded"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">Bono de productividad</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input
                  name="payment-edit-benefit-bonus"
                  v-model="model.bonuses"
                  class="uk-input uk-border-rounded"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div v-if="payment.benefit_bonuses.length !== 0">
            <div class="uk-text-large">Prestaciones</div>

            <div class="uk-margin" v-for="benefit in payment.benefit_bonuses" :key="benefit.id">
              <label
                class="uk-form-label"
                for="form-stacked-text"
              >{{ benefit.type.name }} - {{ benefit.reason }}</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input
                    :name="'payment-edit-bonus-' + benefit.id"
                    v-model="model.benefitBonuses[benefit.id]"
                    class="uk-input uk-border-rounded"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="payment.other_bonuses.length !== 0">
            <div class="uk-text-large">Bonos variables y descuentos</div>

            <div class="uk-margin" v-for="bonus in payment.other_bonuses" :key="bonus.id">
              <label
                class="uk-form-label"
                for="form-stacked-text"
              >{{ bonus.type.name }} - {{ bonus.reason }}</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input
                    :name="'payment-edit-other-bonus-' + bonus.id"
                    v-model="model.otherBonuses[bonus.id]"
                    class="uk-input uk-border-rounded"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="uk-text-center uk-margin-top">
            <button
              dusk="update-payment"
              class="uk-button uk-border-rounded uk-button-primary"
              @click="updatePayment"
              :disabled="isUpdating"
            >
              <span v-if="isUpdating" uk-spinner="ratio: 1"></span>
              <span v-else>Actualizar</span>
            </button>
          </div>
        </div>
      </div>
      <div v-if="payment.fixed_at" class="uk-text-center uk-margin-top">
        <div
          :id="'configuration-fixed-at-' + payment.id"
          class="uk-text-warning"
        >Configuración de pago fijada el {{ formatDate(payment.fixed_at) }}</div>
        <button
          class="uk-button uk-border-rounded uk-button-primary uk-margin-small-top"
          @click="resetPayment"
          :disabled="isUpdating"
        >
          <span v-if="isUpdating" uk-spinner="ratio: 1"></span>
          <span v-else>Restablecer pago</span>
        </button>
      </div>
    </div>

    <hr class="uk-divider-icon" />
    <div>Actividad del pago</div>
    <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Operación</th>
          <th class="uk-text-center">Constancia sin firmar</th>
          <th class="uk-text-center">Constancia firmada</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="audit in payment.audits" :key="audit.id">
          <td
            :id="'detail-audit-created-' + audit.id + '-' + payment.id"
            width="70%"
          >{{ formatDatetime(audit.created_at) }}</td>
          <td
            :id="'detail-audit-paid-' + audit.id + '-' + payment.id"
            width="20%"
          >{{ audit.new_values.paid === 1 ? 'Pagado' : 'Pago revertido' }}</td>
          <td width="5%" class="uk-text-center">
            <a
              v-if="audit.unsigned_record"
              :href="audit.unsigned_record.path"
              target="_blank"
              uk-icon="cloud-download"
            ></a>
          </td>
          <td width="5%" class="uk-text-center">
            <a
              v-if="audit.signed_record"
              :href="audit.signed_record.path"
              target="_blank"
              uk-icon="cloud-download"
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import UIkit from "uikit";

export default {
  name: "PaymentDetail",

  props: ["payment", "refetchPage", "updateLocalPayment", "editPermission"],

  data() {
    return {
      editCardOpen: false,
      isUpdating: false,
      model: {
        base: null,
        bonuses: null,
        otherBonuses: null,
        benefitBonuses: null
      }
    };
  },

  methods: {
    openEditCard() {
      this.editCardOpen = true;
    },
    closeEditCard() {
      this.editCardOpen = false;
    },
    updatePayment() {
      this.isUpdating = true;

      axios
        .put("/payroll/fix-payment/" + this.payment.id.toString(), this.model)
        .then(({ data: { message, model } }) => {
          this.updateLocalPayment(model);
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Pago actualizado</div>",
            { status: "success", timeout: 3000 }
          );
          this.editCardOpen = false;
        })
        .catch(error => {
          console.log("error", error);
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
    resetPayment() {
      this.isUpdating = true;
      axios
        .put("/payroll/reset-payment/" + this.payment.id.toString())
        .then(({ data: { message } }) => {
          this.refetchPage(() => {
            this.isLoading = false;
            this.isUpdating = false;
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Pago restablecido</div>",
              { status: "success", timeout: 3000 }
            );
            const paymentDetailModal = UIkit.modal("#payment-detail-modal");
            if (paymentDetailModal) {
              paymentDetailModal.hide();
            }
            const payrollModalSwitcher = UIkit.switcher(
              "#payroll-modal-switcher"
            );
            if (payrollModalSwitcher) {
              // UIkit.switcher("#payroll-modal-switcher").show(0);
              this.$parent.resetPayrollModalSwitcher();
            }
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdating = false;
        });
    }
  },

  watch: {
    payment() {
      this.model.base = this.payment.base;
      this.model.bonuses = this.payment.bonuses;
      this.model.benefitBonuses = this.payment.benefit_bonuses.reduce(
        (accum, benefitBonus) => {
          accum[benefitBonus.id] = benefitBonus.amount;
          return accum;
        },
        {}
      );
      this.model.otherBonuses = this.payment.other_bonuses.reduce(
        (accum, otherBonus) => {
          accum[otherBonus.id] = otherBonus.amount;
          return accum;
        },
        {}
      );
      this.editCardOpen = false;
    }
  }
};
</script>