<template>
<div>
  <div class="uk-text-large">Categorías de Proveedores</div>
  <service-tree-level :level="payload.providerCategories"/>
  <div v-if="isLoadingProviders" class="uk-text-center uk-margin-top"><span uk-spinner></span></div>
  <div v-if="providers && !isLoadingProviders" id="providers">
    <div class="uk-text-large">Proveedores</div>
    <div class="uk-flex uk-flex-around uk-flex-wrap">
      <div
        v-for="provider in providers"
        @click="selectProvider(provider)"
        class="uk-card uk-border-rounded uk-padding-small uk-width-medium uk-margin-top uk-margin-bottom uk-box-shadow-hover-small provider-card"
        :class="{ 'uk-card-primary uk-box-shadow-small': (selectedProvider && provider.id === selectedProvider.id), 'uk-card-default uk-box-shadow-xlarge': !(selectedProvider && provider.id === selectedProvider.id) }">
        <div class="uk-text-center uk-text-bold uk-text-large">{{ provider.name }}</div>
      </div>
    </div>
    <hr class="uk-divider-icon uk-margin-remove">
    <div v-if="isLoadingServiceCategories" class="uk-text-center uk-margin-top"><span uk-spinner></span></div>
    <div v-if="serviceCategories && !isLoadingServiceCategories" class="uk-margin-large-left" id="services">
      <div v-for="category in serviceCategories">
        <div class="uk-text-large">{{ category.name }}</div>
        <div class="uk-flex uk-flex-wrap">
          <div
            v-for="service in category.services"
            class="uk-card uk-border-rounded uk-padding-small uk-width-medium uk-margin-top uk-margin-bottom uk-box-shadow-hover-small provider-card uk-card-default uk-box-shadow-xlarge">
            <div class="uk-text-center uk-text-bold uk-text-large">{{ service.name }}</div>
            <div class="uk-text-right">{{ toCurrency(service.price) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UIkit from 'uikit'

export default {
  name: 'ServicesExplore',

  props: ['payload'],

  data() {
    return {
      providers: null,
      isLoadingProviders: false,
      isLoadingServiceCategories: false,
      selectedProvider: null,
      serviceCategories: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      app.__vue__.$on('fetch-providers', providerId => this.fetchProviders(providerId))
      app.__vue__.$on('category-changed', this.clearProviders)
    })
  },

  methods: {
    clearProviders() {
      this.selectedProvider = null,
      this.providers = null
      this.serviceCategories = null
    },
    fetchProviders(categoryId) {
      this.isLoadingProviders = true
      axios.get('/services/category/' + categoryId.toString()).then(
        ({ data: { providers } }) => {
          this.providers = providers
          this.isLoadingProviders = false
          this.$nextTick(() => window.scrollTo({
            left: 0,
            top: document.body.scrollHeight,
            behavior: 'smooth'
          }))
        }
      ).catch(
        error => {
          console.log('error', error)
          this.isLoadingProviders = false
        }
      )
    },
    selectProvider(provider) {
      this.selectedProvider = provider
      this.fetchServices(this.selectedProvider.id)
    },
    fetchServices(providerId) {
      this.isLoadingServiceCategories = true
      axios.get('/services/provider/' + providerId.toString()).then(
        ({ data: { categories }}) => {
          this.serviceCategories = categories
          this.isLoadingServiceCategories = false
          this.$nextTick(() => window.scrollTo({
            left: 0,
            top: document.body.scrollHeight,
            behavior: 'smooth'
          }))
        }
      ).catch(
        error => {
          console.log('error', error)
          this.isLoadingServiceCategories = false
        }
      )
    }
  },
}
</script>

<style>
.provider-card {
  transition: box-shadow 0.1s ease-in-out,
              background 0.2s,
              color 0.2s !important;
  cursor: pointer;
}
</style>