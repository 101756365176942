<template>
  <div class="white-background">
    <nav class="uk-margin uk-padding-small uk-margin-remove-bottom" uk-navbar>
      <div class="uk-navbar-left">
        <a class="uk-navbar-toggle uk-hidden@s" uk-navbar-toggle-icon href="#offcanvas" uk-toggle></a>
        <a class="uk-navbar-item uk-logo" href="https://vivequilo.com/">
          <img src="/images/logo.png" alt width="150px" />
        </a>
      </div>

      <div class="uk-navbar-right uk-visible@s">
        <ul class="uk-navbar-nav">
          <li>
            <a href="#contact-modal" uk-toggle>EMPIEZA GRATIS</a>
          </li>
          <li>
            <a href="https://vivequilo.com/impact" target="_blank">NUESTRO IMPACTO</a>
          </li>
          <li>
            <a href="https://medium.com/q%C3%BCilo" target="_blank">ACERCA DE NOSOTROS</a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="uk-flex uk-flex-wrap uk-background-muted uk-flex-middle uk-padding">
      <div class="uk-width-1-2@s">
        <div id="lottie"></div>
      </div>
      <div class="uk-width-1-2@s uk-text-primary uk-margin-top">
        <div
          class="text-huge uk-text-bold uk-text-center uk-text-left@s"
        >Administrar nunca fue tan sencillo</div>

        <div
          class="uk-text-large uk-margin-small-top uk-text-center uk-text-left@s"
        >Ahorra tiempo, dinero y esfuerzo.</div>

        <div
          class="uk-text-large uk-margin-small-top uk-text-center uk-text-left@s"
        >QBoard automatiza la administración de tu empresa, para que te enfoques en cumplir tus metas.</div>

        <div class="uk-margin-top uk-text-center uk-text-right@s">
          <button
            class="uk-button uk-button-primary uk-border-rounded"
            uk-toggle
            href="#contact-modal"
          >Pruébalo gratis</button>
        </div>
      </div>
    </div>

    <div class="uk-padding">
      <div
        class="text-huge uk-text-primary uk-text-center uk-text-bold"
      >Encuentra todo en un mismo lugar</div>

      <div class="uk-flex uk-flex-wrap uk-flex-center margin-medium-top uk-visible@s">
        <div class="uk-width-2-3@s">
          <ul id="features" class="uk-switcher uk-text-primary">
            <li>
              <DirectorioGeneral />
              <div
                class="uk-margin-top uk-padding-small feature-description"
              >Digitaliza los expedientes de tu equipo de trabajo, facilitando su acceso en cualquier momento y con fácil localización.</div>
            </li>
            <li>
              <FichaColaborador />
              <div
                class="uk-margin-top uk-padding-small feature-description"
              >Maneja tus seguros y beneficios de Qüilo de una forma fácil, solicitando altas, bajas y modificaciones, con un clic. Además, disfruta de nuestro programa de prevención, educación y salud financiera para tu equipo de trabajo.</div>
            </li>
            <li>
              <DesglocePagos />
              <div
                class="uk-margin-top uk-padding-small feature-description"
              >Ingresa pagos, bonos, descuentos y crea constancias de pago de forma automatizada. También podrás llevar un cálculo actualizado de tu pasivo laboral, prestaciones, vacaciones, ausencias y horas extras.</div>
            </li>
            <li>
              <Solicitudes />
              <div
                class="uk-margin-top uk-padding-small feature-description"
              >Mantente al día de los requerimientos de tu equipo con un sistema de tickets, para que puedas darle un seguimiento a cada solicitud, y ordenarlas en pendientes, aprobadas y rechazadas.</div>
            </li>
          </ul>
        </div>
        <div class="uk-width-1-3@s uk-flex-middle uk-padding-small">
          <ul class="uk-tab-right" uk-tab="connect: #features; animation: uk-animation-fade">
            <li>
              <button
                href="#"
                class="uk-button uk-text-primary uk-padding-small uk-width-1-1 uk-text-left"
              >Consulta el directorio de la empresa</button>
            </li>
            <li>
              <button
                href="#"
                class="uk-button uk-text-primary uk-padding-small uk-width-1-1 uk-text-left"
              >Administra los beneficios de cada empleado</button>
            </li>
            <li>
              <button
                href="#"
                class="uk-button uk-text-primary uk-padding-small uk-width-1-1 uk-text-left"
              >Calcula tu nómina y prestaciones</button>
            </li>
            <li>
              <button
                href="#"
                class="uk-button uk-text-primary uk-padding-small uk-width-1-1 uk-text-left"
              >Gestiona las solicitudes de tu equipo</button>
            </li>
          </ul>

          <div class="uk-text-right">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              uk-toggle
              href="#contact-modal"
            >Pruébalo gratis</button>
          </div>
        </div>
      </div>

      <div class="uk-hidden@s uk-text-primary">
        <div class="uk-margin-top">
          <DirectorioGeneral />
          <div class="uk-padding-small">
            <div class="uk-text-large">Consulta el directorio de la empresa</div>
            <div
              class="uk-margin-top text-light feature-description"
            >Digitaliza los expedientes de tu equipo de trabajo, facilitando su acceso en cualquier momento y con fácil localización.</div>
          </div>
        </div>
        <div class="uk-margin-top">
          <FichaColaborador />
          <div class="uk-padding-small">
            <div class="uk-text-large">Administra los beneficios de cada empleado</div>
            <div
              class="uk-margin-top text-light feature-description"
            >Maneja tus seguros de Qüilo de una forma fácil, solicitando altas, bajas y modificaciones, con un clic. Además, disfruta de nuestro programa de prevención, educación y salud financiera para tu equipo de trabajo.</div>
          </div>
        </div>
        <div class="uk-margin-top">
          <DesglocePagos />
          <div class="uk-padding-small">
            <div class="uk-text-large">Calcula tus próximos pagos y expórtalos</div>
            <div
              class="uk-margin-top text-light feature-description"
            >Ingresa pagos, bonos, descuentos y crea constancias de pago de forma automatizada. También podrás llevar un cálculo actualizado de tu pasivo laboral, prestaciones, vacaciones, ausencias y horas extras.</div>
          </div>
        </div>
        <div class="uk-margin-top">
          <Solicitudes />
          <div class="uk-padding-small">
            <div class="uk-text-large">Gestión de solicitudes de tu equipo</div>
            <div
              class="uk-margin-top text-light feature-description"
            >Mantente al día de los requerimientos de tu equipo con un sistema de tickets, para que puedas darle un seguimiento a cada solicitud, y ordenarlas en pendientes, aprobadas y rechazadas.</div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="uk-position-relative uk-visible-toggle"
      tabindex="-1"
      uk-slideshow="max-height: 600; min-height: 600"
    >
      <ul class="uk-slideshow-items">
        <li>
          <div
            class="uk-flex uk-flex-middle uk-flex-wrap-reverse uk-background-muted uk-height-1-1 uk-padding"
          >
            <div class="uk-width-1-2@s">
              <div class="text-huge uk-text-primary">Beneficios que agregan valor</div>
              <div
                class="uk-text-primary uk-margin-top uk-text-large"
              >Accede y administra beneficios para tu empresa y trabajadores, fácil y rápido desde un mismo sitio.</div>
              <div class="uk-margin-top">
                <button
                  class="uk-button uk-button-primary uk-border-rounded"
                  uk-toggle
                  href="#contact-modal"
                >Empieza ya</button>
              </div>
            </div>
            <div class="uk-text-center uk-width-1-2@s">
              <img src="/svgs/benefits-lamp.svg" alt uk-svg height="300" />
            </div>
          </div>
        </li>
        <li>
          <div
            class="uk-flex uk-flex-middle uk-flex-wrap uk-background-muted uk-height-1-1 uk-padding"
          >
            <div
              class="uk-width-1-3@s uk-text-primary uk-text-large"
            >Ofrecemos seguros de vida, gastos médicos, contra accidentes y de daños; beneficios dentales, médicos, psicológicos y más soluciones para aportar al bienestar de tu equipo y su salud integral.</div>
            <div class="uk-width-1-3@s">
              <div id="lottie3"></div>
            </div>
            <div class="uk-width-1-3@s">
              <div
                class="uk-text-primary uk-text-large uk-text-right"
              >Además, tu equipo de trabajo podrá disfrutar de nuestro programa de prevención, educación y salud financiera.</div>
              <div class="uk-text-right uk-margin-top">
                <button
                  class="uk-button uk-button-primary uk-border-rounded"
                  uk-toggle
                  href="#contact-modal"
                >Empieza ya</button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="uk-flex uk-flex-middle uk-flex-wrap-reverse uk-height-1-1">
            <div class="uk-width-2-3@s uk-height-1-1 uk-flex uk-flex-middle uk-padding">
              <div
                class="uk-flex uk-flex-wrap uk-flex-center uk-flex-middle uk-width-xxlarge uk-margin-auto"
              >
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/elroble/logo.png"
                    alt
                    width="100%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/universales/logo.png"
                    alt
                    width="100%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/mapfre/logo.png"
                    alt
                    width="100%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/bmi/logo.png"
                    alt
                    width="60%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/laceiba/logo.png"
                    alt
                    width="50%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/bantrab/logo.png"
                    alt
                    width="100%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/gyt/logo.png"
                    alt
                    width="100%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/confio/logo.png"
                    alt
                    width="70%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/bluemedical/logo.png"
                    alt
                    width="100%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/epss/logo.png"
                    alt
                    width="90%"
                  />
                </div>
                <div
                  class="uk-text-center uk-padding-small uk-padding-remove-top uk-width-1-2 uk-width-1-4@s"
                >
                  <img
                    src="https://quilo-public.s3.amazonaws.com/insurers/tuconsejeria/logo.png"
                    alt
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div
              class="uk-background-muted uk-padding uk-text-right uk-width-1-3@s uk-height-1-1 uk-flex uk-flex-middle"
            >
              <div>
                <div class="text-huge uk-text-primary">Nuestros aliados</div>
                <div
                  class="uk-text-primary uk-margin-top uk-text-large"
                >Accede y administra beneficios para tu empresa y trabajadores, desde un mismo sitio.</div>
                <div class="uk-margin-top">
                  <button
                    class="uk-button uk-button-primary uk-border-rounded"
                    uk-toggle
                    href="#contact-modal"
                  >Empieza ya</button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <a
        class="uk-position-center-left uk-position-small uk-hidden-hover"
        href="#"
        uk-slidenav-previous
        uk-slideshow-item="previous"
      ></a>
      <a
        class="uk-position-center-right uk-position-small uk-hidden-hover"
        href="#"
        uk-slidenav-next
        uk-slideshow-item="next"
      ></a>
    </div>

    <div class="uk-padding">
      <div class="text-huge uk-text-primary uk-text-center uk-text-bold">Estás en buenas manos</div>

      <div class="uk-flex uk-flex-wrap uk-text-primary">
        <div class="uk-width-1-1 uk-width-1-3@s uk-padding-small">
          <div class="uk-text-center">
            <img src="/svgs/QB_01.svg" alt uk-svg height="300" />
          </div>
          <div class="uk-text-bold uk-text-large">Acompañamiento en la implementación</div>
          <div
            class="uk-margin-small-top"
          >Nuestros expertos te guiarán paso a paso, para que explores y aproveches al máximo la herramienta.</div>
        </div>
        <div class="uk-width-1-1 uk-width-1-3@s uk-padding-small">
          <div class="uk-text-center">
            <img src="/svgs/QB_02.svg" alt uk-svg height="300" />
          </div>
          <div class="uk-text-bold uk-text-large">Planes a la medida de tus necesidades</div>
          <div
            class="uk-margin-small-top"
          >Customizamos el servicio para poderte ofrecer el esquema que más se adapta a tus necesidades.</div>
        </div>
        <div class="uk-width-1-1 uk-width-1-3@s uk-padding-small">
          <div class="uk-text-center">
            <img src="/svgs/QB_03.svg" alt uk-svg height="300" />
          </div>
          <div class="uk-text-bold uk-text-large">Estamos aquí para apoyarte</div>
          <div
            class="uk-margin-small-top"
          >¿Dudas o inconvenientes? Contáctanos en el momento que lo necesites, para recibir soporte personalizado.</div>
        </div>
      </div>
    </div>

    <div class="uk-padding uk-flex uk-flex-wrap uk-flex-middle uk-background-muted">
      <div class="uk-width-1-2@s">
        <div
          class="text-huge uk-text-primary uk-text-bold uk-text-primary uk-text-center uk-text-left@s"
        >La nueva era de la administración</div>

        <div
          class="uk-text-primary uk-text-large uk-margin-small-top uk-text-center uk-text-left@s"
        >Solicita un demo de QBoard y empieza a ver los resultados que esta herramienta trae a tu empresa.</div>

        <div class="uk-text-center uk-text-left@s">
          <button
            class="uk-button uk-button-primary uk-border-rounded uk-margin-top"
            uk-toggle
            href="#contact-modal"
          >Pruébalo gratis</button>
        </div>
      </div>

      <div class="uk-width-1-2@s">
        <div id="lottie2"></div>
      </div>
    </div>

    <div class="uk-background-primary uk-padding text-white">
      <div class="uk-flex uk-flex-center uk-flex-between@s uk-flex-wrap-reverse">
        <div
          class="uk-flex uk-flex-wrap uk-width-1-1 uk-width-auto@s uk-flex-center uk-flex-left@s"
        >
          <!-- <div class="uk-text-center uk-text-left@s uk-width-1-1 uk-width-auto@s"><a class="uk-button footer-link uk-padding-remove" target="_blank">Preguntas frecuentes</a></div> -->
          <div class="uk-text-center uk-text-left@s uk-width-1-1 uk-width-auto@s">
            <a
              class="uk-button footer-link uk-padding-remove"
              target="_blank"
              href="https://medium.com/q%C3%BCilo"
            >Blog</a>
          </div>
          <div class="uk-text-center uk-text-left@s uk-width-1-1 uk-width-auto@s">
            <a
              class="uk-button footer-link uk-padding-remove"
              target="_blank"
              href="http://bit.ly/2Y1p94i"
            >Términos de servicio</a>
          </div>
          <div class="uk-text-center uk-text-left@s uk-width-1-1 uk-width-auto@s">
            <a
              class="uk-button footer-link uk-padding-remove"
              target="_blank"
              href="https://wa.me/50259090909"
            >Contacto</a>
          </div>
          <!-- <div class="uk-text-center uk-text-left@s uk-width-1-1 uk-width-auto@s"><a class="uk-button footer-link uk-padding-remove" target="_blank">Newsletter</a></div> -->
        </div>
        <div class="uk-width-1-1 uk-width-1-6@s uk-text-center uk-text-right@s">
          <img class="white-logo" src="/images/logo.png" alt width="150px" />
        </div>
      </div>

      <div
        class="uk-flex uk-flex-wrap uk-flex-bottom uk-flex-center uk-flex-between@s uk-margin-small-top"
      >
        <div class="uk-width-1-1 uk-width-5-6@s">
          <div class="uk-text-center uk-text-left@s uk-text-bold">hola@vivequilo.com</div>
          <div class="uk-text-center uk-text-left@s uk-text-bold">+502 5909 0909</div>
          <div class="uk-text-center uk-text-left@s text-light">Vía 4, 1-30, Zona 4, Campus Tec 2.</div>
          <div class="uk-text-center uk-text-left@s text-light">Ciudad de Guatemala, Guatemala.</div>
          <div
            class="uk-text-center uk-text-left@s uk-margin-small-top text-light"
          >Todos los derechos reservados. Qüilo 2019.</div>
        </div>
        <div class="uk-width-1-1 uk-width-auto@s uk-text-right@s uk-text-center">
          <a href="http://m.me/vivequilo">
            <img src="/svgs/facebook.svg" uk-svg alt width="35" class="uk-margin-small-left" />
          </a>
          <a href="https://instagram.com/vivequilo">
            <img src="/svgs/instagram.svg" uk-svg alt width="35" class="uk-margin-small-left" />
          </a>
          <a href="https://wa.me/50259090909">
            <img src="/svgs/whatsapp.svg" uk-svg alt width="35" class="uk-margin-small-left" />
          </a>
        </div>
      </div>
    </div>

    <div id="offcanvas" uk-offcanvas="mode: push; overlay: true">
      <div class="uk-offcanvas-bar">
        <div class="uk-panel">
          <ul class="uk-nav uk-nav-default tm-nav uk-margin-top">
            <li>
              <a class="uk-link-reset" href="#contact-modal" uk-toggle>EMPIEZA GRATIS</a>
            </li>
            <li>
              <a
                class="uk-link-reset"
                href="https://medium.com/q%C3%BCilo"
                target="_blank"
              >ACERCA DE NOSOTROS</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="contact-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div
          class="uk-text-secondary uk-text-center uk-text-large"
        >Déjanos tus datos y un asesor se contactará contigo para apoyarte con la implementación en tu empresa.</div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                class="uk-input uk-border-rounded"
                
                type="text"
                v-model="model.name"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Email</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                class="uk-input uk-border-rounded"
                
                type="text"
                v-model="model.email"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Celular</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                class="uk-input uk-border-rounded"
                
                type="text"
                v-model="model.phone"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Empresa</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                class="uk-input uk-border-rounded"
                
                type="text"
                v-model="model.business"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Número de colaboradores</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                class="uk-input uk-border-rounded"
                
                type="text"
                v-model="model.insureds_quantity"
              />
            </div>
          </div>
        </div>
        <div class="uk-text-center uk-margin-small-top">
          <button
            class="uk-button uk-button-secondary uk-border-rounded uk-width-small uk-box-shadow-small"
            @click="store"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Enviar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import headerAnimation from "./headerAnimation.json";
import footerAnimation from "./footerAnimation.json";
import scrollingAnimation from "./scrollingAnimation.json";
import DesglocePagos from "./Utils/DesglocePagos";
import DirectorioGeneral from "./Utils/DirectorioGeneral";
import FichaColaborador from "./Utils/FichaColaborador";
import Solicitudes from "./Utils/Solicitudes";
import lottie from "../lottie";

export default {
  name: "Landing",

  components: {
    DesglocePagos,
    DirectorioGeneral,
    FichaColaborador,
    Solicitudes
  },

  data() {
    return {
      isLoading: false,
      model: {
        name: "",
        email: "",
        phone: "",
        business: "",
        insureds_quantity: ""
      }
    };
  },

  methods: {
    store() {
      this.isLoading = true;
      axios
        .post("/board", this.model)
        .then(response => {
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Información solicitada</div>",
            { status: "success", timeout: 4000 }
          );
          UIkit.modal("#contact-modal").hide();
          this.model.name = "";
          this.model.email = "";
          this.model.phone = "";
          this.model.business = "";
          this.model.insureds_quantity = "";
          this.isLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    startLottie(elementId, animationJson) {
      let params = {
        container: document.getElementById(elementId),
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationJson
      };

      return lottie.loadAnimation(params);
    }
  },

  mounted() {
    this.startLottie("lottie", headerAnimation);
    this.startLottie("lottie2", footerAnimation);
    this.startLottie("lottie3", scrollingAnimation);
  }
};
</script>

<style scoped>
#lottie {
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
}

.margin-medium-top {
  margin-top: 30px;
}

.uk-tab > .uk-active > .uk-button {
  background-color: rgb(208, 220, 253);
}

.text-huge {
  font-size: 45px;
  line-height: 1;
}

.white-background {
  background-color: white;
}

.white-logo {
  filter: brightness(0) invert(100%);
}

.footer-link {
  background-color: transparent;
  transition: all 0.3s;
}

.footer-link:hover {
  border-bottom: solid 1px rgb(78, 194, 163);
  color: rgb(78, 194, 163);
}

.feature-description {
  font-size: 19px;
}

.uk-button-primary {
  transition: background-color 0.2s;
}
.uk-button-primary:hover {
  background-color: rgb(78, 194, 163);
}

@media only screen and (min-width: 640px) {
  .footer-link {
    margin-right: 30px;
  }
}
</style>