<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Organigrama</div>
      <div class="uk-text-center">
        <button
          class="uk-button uk-border-rounded uk-button-primary"
          @click="printChart"
          v-if="checkPermission('export-organization-chart')"
        >Exportar vista actual</button>
      </div>
    </div>
    <div
      id="cy"
      uk-height-viewport="offset-top: true"
      class="uk-margin-small-top uk-border-rounded uk-overflow-hidden"
    ></div>
  </div>
</template>

<script>
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";

cytoscape.use(dagre);

export default {
  name: "DirectoryOrganizationChart",

  props: ["payload"],

  methods: {
    printChart() {
      const canvas = document.querySelectorAll("[data-id=layer2-node]");
      const img = canvas[0].toDataURL("image/png");
      let windowContent = "<!DOCTYPE html>";
      windowContent += "<html>";
      windowContent += "<head><title></title></head>";
      windowContent += "<body>";
      windowContent += '<img src="' + img + '">';
      windowContent += "</body>";
      windowContent += "</html>";

      const printWin = window.open(
        "",
        "",
        "width=" + screen.availWidth + ",height=" + screen.availHeight
      );
      printWin.document.open();
      printWin.document.write(windowContent);

      printWin.document.addEventListener(
        "load",
        function() {
          printWin.focus();
          printWin.print();
          printWin.document.close();
          printWin.close();
        },
        true
      );
    }
  },

  mounted() {
    let elements = this.payload.divisions.map(division => ({
      group: "nodes",
      data: {
        id: "division-" + division.id,
        name: division.name
      }
    }));

    elements.push(
      ...this.payload.insureds.map(insured => ({
        group: "nodes",
        data: {
          id: insured.id.toString(),
          name: insured.first_name + " " + insured.last_name,
          parent: "division-" + insured.division.id
        }
      }))
    );

    this.payload.insureds.map(insured => {
      if (insured.business_boss) {
        elements.push({
          group: "edges",
          data: {
            source: insured.business_boss.id.toString(),
            target: insured.id.toString()
          }
        });
      }
    });

    var cy = (window.cy = cytoscape({
      container: document.getElementById("cy"),

      layout: {
        name: "dagre",
        spacingFactor: 1.2,
        // padding: 30,
        // fit: true,
        nodeDimensionsIncludeLabels: true,
        animate: true,
        nodeDimensionsIncludeLabels: true
        // boundingBox: {
        //   x1: 100,
        //   y1: 120,
        //   w: 400,
        //   h: 400
        // }
      },

      style: [
        {
          selector: "node",
          style: {
            label: "data(name)",
            "background-color": "#2c5fa1",
            padding: "60px",
            "text-valign": "center",
            "text-halign": "center",
            color: "white"
          }
        },
        {
          selector: ":parent",
          style: {
            "background-opacity": 0.333
          }
        },
        {
          selector: "edge",
          style: {
            width: 3,
            "line-color": "rgb(78, 194, 163)",
            "curve-style": "straight"
          }
        },
        {
          selector: "edge[arrow]",
          style: {
            "target-arrow-shape": "triangle"
          }
        }
      ],

      elements
    }));
    cy.fit();
    cy.panBy({
      x: 0,
      y: 100
    });
  }
};
</script>

<style>
#cy {
  border: solid 1px;
}
</style>