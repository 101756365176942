<template>
  <div>
    <div>
      <div>Resultado</div>
      <div class="uk-background-muted uk-padding-small uk-border-rounded">
        <latex v-if="formulaIsValid && postfix" :postfix="postfix" :tokens="tokens" />
        <div class="uk-text-center" v-else>Fórmula incompleta</div>
      </div>
    </div>
    <div class="uk-margin-small-top">
      <div>Editor</div>
      <div class="uk-background-muted uk-padding-small uk-border-rounded">
        <span v-for="(token, index) in formula" :key="index">
          <span class="uk-margin-small-right">
            <span v-if="token.type === 'token'" class="uk-badge">{{ token.name }}</span>
            <span v-else>{{ token.name }}</span>
          </span>
        </span>
      </div>
    </div>
    <div class="uk-margin-small-top uk-flex uk-flex-between">
      <div>
        <button
          class="uk-button uk-border-rounded uk-button-primary"
          @click="addToken({ name: '(', slug: '(', type: 'opening-parenthesis' })"
          :disabled="!canOpenParenthesis"
        >(</button>
        <button
          class="uk-button uk-border-rounded uk-button-primary"
          @click="addToken({ name: ')', slug: ')', type: 'closing-parenthesis' })"
          :disabled="!canCloseParenthesis"
        >)</button>
        <button
          class="uk-button uk-border-rounded uk-button-primary"
          v-for="operator in operators"
          :key="operator.name"
          @click="addToken(operator)"
          :disabled="!canPutOperator"
        >{{ operator.name }}</button>
      </div>
      <div>
        <button
          class="uk-button uk-border-rounded uk-button-primary"
          @click="deleteToken"
          :disabled="!canDelete"
        >Borrar última entrada</button>
      </div>
    </div>
    <div class="uk-margin-small-top">
      <div>Buscar variables</div>
      <v-select
        :disabled="canPutOperator"
        class="uk-input uk-border-rounded"
        :options="tokens.map(token => ({ ...token, type: 'token' }))"
        :reduce="token => token"
        :getOptionLabel="option => option.name"
        v-model="tokenSelector"
        @input="selectToken"
      ></v-select>
    </div>
    <div class="uk-margin-small-top">
      <div>Ingresar valor constante</div>
      <div class="uk-flex">
        <input
          class="uk-input uk-border-rounded uk-width-4-5"
          type="text"
          :disabled="canPutOperator"
          v-model="constantInput"
        />
        <button
          class="uk-button uk-border-rounded uk-button-primary uk-width-1-5"
          @click="addToken({ name: constantInput, slug: constantInput, type: 'constant' })"
          :disabled="canPutOperator || constantInput === null"
        >Agregar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueMathjax } from "vue-mathjax";
// https://www.mathblog.dk/tools/infix-postfix-converter/
const isOperator = c => {
  return c == "+" || c == "-" || c == "*" || c == "/" || c == "^";
};

const leftAssoc = c => {
  return c != "^";
};

const priority = c => {
  if (c == "^") return 3;
  if (c == "*") return 2;
  if (c == "/") return 2;
  if (c == "+") return 1;
  if (c == "-") return 1;
  return 0;
};

const rightPriority = c => {
  if (c == "+") return 1;
  if (c == "-") return 2;
  if (c == "*") return 3;
  if (c == "/") return 4;
  if (c == "^") return 5;
  return 0;
};

const operators = ["+", "-", "*", "/", "^"].map(operator => ({
  name: operator,
  slug: operator,
  type: "operator"
}));
const postfixToInfix = (postfix, availableTokens) => {
  let stack = [],
    op1,
    op2,
    operator;
  postfix.forEach((token, index) => {
    operator = operators.find(operator => operator.slug === token);
    if (operator) {
      switch (token) {
        default:
          op2 = stack.pop();
          op1 = stack.pop();
          stack.push(
            ...[
              postfix.length !== index + 1
                ? { name: "(", slug: "(", type: "opening-parenthesis" }
                : null,
              op1,
              operator,
              op2,
              postfix.length !== index + 1
                ? { name: ")", slug: ")", type: "closing-parenthesis" }
                : null
            ].filter(x => x !== null)
          );
          break;
      }
    } else {
      let structuredToken = availableTokens.find(
        availableToken => availableToken.slug === token
      );
      stack.push({ ...structuredToken, type: "token" });
    }
  });
  return stack;
};

export default {
  name: "FormulaEditor",

  props: ["value", "tokens", "initialValue"],

  components: {
    VueMathjax
  },

  data() {
    return {
      formula: [],
      operators,
      tokenSelector: null,
      constantInput: null
    };
  },

  methods: {
    parseInitialValue() {
      if (this.initialValue) {
        this.formula = postfixToInfix(this.initialValue, this.tokens);
      } else {
        this.formula = [];
      }
    },
    addToken(token) {
      this.formula.push(token);
      if (token.type === "constant") {
        this.constantInput = null;
      }
    },
    deleteToken() {
      this.formula.pop();
    },
    selectToken(token) {
      if (token) {
        this.addToken(token);
        this.$nextTick(() => {
          this.tokenSelector = null;
        });
      }
    }
  },

  computed: {
    displayFormula() {
      return this.formula.map(token => token.name).join(" ");
    },
    canDelete() {
      // The formula is not empty
      return this.formula.length !== 0;
    },
    canPutOperator() {
      // The last token is a token or a closing-parenthesis
      return (
        this.formula.length !== 0 &&
        (this.formula[this.formula.length - 1].type === "token" ||
          this.formula[this.formula.length - 1].type === "constant" ||
          this.formula[this.formula.length - 1].type === "closing-parenthesis")
      );
    },
    canOpenParenthesis() {
      // At the start of the formula or the last token is an operator or another opening-parenthesis
      return (
        this.formula.length === 0 ||
        (this.formula.length !== 0 &&
          (this.formula[this.formula.length - 1].type === "operator" ||
            this.formula[this.formula.length - 1].type ===
              "opening-parenthesis"))
      );
    },
    canCloseParenthesis() {
      // opening-paretheses quantity is greater than the closing-parentheses quantity and the last token is a token or a closing-parenthesis
      return (
        this.formula.filter(token => token.slug === "(").length >
          this.formula.filter(token => token.slug === ")").length &&
        this.formula.length !== 0 &&
        (this.formula[this.formula.length - 1].type === "token" ||
          this.formula[this.formula.length - 1].type === "constant" ||
          this.formula[this.formula.length - 1].type === "closing-parenthesis")
      );
    },
    formulaIsValid() {
      // Parentheses are even and the last token is a closing-parenthesis or token
      return (
        this.formula.length !== 0 &&
        this.formula.filter(token => token.slug === "(").length ===
          this.formula.filter(token => token.slug === ")").length &&
        (this.formula[this.formula.length - 1].type === "token" ||
          this.formula[this.formula.length - 1].type === "constant" ||
          this.formula[this.formula.length - 1].type === "closing-parenthesis")
      );
    },
    postfix() {
      let tokens = this.formula.map(token => token.slug);
      let S = [],
        O = [],
        tok;

      while ((tok = tokens.shift())) {
        if (tok == "(") S.push(tok);
        else if (tok == ")") {
          while (S.length > 0 && S[S.length - 1] != "(") O.push(S.pop());
          if (S.length == 0) return null;
          S.pop();
        } else if (isOperator(tok)) {
          while (
            S.length > 0 &&
            isOperator(S[S.length - 1]) &&
            ((leftAssoc(tok) && priority(tok) <= priority(S[S.length - 1])) ||
              (!leftAssoc(tok) && priority(tok) < priority(S[S.length - 1])))
          )
            O.push(S.pop());
          S.push(tok);
        } else {
          O.push(tok);
        }
      }

      while (S.length > 0) {
        if (!isOperator(S[S.length - 1])) return null;
        O.push(S.pop());
      }

      if (O.length == 0) return null;
      return O;
    }
  },

  watch: {
    postfix() {
      this.$emit("input", this.postfix);
    }
  }
};
</script>