<template>
  <div>
    <div class="uk-flex uk-flex-between uk-flex-wrap">
      <div v-if=" view === 'next'" class="uk-text-large">Próximos pagos</div>
      <div v-else class="uk-text-large">Pagos realizados</div>
      <div class="uk-text-center">
        <button
          v-if="view === 'next' ? checkPermission('pay') : checkPermission('revert-payment')"
          dusk="process-payments"
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#payment-confirmation-modal"
          uk-toggle
        >{{ view == "next" ? "Pagar seleccionados" : "Revertir seleccionados"}}</button>
        <!-- EXPORT NEXT PAYMENTS -->
        <div v-if="view === 'next'" class="uk-inline">
          <button
            class="uk-button uk-border-rounded uk-button-muted"
            type="button"
            :disabled="isFetching"
          >
            <span v-if="isFetching" uk-spinner></span>
            <span v-else>Reportes</span>
          </button>
          <div
            uk-dropdown="pos: bottom-right; animation: uk-animation-slide-top-small; duration: 100;"
            class="uk-card uk-card-default uk-border-rounded"
          >
            <ul class="uk-nav uk-dropdown-nav">
              <li class="uk-text-right" v-if="checkPermission('export-next-payments')">
                <a
                  :href="'/payroll/next-payments/bulk?from=' + paymentsFilterFrom +
                '&to=' + paymentsFilterTo +
                '&query=' + paymentsFilter +
                '&payrollType=' + payrollType +
                '&division=' + division +
                '&tag=' + tag"
                >Exportar pagos en rango</a>
              </li>
              <li class="uk-text-right" v-if="checkPermission('export-payroll')">
                <a
                  :disabled="isFetching"
                  @click="isFetching ? null : requestPayrollReport()"
                >Planilla en rango</a>
              </li>
              <li class="uk-text-right" v-if="checkPermission('export-detailed-payroll')">
                <a
                  :disabled="isFetching"
                  @click="isFetching ? null : requestDetailedPayrollReport()"
                >Planilla detallada en rango</a>
              </li>
              <li class="uk-text-right" v-if="checkPermission('export-employer-report')">
                <a
                  :disabled="isFetching"
                  @click="isFetching ? null : requestEmployerReport()"
                >Reporte del empleador</a>
              </li>
            </ul>
          </div>
        </div>
        <span v-else>
          <a
            v-if="checkPermission('export-past-payments')"
            :href="'/payroll/past-payments/bulk?from=' + paymentsFilterFrom + 
          '&to=' + paymentsFilterTo + 
          '&query=' + paymentsFilter +  
          '&payrollType=' + payrollType + 
          '&division=' + division + 
          '&tag=' + tag"
            class="uk-button uk-border-rounded uk-button-primary"
          >Exportar pagos en rango</a>
        </span>
      </div>
    </div>

    <div class="uk-flex uk-flex-center">
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          id="start-date"
          class="uk-input uk-border-rounded uk-text-center"
          v-model="paymentsFilterFrom"
          :config="datepickerConfig"
          :disabled="isFetching"
        ></flat-pickr>
      </div>
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          id="end-date"
          class="uk-input uk-border-rounded uk-text-center"
          v-model="paymentsFilterTo"
          :config="datepickerConfig"
          :disabled="isFetching"
        ></flat-pickr>
      </div>
    </div>

    <div class="uk-flex uk-flex-around uk-flex-wrap">
      <div>
        <div>Tipo de remuneración</div>
        <select
          name="payroll-type"
          class="uk-select uk-width-medium uk-border-rounded"
          v-model="payrollType"
          :disabled="isFetching"
        >
          <option disabled>Tipo de remuneración</option>
          <option value="all">Todos</option>
          <option
            v-for="payrollType in payload.payrollTypes"
            :key="payrollType.id"
            :value="payrollType.id"
          >{{ payrollType.name }}</option>
        </select>
      </div>

      <div>
        <div>División</div>
        <select
          name="division-id-select"
          class="uk-select uk-width-medium uk-border-rounded"
          v-model="division"
          :disabled="isFetching"
        >
          <option disabled>División</option>
          <option value="all">Todas</option>
          <option
            v-for="division in payload.divisions"
            :key="division.id"
            :value="division.id"
          >{{ division.name }}</option>
        </select>
      </div>

      <div>
        <div>Tag</div>
        <select
          class="uk-select uk-width-medium uk-border-rounded"
          v-model="tag"
          :disabled="isFetching"
        >
          <option disabled>Tag</option>
          <option value="all">Todos</option>
          <option v-for="tag in payload.tags" :key="tag.id" :value="tag.name.es">#{{ tag.name.es }}</option>
        </select>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <form autocomplete="off">
            <input
              autocomplete="off"
              class="uk-search-input search-input-animation"
              name="search"
              placeholder="Buscar pago..."
              v-model="paymentsFilter"
            />
          </form>
        </form>
        <pagination-buttons
          :prev-page-url="payments.prev_page_url"
          :is-fetching="isFetching"
          :current-page="payments.current_page"
          :last-page="payments.last_page"
          :next-page-url="payments.next_page_url"
        ></pagination-buttons>
      </div>

      <div
        class="uk-card uk-card-primary uk-card-body uk-padding-small uk-border-rounded uk-width-1-2@m uk-margin-auto uk-text-center uk-margin-small-top"
        v-if="paymentSelected"
        id="payments-total"
      >
        {{ selectedPayments.length }} pago{{ selectedPayments.length === 1 ? '' : 's' }} seleccionado{{ selectedPayments.length === 1 ? '' : 's' }}
        para {{ selectedInsureds.length }} colaborador{{ selectedInsureds.length === 1 ? '' : 'es' }}
        con un total de {{ toCurrency(selectedPayments.map(payment => payment.totalAmount).reduce((accum, value) => accum + value, 0)) }}
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th v-if="view === 'next' ? checkPermission('pay') : checkPermission('revert-payment')">
              <input class="uk-checkbox" type="checkbox" v-model="allSelected" />
            </th>
            <th>Período</th>
            <th>Colaborador</th>
            <th>División</th>
            <th>Base / Honorarios</th>
            <th>Prestaciones</th>
            <th>Bonos de productividad</th>
            <th>Otros bonos</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in payments.data" :key="payment.id">
            <td
              width="5%"
              v-if="view === 'next' ? checkPermission('pay') : checkPermission('revert-payment')"
            >
              <input
                class="uk-checkbox"
                :name="'check-payment-' + payment.id"
                type="checkbox"
                v-model="payment.selected"
              />
            </td>
            <td width="20%">
              <div :id="'record-start-date-' + payment.id">{{ formatDate(payment.start_date) }}</div>
              <div :id="'record-end-date-' + payment.id">{{ formatDate(payment.end_date) }}</div>
            </td>
            <td width="15%">
              <a
                :href="'/directory/general/' + payment.insured_business.insured.id.toString()"
                class="uk-text-bold uk-link-reset"
                :id="'record-insured-name-' + payment.id"
              >{{ payment.insured_business.insured.first_name }} {{ payment.insured_business.insured.last_name }}</a>
            </td>
            <td
              width="5%"
              :id="'record-division-name-' + payment.id"
            >{{ payment.insured_business.division.name }}</td>
            <td width="10%" :id="'record-base-' + payment.id">{{ toCurrency(payment.base) }}</td>
            <td
              width="10%"
              :id="'record-benefits-' + payment.id"
            >{{ toCurrency(payment.benefitBonusesAmount) }}</td>
            <td
              width="10%"
              :id="'record-fixed-bonuses-' + payment.id"
            >{{ toCurrency(payment.fixedBonuses) }}</td>
            <td
              width="10%"
              :id="'record-other-bonuses-' + payment.id"
            >{{ toCurrency(payment.otherBonusesAmount) }}</td>
            <td
              width="10%"
              :id="'record-total-amount-' + payment.id"
            >{{ toCurrency(payment.totalAmount) }}</td>
            <td width="5%">
              <button
                :id="'payment-detail-' + payment.id"
                :dusk="'payment-detail-' + payment.id"
                class="uk-button uk-border-rounded uk-button-secondary"
                uk-icon="chevron-right"
                @click="setPayment(payment)"
                href="#payment-detail-modal"
                uk-toggle
                :disabled="isLoading"
              ></button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="bottom-divider uk-hidden@s" v-for="payment in payments.data" :key="payment.id">
        <div class="uk-flex uk-flex-between">
          <div>
            <a
              :href="'/directory/general/' + payment.insured_business.insured.id.toString()"
              class="link-underline"
            >
              <span
                :id="'responsive-record-insured-name-' + payment.id"
                class="uk-text-bold"
              >{{ payment.insured_business.insured.first_name }} {{ payment.insured_business.insured.last_name }}</span>
            </a>
            <span
              v-if="payment.insured_business.division"
              :id="'responsive-record-insured-division-' + payment.id"
            >- {{ payment.insured_business.division.name }}</span>
          </div>
          <div></div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span
              :id="'responsive-record-date-string-' + payment.id"
            >{{ formatDate(payment.start_date) }} - {{ formatDate(payment.end_date) }}</span>
          </div>
          <div></div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div
            class="uk-width-expand"
            uk-leader
          >{{ payment.payroll.payroll_type_id === 1 ? 'Base' : 'Honorarios' }}</div>
          <div :id="'responsive-record-base-' + payment.id">{{ toCurrency(payment.base) }}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div class="uk-width-expand" uk-leader>Prestaciones</div>
          <div :id="'responsive-record-benefits-' + payment.id">{{ toCurrency(payment.benefits) }}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div class="uk-width-expand" uk-leader>Bonos de productividad</div>
          <div
            :id="'responsive-record-fixed-bonuses-' + payment.id"
          >{{ toCurrency(payment.fixedBonuses) }}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div class="uk-width-expand" uk-leader>Otros bonos</div>
          <div
            :id="'responsive-record-toher-bonuses-' + payment.id"
          >{{ toCurrency(payment.otherBonusesAmount) }}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div class="uk-width-expand" uk-leader>Total</div>
          <div
            :id="'responsive-record-total-amount-' + payment.id"
          >{{ toCurrency(payment.totalAmount) }}</div>
        </div>

        <div class="uk-text-center">
          <button
            :id="'responsivle-payment-detail' + payment.id"
            :dusk="'responsivle-payment-detail' + payment.id"
            class="uk-button uk-border-rounded uk-button-secondary"
            uk-icon="chevron-right"
            @click="setPayment(payment)"
            href="#payment-detail-modal"
            uk-toggle
            :disabled="isLoading"
          ></button>
        </div>
      </div>

      <div
        v-if="payments.data && payments.data.length === 0"
        class="uk-text-center uk-margin-top"
      >No se han encontrado resultados con los filtros seleccionados.</div>
    </div>

    <div
      id="payment-detail-modal"
      class="uk-flex-top uk-modal-container"
      uk-modal="bg-close: false"
    >
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button dusk="close-detail-modal" class="uk-modal-close-default" type="button" uk-close></button>
        <payment-detail
          v-if="view === 'next'"
          :edit-permission="checkPermission('edit-payment')"
          :payment="activePayment"
          :update-local-payment="updateLocalPayment"
          :refetch-page="refetchPage"
        />
        <payment-detail
          v-else
          :payment="activePayment"
          :edit-permission="checkPermission('edit-payment')"
        />
        <div class="uk-margin-small-top uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            href="#payment-detail-modal"
            uk-toggle
          >
            <div class="uk-flex uk-flex-middle">
              <div uk-icon="chevron-left"></div>
              <div>Regresar</div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div id="payment-confirmation-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button dusk="close-confirmation" class="uk-modal-close-default" type="button" uk-close></button>
        Confirmar {{view === "next" ? "" : "la reversión de"}} {{ selectedPayments.length }} pago{{ selectedPayments.length === 1 ? '' : 's' }} por un total de {{ toCurrency(selectedPayments.map(payment => payment.totalAmount).reduce((accum, value) => accum + value, 0)) }}
        <div class="uk-margin uk-text-center">
          <button
            dusk="accept-payments"
            :class="'uk-button uk-border-rounded uk-button-' + (view ==='next' ? 'primary' : 'danger')"
            @click="pay"
            :disabled="isPaying"
          >
            <span v-if="isPaying" uk-spinner="ratio: 1"></span>
            <span v-else>Actualizar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UIkit from "uikit";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default {
  name: "Payment",
  props: ["view", "payload"],
  components: {
    flatPickr
  },
  data() {
    return {
      payments: [],
      isPaying: false,
      isLoading: false,
      path: "",
      isFetching: false,
      activePayment: "",
      allSelected: false,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      },
      paymentsFilter: "",
      paymentsFilterTimer: null,
      paymentsFilterFrom: this.payload.paymentsFilterFrom,
      paymentsFilterTo: this.payload.paymentsFilterTo,
      itemsPerPage: 20,
      payrollType: "all",
      division: "all",
      tag: "all"
    };
  },

  computed: {
    paymentSelected() {
      if (this.payments.data) {
        return this.payments.data.some(payment => payment.selected === true);
      } else {
        return false;
      }
    },
    selectedPayments() {
      if (this.payments.data) {
        return this.payments.data.filter(payment => payment.selected);
      } else {
        return [];
      }
    },
    selectedInsureds() {
      return this.selectedPayments.reduce((accum, value) => {
        if (!accum.includes(value.insured_business_id)) {
          accum.push(value.insured_business_id);
        }
        return accum;
      }, []);
    }
  },

  mounted() {
    this.payments = this.payload.payments;
    this.payments.data = this.payments.data.map(payment => ({
      ...payment,
      selected: false
    }));
    this.path = window.location.pathname;
  },

  methods: {
    updateLocalPayment(payment) {
      this.payments.data.splice(
        this.payments.data.map(payment => payment.id).indexOf(payment.id),
        1,
        payment
      );
      this.activePayment = payment;
    },
    requestPayrollReport() {
      this.isFetching = true;
      axios
        .get(
          "/payroll/reports/payroll?from=" +
            this.paymentsFilterFrom +
            "&to=" +
            this.paymentsFilterTo +
            "&query=" +
            this.paymentsFilter +
            "&payrollType=" +
            this.payrollType +
            "&division=" +
            this.division +
            "&tag=" +
            this.tag
        )
        .then(response => {
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>El reporte de planilla ha sido solicitado, en unos momentos lo recibirás en tu correo.</div>",
            { status: "success", timeout: 6000 }
          );
          this.isFetching = false;
        })
        .catch(error => {
          const errorNotification = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>No se ha podido generar el reporte de planilla, inténtalo más tarde.</div>",
            { status: "warning", timeout: 6000 }
          );
          this.isFetching = false;
        });
    },
    requestDetailedPayrollReport() {
      this.isFetching = true;
      axios
        .get(
          "/payroll/reports/detailed-payroll?from=" +
            this.paymentsFilterFrom +
            "&to=" +
            this.paymentsFilterTo +
            "&query=" +
            this.paymentsFilter +
            "&payrollType=" +
            this.payrollType +
            "&division=" +
            this.division +
            "&tag=" +
            this.tag
        )
        .then(response => {
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>El reporte detallado de planilla ha sido solicitado, en unos momentos lo recibirás en tu correo.</div>",
            { status: "success", timeout: 6000 }
          );
          this.isFetching = false;
        })
        .catch(error => {
          const errorNotification = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>No se ha podido generar el reporte detallado de planilla, inténtalo más tarde.</div>",
            { status: "warning", timeout: 6000 }
          );
          this.isFetching = false;
        });
    },
    requestEmployerReport() {
      this.isFetching = true;
      axios
        .get("/payroll/reports/employer-report")
        .then(response => {
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>El reporte del empleador ha sido solicitado, en unos momentos lo recibirás en tu correo.</div>",
            { status: "success", timeout: 6000 }
          );
          this.isFetching = false;
        })
        .catch(error => {
          const errorNotification = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>No se ha podido generar el reporte del empleador, inténtalo más tarde.</div>",
            { status: "warning", timeout: 6000 }
          );
          this.isFetching = false;
        });
    },
    setPayment(payment) {
      this.activePayment = payment;
    },
    prevPage() {
      this.currentPage = this.currentPage - 1;
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.paymentsFilter && !url.includes("&query")) {
        url = url + "&query=" + this.paymentsFilter;
      }
      url =
        url +
        "&items=" +
        this.itemsPerPage +
        "&from=" +
        this.paymentsFilterFrom +
        "&to=" +
        this.paymentsFilterTo +
        "&payrollType=" +
        this.payrollType +
        "&division=" +
        this.division +
        "&tag=" +
        this.tag;
      axios
        .get(url)
        .then(({ data: { payments } }) => {
          this.payments = payments;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.paymentsFilterTimer) {
        clearTimeout(this.paymentsFilterTimer);
      }
      this.paymentsFilterTimer = setTimeout(() => {
        let urlSearchString = new URL(window.location.href).search;

        this.fetchPage(
          window.location.pathname +
            urlSearchString +
            (urlSearchString == "" ? "?" : "&") +
            "dataOnly=true&query=" +
            query
        );
        this.paymentsFilterTimer = null;
      }, 500);
    },
    refetchPage(callback) {
      this.isFetching = true;
      let path =
        window.location.pathname +
        "?dataOnly=true&page=" +
        this.payments.current_page.toString() +
        "&items=" +
        this.itemsPerPage +
        "&from=" +
        this.paymentsFilterFrom +
        "&to=" +
        this.paymentsFilterTo +
        "&payrollType=" +
        this.payrollType +
        "&division=" +
        this.division +
        "&tag=" +
        this.tag;
      if (this.paymentsFilter && !path.includes("&query")) {
        path = path + "&query=" + this.paymentsFilter;
      }
      axios
        .get(path)
        .then(({ data: { payments } }) => {
          this.payments = payments;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    pay() {
      this.isPaying = true;
      const payingIds = this.selectedPayments.map(payment => payment.id);
      let api =
        this.path == "/payroll/next-payments"
          ? "/payroll/pay/bulk"
          : "/payroll/unpay/bulk";

      axios
        .post(api, payingIds)
        .then(response => {
          this.refetchPage(() => {
            this.isPaying = false;
            this.isLoading = false;
            const successText =
              this.path == "/payroll/next-payments"
                ? ["pagados", "pago"]
                : ["revertidos", "reversión"];
            UIkit.modal("#payment-confirmation-modal").hide();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Los pagos seleccionados han sido " +
                successText[0] +
                ", en unos momentos recibirás las constancias de " +
                successText[1] +
                " en tu correo.</div>",
              { status: "success", timeout: 6000 }
            );
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isPaying = false;
        });
    },
    fetchUrl(url) {
      this.isLoading = true;
      axios
        .get(url)
        .then(({ data: { payments } }) => {
          this.payments = payments.map(payment => ({
            ...payment,
            selected: false
          }));
          this.isLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    }
  },

  watch: {
    paymentsFilter: function(value) {
      this.search(value);
    },
    allSelected(value) {
      this.payments.data = this.payments.data.map(payment => ({
        ...payment,
        selected: value
      }));
    },
    paymentsFilterFrom(value) {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    paymentsFilterTo(value) {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    payrollType(value) {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    division(value) {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    tag(value) {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.tab1 {
  padding-left: 20px;
}

.tab2 {
  padding-left: 40px;
}
</style>