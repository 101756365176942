<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Usuarios</div>
      <div>
        <button
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-user-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-user')"
        >Crear usuario</button>
      </div>
    </div>
    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th width="25%">Nombre</th>
            <th width="25%">Email de acceso</th>
            <th width="30%">Roles asociados</th>
            <th width="20%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.first_name }} {{ user.last_name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.user_role.map(userRole => userRole.role.name).join(', ') }}</td>
            <td class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  class="uk-button uk-width-1-2 uk-button-primary"
                  href="#edit-user-modal"
                  uk-toggle
                  @click="setEditMode(user)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-user')"
                ></button>
                <button
                  class="uk-button uk-width-1-2 uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(user)"
                  uk-icon="trash"
                  v-if="checkPermission('delete-user')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="edit-user-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          usuario
        </div>
        <div
          v-if="mode === 'new'"
        >En caso de que ya exista un usuario con el email indicado, se le notificará de su asociación a esta empresa. En caso contrario, se creará una nueva cuenta siguiendo el proceso de creación normal.</div>
        <div class="uk-margin">
          <label class="uk-form-label">Email</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="model.email"
                :disabled="mode === 'edit' || searchingUser"
              />
            </div>
          </div>
        </div>
        <div class="uk-text-center" v-if="searchingUser">
          <span uk-spinner></span>
        </div>
        <div v-if="!userExists && model.email !== '' && !searchingUser">
          <div class="uk-margin">
            <label class="uk-form-label">Nombres</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input class="uk-input uk-border-rounded" type="text" v-model="model.first_name" />
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label">Apellidos</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input class="uk-input uk-border-rounded" type="text" v-model="model.last_name" />
              </div>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Roles asociados</label>
          <div v-for="role in payload.roles" :key="role.id">
            <label>
              <input
                class="uk-checkbox"
                type="checkbox"
                v-model="model.roles[role.id]"
                v-if="model.roles"
              />
              {{ role.name }}
            </label>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="searchUserTimer || searchingUser || isLoading || !userIsValid"
            v-if="mode === 'new'"
          >Crear usuario</button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="searchUserTimer || searchingUser || isLoading || !userIsValid"
            v-else
          >Actualizar usuario</button>
        </div>
      </div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span>¿Está seguro que desea eliminar el usuario {{ this.model.first_name }} {{ this.model.last_name }}?</span>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="destroy"
            :disabled="isLoading"
          >Eliminar usuario</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

export default {
  name: "ProfileUsers",

  props: ["payload"],

  data() {
    return {
      searchUserTimer: null,
      users: this.payload.users,
      activeUser: null,
      isLoading: false,
      mode: "new",
      searchingUser: false,
      userExists: true,
      model: {
        first_name: "",
        last_name: "",
        email: "",
        roles: null
      }
    };
  },

  methods: {
    setNewMode() {
      this.mode = "new";
      this.activeUser = null;
      this.model.email = "";
      this.model.first_name = "";
      this.model.last_name = "";
      this.model.roles = this.payload.roles.reduce((accum, role) => {
        accum[role.id] = false;
        return accum;
      }, {});
    },
    setEditMode(user) {
      const userRoleIds = user.user_role.map(userRole => userRole.role.id);
      this.mode = "edit";
      this.activeUser = user;
      this.model.first_name = user.first_name;
      this.model.last_name = user.last_name;
      this.model.email = user.email;
      this.model.roles = this.payload.roles.reduce((accum, role) => {
        accum[role.id] = userRoleIds.includes(role.id);
        return accum;
      }, {});
    },
    store() {
      this.isLoading = true;
      axios
        .post("/profile/users", {
          ...this.model,
          roles: Object.keys(this.model.roles).filter(
            roleId => this.model.roles[roleId]
          )
        })
        .then(response => {
          this.refetchPage(() => {
            UIkit.modal("#edit-user-modal").hide();
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      axios
        .put("/profile/users/" + this.activeUser.id, {
          ...this.model,
          roles: Object.keys(this.model.roles).filter(
            roleId => this.model.roles[roleId]
          )
        })
        .then(response => {
          this.refetchPage(() => {
            UIkit.modal("#edit-user-modal").hide();
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    destroy() {
      this.isLoading = true;
      axios
        .delete("/profile/users/" + this.activeUser.id)
        .then(response => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(window.location.pathname + "?dataOnly=true")
        .then(({ data: { users } }) => {
          this.users = users;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    searchUser(email) {
      if (this.searchUserTimer) {
        clearTimeout(this.searchUserTimer);
      }
      if (this.emailIsValid) {
        this.searchUserTimer = setTimeout(() => {
          this.searchingUser = true;
          axios
            .get("/profile/users/email?email=" + email)
            .then(({ data: { exists } }) => {
              this.userExists = exists;
            })
            .catch(error => {
              console.log("error", error);
            })
            .finally(() => {
              this.searchingUser = false;
            });
          this.searchUserTimer = null;
        }, 800);
      }
    }
  },

  computed: {
    emailIsValid() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.model.email);
    },
    firstNameIsValid() {
      return this.model.first_name !== "";
    },
    lastNameIsValid() {
      return this.model.last_name !== "";
    },
    email() {
      return this.model.email;
    },
    userIsValid() {
      if (!this.userExists) {
        return [
          this.emailIsValid,
          this.firstNameIsValid,
          this.lastNameIsValid
        ].every(validation => validation);
      } else {
        return true;
      }
    }
  },

  watch: {
    email: function(email) {
      this.searchUser(email);
    }
  }
};
</script>