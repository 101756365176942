<template>
  <div>
    <ul
      class="uk-subnav uk-subnav-pill uk-flex-center"
      uk-switcher="animation: uk-animation-slide-bottom-small, uk-animation-slide-top-small"
    >
      <li v-for="benefit in payload.benefits" :key="benefit.id">
        <a :dusk="benefit.name" href="#" class="uk-border-rounded">{{ benefit.name }}</a>
      </li>
    </ul>
    <ul class="uk-switcher">
      <li v-for="benefit in payload.benefits" :key="benefit.id">
        <div v-if="benefit.slug === 'igss'">
          <div class="uk-flex uk-flex-center uk-margin-small-bottom">
            <div class="uk-margin-right uk-width-medium">
              <flat-pickr
                class="uk-input uk-border-rounded uk-text-center"
                v-model="paymentsFilterFrom"
                :config="datepickerConfig"
                :disabled="isLoading"
              ></flat-pickr>
            </div>
            <div class="uk-margin-left uk-width-medium">
              <flat-pickr
                class="uk-input uk-border-rounded uk-text-center"
                v-model="paymentsFilterTo"
                :config="datepickerConfig"
                :disabled="isLoading"
              ></flat-pickr>
            </div>
          </div>
          <div class="uk-card uk-card-default uk-card-body uk-overflow-auto">
            <div class="uk-card-title uk-flex uk-flex-between uk-flex-wrap">
              <div>Total de IGSS para el período {{ formatDate(paymentsFilterFrom) }} - {{ formatDate(paymentsFilterTo) }}</div>
              <button
                @click="downloadTable('igssBonusesTable')"
                class="uk-button uk-border-rounded uk-button-primary uk-button-small"
                v-if="checkPermission('export-igss')"
              >Exportar</button>
            </div>
            <div class="uk-text-large uk-text-center">
              <div uk-spinner v-if="isLoading"></div>
              <div
                class="uk-margin-small-top uk-text-bold"
                v-else
              >{{ toCurrency(payments.reduce((accum, payment) => accum + ((-payment.igss + payment.bonusIGSSAdjustments + payment.absencesIGSSAdjustments - payment.overtimesIGSSAdjustments) / 4.83 * 10.67 - payment.igss + payment.bonusIGSSAdjustments + payment.absencesIGSSAdjustments - payment.overtimesIGSSAdjustments), 0)) }}</div>
            </div>

            <table
              id="igssBonusesTable"
              class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s"
              v-if="!isLoading"
            >
              <thead>
                <tr>
                  <th>Período</th>
                  <th>Carné del IGSS</th>
                  <th>Colaborador</th>
                  <th class="uk-text-right">IGSS sobre base</th>
                  <th class="uk-text-right">IGSS sobre bonos aplicables</th>
                  <th class="uk-text-right">Ajuste de IGSS sobre ausencias y horas extra</th>
                  <th class="uk-text-right">IGSS total del colaborador</th>
                  <th class="uk-text-right">IGSS del patrono</th>
                  <th class="uk-text-right">IGSS total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in payments" :key="payment.id">
                  <td width="15%">
                    <div>{{ formatDate(payment.start_date) }}</div>
                    <div>{{ formatDate(payment.end_date) }}</div>
                  </td>
                  <td width="10%">
                    <div>{{ payment.insured_business.igss }}</div>
                  </td>
                  <td width="15%">
                    <div>{{ payment.insured_business.insured.first_name }} {{ payment.insured_business.insured.last_name }}</div>
                    <div>DPI: {{ payment.insured_business.insured.dpi }}</div>
                    <div>División: {{ payment.insured_business.division.name }}</div>
                  </td>
                  <td width="10%" class="uk-text-right">{{ toCurrency(-payment.igss) }}</td>
                  <td
                    width="10%"
                    class="uk-text-right"
                  >{{ toCurrency(payment.bonusIGSSAdjustments) }}</td>
                  <td
                    width="10%"
                    class="uk-text-right"
                  >{{ toCurrency(payment.absencesIGSSAdjustments - payment.overtimesIGSSAdjustments) }}</td>
                  <td
                    width="10%"
                    class="uk-text-right"
                  >{{ toCurrency(-payment.igss + payment.bonusIGSSAdjustments + payment.absencesIGSSAdjustments - payment.overtimesIGSSAdjustments) }}</td>
                  <td
                    width="10%"
                    class="uk-text-right"
                  >{{ toCurrency((-payment.igss + payment.bonusIGSSAdjustments + payment.absencesIGSSAdjustments - payment.overtimesIGSSAdjustments) / 4.83 * 10.67) }}</td>
                  <td
                    width="10%"
                    class="uk-text-right"
                  >{{ toCurrency((-payment.igss + payment.bonusIGSSAdjustments + payment.absencesIGSSAdjustments - payment.overtimesIGSSAdjustments) / 4.83 * 10.67 - payment.igss + payment.bonusIGSSAdjustments + payment.absencesIGSSAdjustments - payment.overtimesIGSSAdjustments) }}</td>
                </tr>
              </tbody>
            </table>

            <div class="bottom-divider uk-hidden@s" v-for="payment in payments" :key="payment.id">
              <div>
                <div
                  class="uk-text-center uk-text-bold"
                >{{ payment.insured_business.insured.first_name }} {{ payment.insured_business.insured.last_name }}</div>
                <div class="uk-text-center">{{ payment.insured_business.division.name }}</div>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold">DPI:</div>
                  <div>{{ payment.insured_business.insured.dpi }}</div>
                </div>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold">Del:</div>
                  <div>{{ formatDate(payment.start_date) }}</div>
                </div>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold">Al:</div>
                  <div>{{ formatDate(payment.end_date) }}</div>
                </div>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold">IGSS sobre base:</div>
                  <div>{{ toCurrency(-payment.igss) }}</div>
                </div>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold">IGSS sobre bonos aplicables:</div>
                  <div>{{ toCurrency(payment.bonusIGSSAdjustments) }}</div>
                </div>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold">IGSS total del colaborador:</div>
                  <div>{{ toCurrency(-payment.igss + payment.bonusIGSSAdjustments) }}</div>
                </div>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold">IGSS del patrono:</div>
                  <div>{{ toCurrency((-payment.igss + payment.bonusIGSSAdjustments) / 4.83 * 10.67) }}</div>
                </div>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold">IGSS total:</div>
                  <div>{{ toCurrency((-payment.igss + payment.bonusIGSSAdjustments) / 4.83 * 10.67 - payment.igss + payment.bonusIGSSAdjustments) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="benefit.slug === 'bono14'">
          <div class="uk-card uk-card-default uk-card-body uk-overflow-auto">
            <div class="uk-card-title uk-flex uk-flex-between uk-flex-wrap">
              <div>Total de Bono 14</div>
              <button
                @click="downloadTable('bono14BonusesTable')"
                class="uk-button uk-border-rounded uk-button-primary uk-button-small"
                v-if="checkPermission('export-bono14')"
              >Exportar</button>
            </div>
            <div class="uk-text-large uk-text-center">
              <div uk-spinner v-if="isLoading"></div>
              <div
                class="uk-margin-small-top uk-text-bold"
                v-else
              >{{ toCurrency(payload.bono14Bonuses.reduce((accum, bonus) => accum + parseFloat(bonus.amount), 0)) }}</div>
            </div>

            <table
              id="bono14BonusesTable"
              class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s"
              v-if="!isLoading"
            >
              <thead>
                <tr>
                  <th>Fecha de aplicación</th>
                  <th>Colaborador</th>
                  <th class="uk-text-right">Monto</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="bono14 in payload.bono14Bonuses" :key="bono14.id">
                  <td width="20%">{{ formatDate(bono14.application_date) }}</td>
                  <td
                    width="60%"
                  >{{ bono14.business_relation.insured.first_name }} {{ bono14.business_relation.insured.last_name }}</td>
                  <td width="20%%" class="uk-text-right">{{ toCurrency(bono14.amount) }}</td>
                </tr>
              </tbody>
            </table>

            <div
              class="bottom-divider uk-hidden@s"
              v-for="bono14 in payload.bono14Bonuses"
              :key="bono14.id"
            >
              <div
                class="uk-text-center"
              >{{ bono14.business_relation.insured.first_name }} {{ bono14.business_relation.insured.last_name }}</div>
              <div>
                <div class="uk-text-bold">Fecha de aplicación:</div>
                <div>{{ formatDate(bono14.application_date) }}</div>
              </div>
              <div>
                <span class="uk-text-bold">Monto:</span>
                {{ toCurrency(bono14.amount) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="benefit.slug === 'aguinaldo'">
          <div class="uk-card uk-card-default uk-card-body uk-overflow-auto">
            <div class="uk-card-title uk-flex uk-flex-between uk-flex-wrap">
              <div>Total de Aguinaldo</div>
              <button
                @click="downloadTable('aguinaldoBonusesTable')"
                class="uk-button uk-border-rounded uk-button-primary uk-button-small"
                v-if="checkPermission('export-aguinaldo')"
              >Exportar</button>
            </div>
            <div class="uk-text-large uk-text-center">
              <div uk-spinner v-if="isLoading"></div>
              <div
                class="uk-margin-small-top uk-text-bold"
                v-else
              >{{ toCurrency(payload.aguinaldoBonuses.reduce((accum, bonus) => accum + parseFloat(bonus.amount), 0)) }}</div>
            </div>

            <table
              id="aguinaldoBonusesTable"
              class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s"
              v-if="!isLoading"
            >
              <thead>
                <tr>
                  <th>Fecha de aplicación</th>
                  <th>Colaborador</th>
                  <th class="uk-text-right">Monto</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="aguinaldo in payload.aguinaldoBonuses" :key="aguinaldo.id">
                  <td width="20%">{{ formatDate(aguinaldo.application_date) }}</td>
                  <td
                    width="60%"
                  >{{ aguinaldo.business_relation.insured.first_name }} {{ aguinaldo.business_relation.insured.last_name }}</td>
                  <td width="20%%" class="uk-text-right">{{ toCurrency(aguinaldo.amount) }}</td>
                </tr>
              </tbody>
            </table>

            <div
              class="bottom-divider uk-hidden@s"
              v-for="aguinaldo in payload.aguinaldoBonuses"
              :key="aguinaldo.id"
            >
              <div
                class="uk-text-center"
              >{{ aguinaldo.business_relation.insured.first_name }} {{ aguinaldo.business_relation.insured.last_name }}</div>
              <div>
                <div class="uk-text-bold">Fecha de aplicación:</div>
                <div>{{ formatDate(aguinaldo.application_date) }}</div>
              </div>
              <div>
                <span class="uk-text-bold">Monto:</span>
                {{ toCurrency(aguinaldo.amount) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="benefit.slug === 'indemnizacion'">
          <div class="uk-card uk-card-default uk-card-body uk-overflow-auto">
            <div class="uk-card-title uk-flex uk-flex-between uk-flex-wrap">
              <div>Total de Indemnizaciones</div>
              <div>
                <button
                  @click="downloadTable('indemnizacionBonusesTable')"
                  class="uk-button uk-border-rounded uk-button-primary uk-button-small"
                  :disabled="settingIndemnizacion"
                  v-if="checkPermission('export-indemnizacion')"
                >Exportar</button>
                <button
                  @click="setIndemnizacionBonuses"
                  class="uk-button uk-border-rounded uk-button-primary uk-button-small"
                  :disabled="settingIndemnizacion"
                >
                  <span v-if="settingIndemnizacion" uk-spinner="ratio: 1"></span>
                  <span v-else>Asignar indemnizaciones seleccionadas</span>
                </button>
              </div>
            </div>
            <div class="uk-text-large uk-text-center">
              <div uk-spinner v-if="isLoading"></div>
              <div
                class="uk-margin-small-top uk-text-bold"
                v-else
              >{{ toCurrency(indemnizacionBonuses.reduce((accum, bonus) => accum + bonus.amount, 0)) }}</div>
            </div>

            <table
              id="indemnizacionBonusesTable"
              class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s"
              v-if="!isLoading"
            >
              <thead>
                <tr>
                  <th>
                    <input class="uk-checkbox" type="checkbox" v-model="allSelected" />
                  </th>
                  <th>Fecha de aplicación</th>
                  <th>Colaborador</th>
                  <th class="uk-text-right">Monto</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="indemnizacion in indemnizacionBonuses" :key="indemnizacion.id">
                  <td width="10%">
                    <input
                      class="uk-checkbox"
                      type="checkbox"
                      v-model="indemnizacion.selected"
                      :disabled="settingIndemnizacion"
                    />
                  </td>
                  <td width="20%">{{ formatDate(indemnizacion.application_date) }}</td>
                  <td
                    width="50%"
                  >{{ indemnizacion.business_relation.insured.first_name }} {{ indemnizacion.business_relation.insured.last_name }}</td>
                  <td width="20%" class="uk-text-right">{{ toCurrency(indemnizacion.amount) }}</td>
                </tr>
              </tbody>
            </table>

            <div
              class="bottom-divider uk-hidden@s"
              v-for="indemnizacion in payload.indemnizacionBonuses"
              :key="indemnizacion.id"
            >
              <div>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  v-model="indemnizacion.selected"
                  :disabled="settingIndemnizacion"
                />
                {{ indemnizacion.business_relation.insured.first_name }} {{ indemnizacion.business_relation.insured.last_name }}
              </div>
              <div>
                <div class="uk-text-bold">Fecha de aplicación:</div>
                <div>{{ formatDate(indemnizacion.application_date) }}</div>
              </div>
              <div>
                <span class="uk-text-bold">Monto:</span>
                {{ toCurrency(indemnizacion.amount) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="benefit.slug === 'isr'">
          <div class="uk-margin uk-text-center">
            <button
              class="uk-button uk-border-rounded uk-button-secondary"
              @click="requestProjection"
              :disabled="requestingProjection"
              v-if="checkPermission('export-projection')"
            >
              <span v-if="requestingProjection" uk-spinner></span>
              <span v-else>Exportar proyección</span>
            </button>
            <div
              v-if="!requestingProjection && nullNitInsureds.length !== 0"
              class="uk-margin-small-top"
            >
              No fue posible generar la proyección de ISR ya que los siguientes colaboradores ({{ nullNitInsureds.length }}) no tienen NIT asignado:
              <ul class="uk-list uk-margin-top uk-list-divider">
                <li
                  v-for="insured in nullNitInsureds"
                  :key="insured.id"
                >{{ insured.first_name }} {{ insured.last_name }}</li>
              </ul>
              <div>
                Agrega la información faltante en
                <a href="/directory/general">Directorio</a>
              </div>
            </div>
          </div>
          <hr class="uk-divider-icon" />
          <div class="uk-margin uk-text-center" v-if="checkPermission('export-retention')">
            <div class="uk-flex uk-flex-center uk-margin-small-bottom">
              <div class="uk-margin-right uk-width-medium">
                <flat-pickr
                  class="uk-input uk-border-rounded uk-text-center"
                  v-model="paymentsFilterFrom"
                  :config="datepickerConfig"
                  :disabled="isLoading"
                ></flat-pickr>
              </div>
              <div class="uk-margin-left uk-width-medium">
                <flat-pickr
                  class="uk-input uk-border-rounded uk-text-center"
                  v-model="paymentsFilterTo"
                  :config="datepickerConfig"
                  :disabled="isLoading"
                ></flat-pickr>
              </div>
            </div>
            <a
              class="uk-button uk-border-rounded uk-button-secondary"
              :href="'/payroll/isr/retention?from=' + paymentsFilterFrom + '&to=' + paymentsFilterTo"
              target="_blank"
            >Exportar retenciones del rango seleccionado</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import XLSX from "xlsx";
import UIkit from "uikit";

export default {
  name: "PayrollBenefits",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      requestingProjection: false,
      nullNitInsureds: [],
      indemnizacionBonuses: [],
      payments: this.payload.payments,
      isLoading: false,
      settingIndemnizacion: false,
      activePayment: "",
      allSelected: false,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      },
      paymentsFilterFrom: this.payload.paymentsFilterFrom,
      paymentsFilterTo: this.payload.paymentsFilterTo
    };
  },

  methods: {
    fetchUrl(url) {
      this.isLoading = true;
      axios
        .get(url)
        .then(({ data: { payments } }) => {
          this.payments = payments.map(payment => ({
            ...payment,
            selected: false
          }));
          this.isLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    downloadTable(tableId) {
      const wb = XLSX.utils.table_to_book(document.getElementById(tableId));
      XLSX.writeFile(wb, tableId + ".xlsx");
    },
    requestProjection() {
      this.requestingProjection = true;
      axios
        .get("/payroll/isr/projection-request")
        .then(({ data: { nullNitInsureds } }) => {
          if (nullNitInsureds.length !== 0) {
            this.nullNitInsureds = nullNitInsureds;
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small danger-background text-white'>No es posible generar la proyección para ISR ya que hay colaboradores sin NIT asignado</div>",
              { status: "warning", timeout: 4000 }
            );
          } else {
            window.open("/payroll/isr/projection");
            this.clearNullNitInsureds();
          }
          this.requestingProjection = false;
        })
        .catch(error => {
          console.log("error", error);
          this.requestingProjection = false;
        });
    },
    clearNullNitInsureds() {
      this.nullNitInsureds = [];
    },
    setIndemnizacionBonuses() {
      this.settingIndemnizacion = true;
      axios
        .post(
          "/payroll/benefits/indemnizacion",
          this.indemnizacionBonuses
            .filter(indemnizacionBonus => indemnizacionBonus.selected)
            .map(indemnizacionBonus => indemnizacionBonus.business_relation.id)
        )
        .then(response => {
          this.indemnizacionBonuses = this.indemnizacionBonuses.filter(
            indemnizacionBonus => !indemnizacionBonus.selected
          );
          this.settingIndemnizacion = false;
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Indemnizaciones asignadas</div>",
            { status: "success", timeout: 3000 }
          );
        })
        .catch(error => {
          console.log("error", error);
          this.settingIndemnizacion = false;
        });
    }
  },

  mounted() {
    this.indemnizacionBonuses = this.payload.indemnizacionBonuses.map(
      indemnizacion => ({
        ...indemnizacion,
        selected: false
      })
    );
  },

  watch: {
    paymentsFilterFrom(value) {
      let params = "?dataOnly=true";
      if (value !== "") {
        params = params + "&from=" + value;
      }
      if (this.paymentsFilterTo) {
        params = params + "&to=" + this.paymentsFilterTo;
      }
      this.fetchUrl("/payroll/benefits" + params);
    },
    paymentsFilterTo(value) {
      let params = "?dataOnly=true";
      if (value !== "") {
        params = params + "&to=" + value;
      }
      if (this.paymentsFilterFrom) {
        params = params + "&from=" + this.paymentsFilterFrom;
      }
      this.fetchUrl("/payroll/benefits" + params);
    },
    allSelected(value) {
      this.indemnizacionBonuses = this.indemnizacionBonuses.map(
        indemnizacionBonus => ({
          ...indemnizacionBonus,
          selected: value
        })
      );
    }
  }
};
</script>