<template>
  <div>
    <canvas :id="'chartCanvas-' + chartId"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import { v4 as uuid } from "uuid";

export default {
  name: "GenericChart",

  props: ["configuration"],

  data() {
    return {
      chartId: uuid(),
      chart: null
    };
  },

  mounted() {
    var ctx = "chartCanvas-" + this.chartId;
    if (this.configuration) {
      this.chart = new Chart(ctx, this.configuration);
    }
  }

  // Pending reactive charts
};
</script>