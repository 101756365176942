<template>
<div class="uk-margin-bottom">
  <div class="uk-text-center uk-margin-large-top">
    <img src="/images/logo.png" width="300px" alt="">
  </div>
  <form method="POST" action="/register" class="uk-width-medium uk-margin-auto uk-text-center">
    <input type="hidden" :value="csrf" name="_token">
    <div class="uk-margin-large-top uk-text-large border-bottom">Crea una cuenta</div>
    <input name="first_name" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="text" placeholder="Nombres">
    <div class="uk-text-danger" v-if="errors.first_name">{{ errors.first_name[0] }}</div>
    <input name="last_name" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="text" placeholder="Apellidos">
    <div class="uk-text-danger" v-if="errors.last_name">{{ errors.last_name[0] }}</div>
    <input name="email" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="text" placeholder="Email">
    <div class="uk-text-danger" v-if="errors.email">{{ errors.email[0] }}</div>
    <input name="password" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="password" placeholder="Contraseña">
    <input name="password_confirmation" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="password" placeholder="Confirmación de contraseña">
    <div class="uk-text-danger" v-if="errors.password">{{ errors.password[0] }}</div>
    <div class="uk-margin-top">
      <label><input class="uk-checkbox" name="remember" type="checkbox" checked> Recuérdame</label>
    </div>
    <button class="uk-margin-top uk-width-1-1 uk-button uk-border-rounded uk-button-secondary" type="submit">Crear cuenta</button>
    <a href="/login" class="uk-margin-top uk-width-1-1 uk-button uk-border-rounded uk-button-primary">Ya tengo una cuenta</a>
  </form>
</div>
</template>

<script>
export default {
  name: 'AuthRegister',

  props: ['csrf', 'errors']
}
</script>

<style>
.border-bottom {
  border-bottom: solid 1px;
}
</style>