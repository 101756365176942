<template>
  <div>
    <div v-for="module in modules" :key="module.id" class="uk-margin-small-bottom">
      <label class="uk-text-bold">
        <input class="uk-checkbox" type="checkbox" v-model="module.selected" />
        {{ module.name }}
      </label>
      <div class="uk-margin-left">
        <div v-for="action in module.available_actions" :key="action.id">
          <label>
            <input class="uk-checkbox" type="checkbox" v-model="action.selected" />
            {{ action.name }}
          </label>
        </div>
        <modules-selection :modules="module.children" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModulesSelection",

  props: ["modules"]
};
</script>