<template>
  <div>
    <!-- {{payload.ShiftTypes.data}} -->
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Jornadas disponibles</div>
      <div>
        <button
          dusk="add-shift"
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-shift-modal"
          uk-toggle
          @click="setNewMode"
        >Añadir jornada</button>
      </div>
    </div>
    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input search-input-animation"
            type="search"
            placeholder="Buscar jornada..."
            v-model="shiftFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="shifts.prev_page_url"
          :is-fetching="isFetching"
          :current-page="shifts.current_page"
          :last-page="shifts.last_page"
          :next-page-url="shifts.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th width="20%">Jornada</th>
            <th width="15%">Actividad</th>
            <th width="10%">Hora de inicio</th>
            <th width="10%">Hora de finalización</th>
            <th width="20%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(shift, index) in shifts.data" :key="shift.id">
            <!--<td>{{ index }}</td>-->
            <td>{{ shift.name }}</td>
            <td>
              <tr v-for="(activity) in shift.activities" :key="activity.id">
                <td>{{activity.name}}</td>
              </tr>
            </td>
            <td>
              <tr v-for="(activity) in shift.activities" :key="activity.id">
                <td>{{activity.pivot.start_time}}</td>
              </tr>
            </td>
            <td>
              <tr v-for="(activity) in shift.activities" :key="activity.id">
                <td>{{activity.pivot.end_time}}</td>
              </tr>
            </td>
            <td class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  :dusk="'edit-shift-button-show-' + shift.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                  href="#edit-shift-modal"
                  uk-toggle
                  @click="setEditMode(shift, index)"
                  uk-icon="pencil"
                ></button>
                <button
                  :dusk="'delete-shift-button-show-' + shift.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(shift, index)"
                  uk-icon="trash"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="edit-shift-modal" class="uk-flex-top uk-modal-container" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          jornada
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre de la jornada</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': nameIsValid }"
                uk-icon="check"
              ></span>
              <input class="uk-input uk-border-rounded" type="text" v-model="model.name" />
            </div>
          </div>
        </div>
        <div>Actividades de la jornada</div>
        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
          <thead>
            <tr>
              <th width="20%">Actividad</th>
              <th width="30%">Desde</th>
              <th width="30%">Hasta</th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in model.content" :key="index">
              <td>
                <v-select
                  :options="payload.activities"
                  :reduce="activity => activity.id"
                  :getOptionLabel="activity => activity.name"
                  v-model="item.id"
                ></v-select>
              </td>
              <td>
                <div class="uk-inline">
                  <v-select
                    :options="hours"
                    :getOptionLabel="hour => hour"
                    v-model="item.start_hour"
                  ></v-select>
                  <!-- <input class="uk-input" type="text" v-model="item.start_time" /> -->
                </div>:
                <div class="uk-inline">
                  <!-- <input class="uk-input" type="text" v-model="item.start_time" /> -->
                  <v-select
                    :options="minutes"
                    :getOptionLabel="minute => minute"
                    v-model="item.start_minute"
                    label="Standard"
                    dense
                  ></v-select>
                </div>
              </td>
              <td>
                <div class="uk-inline">
                  <v-select :options="hours" :getOptionLabel="hour => hour" v-model="item.end_hour"></v-select>
                  <!-- <input class="uk-input" type="text" v-model="item.end_time" /> -->
                </div>:
                <div class="uk-inline">
                  <!-- <input class="uk-input" type="text" v-model="item.end_time" /> -->
                  <v-select
                    :options="minutes"
                    :getOptionLabel="minute => minute"
                    v-model="item.end_minute"
                    label="Standard"
                    dense
                  ></v-select>
                </div>
              </td>
              <td>
                <div class="uk-text-right">
                  <button
                    class="uk-button uk-border-rounded uk-padding-remove uk-button-danger"
                    uk-toggle
                    @click="removeContent(index)"
                    uk-icon="minus-circle"
                  ></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="model.content.length < 1"
          class="uk-text-center"
        >No existen actividades asociadas.</div>
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-border-rounded uk-button-primary" @click="addNewActivity()">+</button>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !nameIsValid"
            v-if="mode === 'new'"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Crear jornada</span>
          </button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !nameIsValid"
            v-else
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Actualizar jornada</span>
          </button>
        </div>
      </div>
    </div>
    <div id="edit-shifts-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-container uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close @click="setNewMode"></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          jornada
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                id="shift-name-check"
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': nameIsValid }"
                uk-icon="check"
              ></span>
              <input
                name="new-shift-name"
                class="uk-input uk-border-rounded"
                type="text"
                v-model="model.name"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            dusk="create-shift"
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !nameIsValid"
            v-if="mode === 'new'"
          >
            <span v-if="isLoading" uk-spinner></span>Crear jornada
          </button>
          <button
            dusk="update-type"
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !nameIsValid"
            v-else
          >
            <span v-if="isLoading" uk-spinner></span>Actualizar jornada
          </button>
        </div>
      </div>
    </div>
    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span>¿Está seguro que desea eliminar la jornada de {{ this.model.name }}?</span>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            dusk="delete-division-accept"
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteShift"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>Eliminar actividad
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UIkit from "uikit";

export default {
  name: "TimeTrackingShifts",
  props: ["payload"],
  data() {
    return {
      model: {
        name: "",
        content: []
      },
      itemsPerPage: 20,
      activeShiftId: "",
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      mode: "new",
      shiftFilter: "",
      shifts: {},
      activities: {},
      shiftFilterTimer: null,
      acitivitySelector: null,
      hours: [],
      minutes: []
    };
  },
  mounted() {
    this.shifts = this.payload.ShiftTypes;
    this.activities = this.payload.activities;
    this.hours = this.fillArrayHours();
    this.minutes = this.fillArrayMinutes();
  },
  computed: {
    nameIsValid() {
      let activityId = true;
      for (var i = 0; i < this.model.content.length; i++) {
        if (this.model.content[i].id === 0) {
          activityId = false;
        }
      }
      return (
        this.model.name &&
        this.model.name.length > 1 &&
        this.model.content.length > 0 &&
        activityId
      );
    },
    withActivities() {
      return this.model.content.length > 0;
    }
  },

  methods: {
    fillArray(array) {
      for (let i = 0; i < array.length; i++) {
        if (i < 10) {
          //array[i] = "0" + i.toString();
          array.fill(i);
        } else {
          //array[i] = i.toString();
          array.fill(i);
        }
        return array;
      }
    },
    fillArrayHours() {
      // console.log(this.hours);
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          this.hours.push("0" + i);
        } else {
          this.hours.push(i.toString());
        }
      }
      // console.log(this.hours);
      return this.hours;
    },
    fillArrayMinutes() {
      // console.log(this.hours);
      for (let i = 0; i < 60; i++) {
        if (i < 10) {
          this.minutes.push("0" + i);
        } else {
          this.minutes.push(i.toString());
        }
      }
      // console.log(this.hours);
      return this.minutes;
    },
    addNewActivity() {
      const times = ["13", "00"];
      this.model.content.push({
        id: 0,
        start_time: times.join(":"),
        end_time: times.join(":"),
        start_hour: times[0],
        start_minute: times[1],
        end_hour: times[0],
        end_minute: times[1]
      });

      this.$nextTick(() => {
        this.acitivitySelector = null;
      });
    },
    removeContent(index) {
      this.model.content.splice(index, 1);
    },
    setNewMode() {
      this.mode = "new";
      this.model.name = "";
      this.model.content = [
        // { id: 0, start_time: "10", end_time: "11" },
        // { id: 2, start_time: "4", end_time: "6" }
      ];
    },
    getHour(time) {
      return time.split(":")[0];
    },
    getMinute(time) {
      return time.split(":")[1];
    },
    setEditMode(shift, index) {
      this.mode = "edit";
      this.activeShiftId = shift.id;
      this.payloadPosition = index;
      this.model.name = shift.name;
      this.model.content = shift.activities.map(item => ({
        //shift_type_id: item.pivot.shift_type_id,
        id: item.id,
        start_hour: this.getHour(item.pivot.start_time),
        start_minute: this.getMinute(item.pivot.start_time),
        end_hour: this.getHour(item.pivot.end_time),
        end_minute: this.getMinute(item.pivot.end_time),
        start_time: item.pivot.start_time,
        end_time: item.pivot.end_time
      }));
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.shifts.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { ShiftTypes } }) => {
          this.shifts = ShiftTypes;
          this.isFetching = false;
          console.log(ShiftTypes);
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    resetForm() {
      this.model = {
        name: "",
        content: []
      };
    },
    getActivityName(activity_id) {
      return this.activities.find(activity => activity.id === activity_id).name;
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando jornada...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/time-tracking/shifts", this.model)
        .then(({ data: { model } }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-shift-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='successfull-store' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Jornada creada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    updateContent() {
      for (var i = 0; i < this.model.content.length; i++) {
        this.model.content[i]["start_time"] =
          this.model.content[i]["start_hour"] +
          ":" +
          this.model.content[i]["start_minute"];

        this.model.content[i]["end_time"] =
          this.model.content[i]["end_hour"] +
          ":" +
          this.model.content[i]["end_minute"];
      }
    },

    update() {
      this.updateContent();
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando jornada...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put(
          "/time-tracking/shifts/" + this.activeShiftId.toString(),
          this.model
        )
        .then(({ data: { model } }) => {
          // this.reports.data[this.payloadPosition] = model
          this.refetchPage(() => {
            UIkit.modal("#edit-shift-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Jornada actualizada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    deleteShift() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando jornada...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/time-tracking/shifts/" + this.activeShiftId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Jornada eliminada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.shiftFilter && !url.includes("&query")) {
        url = url + "&query=" + this.shiftFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { ShiftTypes } }) => {
          this.shifts = ShiftTypes;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.shiftFilterTimer) {
        clearTimeout(this.shiftFilterTimer);
      }
      this.shiftFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.shiftFilterTimer = null;
      }, 1000);
    }
  },
  watch: {
    shiftFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>