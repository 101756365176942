<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Calendarización de envíos</div>
      <div class="uk-text-center">
        <button
          class="uk-button uk-border-rounded uk-button-secondary uk-margin"
          href="#edit-schedule-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-schedule')"
        >Crear calendarización</button>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input search-input-animation"
            type="search"
            placeholder="Buscar calendarización..."
            v-model="scheduleFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="schedules.prev_page_url"
          :is-fetching="isFetching"
          :current-page="schedules.current_page"
          :last-page="schedules.last_page"
          :next-page-url="schedules.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Frecuencia</th>
            <th>Reportes</th>
            <th>Destinatarios</th>
            <th>Fecha de último envío</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="schedule in schedules.data" :key="schedule.id">
            <td width="15%">{{ schedule.name }}</td>
            <td width="15%">
              <span>{{ schedule.frequency.name }} ({{ translateRepeatOn(schedule.frequency, schedule.repeat_on) }})</span>
            </td>
            <td width="20%">
              <ul class="uk-list uk-list-bullet">
                <li v-for="report in schedule.reports" :key="report.id">{{ report.name }}</li>
              </ul>
            </td>
            <td width="25%">
              <ul class="uk-list uk-list-bullet">
                <li
                  v-for="user in schedule.users"
                  :key="user.id"
                >{{ user.first_name }} {{ user.last_name }} ({{ user.email }})</li>
              </ul>
            </td>
            <td width="15%">
              <span>{{ }}</span>
            </td>
            <td width="10%" class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                  href="#edit-schedule-modal"
                  uk-toggle
                  @click="setEditMode(schedule)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-schedule')"
                ></button>
                <button
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(schedule)"
                  uk-icon="trash"
                  v-if="checkPermission('delete-schedule')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="bottom-divider uk-hidden@s" v-for="schedule in schedules.data" :key="schedule.id">
        <div class="uk-flex uk-flex-between">
          <div>{{ schedule.name }}</div>
          <div>{{ toCurrency(schedule.amount) }}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span v-if="schedule.type">{{ schedule.type.name }}</span>
          </div>
          <div>
            <span v-if="schedule.application_date">{{ formatDate(schedule.application_date) }}</span>
          </div>
        </div>
        <div>{{ schedule.reason }}</div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
        >
          <button
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-schedule-modal"
            uk-toggle
            @click="setEditMode(schedule)"
            uk-icon="pencil"
            v-if="checkPermission('edit-schedule')"
          ></button>
          <button
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(schedule)"
            uk-icon="trash"
            v-if="checkPermission('delete-schedule')"
          ></button>
        </div>
      </div>

      <div
        v-if="schedules.data && schedules.data.length === 0"
        class="uk-text-center uk-margin-top"
      >No se han encontrado resultados con los filtros seleccionados.</div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div
          class="uk-text-large"
        >¿Está seguro que desea eliminar la calendarización {{ this.model.name }}?</div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteSchedule"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Eliminar calendarización</span>
          </button>
        </div>
      </div>
    </div>

    <div id="edit-schedule-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          calendarización
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                name="name"
                v-model="model.name"
                class="uk-input uk-border-rounded"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Frecuencia</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select class="uk-select uk-border-rounded" v-model="model.frequency_id">
                <option
                  v-for="frequency in payload.scheduleFrequencies"
                  :key="frequency.id"
                  :value="frequency.id"
                >{{ frequency.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Enviar en</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select class="uk-select uk-border-rounded" v-model="model.repeat_on">
                <option
                  v-for="(option, index) in repeatOptions"
                  :value="option.value"
                  :key="index"
                >{{ option.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin-top">Reportes</div>
        <div v-for="report in payload.reports" :key="'report-' + report.id">
          <label>
            <input class="uk-checkbox" type="checkbox" v-model="model.reports[report.id]" />
            {{ report.name }}
          </label>
        </div>
        <div class="uk-margin-top">Destinatarios</div>
        <div v-for="user in payload.users" :key="'user-' + user.id">
          <label>
            <input class="uk-checkbox" type="checkbox" v-model="model.users[user.id]" />
            {{ user.first_name }} {{ user.last_name }} ({{ user.email }})
          </label>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !formIsValid"
            v-if="mode === 'new'"
          >
            <span v-if="isLoading" uk-spinner="ratio: 1"></span>
            <span v-else>Crear calendarización</span>
          </button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !formIsValid"
            v-else
          >
            <span v-if="isLoading" uk-spinner="ratio: 1"></span>
            <span v-else>Actualizar calendarización</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import xlsx from "xlsx";

const getRepeatOptions = frequency => {
  switch (frequency.carbon_string) {
    case "1 day":
      return {
        0: { value: 0, name: "00:00 horas" },
        1: { value: 1, name: "01:00 horas" },
        2: { value: 2, name: "02:00 horas" },
        3: { value: 3, name: "03:00 horas" },
        4: { value: 4, name: "04:00 horas" },
        5: { value: 5, name: "05:00 horas" },
        6: { value: 6, name: "06:00 horas" },
        7: { value: 7, name: "07:00 horas" },
        8: { value: 8, name: "08:00 horas" },
        9: { value: 9, name: "09:00 horas" },
        10: { value: 10, name: "10:00 horas" },
        11: { value: 11, name: "11:00 horas" },
        12: { value: 12, name: "12:00 horas" },
        13: { value: 13, name: "13:00 horas" },
        14: { value: 14, name: "14:00 horas" },
        15: { value: 15, name: "15:00 horas" },
        16: { value: 16, name: "16:00 horas" },
        17: { value: 17, name: "17:00 horas" },
        18: { value: 18, name: "18:00 horas" },
        19: { value: 19, name: "19:00 horas" },
        20: { value: 20, name: "20:00 horas" },
        21: { value: 21, name: "21:00 horas" },
        22: { value: 22, name: "22:00 horas" },
        23: { value: 23, name: "23:00 horas" }
      };
      break;
    case "1 week":
      return {
        0: { value: 0, name: "Lunes" },
        1: { value: 1, name: "Martes" },
        2: { value: 2, name: "Miércoles" },
        3: { value: 3, name: "Jueves" },
        4: { value: 4, name: "Viernes" },
        5: { value: 5, name: "Sábado" },
        6: { value: 6, name: "Domingo" }
      };
      break;
    case "1 month":
      return {
        0: { value: 0, name: "01" },
        1: { value: 1, name: "02" },
        2: { value: 2, name: "03" },
        3: { value: 3, name: "04" },
        4: { value: 4, name: "05" },
        5: { value: 5, name: "06" },
        6: { value: 6, name: "07" },
        7: { value: 7, name: "08" },
        8: { value: 8, name: "09" },
        9: { value: 9, name: "10" },
        10: { value: 10, name: "11" },
        11: { value: 11, name: "12" },
        12: { value: 12, name: "13" },
        13: { value: 13, name: "14" },
        14: { value: 14, name: "15" },
        15: { value: 15, name: "16" },
        16: { value: 16, name: "17" },
        17: { value: 17, name: "18" },
        18: { value: 18, name: "19" },
        19: { value: 19, name: "20" },
        20: { value: 20, name: "21" },
        21: { value: 21, name: "22" },
        22: { value: 22, name: "23" },
        23: { value: 23, name: "24" },
        24: { value: 24, name: "25" },
        25: { value: 25, name: "26" },
        26: { value: 26, name: "27" },
        27: { value: 27, name: "28" },
        "last day": { value: "last day", name: "Último día del mes" }
      };
      break;
    default:
      return [];
      break;
  }
};

export default {
  name: "ReportsScheduling",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        name: "",
        frequency_id: "",
        repeat_on: "",
        reports: {},
        users: {}
      },
      itemsPerPage: 20,
      activeScheduleId: "",
      activeSchedule: "",
      isLoading: false,
      isUpdating: false,
      isFetching: false,
      mode: "new",
      scheduleFilter: "",
      schedules: {},
      scheduleFilterTimer: null,
      errors: null,
      viewDatepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      },
      filterFrom: this.payload.filterFrom,
      filterTo: this.payload.filterTo
    };
  },

  mounted() {
    this.schedules = this.payload.schedules;
  },

  computed: {
    nameIsValid() {
      return this.model.name.length !== 0;
    },
    formIsValid() {
      const fields = [this.nameIsValid];
      return fields.every(field => field);
    },
    repeatOptions() {
      const selectedFrequency = this.payload.scheduleFrequencies.find(
        frequency => frequency.id === this.model.frequency_id
      );
      if (selectedFrequency) {
        return getRepeatOptions(selectedFrequency);
      }
      return [];
    }
  },

  methods: {
    moment,
    translateRepeatOn(frequency, repeat_on) {
      return getRepeatOptions(frequency)[repeat_on].name;
    },
    setNewMode() {
      this.mode = "new";
      this.model = {
        name: "",
        frequency_id: "",
        repeat_on: "",
        reports: {},
        users: {}
      };
    },
    setEditMode(schedule) {
      this.mode = "edit";
      this.activeSchedule = schedule;
      this.activeScheduleId = schedule.id;
      const scheduleUsers = schedule.users.map(user => user.id);
      const scheduleReports = schedule.reports.map(report => report.id);
      this.model = {
        name: schedule.name,
        frequency_id: schedule.frequency_id,
        repeat_on: schedule.repeat_on,
        users: this.payload.users.reduce((accum, user) => {
          accum[user.id] = scheduleUsers.includes(user.id);
          return accum;
        }, {}),
        reports: this.payload.reports.reduce((accum, report) => {
          accum[report.id] = scheduleReports.includes(report.id);
          return accum;
        }, {})
      };
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.scheduleFilter && !url.includes("&query")) {
        url = url + "&query=" + this.scheduleFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { schedules } }) => {
          this.schedules = schedules;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.scheduleFilterTimer) {
        clearTimeout(this.scheduleFilterTimer);
      }
      this.scheduleFilterTimer = setTimeout(() => {
        let urlSearchString = new URL(window.location.href).search;

        this.fetchPage(
          window.location.pathname +
            urlSearchString +
            (urlSearchString == "" ? "?" : "&") +
            "dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage +
            "&from=" +
            this.filterFrom +
            "&to=" +
            this.filterTo
        );
        this.scheduleFilterTimer = null;
      }, 500);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.schedules.current_page.toString() +
            "&query=" +
            this.scheduleFilter +
            "&items=" +
            this.itemsPerPage +
            "&from=" +
            this.filterFrom +
            "&to=" +
            this.filterTo
        )
        .then(({ data: { schedules } }) => {
          this.schedules = schedules;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      axios
        .post("/reports/scheduling", {
          ...this.model,
          users: Object.keys(this.model.users).filter(
            userId => this.model.users[userId]
          ),
          reports: Object.keys(this.model.reports).filter(
            reportId => this.model.reports[reportId]
          )
        })
        .then(response => {
          this.refetchPage(() => {
            this.resetForm();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Calendarización creada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
            UIkit.modal("#edit-schedule-modal").hide();
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando calendarización...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/reports/scheduling/" + this.activeScheduleId.toString(), {
          ...this.model,
          users: Object.keys(this.model.users).filter(
            userId => this.model.users[userId]
          ),
          reports: Object.keys(this.model.reports).filter(
            reportId => this.model.reports[reportId]
          )
        })
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-schedule-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Calendarización actualizada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteSchedule() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando calendarización...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/reports/scheduling/" + this.activeScheduleId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Calendarización eliminada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteScheduleGroup() {
      this.isLoading = true;
      axios
        .delete(
          "/reports/scheduling/bulk/" + this.activeSchedule.recurrent_group
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Calendarización eliminada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },

    handleFileChange(file) {
      this.model.file = file;
      this.model.filename = file.name;
    },

    resetForm() {
      this.model = {
        name: "",
        frequency_id: "",
        repeat_on: "",
        reports: {},
        users: {}
      };
    }
  },

  watch: {
    scheduleFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    filterFrom: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    filterTo: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    model: {
      deep: true,
      handler() {
        // document
        //   .querySelector("#application_date_flatpickr")
        //   ._flatpickr.redraw();
      }
    }
  }
};
</script>