<template>
<div>
  <div class="uk-text-large">Seguridad</div>
  <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-width-1-2@m uk-margin-auto">
    <h3 class="uk-card-title">Actualización de contraseña</h3>
    <div class="uk-flex uk-flex-wrap uk-flex-center">
      <div class="uk-text-bold uk-width-1-4@s">Contraseña actual:</div>
      <div class="uk-width-1-2@s">
        <input class="uk-input uk-border-rounded uk-background-default"  type="password" v-model="model.old_password">
      </div>
    </div>
    <div class="uk-flex uk-flex-wrap uk-flex-center uk-margin-small-top">
      <div class="uk-text-bold uk-width-1-4@s">Nueva contraseña:</div>
      <div class="uk-width-1-2@s">
        <input class="uk-input uk-border-rounded uk-background-default"  type="password" v-model="model.password">
      </div>
    </div>
    <div class="uk-flex uk-flex-wrap uk-flex-center uk-margin-small-top">
      <div class="uk-text-bold uk-width-1-4@s">Confirmación de contraseña:</div>
      <div class="uk-width-1-2@s">
        <input class="uk-input uk-border-rounded uk-background-default"  type="password" v-model="model.password_confirmation">
      </div>
    </div>
    <div class="uk-margin-small-top uk-text-muted uk-text-center" v-if="!newPasswordIsValid">Mínimo 6 caracteres</div>
    <div class="uk-text-center uk-margin-top">
      <button class="uk-button uk-border-rounded uk-button-primary" @click="update" :disabled="isUpdating || !newPasswordIsValid">
        <span v-if="isUpdating" uk-spinner="ratio: 1"></span>
        <span v-else>Actualizar</span>
      </button>
    </div>
  </div>
</div>
</template>

<script>
import UIkit from 'uikit'

export default {
  name: 'ProfileSecurity',

  data() {
    return {
      isUpdating: false,
      model: {
        old_password: '',
        password: '',
        password_confirmation: '',
      }
    }
  },

  methods: {
    update() {
      this.isUpdating = true
      axios.put('/profile/security', this.model).then(
        response => {
          this.isUpdating = false
          this.model.old_password = ''
          this.model.password = ''
          this.model.password_confirmation = ''
          const success = UIkit.notification("<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Contraseña actualizada</div>", { status: 'success', timeout: 3000 })
        }
      ).catch(
        error => {
          console.log('error', error)
          const success = UIkit.notification("<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Contraseña actual incorrecta</div>", { status: 'danger', timeout: 3000 })
          this.isUpdating = false
        }
      )
    }
  },

  computed: {
    newPasswordIsValid(){
      return (this.model.password.length > 5) && (this.model.password_confirmation.length > 5) && (this.model.password === this.model.password_confirmation)
    }
  }
}
</script>