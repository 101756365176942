<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-width-1-1">
      <div class="uk-width-1-4@s">
        <div class="uk-text-center">
          <div
            class="uk-inline uk-dark uk-visible-toggle uk-text-center uk-border-rounded uk-overflow-hidden avatar-placeholder"
          >
            <span v-if="isUpdatingAvatar" uk-spinner="ratio: 8" class="uk-width-1-1"></span>
            <img v-else id="avatar" :src="payload.business.avatar" alt width="250px" height="250px" />
            <div uk-form-custom class="uk-display-block position-static">
              <input
                name="avatar"
                type="file"
                accept="image/*"
                @change="handleAvatar($event.target.files[0])"
              />
              <div
                class="uk-overlay-default uk-position-cover"
                :class="{ 'uk-invisible-hover': payload.business.avatar }"
              >
                <div class="uk-position-center">
                  <span class="uk-link" uk-overlay-icon="icon: pencil; ratio: 3;"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-margin-top uk-text-center">
          <div>Total de colaboradores: {{ payload.insuredQuantity }}</div>
        </div>
      </div>
      <div class="uk-width-3-4@s">
        <div class="uk-card uk-card-default uk-card-body uk-border-rounded">
          <div class="uk-flex uk-flex-between">
            <h3 class="uk-card-title">Información general</h3>
            <div v-if="checkPermission('edit-profile')">
              <button
                v-if="mode ==='view'"
                class="uk-button uk-border-rounded uk-button-primary"
                @click="setEditMode"
                uk-icon="pencil"
              ></button>
              <button
                v-else
                class="uk-button uk-border-rounded uk-button-danger"
                @click="setViewMode"
                uk-icon="close"
                :disabled="isUpdating"
              ></button>
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap">
            <div class="uk-text-bold uk-width-1-4@s">Nombre comercial:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">{{ current.commercial_name }}</span>
              <input
                v-else
                class="uk-input uk-border-rounded uk-background-default"
                type="text"
                v-model="model.commercial_name"
              />
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">Nombre legal:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">{{ current.legal_name }}</span>
              <input
                v-else
                class="uk-input uk-border-rounded uk-background-default"
                type="text"
                v-model="model.legal_name"
              />
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">Industria:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">{{ industryName }}</span>
              <select
                v-else
                class="uk-select uk-border-rounded uk-background-default"
                v-model="model.industry_id"
              >
                <option
                  v-for="industry in this.payload.industries"
                  :key="industry.id"
                  :value="industry.id"
                >{{ industry.name }}</option>
              </select>
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">Giro:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">{{ current.business_core }}</span>
              <input
                v-else
                class="uk-input uk-border-rounded uk-background-default"
                type="text"
                v-model="model.business_core"
              />
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">NIT:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">{{ current.nit }}</span>
              <input
                v-else
                class="uk-input uk-border-rounded uk-background-default"
                type="text"
                v-model="model.nit"
              />
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">Dirección:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">{{ current.address }}</span>
              <input
                v-else
                class="uk-input uk-border-rounded uk-background-default"
                type="text"
                v-model="model.address"
              />
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">Sitio web:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">{{ current.website }}</span>
              <input
                v-else
                class="uk-input uk-border-rounded uk-background-default"
                type="text"
                v-model="model.website"
              />
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">Establecida en:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">{{ formatDate(current.established_at) }}</span>
              <flat-pickr
                v-else
                class="uk-input uk-border-rounded uk-background-default"
                v-model="model.established_at"
                :config="datepickerConfig"
              ></flat-pickr>
            </div>
          </div>
          <h3 class="uk-card-title">Contacto</h3>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">Administrador primario:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">
                <div v-if="adminUser">
                  <div class="uk-text-bold">{{ adminUser.first_name }} {{ adminUser.last_name }}</div>
                  <div>Teléfono: {{ adminUser.phone }}</div>
                  <div>Email: {{ adminUser.email }}</div>
                </div>
                <div v-else>-</div>
              </span>
              <!-- <input v-else class="uk-input uk-border-rounded uk-background-default"  type="text" v-model="model.admin_user_id"> -->
              <select
                v-else
                class="uk-select uk-border-rounded uk-background-default"
                v-model="model.admin_user_id"
              >
                <option
                  v-for="user in businessUsers"
                  :key="user.id"
                  :value="user.id"
                >{{ user.first_name }} {{ user.last_name }}</option>
              </select>
            </div>
          </div>
          <div class="uk-flex uk-flex-wrap uk-margin-small-top">
            <div class="uk-text-bold uk-width-1-4@s">Contacto de recursos humanos:</div>
            <div class="uk-width-1-2@s">
              <span v-if="mode ==='view'">
                <div v-if="hrUser">
                  <div class="uk-text-bold">{{ hrUser.first_name }} {{ hrUser.last_name }}</div>
                  <div>Teléfono: {{ hrUser.phone }}</div>
                  <div>Email: {{ hrUser.email }}</div>
                </div>
                <div v-else>-</div>
              </span>
              <!-- <input v-else class="uk-input uk-border-rounded uk-background-default"  type="text" v-model="model.hr_user_id"> -->
              <select
                v-else
                class="uk-select uk-border-rounded uk-background-default"
                v-model="model.hr_user_id"
              >
                <option
                  v-for="user in businessUsers"
                  :key="user.id"
                  :value="user.id"
                >{{ user.first_name }} {{ user.last_name }}</option>
              </select>
            </div>
          </div>
          <div class="uk-text-center uk-margin-top">
            <button
              v-if="mode ==='edit'"
              class="uk-button uk-border-rounded uk-button-primary"
              @click="update"
              :disabled="isUpdating"
            >
              <span v-if="isUpdating" uk-spinner="ratio: 1"></span>
              <span v-else>Actualizar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default {
  name: "ProfileGeneral",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      mode: "view",
      model: {
        commercial_name: "",
        legal_name: "",
        industry_id: "",
        business_core: "",
        nit: "",
        address: "",
        website: "",
        established_at: "",
        admin_user_id: "",
        hr_user_id: "",
        admin_user: "",
        hr_user: ""
      },
      current: {
        commercial_name: this.payload.business.commercial_name,
        legal_name: this.payload.business.legal_name,
        industry: this.payload.business.industry_id,
        business_core: this.payload.business.business_core,
        nit: this.payload.business.nit,
        address: this.payload.business.address,
        website: this.payload.business.website,
        established_at: this.payload.business.established_at,
        admin_user_id: this.payload.business.admin_user_id,
        hr_user_id: this.payload.business.hr_user_id,
        admin_user: this.payload.business.admin_user,
        hr_user: this.payload.business.hr_user
      },
      isUpdating: false,
      isUpdatingAvatar: false,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  computed: {
    industryName() {
      if (this.current.industry) {
        return this.payload.industries.filter(
          industry => industry.id === this.current.industry
        )[0].name;
      } else {
        return "";
      }
    },
    businessUsers() {
      return this.payload.business.roles.reduce((accum, role) => {
        role.users.map(user => {
          if (!(user.id in accum)) {
            accum[user.id] = user;
          }
        });
        return accum;
      }, {});
    },
    adminUser() {
      if (this.current.admin_user_id) {
        return this.businessUsers[this.current.admin_user_id];
      } else {
        return null;
      }
    },
    hrUser() {
      if (this.current.hr_user_id) {
        return this.businessUsers[this.current.hr_user_id];
      } else {
        return null;
      }
    }
  },

  methods: {
    setEditMode() {
      this.mode = "edit";
      this.model.commercial_name = this.current.commercial_name;
      this.model.legal_name = this.current.legal_name;
      this.model.industry_id = this.current.industry;
      this.model.business_core = this.current.business_core;
      this.model.nit = this.current.nit;
      this.model.address = this.current.address;
      this.model.website = this.current.website;
      this.model.established_at = this.current.established_at;
      this.model.admin_user_id = this.current.admin_user_id;
      this.model.hr_user_id = this.current.hr_user_id;
    },
    setViewMode() {
      this.mode = "view";
    },
    update() {
      this.isUpdating = true;
      axios
        .put("/profile/general", this.model)
        .then(response => {
          this.current.commercial_name = this.model.commercial_name;
          this.current.legal_name = this.model.legal_name;
          this.current.industry = this.model.industry_id;
          this.current.business_core = this.model.business_core;
          this.current.nit = this.model.nit;
          this.current.address = this.model.address;
          this.current.website = this.model.website;
          this.current.established_at = this.model.established_at;
          this.current.admin_user_id = this.model.admin_user_id;
          this.current.hr_user_id = this.model.hr_user_id;
          this.isUpdating = false;
          this.mode = "view";
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Cambios aplicados</div>",
            { status: "success", timeout: 3000 }
          );
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdating = false;
        });
    },
    handleAvatar(file) {
      this.isUpdatingAvatar = true;
      let formData = new FormData();
      formData.append("avatar", file);
      axios
        .post("/profile/general/avatar", formData)
        .then(({ data: { url } }) => {
          this.isUpdatingAvatar = false;
          document.getElementById("avatar").src = url;
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdatingAvatar = false;
        });
    }
  }
};
</script>

<style scoped>
.position-static {
  position: static;
}

.avatar-placeholder {
  min-height: 100px;
}
</style>
