<template>
  <div>
    <div class="uk-text-large">Solicitudes</div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input"
            type="search"
            placeholder="Buscar solicitud..."
            v-model="requestFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="requests.prev_page_url"
          :is-fetching="isFetching"
          :current-page="requests.current_page"
          :last-page="requests.last_page"
          :next-page-url="requests.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th width="15%">Colaborador</th>
            <th width="35%">Solicitud</th>
            <th width="10%">Estado</th>
            <th width="20%">Notas de resolución</th>
            <th width="20%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(request, index) in requests.data" :key="request.id">
            <td>
              <div>{{ request.insured_business.insured.first_name }} {{ request.insured_business.insured.last_name }}</div>
              <div>{{ request.insured_business.division.name }}</div>
            </td>
            <td>
              <div class="uk-text-bold">{{ translate(request.requestable_type) }}</div>
              <div
                class="uk-margin-small-top"
                v-if="request.requestable_type === 'App\\Models\\InsuredBusinessAbsence'"
              >
                <div>
                  <span class="uk-text-bold">Fecha de aplicación</span>
                  : {{ formatDate(request.model.application_date) }}
                </div>
                <div>
                  <span class="uk-text-bold">Tipo</span>
                  : {{ payload.absenceTypes.find(type => type.id === request.model.absence_type_id).name }}
                </div>
                <!-- <div> -->
                <!-- <span class="uk-text-bold">Razón</span>
                : {{ payload.absenceReasons.find(reason => request.model.absence_reason_id).name }}-->
                <!-- </div> -->
                <div>
                  <span class="uk-text-bold">Duración</span>
                  : {{ request.model.duration }} días
                </div>
                <div>
                  <span class="uk-text-bold">Notas</span>
                  : {{ request.model.notes }}
                </div>
              </div>
            </td>
            <td>{{ request.status.name }}</td>
            <td>{{ request.resolution_notes }}</td>
            <td class="uk-text-right">
              <div
                class="uk-button-group uk-border-rounded uk-overflow-hidden"
                v-if="checkPermission('resolve-request') && request.status.slug === 'pending'"
              >
                <button
                  class="uk-button uk-width-1-2 uk-button-primary"
                  href="#edit-request-modal"
                  uk-toggle
                  @click="setEditMode(request, index)"
                  uk-icon="check"
                ></button>
                <button
                  class="uk-button uk-width-1-2 uk-button-danger"
                  href="#confirm-rejection-modal"
                  uk-toggle
                  @click="setEditMode(request, index)"
                  uk-icon="ban"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="bottom-divider uk-hidden@s"
        v-for="(request, index) in requests.data"
        :key="request.id"
      >
        <div class="uk-text-center uk-text-bold">
          <span>{{ request.request_name }}</span>
        </div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
        >
          <button
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-request-modal"
            uk-toggle
            @click="setEditMode(request, index)"
            uk-icon="pencil"
            v-if="checkPermission('edit-request')"
          ></button>
          <button
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-rejection-modal"
            uk-toggle
            @click="setEditMode(request, index)"
            uk-icon="trash"
            v-if="checkPermission('delete-request')"
          ></button>
        </div>
      </div>
    </div>

    <div id="confirm-rejection-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large" v-if="activeRequest">
          <span>¿Está seguro que desea rechazar la solicitud de {{ translate(this.activeRequest.requestable_type) }} de {{ this.activeRequest.insured_business.insured.first_name }} {{ this.activeRequest.insured_business.insured.last_name }}?</span>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Notas de resolución</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <textarea
                name="absence-notes-text"
                v-model="model.resolution_notes"
                rows="5"
                class="uk-textarea uk-border-rounded"
                type="text"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="resolveRequest('rejected')"
            :disabled="isLoading"
          >Rechazar solicitud</button>
        </div>
      </div>
    </div>

    <div id="edit-request-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
        v-if="model.model"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div
          class="uk-text-large"
        >Aprobar solicitud - {{ activeRequest.insured_business.insured.first_name }} {{ activeRequest.insured_business.insured.last_name }}</div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Tipo de ausencia*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select
                name="absence-type-select"
                class="uk-select uk-border-rounded"
                v-model="model.model.absence_type_id"
              >
                <option
                  v-for="type in payload.absenceTypes"
                  :key="type.id"
                  :value="type.id"
                >{{ type.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Razón*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select
                name="absence-reason-select"
                class="uk-select uk-border-rounded"
                v-model="model.model.absence_reason_id"
              >
                <option
                  v-for="reason in payload.absenceReasons"
                  :key="reason.id"
                  :value="reason.id"
                >{{ reason.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Notas</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <textarea
                name="absence-notes-text"
                v-model="model.model.notes"
                rows="5"
                class="uk-textarea uk-border-rounded"
                type="text"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Fecha de aplicación*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <flat-pickr
                id="absence-apllication-date-input"
                class="uk-input uk-border-rounded"
                v-model="model.model.application_date"
                :config="datepickerConfig"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label
            class="uk-form-label"
            for="form-stacked-text"
          >Duración en días (puedes ingresar medios días)*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                name="application-date-duration"
                v-model="model.model.duration"
                class="uk-input uk-border-rounded"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label
            class="uk-form-label"
            for="form-stacked-text"
          >Duración en horas (puedes ingresar decimales)*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input v-model="model.model.hours" class="uk-input uk-border-rounded" type="text" />
            </div>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Notas de resolución</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <textarea
                name="absence-notes-text"
                v-model="model.resolution_notes"
                rows="5"
                class="uk-textarea uk-border-rounded"
                type="text"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="resolveRequest('approved')"
            :disabled="isLoading || !requestIsValid"
          >Aprobar solicitud</button>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <div uk-form-custom>
            <input name="file" type="file" @change="handleFile($event)" />
            <button class="uk-button uk-border-rounded uk-button-secondary">Importar</button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-border-rounded uk-button-secondary">Exportar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import xlsx from "xlsx";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default {
  name: "DirectoryBusinessRequests",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        request_name: "",
        request_number: "",
        bank_id: "",
        request_type_id: ""
      },
      itemsPerPage: 20,
      activeRequest: null,
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      mode: "new",
      requestFilter: "",
      requests: {},
      requestFilterTimer: null,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  mounted() {
    this.requests = this.payload.requests;
  },

  computed: {
    requestIsValid() {
      return true;
    }
  },

  methods: {
    setEditMode(request, index) {
      this.mode = "edit";
      this.activeRequest = request;
      this.payloadPosition = index;
      this.model = request;
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.requestFilter && !url.includes("&query")) {
        url = url + "&query=" + this.requestFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { requests } }) => {
          this.requests = requests;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.requestFilterTimer) {
        clearTimeout(this.requestFilterTimer);
      }
      this.requestFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.requestFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.requests.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { requests } }) => {
          this.requests = requests;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    resolveRequest(status) {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Resolviendo solicitud...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/directory/requests/" + this.activeRequest.id.toString(), {
          status,
          resolution_notes: this.model.resolution_notes,
          model: this.model.model
        })
        .then(({ data: { model } }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-request-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Solicitud actualizada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    handleFile(e) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        const newRequests = xlsx.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 2 }
        );

        axios
          .post("/directory/requests/bulk", newRequests)
          .then(({ data }) => {
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                data.count +
                " Solicitudes creadas</div>",
              { status: "success", timeout: 3000 }
            );
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        request_name: "",
        request_number: "",
        bank_id: "",
        request_type_id: ""
      };
    }
  },

  watch: {
    requestFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}
</style>