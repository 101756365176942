<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Roles</div>
      <div>
        <button
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-role-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-role')"
        >Crear rol</button>
      </div>
    </div>
    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th width="15%">Nombre</th>
            <th width="70%">Acceso a módulos</th>
            <th width="15%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="role in roles" :key="role.id">
            <td>{{ role.name }}</td>
            <td>
              <modules-description :modules="role.sorted_modules" />
            </td>
            <td class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  class="uk-button uk-width-1-2 uk-button-primary"
                  href="#edit-role-modal"
                  uk-toggle
                  @click="setEditMode(role)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-role')"
                ></button>
                <button
                  class="uk-button uk-width-1-2 uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(role)"
                  uk-icon="trash"
                  v-if="checkPermission('delete-role')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="edit-role-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          rol
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input class="uk-input uk-border-rounded" type="text" v-model="model.name" />
            </div>
          </div>
        </div>

        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Permisos</label>
          <modules-selection :modules="model.modules" />
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !roleIsValid"
            v-if="mode === 'new'"
          >
            <span v-if="isLoading" uk-spinner></span>Crear rol
          </button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !roleIsValid"
            v-else
          >
            <span v-if="isLoading" uk-spinner></span> Actualizar rol
          </button>
        </div>
      </div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span>¿Está seguro que desea eliminar el rol {{ this.model.name }}?</span>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="destroy"
            :disabled="isLoading"
          >Eliminar rol</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

const injectSelected = (modules, moduleIds, actionIds) =>
  modules.map(module => ({
    ...module,
    selected: moduleIds.includes(module.id),
    available_actions: module.available_actions.map(action => ({
      ...action,
      selected: actionIds.includes(action.id)
    })),
    children: injectSelected(module.children, moduleIds, actionIds)
  }));

const extractSelected = modules => {
  const selectedModules = modules.filter(module => module.selected);
  let currentModuleIds = selectedModules.map(module => module.id);
  let currentActionIds = selectedModules
    .map(module => module.available_actions)
    .flat()
    .filter(action => action.selected)
    .map(action => action.id);
  const children = selectedModules.map(module => module.children).flat();
  if (children.length !== 0) {
    const { moduleIds, actionIds } = extractSelected(children);
    currentModuleIds.push(...moduleIds);
    currentActionIds.push(...actionIds);
  }
  return {
    moduleIds: currentModuleIds,
    actionIds: currentActionIds
  };
};

export default {
  name: "ProfileRoles",

  props: ["payload"],

  data() {
    return {
      roles: this.payload.roles,
      activeRole: null,
      isLoading: false,
      mode: "new",
      model: {
        name: "",
        modules: null
      }
    };
  },

  computed: {
    roleIsValid() {
      return this.model.name !== "";
    }
  },

  methods: {
    setNewMode() {
      this.mode = "new";
      this.model.name = "";
      this.model.modules = injectSelected(this.payload.modules, [], []);
      this.activeRole = null;
    },
    setEditMode(role) {
      this.mode = "edit";
      this.activeRole = role;
      this.model.name = role.name;
      this.model.modules = injectSelected(
        this.payload.modules,
        role.modules.map(module => module.id),
        role.module_actions.map(action => action.id)
      );
    },
    store() {
      this.isLoading = true;
      const { moduleIds, actionIds } = extractSelected(this.model.modules);
      axios
        .post("/profile/roles", {
          module_ids: moduleIds,
          action_ids: actionIds,
          name: this.model.name
        })
        .then(response => {
          this.refetchPage(() => {
            UIkit.modal("#edit-role-modal").hide();
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const { moduleIds, actionIds } = extractSelected(this.model.modules);
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando roles...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/profile/roles/" + this.activeRole.id, {
          module_ids: moduleIds,
          action_ids: actionIds,
          name: this.model.name
        })
        .then(response => {
          this.refetchPage(() => {
            UIkit.modal("#edit-role-modal").hide();
            loader.close();
            const success = UIkit.notification(
              "<div id='successfull-store' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Rol actualizado.</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    destroy() {
      this.isLoading = true;
      axios
        .delete("/profile/roles/" + this.activeRole.id)
        .then(response => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(window.location.pathname + "?dataOnly=true")
        .then(({ data: { roles } }) => {
          this.roles = roles;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    }
  }
};
</script>