<template>
<div class="uk-margin-bottom">
  <div class="uk-text-center uk-margin-large-top">
    <img src="/images/logo.png" width="300px" alt="">
  </div>
  <form method="POST" action="/password/email" class="uk-width-medium uk-margin-auto uk-text-center">

    <div class="uk-margin-large-top uk-text-large border-bottom">Verificar cuenta</div>
    <div class="uk-margin-top uk-text-muted">Te hemos enviado un correo con instrucciones para verificar tu cuenta.</div>
    <a :href="resendLink" class="uk-margin-top uk-width-1-1 uk-button uk-border-rounded uk-button-secondary">Reenviar correo de verificación</a>
    <a href="/login" class="uk-margin-top uk-width-1-1 uk-button uk-border-rounded uk-button-primary">Iniciar sesión</a>
  </form>
</div>
</template>

<script>
export default {
  name: 'AuthVerify',

  props: ['resendLink']
}
</script>

<style>
.border-bottom {
  border-bottom: solid 1px;
}
</style>