<!-- <template>
<div>
  <div class="uk-flex uk-flex-around uk-flex-wrap">
    <div
      v-if="level"
      v-show="selectedCategory === null || (selectedCategory && selectedCategory.id === category.id) || expanded"
      @click="selectCategory(category, level)"
      class="uk-card uk-border-rounded uk-padding-small uk-width-medium uk-box-shadow-hover-small service-card uk-flex uk-flex-around uk-flex-middle"
      :class="{
        'uk-card-primary uk-box-shadow-small': (selectedCategory && category.id === selectedCategory.id),
        'uk-card-default uk-box-shadow-xlarge uk-margin-top uk-margin-bottom': !(selectedCategory && category.id === selectedCategory.id),
      }"
      v-for="category in level">
      <div class="uk-text-center uk-text-bold" :class="{ 'uk-text-large' : expanded }">{{ category.name }}</div>
      <div class="uk-text-center" v-if="expanded === false && selectedCategory">
        <button class="uk-button uk-button-small uk-button-secondary uk-border-rounded" @click="expanded = true">Cambiar</button>
      </div>
    </div>
  </div>
  <hr class="uk-divider-icon uk-margin-remove">
  <service-tree-level :ref="levelId + '-child'" v-if="selectedCategory && selectedCategory.children.length !== 0" :level="selectedCategory.children" />
</div>
</template> -->

<template>
<div class="uk-flex uk-flex-center">
  <div>
    <div
      v-if="level"
      v-show="selectedCategory === null || (selectedCategory && selectedCategory.id === category.id) || expanded"
      @click="selectCategory(category, level)"
      class="uk-card uk-border-rounded uk-padding-small uk-width-medium uk-box-shadow-hover-small service-card uk-flex uk-flex-around uk-flex-middle uk-margin-bottom uk-margin-small-right"
      :class="{
        'uk-card-primary uk-box-shadow-small': (selectedCategory && category.id === selectedCategory.id),
        'uk-card-default uk-box-shadow-xlarge': !(selectedCategory && category.id === selectedCategory.id),
      }"
      v-for="category in level">
      <div class="uk-text-center uk-text-bold" :class="{ 'uk-text-large' : expanded }">{{ category.name }}</div>
      <div class="uk-text-center" v-if="expanded === false && selectedCategory">
        <button class="uk-button uk-button-small uk-button-secondary uk-border-rounded" @click="expanded = true">Cambiar</button>
      </div>
    </div>
  </div>
  <hr class="uk-divider-icon uk-margin-remove">
  <service-tree-level :ref="levelId + '-child'" v-if="selectedCategory && selectedCategory.children.length !== 0" :level="selectedCategory.children" />
</div>
</template>

<script>
const uuidv4 = () => ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
  (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
)

export default {
  name: 'ServiceTreeLevel',

  props: ['level'],

  data() {
    return {
      levelId: uuidv4(),
      selectedCategory: null,
      expanded: true
    }
  },

  mounted() {
    // app.__vue__.$on('fetch-providers', (payload) => app.__vue__.$emit('fetch-providers', payload))
    // app.__vue__.$on('category-changed', () => app.__vue__.$emit('category-changed'))
  },

  methods: {
    selectCategory(category, level) {
      if (!(this.selectedCategory && this.selectedCategory.id === category.id)) {
        if (this.selectedCategory && this.selectedCategory.children.length !== 0 && (this.levelId + '-child') in this.$refs) {
          this.$refs[this.levelId + '-child'].deselectDownwards()
        }
        this.selectedCategory = category
        this.expanded = false
        app.__vue__.$emit('category-changed')
        if (this.selectedCategory.children.length === 0) {
          app.__vue__.$emit('fetch-providers', this.selectedCategory.id)
        }
      }
    },
    deselectDownwards() {
      if (this.selectedCategory && this.selectedCategory.children.length !== 0 && (this.levelId + '-child') in this.$refs) {
        this.$refs[this.levelId + '-child'].deselectDownwards()
      }
      this.selectedCategory = null
      this.expanded = true
    }
  }
}
</script>

<style scoped>
.service-card {
  transition: box-shadow 0.1s ease-in-out,
              background 0.2s,
              color 0.2s !important;
  cursor: pointer;
}
</style>