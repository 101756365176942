<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Descuentos</div>
      <div class="uk-text-center">
        <a
          class="uk-button uk-border-rounded uk-button-primary"
          href="/payroll/discount-types"
        >Configuración de tipos</a>
        <button
          class="uk-button uk-border-rounded uk-button-primary"
          href="#import-export-modal"
          uk-toggle
        >Importar / Exportar</button>
        <button
          class="uk-button uk-border-rounded uk-button-secondary uk-margin"
          href="#edit-discount-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-discount')"
        >Asignar descuento</button>
      </div>
    </div>

    <div class="uk-flex uk-flex-center uk-margin-small-bottom">
      <div class="uk-margin-right uk-width-medium">
        <flat-pickr
          class="uk-input uk-border-rounded uk-text-center"
          v-model="filterFrom"
          :config="viewDatepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
      <div class="uk-margin-left uk-width-medium">
        <flat-pickr
          class="uk-input uk-border-rounded uk-text-center"
          v-model="filterTo"
          :config="viewDatepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input search-input-animation"
            type="search"
            placeholder="Buscar descuento..."
            v-model="discountFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="discounts.prev_page_url"
          :is-fetching="isFetching"
          :current-page="discounts.current_page"
          :last-page="discounts.last_page"
          :next-page-url="discounts.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Título</th>
            <th>Tipo de descuento</th>
            <th>Razón</th>
            <th>Constancia</th>
            <th>Monto</th>
            <th>Fecha de aplicación</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="discount in discounts.data" :key="discount.id">
            <td width="10%">
              <a
                :href="'/directory/general/' + discount.business_relation.insured.id.toString()"
                class="uk-link-reset"
              >
                <img
                  v-if="discount.avatar"
                  :data-src="discount.avatar"
                  height="80"
                  width="80"
                  alt
                  uk-img
                  class="uk-border-circle avatar"
                />
                <div
                  class="user-logo uk-border-circle uk-padding-small uk-text-center"
                  v-else
                >{{ discount.business_relation.insured.first_name.substr(0, 1) }} {{ discount.business_relation.insured.last_name.substr(0, 1) }}</div>
              </a>
            </td>
            <td width="15%">
              <a
                :href="'/directory/general/' + discount.business_relation.insured.id.toString()"
                class="uk-text-bold uk-link-reset"
              >{{ discount.business_relation.insured.first_name }} {{ discount.business_relation.insured.last_name }}</a>
            </td>
            <td width="10%">
              <span v-if="discount.business_relation">{{ discount.business_relation.title }}</span>
            </td>
            <td width="10%">
              <span v-if="discount.type">{{ discount.type.name }}</span>
            </td>
            <td width="20%">
              <span v-if="discount.reason">{{ discount.reason }}</span>
            </td>
            <td width="5%" class="uk-text-center">
              <a
                v-if="discount.file"
                :href="discount.file.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
            <td width="10%">
              <span v-if="discount.amount">{{ toCurrency(discount.amount) }}</span>
            </td>
            <td width="10%">
              <span v-if="discount.application_date">{{ formatDate(discount.application_date) }}</span>
            </td>
            <td width="10%" class="uk-text-right">
              <div
                class="uk-button-group uk-border-rounded uk-overflow-hidden"
                v-if="discount.paid === 0"
              >
                <button
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                  href="#edit-discount-modal"
                  uk-toggle
                  @click="setEditMode(discount)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-discount')"
                ></button>
                <button
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(discount)"
                  uk-icon="trash"
                  v-if="checkPermission('delete-discount')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="bottom-divider uk-hidden@s" v-for="discount in discounts.data" :key="discount.id">
        <div class="uk-flex uk-flex-between">
          <div>
            <a :href="'/directory/general/' + discount.id.toString()" class="link-underline">
              <span
                class="uk-text-bold"
              >{{ discount.business_relation.insured.first_name }} {{ discount.business_relation.insured.last_name }}</span>
            </a>
            <span v-if="discount.business_relation">- {{ discount.business_relation.title }}</span>
          </div>
          <div>{{ toCurrency(discount.amount) }}</div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span v-if="discount.type">{{ discount.type.name }}</span>
          </div>
          <div>
            <span v-if="discount.application_date">{{ formatDate(discount.application_date) }}</span>
          </div>
        </div>
        <div>{{ discount.reason }}</div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
        >
          <button
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-discount-modal"
            uk-toggle
            @click="setEditMode(discount)"
            uk-icon="pencil"
            v-if="checkPermission('edit-discount')"
          ></button>
          <button
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(discount)"
            uk-icon="trash"
            v-if="checkPermission('delete-discount')"
          ></button>
        </div>
      </div>

      <div
        v-if="discounts.data && discounts.data.length === 0"
        class="uk-text-center uk-margin-top"
      >No se han encontrado resultados con los filtros seleccionados.</div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div
          class="uk-text-large"
        >¿Está seguro que desea eliminar el descuento de {{ toCurrency(this.model.amount) }}?</div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deletediscount"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Eliminar descuento</span>
          </button>
        </div>
        <div v-if="activeDiscount.recurrent_group">
          <div>Este descuento es parte de un grupo recurrente, desea borrar los descuentos no pagados del grupo?</div>
          <div class="uk-text-center uk-margin-small-top">
            <button
              class="uk-button uk-border-rounded uk-button-danger"
              @click="deleteDiscountGroup"
              :disabled="isLoading"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else>Eliminar grupo de descuentos recurrente</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="edit-discount-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Asignar</span>
          <span v-else>Editar</span>
          descuento
        </div>
        <div class="uk-margin" v-if="mode === 'new'">
          <label class="uk-form-label" for="form-stacked-text">Colaborador</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <v-select
                v-model="model.insured_business_id"
                class="uk-input uk-border-rounded"
                :options="payload.insureds"
                :reduce="insured => insured.id"
                :getOptionLabel="option => option.insured.dpi + ' - ' + option.insured.first_name + ' ' + option.insured.last_name"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">
            Tipo
            <span
              v-if="!payload.bonusTypes.map(type => type.id).includes(model.bonus_type_id) && model.bonus_type_id !== ''"
              uk-icon="warning"
            >(No es posible editar el tipo de este descuento)</span>
          </label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select
                class="uk-select uk-border-rounded"
                v-model="model.bonus_type_id"
                :disabled="!payload.bonusTypes.map(type => type.id).includes(model.bonus_type_id) && model.bonus_type_id !== ''"
              >
                <option
                  v-for="discountType in payload.bonusTypes"
                  :key="discountType.id"
                  :value="discountType.id"
                >{{ discountType.name }} - Prestaciones: {{ discountType.benefits.length !== 0 ? discountType.benefits.map(benefit => benefit.name).join(', ') : 'Ninguna' }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Razón</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input v-model="model.reason" class="uk-input uk-border-rounded" type="text" />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Monto (Q.)</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                v-currencyOnly
                v-model="model.amount"
                class="uk-input uk-border-rounded"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Fecha de aplicación</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <flat-pickr
                id="application_date_flatpickr"
                v-model="model.application_date"
                class="uk-input uk-border-rounded"
                :config="datepickerConfig"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Constancia</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1" uk-form-custom="target: true">
              <input type="file" @change="handleFileChange($event.target.files[0])" />
              <input
                class="uk-input uk-border-rounded"
                type="text"
                disabled
                v-model="model.filename"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <label>
            <input class="uk-checkbox" type="checkbox" v-model="model.recurrent" /> Descuento recurrente
          </label>
        </div>
        <div class="uk-margin" v-if="model.recurrent">
          <label class="uk-form-label" for="form-stacked-text">
            Cantidad de descuentos
            <span
              v-if="model.recurrent_quantity && model.amount"
            >(Total: {{ toCurrency(model.recurrent_quantity * model.amount) }})</span>
          </label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input
                v-numericOnly
                v-model="model.recurrent_quantity"
                class="uk-input uk-border-rounded"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin" v-if="model.recurrent">
          <label class="uk-form-label" for="form-stacked-text">Frecuencia del descuento</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select class="uk-select uk-border-rounded" v-model="model.recurrent_frequency">
                <option
                  v-for="frequency in payload.frequencies"
                  :key="frequency.id"
                  :value="frequency.id"
                >{{ frequency.name }}</option>
              </select>
            </div>
          </div>
          <div v-if="model.application_date" class="uk-margin-small-top">
            <div
              v-if="model.recurrent_frequency === 1"
            >Los descuentos serán generados en el día {{ moment(model.application_date).date() }} de cada mes.</div>
            <div
              v-if="model.recurrent_frequency === 2"
            >Los descuentos serán generados en el día {{ moment(model.application_date).date() }} y {{ secondRecurrentDate }} de cada mes.</div>
          </div>
          <div
            v-else
            class="uk-margin-small-top"
          >Seleccione una fecha para el inicio de la recurrencia.</div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !formIsValid"
            v-if="mode === 'new'"
          >
            <span v-if="isLoading" uk-spinner="ratio: 1"></span>
            <span v-else>Asignar descuento</span>
          </button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !formIsValid"
            v-else
          >
            <span v-if="isLoading" uk-spinner="ratio: 1"></span>
            <span v-else>Actualizar descuento</span>
          </button>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <a
            class="uk-button uk-border-rounded uk-button-secondary"
            href="/payroll/discounts/template"
            target="”_blank”"
          >Descargar plantilla de importación</a>
        </div>
        <div class="uk-margin uk-text-center" v-if="checkPermission('import-discounts')">
          <div uk-form-custom>
            <input
              name="import-file"
              id="import-file"
              type="file"
              @change="handleImportFile($event)"
            />
            <button class="uk-button uk-border-rounded uk-button-secondary" :disabled="isLoading">
              <span v-if="isLoading" uk-spinner></span>
              <span v-else>Importar</span>
            </button>
          </div>
          <div v-if="errors" class="uk-margin-small-top">
            <div
              class="uk-margin-small-bottom"
            >Se ha encontrado información inválida en la plantilla cargada</div>
            <div
              v-for="row in Object.keys(errors)"
              :key="row"
              class="uk-flex uk-flex-middle uk-flex-start uk-text-left"
            >
              <div uk-icon="warning"></div>
              <div
                class="uk-margin-small-left"
              >Fila {{ parseFloat(row.split('.')[0]) + 4 }}: {{ errors[row][0] }}</div>
            </div>
            <div class="uk-margin-small-top">Luego de corregir estos errores, inténtalo nuevamente.</div>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center" v-if="checkPermission('export-discounts')">
          <a
            :href="'/payroll/discounts/bulk?from=' + filterFrom + '&to=' + filterTo"
            class="uk-button uk-border-rounded uk-button-secondary"
          >Exportar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import xlsx from "xlsx";

export default {
  name: "PayrollBonuses",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        insured_business_id: "",
        reason: "",
        amount: "",
        application_date: "",
        bonus_type_id: "",
        file: "",
        filename: "",
        recurrent: false,
        recurrent_quantity: "",
        recurrent_frequency: ""
      },
      itemsPerPage: 20,
      activeDiscountId: "",
      activeDiscount: "",
      isLoading: false,
      isUpdating: false,
      isFetching: false,
      mode: "new",
      discountFilter: "",
      discounts: {},
      discountFilterTimer: null,
      errors: null,
      viewDatepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      },
      filterFrom: this.payload.filterFrom,
      filterTo: this.payload.filterTo
    };
  },

  mounted() {
    this.discounts = this.payload.bonuses;
  },

  computed: {
    selectedInsured() {
      if (this.model.insured_business_id) {
        return this.payload.insureds.filter(
          insuredBusiness =>
            insuredBusiness.id === this.model.insured_business_id
        )[0];
      } else {
        return {
          payroll_history: []
        };
      }
    },
    invalidRecurrentDates() {
      if (this.model.recurrent_frequency === 1) {
        return [29, 30, 31];
      }
      if (this.model.recurrent_frequency === 2) {
        return [14, 15, 16, 29, 30, 31];
      }
      return [];
    },
    datepickerConfig() {
      return {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y",
        enable: [
          date => {
            let isBetween = this.selectedInsured.payroll_history.map(record =>
              moment(date).isBetween(
                moment(record.start_date.substr(0, 10)),
                moment(record.end_date.substr(0, 10)),
                null,
                "[]"
              )
            );
            isBetween = isBetween.some(x => x === true);
            return (
              !this.invalidRecurrentDates.includes(date.getDate()) && isBetween
            );
          }
        ]
      };
    },
    secondRecurrentDate() {
      return moment(this.model.application_date)
        .add(15, "days")
        .date();
    },
    insuredBusinessIdIsValid() {
      return this.model.insured_business_id !== "";
    },
    reasonIsValid() {
      return this.model.reason !== "";
    },
    amountIsValid() {
      return this.model.amount !== "";
    },
    applicationDateIsValid() {
      return this.model.application_date !== "";
    },
    discountTypeIdIsValid() {
      return this.model.bonus_type_id !== "";
    },
    formIsValid() {
      const fields =
        this.mode === "new"
          ? [
              this.insuredBusinessIdIsValid,
              this.reasonIsValid,
              this.amountIsValid,
              this.applicationDateIsValid,
              this.discountTypeIdIsValid
            ]
          : [
              this.reasonIsValid,
              this.amountIsValid,
              this.applicationDateIsValid,
              this.discountTypeIdIsValid
            ];
      return fields.every(field => field);
    }
  },

  methods: {
    moment,
    setNewMode() {
      this.mode = "new";
      this.model = {
        insured_business_id: "",
        reason: "",
        amount: "",
        application_date: "",
        bonus_type_id: "",
        file: "",
        filename: "",
        recurrent: false,
        recurrent_quantity: "",
        recurrent_frequency: ""
      };
    },
    setEditMode(discount) {
      this.mode = "edit";
      this.activeDiscount = discount;
      this.activeDiscountId = discount.id;
      this.model = {
        insured_business_id: discount.insured_business_id,
        reason: discount.reason,
        amount: discount.amount,
        application_date: discount.application_date,
        bonus_type_id: discount.bonus_type_id,
        file: "",
        filename: "",
        recurrent: false,
        recurrent_quantity: "",
        recurrent_frequency: ""
      };
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.discountFilter && !url.includes("&query")) {
        url = url + "&query=" + this.discountFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { bonuses } }) => {
          this.discounts = bonuses;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.discountFilterTimer) {
        clearTimeout(this.discountFilterTimer);
      }
      this.discountFilterTimer = setTimeout(() => {
        let urlSearchString = new URL(window.location.href).search;

        this.fetchPage(
          window.location.pathname +
            urlSearchString +
            (urlSearchString == "" ? "?" : "&") +
            "dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage +
            "&from=" +
            this.filterFrom +
            "&to=" +
            this.filterTo
        );
        this.discountFilterTimer = null;
      }, 500);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.discounts.current_page.toString() +
            "&query=" +
            this.discountFilter +
            "&items=" +
            this.itemsPerPage +
            "&from=" +
            this.filterFrom +
            "&to=" +
            this.filterTo
        )
        .then(({ data: { bonuses } }) => {
          this.discounts = bonuses;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      let formData = new FormData();
      for (const key in this.model) {
        if (this.model[key] !== "") {
          if (typeof this.model[key] === "boolean") {
            formData.append(key, this.model[key] === true ? 1 : 0);
          } else {
            formData.append(key, this.model[key]);
          }
        }
      }
      axios
        .post("/payroll/discounts", formData)
        .then(response => {
          this.refetchPage(() => {
            this.assignBonusCard = false;
            this.model.reason = "";
            this.model.amount = "";
            this.model.application_date = "";
            this.model.bonus_type_id = "";
            this.model.file = "";
            this.model.filename = "";
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Descuento asignado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
            UIkit.modal("#edit-discount-modal").hide();
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      let formData = new FormData();
      for (const key in this.model) {
        if (this.model[key] !== "") {
          if (typeof this.model[key] === "boolean") {
            formData.append(key, this.model[key] === true ? 1 : 0);
          } else {
            formData.append(key, this.model[key]);
          }
        }
      }
      formData.append("_method", "PUT");
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando descuento...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post(
          "/payroll/discounts/" + this.activeDiscountId.toString(),
          formData
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-discount-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Descuento actualizado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deletediscount() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando descuento...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/payroll/discounts/" + this.activeDiscountId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Descuento eliminado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteDiscountGroup() {
      this.isLoading = true;
      axios
        .delete(
          "/payroll/discounts/bulk/" + this.activeDiscount.recurrent_group
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Descuentos eliminados</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },

    handleImportFile(e) {
      this.isLoading = true;
      this.errors = null;
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        let newBonuses = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .slice(2);

        newBonuses = newBonuses.map(discount => {
          discount.application_date = moment(discount.application_date).format(
            "YYYY-MM-DD"
          );
          return discount;
        });

        axios
          .post("/payroll/discounts/bulk", newBonuses)
          .then(({ data: { createdCount, updatedCount } }) => {
            this.refetchPage(() => {
              UIkit.modal("#import-export-modal").hide();
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  createdCount +
                  " descuentos creados, " +
                  updatedCount +
                  " actualizados</div>",
                { status: "success", timeout: 3000 }
              );
              this.isLoading = false;
            });
          })
          .catch(error => {
            if ("errors" in error.response.data) {
              this.errors = error.response.data.errors;
            }
            console.log("error", error);
            this.isLoading = false;
          })
          .finally(() => {
            let importFileInput = document.getElementById("import-file");
            if (importFileInput) {
              importFileInput.value = "";
            }
          });
      };
      reader.readAsBinaryString(f);
    },

    handleFileChange(file) {
      this.model.file = file;
      this.model.filename = file.name;
    },

    resetForm() {
      this.model.insured_business_id = "";
      this.model.reason = "";
      this.model.amount = "";
      this.model.application_date = "";
      this.model.bonus_type_id = "";
    }
  },

  watch: {
    discountFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    filterFrom: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    filterTo: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    model: {
      deep: true,
      handler() {
        document
          .querySelector("#application_date_flatpickr")
          ._flatpickr.redraw();
        if (
          this.invalidRecurrentDates.includes(
            moment(this.model.application_date).date()
          )
        ) {
          document
            .querySelector("#application_date_flatpickr")
            ._flatpickr.clear();
        }
      }
    }
  }
};
</script>

<style>
.user-logo {
  color: white;
  background: #2c5fa1;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  letter-spacing: -3px;
}

.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}

.avatar {
  width: 80px;
  max-width: 80px;
}

.uk-badge {
  margin: 1px;
}

.v-select.searchable .dropdown-toggle {
  border-radius: 15px;
  background: white;
  border: none;
}

.v-select .selected-tag {
  border-radius: 15px;
  background-color: #2c5fa1;
  color: white;
  padding: 5px 10px;
}

.bottom-divider {
  border-bottom: solid 1px;
  padding: 10px 0;
}
</style>