
<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Mi planilla</div>
      <div class="uk-text-center">
        <a
          class="uk-button uk-border-rounded uk-button-primary"
          href="/payroll/proportions"
          >Configuración de proporciones</a
        >
      </div>
      <div class="uk-margin uk-text-center">
        <div uk-form-custom>
          <input name="file" type="file" @change="handleFile($event)" />
          <button class="uk-button uk-border-rounded uk-button-secondary">
            Importar historial de pagos
          </button>
        </div>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input search-input-animation"
            type="search"
            placeholder="Buscar colaborador..."
            v-model="insuredFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="insureds.prev_page_url"
          :is-fetching="isFetching"
          :current-page="insureds.current_page"
          :last-page="insureds.last_page"
          :next-page-url="insureds.next_page_url"
        ></pagination-buttons>
      </div>
      <table
        class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s"
      >
        <thead>
          <tr>
            <th></th>
            <th>Nombre completo</th>
            <th>Tipo de compensación</th>
            <th>Tipo de pago</th>
            <th>Próxima fecha de pago</th>
            <th>Base</th>
            <th>Prestaciones</th>
            <th>Bonos de productividad</th>
            <th>Total (sin bonos variables)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(insured, index) in insureds.data" :key="insured.id">
            <td width="10%">
              <a
                :href="'/directory/general/' + insured.id.toString()"
                class="uk-link-reset"
              >
                <div
                  v-if="insured.avatar"
                  class="rounded-avatar"
                  :style="{ 'background-image': 'url(' + insured.avatar + ')' }"
                ></div>
                <div
                  class="user-logo uk-border-circle uk-padding-small uk-text-center"
                  v-else
                >
                  {{ insured.first_name.substr(0, 1) }}
                  {{ insured.last_name.substr(0, 1) }}
                </div>
              </a>
            </td>
            <td width="10%">
              <a
                :href="'/directory/general/' + insured.id.toString()"
                class="uk-text-bold uk-link-reset"
                :id="'insured-name-' + insured.id"
                >{{ insured.first_name }} {{ insured.last_name }}</a
              >
            </td>
            <td>
              <span
                v-if="insured.business_relation.payroll"
                :id="'insured-payroll-name-' + insured.id"
                >{{ insured.business_relation.payroll.type.name }}</span
              >
            </td>
            <td>
              <span
                v-if="insured.business_relation.payroll"
                :id="'insured-frequency-name-' + insured.id"
                >{{ insured.business_relation.payroll.frequency.name }}</span
              >
            </td>
            <td>
              <span
                v-if="insured.business_relation.payroll"
                :id="'insured-payroll-nextpayment-' + insured.id"
                >{{
                  insured.business_relation.payroll.nextPaymentDate
                    ? formatDate(
                        insured.business_relation.payroll.nextPaymentDate
                      )
                    : "Sin pagos pendientes"
                }}</span
              >
            </td>
            <td>
              <span
                v-if="insured.business_relation.payroll"
                :id="'insured-payroll-base-' + insured.id"
                >{{ toCurrency(insured.business_relation.payroll.base) }}</span
              >
            </td>
            <td>
              <span
                v-if="insured.business_relation.payroll"
                :id="'insured-payroll-adjustments-' + insured.id"
                >{{
                  toCurrency(insured.business_relation.payroll.fixedAdjustments)
                }}</span
              >
            </td>
            <td>
              <span
                v-if="insured.business_relation.payroll"
                :id="'insured-payroll-bonuses-' + insured.id"
                >{{
                  toCurrency(insured.business_relation.payroll.fixedBonuses)
                }}</span
              >
            </td>
            <td>
              <span
                v-if="insured.business_relation.payroll"
                :id="'insured-payroll-total-' + insured.id"
                >{{ toCurrency(insured.business_relation.payroll.total) }}</span
              >
            </td>
            <td class="uk-text-right">
              <button
                :dusk="'edit-payment-' + insured.id"
                class="uk-button uk-border-rounded uk-button-primary"
                href="#edit-insured-modal"
                uk-toggle
                @click="setEditMode(insured, index)"
                uk-icon="search"
              ></button>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="bottom-divider uk-hidden@s"
        v-for="insured in insureds.data"
        :key="insured.id"
      >
        <div class="uk-flex uk-flex-between">
          <div>
            <a
              :href="'/directory/general/' + insured.id.toString()"
              class="link-underline"
            >
              <span
                class="uk-text-bold"
                :id="'responsive-insured-name-' + insured.id"
                >{{ insured.first_name }} {{ insured.last_name }}</span
              >
            </a>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-payroll-title-' + insured.id"
              >- {{ insured.business_relation.title }}</span
            >
          </div>
          <div>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-payroll-status-' + insured.id"
              >{{ insured.business_relation.status }}</span
            >
          </div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span
              v-if="insured.division"
              :id="'responsive-insured-payroll-division-' + insured.id"
              >{{ insured.division.name }}</span
            >
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-payroll-location-' + insured.id"
              >- {{ insured.business_relation.location }}</span
            >
          </div>
          <div>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-payroll-type-' + insured.id"
              >{{ insured.business_relation.type }}</span
            >
          </div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span
              v-if="insured.business_relation.payroll"
              :id="'responsive-insured-payroll-base-' + insured.id"
              >Base:
              {{ toCurrency(insured.business_relation.payroll.base) }}</span
            >
          </div>
          <div>
            <span
              v-if="insured.business_relation.payroll"
              :id="'responsive-insured-payroll-bonuses-' + insured.id"
              >Bonos de productividad:
              {{
                toCurrency(insured.business_relation.payroll.fixedBonuses)
              }}</span
            >
          </div>
        </div>
        <div
          v-if="insured.business_relation.payroll"
          :id="'responsive-insured-payroll-adjustments-' + insured.id"
        >
          Prestaciones:
          {{ toCurrency(insured.business_relation.payroll.fixedAdjustments) }}
        </div>
        <div
          v-if="insured.business_relation.payroll"
          :id="'responsive-insured-payroll-total-' + insured.id"
        >
          Total (sin bonos variables):
          {{ toCurrency(insured.business_relation.payroll.total) }}
        </div>
        <div class="uk-text-center uk-margin-small-top">
          <span v-if="insured.business_relation">
            <a
              :href="tagLink(tag)"
              v-for="(tag, index) in insured.business_relation.tags"
              :key="index"
            >
              <span
                class="uk-badge uk-padding-small uk-margin-small-left uk-margin-small-right"
                >#{{ tag.name.es }}</span
              >
            </a>
          </span>
        </div>
        <button
          :dusk="'responsive-edit-payment-' + insured.id"
          class="uk-margin-small-top uk-button uk-width-1-1 uk-button-primary uk-border-rounded"
          href="#edit-insured-modal"
          uk-toggle
          @click="setEditMode(insured)"
          uk-icon="search"
        ></button>
      </div>

      <div
        v-if="insureds.data && insureds.data.length === 0"
        class="uk-text-center uk-margin-top"
      >
        No se han encontrado resultados con los filtros seleccionados.
      </div>
    </div>

    <div
      id="edit-insured-modal"
      class="uk-flex-top uk-modal-container"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button
          dusk="close"
          class="uk-modal-close-default"
          type="button"
          uk-close
        ></button>
        <h3 class="uk-card-title" v-if="activeInsured">
          Detalle de {{ activeInsured.first_name }}
          {{ activeInsured.last_name }}
        </h3>

        <ul
          id="payroll-modal-switcher"
          uk-switcher
          class="uk-hidden"
          swipping="False"
          animation="uk-animation-slide-right, uk-animation-slide-left"
        >
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
        </ul>

        <ul class="uk-switcher" v-if="activeInsured">
          <li>
            <div v-if="checkPermission('edit-payroll')">
              <div class="uk-flex uk-flex-between">
                <div class="uk-text-large">Configuración de pagos</div>
                <div v-if="activeInsured.business_relation.payroll !== null">
                  <button
                    v-if="payrollConfigurationMode === 'view'"
                    class="uk-button uk-border-rounded uk-button-primary"
                    @click="setPayrollConfigurationEditMode"
                    uk-icon="pencil"
                  ></button>
                  <button
                    v-else
                    class="uk-button uk-border-rounded uk-button-danger"
                    @click="setPayrollConfigurationViewMode"
                    uk-icon="close"
                    :disabled="isUpdating"
                  ></button>
                </div>
              </div>
              <div class="uk-flex uk-flex-wrap">
                <div
                  class="uk-width-1-2@s uk-padding-small uk-padding-remove-bottom"
                >
                  <label class="uk-form-label" for="form-stacked-text"
                    >Contratado por</label
                  >
                  <div
                    class="uk-form-controls"
                    v-if="payrollConfigurationMode === 'edit'"
                  >
                    <div class="uk-inline uk-width-1-1">
                      <select
                        name="payroll-type"
                        class="uk-select uk-border-rounded"
                        v-model="payrollConfiguration.payroll_type_id"
                      >
                        <option
                          v-for="type in payload.payrollTypes"
                          :key="type.id"
                          :value="type.id"
                          >{{ type.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="light-weight" v-else>
                    {{ activeInsured.business_relation.payroll.type.name }}
                  </div>
                </div>
                <div
                  class="uk-width-1-2@s uk-padding-small uk-padding-remove-bottom"
                >
                  <label class="uk-form-label" for="form-stacked-text"
                    >Frecuencia de pago</label
                  >
                  <div
                    class="uk-form-controls"
                    v-if="payrollConfigurationMode === 'edit'"
                  >
                    <div class="uk-inline uk-width-1-1">
                      <select
                        name="payroll-frequency"
                        class="uk-select uk-border-rounded"
                        v-model="payrollConfiguration.payroll_frequency_id"
                      >
                        <option
                          v-for="frequency in payload.payrollFrequencies"
                          :key="frequency.id"
                          :value="frequency.id"
                          >{{ frequency.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="light-weight" v-else>
                    {{ activeInsured.business_relation.payroll.frequency.name }}
                  </div>
                </div>
                <div
                  class="uk-width-1-2@s uk-padding-small uk-padding-remove-bottom"
                >
                  <label class="uk-form-label" for="form-stacked-text">
                    Base (Q.)
                    <span v-if="payrollConfiguration.payroll_frequency_id"
                      >({{
                        payload.payrollFrequencies.filter(
                          frequency =>
                            frequency.id ===
                            payrollConfiguration.payroll_frequency_id
                        )[0].name
                      }})</span
                    >
                  </label>
                  <div
                    class="uk-form-controls"
                    v-if="payrollConfigurationMode === 'edit'"
                  >
                    <div class="uk-inline uk-width-1-1">
                      <input
                        name="payroll-base"
                        v-currencyOnly
                        class="uk-input uk-border-rounded"
                        type="text"
                        v-model="payrollConfiguration.base"
                      />
                    </div>
                  </div>
                  <div class="light-weight" v-else>
                    {{
                      toCurrency(activeInsured.business_relation.payroll.base)
                    }}
                  </div>
                </div>
                <div
                  class="uk-width-1-2@s uk-padding-small uk-padding-remove-bottom"
                >
                  <label class="uk-form-label" for="form-stacked-text"
                    >Bonos de productividad (Q.)</label
                  >
                  <div
                    class="uk-form-controls"
                    v-if="payrollConfigurationMode === 'edit'"
                  >
                    <div class="uk-inline uk-width-1-1">
                      <input
                        name="payroll-fixed-bonusses"
                        v-currencyOnly
                        class="uk-input uk-border-rounded"
                        type="text"
                        v-model="payrollConfiguration.bonuses"
                      />
                      <span v-if="payrollConfiguration.payroll_type_id === 1"
                        >La contratación por planilla incluye automáticamente un
                        bono fijo de Q250.</span
                      >
                    </div>
                  </div>
                  <div class="light-weight" v-else>
                    {{
                      toCurrency(
                        activeInsured.business_relation.payroll.bonuses
                      )
                    }}
                  </div>
                </div>
                <div
                  class="uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  v-if="payrollConfiguration.payroll_frequency_id === 2"
                >
                  <label class="uk-form-label" for="form-stacked-text"
                    >Proporción de quincenas del colaborador</label
                  >
                  <div class="uk-form-controls">
                    <field-proportions
                      v-for="(field, fieldIndex) in payrollConfiguration.fields"
                      :key="fieldIndex"
                      :duskSelect="'field-' + fieldIndex"
                      :field="field"
                      :field-index="fieldIndex"
                      :active-insured="activeInsured"
                      :payroll-configuration-mode="payrollConfigurationMode"
                      :payroll-configuration="payrollConfiguration"
                      class="uk-margin-top"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="payrollConfigurationMode === 'edit'"
                class="uk-text-center uk-margin-top"
              >
                <div v-if="activeInsured.business_relation.payroll !== null">
                  Se actualizarán todos los pagos a partir del último pago
                  realizado.
                </div>
                <button
                  dusk="config-payments"
                  class="uk-button uk-border-rounded uk-button-primary"
                  @click="
                    activeInsured.business_relation.payroll !== null
                      ? updatePayrollConfiguration()
                      : createPayrollConfiguration()
                  "
                  :disabled="isUpdating || !proportionsAreValid"
                >
                  <span v-if="isUpdating" uk-spinner="ratio: 1"
                    >Calculando pagos, espera un momento</span
                  >
                  <span v-else>
                    <span
                      v-if="activeInsured.business_relation.payroll !== null"
                      >Actualizar pagos</span
                    >
                    <span v-else>Configurar pagos</span>
                  </span>
                </button>
              </div>
            </div>
            <div
              class="uk-margin-top"
              v-if="checkPermission('edit-insured-bank-account')"
            >
              <div class="uk-flex uk-flex-between">
                <div class="uk-text-large">Configuración de banco</div>
                <div v-if="activeInsured.bank_account !== null">
                  <button
                    v-if="bankConfigurationMode === 'view'"
                    class="uk-button uk-border-rounded uk-button-primary"
                    @click="setBankConfigurationEditMode"
                    uk-icon="pencil"
                  ></button>
                  <button
                    v-else
                    class="uk-button uk-border-rounded uk-button-danger"
                    @click="setBankConfigurationViewMode"
                    uk-icon="close"
                    :disabled="isUpdating"
                  ></button>
                </div>
              </div>
              <div class="uk-flex uk-flex-wrap">
                <div
                  class="uk-width-1-2@s uk-padding-small uk-padding-remove-bottom"
                >
                  <label class="uk-form-label" for="form-stacked-text"
                    >Banco</label
                  >
                  <div
                    class="uk-form-controls"
                    v-if="bankConfigurationMode === 'edit'"
                  >
                    <div class="uk-inline uk-width-1-1">
                      <select
                        name="bank-account"
                        class="uk-select uk-border-rounded"
                        v-model="bankConfiguration.bank_id"
                      >
                        <option
                          v-for="bank in payload.banks"
                          :key="bank.id"
                          :value="bank.id"
                          >{{ bank.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="light-weight" v-else>
                    <span v-if="activeInsured.bank_account">{{
                      activeInsured.bank_account.bank.name
                    }}</span>
                  </div>
                </div>
                <div
                  class="uk-width-1-2@s uk-padding-small uk-padding-remove-bottom"
                >
                  <label class="uk-form-label" for="form-stacked-text"
                    >Tipo de cuenta</label
                  >
                  <div
                    class="uk-form-controls"
                    v-if="bankConfigurationMode === 'edit'"
                  >
                    <div class="uk-inline uk-width-1-1">
                      <select
                        name="bank-type"
                        class="uk-select uk-border-rounded"
                        v-model="bankConfiguration.bank_account_type_id"
                      >
                        <option
                          v-for="bankAccountType in payload.bankAccountTypes"
                          :key="bankAccountType.id"
                          :value="bankAccountType.id"
                          >{{ bankAccountType.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="light-weight" v-else>
                    <span v-if="activeInsured.bank_account">{{
                      activeInsured.bank_account.type.name
                    }}</span>
                  </div>
                </div>
                <div
                  class="uk-width-1-2@s uk-padding-small uk-padding-remove-bottom"
                >
                  <label class="uk-form-label" for="form-stacked-text"
                    >Nombre de cuenta</label
                  >
                  <div
                    class="uk-form-controls"
                    v-if="bankConfigurationMode === 'edit'"
                  >
                    <div class="uk-inline uk-width-1-1">
                      <input
                        name="insured-bank-name"
                        class="uk-input uk-border-rounded"
                        type="text"
                        v-model="bankConfiguration.account_name"
                      />
                    </div>
                  </div>
                  <div class="light-weight" v-else>
                    <span v-if="activeInsured.bank_account">{{
                      activeInsured.bank_account.account_name
                    }}</span>
                  </div>
                </div>
                <div
                  class="uk-width-1-2@s uk-padding-small uk-padding-remove-bottom"
                >
                  <label class="uk-form-label" for="form-stacked-text"
                    >Número de cuenta</label
                  >
                  <div
                    class="uk-form-controls"
                    v-if="bankConfigurationMode === 'edit'"
                  >
                    <div class="uk-inline uk-width-1-1">
                      <input
                        name="insured-bank-account"
                        class="uk-input uk-border-rounded"
                        type="text"
                        v-model="bankConfiguration.account_number"
                      />
                    </div>
                  </div>
                  <div class="light-weight" v-else>
                    <span v-if="activeInsured.bank_account">{{
                      activeInsured.bank_account.account_number
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                v-if="bankConfigurationMode === 'edit'"
                class="uk-text-center uk-margin-top"
              >
                <button
                  dusk="config-bank-account"
                  class="uk-button uk-border-rounded uk-button-primary"
                  @click="
                    activeInsured.bank_account !== null
                      ? updateBankConfiguration()
                      : createBankConfiguration()
                  "
                  :disabled="isUpdating"
                >
                  <span v-if="isUpdating" uk-spinner="ratio: 1"></span>
                  <span v-else>
                    <span v-if="activeInsured.bank_account !== null"
                      >Actualizar cuenta</span
                    >
                    <span v-else>Configurar cuenta</span>
                  </span>
                </button>
              </div>
            </div>
            <div v-if="activeInsured.business_relation.payroll !== null">
              <div v-if="!assignBonusCard" class="uk-text-center uk-margin-top">
                <button
                  class="uk-button uk-border-rounded uk-button-primary"
                  @click="showAssignBonusCard"
                  v-if="checkPermission('assign-bonuses')"
                >
                  Asignar un bono o descuento variable
                </button>
              </div>
              <!-- BONO VARIABLE MODAL -->
              <div
                class="uk-card uk-card-primary uk-border-rounded uk-margin-auto uk-margin-small-top uk-width-1-2@s"
                v-else
              >
                <div class="uk-card-body uk-padding-small">
                  <div class="uk-flex uk-flex-between">
                    <div class="uk-text-large">
                      Asignar un bono o descuento variable
                    </div>
                    <button
                      type="button"
                      uk-close
                      @click="hideAssignBonusCard"
                    ></button>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text"
                      >Tipo</label
                    >
                    <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <select
                          class="uk-select uk-border-rounded"
                          v-model="bonusModel.bonus_type_id"
                        >
                          <option
                            v-for="bonusType in payload.bonusTypes"
                            :key="bonusType.id"
                            :value="bonusType.id"
                            >{{ bonusType.name }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text"
                      >Razón</label
                    >
                    <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <input
                          v-model="bonusModel.reason"
                          class="uk-input uk-border-rounded"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text"
                      >Monto</label
                    >
                    <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <input
                          v-model="bonusModel.amount"
                          class="uk-input uk-border-rounded"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text"
                      >Fecha de aplicación</label
                    >
                    <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <flat-pickr
                          v-model="bonusModel.application_date"
                          class="uk-input uk-border-rounded"
                          :config="datepickerConfig"
                        ></flat-pickr>
                      </div>
                    </div>
                  </div>
                  <div class="uk-text-center uk-margin-top">
                    <button
                      class="uk-button uk-border-rounded uk-button-primary"
                      @click="assignBonus"
                      :disabled="isUpdating"
                    >
                      <span v-if="isUpdating" uk-spinner="ratio: 1"></span>
                      <span v-else>Asignar</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- PAGOS PROGRAMADOS -->
              <div
                v-if="
                  (activeInsuredPayrollHistory || !isLoadingHistory) &&
                    checkPermission('payments-history')
                "
                class="uk-margin-top"
              >
                <div class="uk-text-large">Pagos programados</div>
                <div class="uk-flex uk-flex-right">
                  <pagination-buttons
                    :prev-page-url="activeInsuredPayrollHistory.prev_page_url"
                    :is-fetching="isLoadingHistory"
                    prevItemsBtnId="detailPaymentsPrev"
                    nextItemsBtnId="detailPaymentsNext"
                    :current-page="activeInsuredPayrollHistory.current_page"
                    :last-page="activeInsuredPayrollHistory.last_page"
                    :next-page-url="activeInsuredPayrollHistory.next_page_url"
                    method-name="fetchPaymentsHistoryPage"
                    items-per-page="itemsPerPageHistory"
                  ></pagination-buttons>
                </div>
                <table
                  class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s"
                >
                  <thead>
                    <tr>
                      <th>Período</th>
                      <th>Base</th>
                      <th>Prestaciones</th>
                      <th>Bonos de productividad</th>
                      <th>Otros bonos</th>
                      <th>Total</th>
                      <th class="uk-text-center">Pagado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="record in activeInsuredPayrollHistory.data"
                      :key="record.id"
                    >
                      <td :id="'modal-insured-payroll-dates-' + record.id">
                        {{ formatDate(record.start_date) }} -
                        {{ formatDate(record.end_date) }}
                      </td>
                      <td :id="'modal-insured-payroll-base-' + record.id">
                        {{ toCurrency(record.base) }}
                      </td>
                      <td :id="'modal-insured-payroll-benefits-' + record.id">
                        {{ toCurrency(record.benefits) }}
                      </td>
                      <td
                        :id="'modal-insured-payroll-fixed-bonuses-' + record.id"
                      >
                        {{ toCurrency(record.fixedBonuses) }}
                      </td>
                      <td
                        :id="'modal-insured-payroll-other-bonuses' + record.id"
                      >
                        {{ toCurrency(record.otherBonusesAmount) }}
                      </td>
                      <td :id="'modal-insured-payroll-total-' + record.id">
                        {{ toCurrency(record.totalAmount) }}
                      </td>
                      <td class="uk-text-center">
                        <span
                          class="uk-badge"
                          v-if="record.paid === 1"
                          uk-icon="icon: check; ratio: 1;"
                        ></span>
                      </td>
                      <td class="uk-text-right">
                        <a
                          class="uk-link-reset"
                          href="#"
                          uk-switcher-item="next"
                          @click="setPayment(record)"
                        >
                          <button
                            class="uk-button uk-border-rounded uk-button-secondary"
                            uk-icon="chevron-right"
                          ></button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="uk-hidden@s">
                  <div
                    v-for="record in activeInsuredPayrollHistory.data"
                    :key="record.id"
                    class="bottom-divider"
                  >
                    <div class="uk-flex uk-flex-between">
                      <div
                        :id="
                          'responsive-modal-insured-payroll-dates-' +
                            activeInsured.id
                        "
                      >
                        {{ formatDate(record.start_date) }} -
                        {{ formatDate(record.end_date) }}
                      </div>
                      <div>
                        <span class="uk-badge" v-if="record.paid === 1">
                          <span uk-icon="icon: check; ratio: 1;"></span>Pagado
                        </span>
                      </div>
                    </div>
                    <div class="uk-flex uk-flex-between">
                      <div class="uk-width-expand" uk-leader>Base</div>
                      <div
                        :id="
                          'responsive-modal-insured-payroll-base-' +
                            activeInsured.id
                        "
                      >
                        {{ toCurrency(record.base) }}
                      </div>
                    </div>
                    <div class="uk-flex uk-flex-between">
                      <div class="uk-width-expand" uk-leader>Prestaciones</div>
                      <div
                        :id="
                          'responsive-modal-insured-payroll-benefits-' +
                            activeInsured.id
                        "
                      >
                        {{ toCurrency(record.benefits) }}
                      </div>
                    </div>
                    <div class="uk-flex uk-flex-between">
                      <div class="uk-width-expand" uk-leader>
                        + Bonos de productividad
                      </div>
                      <div
                        :id="
                          'responsive-modal-insured-payroll-fixed-bonuses-' +
                            activeInsured.id
                        "
                      >
                        {{ toCurrency(record.fixedBonuses) }}
                      </div>
                    </div>
                    <div
                      class="uk-flex uk-flex-between"
                      v-if="record.other_bonuses.length !== 0"
                    >
                      <div class="uk-width-expand" uk-leader>+ Otros bonos</div>
                      <div
                        :id="
                          'responsive-modal-insured-payroll-other-bonuses' +
                            activeInsured.id
                        "
                      >
                        {{ toCurrency(record.otherBonusesAmount) }}
                      </div>
                    </div>
                    <div class="uk-flex uk-flex-between">
                      <div class="uk-width-expand" uk-leader>Total</div>
                      <div
                        :id="
                          'responsive-modal-insured-payroll-total-' +
                            activeInsured.id
                        "
                      >
                        {{ toCurrency(record.totalAmount) }}
                      </div>
                    </div>
                    <div class="uk-margin-small-top uk-text-center">
                      <a
                        class="uk-link-reset"
                        href="#"
                        uk-switcher-item="next"
                        @click="setPayment(record)"
                      >
                        <button
                          class="uk-width-1-1 uk-button uk-border-rounded uk-button-secondary"
                          uk-icon="chevron-right"
                        >
                          Desglose
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-text-center uk-margin-top" v-if="isLoadingHistory">
                <span uk-spinner></span>
              </div>
            </div>
          </li>

          <li>
            <payment-detail
              :edit-permission="checkPermission('edit-payment')"
              :payment="activePayment"
              :update-local-payment="updateLocalPayment"
              :refetch-page="refetchPaymentsPage"
            />
            <div class="uk-margin-small-top uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="previous">
                <button class="uk-button uk-border-rounded uk-button-secondary">
                  <span uk-icon="chevron-left"></span> Regresar
                </button>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import xlsx from "xlsx";
import moment from "moment";

export default {
  name: "PayrollGeneral",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      activeInsured: "",
      activeInsuredIndex: "",
      activeInsuredPayrollHistory: {},
      isLoadingHistory: false,
      activePayment: "",
      isLoading: false,
      isFetching: false,
      isUpdating: false,
      mode: "new",
      payrollConfigurationMode: "view",
      bankConfigurationMode: "view",
      insuredFilter: "",
      insureds: {},
      insuredFilterTimer: null,
      itemsPerPage: 20,
      itemsPerPageHistory: 20,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      },
      payrollConfiguration: {
        base: "",
        bonuses: "",
        payroll_frequency_id: "",
        payroll_type_id: "",
        fields: {}
      },
      bankConfiguration: {
        bank_id: "",
        bank_account_type_id: "",
        account_number: "",
        account_name: ""
      },
      assignBonusCard: false,
      bonusModel: {
        reason: "",
        amount: "",
        application_date: "",
        bonus_type_id: ""
      }
    };
  },

  computed: {
    proportionsAreValid() {
      return Object.keys(this.payrollConfiguration.fields)
        .map(fieldId => this.payrollConfiguration.fields[fieldId])
        .filter(field => field.active)
        .map(
          field =>
            field.proportions.reduce(
              (accum, proportion) => accum + parseFloat(proportion),
              0
            ) === 100
        )
        .every(field => field);
    }
  },

  mounted() {
    this.insureds = this.payload.insureds;
  },

  methods: {
    handleFile(e) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        const payments = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .map(payment => ({
            ...payment,
            start_date: moment(payment.start_date).format("YYYY-MM-DD"),
            end_date: moment(payment.end_date).format("YYYY-MM-DD")
          }));

        axios
          .post("/payroll/history-payments", payments)
          .then(({ data }) => {
            this.refetchPage(() => {
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Historial cargado</div>",
                { status: "success", timeout: 3000 }
              );
            });
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      reader.readAsBinaryString(f);
    },
    updateLocalPayment(payment) {
      this.activeInsuredPayrollHistory.data.splice(
        this.activeInsuredPayrollHistory.data
          .map(payment => payment.id)
          .indexOf(payment.id),
        1,
        payment
      );
      this.activePayment = payment;
    },
    tagLink(tag) {
      if (
        this.payload.filtertags &&
        this.payload.filtertags.includes(tag.name.es)
      ) {
        return "#";
      } else {
        if (this.payload.filtertags && this.payload.filtertags) {
          return (
            "/directory/general?" +
            this.payload.filtertags
              .map(
                (filtertag, index) =>
                  "filtertags[" + index.toString() + "]=" + filtertag
              )
              .join("&") +
            "&filtertags[" +
            this.payload.filtertags.length.toString() +
            "]=" +
            tag.name.es
          );
        } else {
          return "/directory/general?filtertags[]=" + tag.name.es;
        }
      }
    },
    removeTagLink(tag) {
      let remainingTags = this.payload.filtertags.map(tag => tag);
      remainingTags.splice(remainingTags.indexOf(tag), 1);
      if (remainingTags.length !== 0) {
        return (
          "/directory/general?" +
          remainingTags
            .map(
              (filtertag, index) =>
                "filtertags[" + index.toString() + "]=" + filtertag
            )
            .join("&")
        );
      } else {
        return "/directory/general";
      }
    },
    resetPayrollModalSwitcher() {
      UIkit.switcher("#payroll-modal-switcher").show(0);
      UIkit.modal("#edit-insured-modal").hide();
    },
    setEditMode(insured, index) {
      UIkit.switcher("#payroll-modal-switcher").show(0);
      this.activeInsured = insured;
      this.activeInsuredPayrollHistory = null;
      this.activeInsuredIndex = index;
      this.assignBonusCard = false;
      if (insured.business_relation.payroll) {
        this.payrollConfiguration = {
          base: insured.business_relation.payroll.base,
          bonuses: insured.business_relation.payroll.bonuses,
          payroll_frequency_id:
            insured.business_relation.payroll.payroll_frequency_id,
          payroll_type_id: insured.business_relation.payroll.payroll_type_id,
          fields: this.payload.proportionableFields.reduce((accum, field) => {
            accum[field.id] = {
              slug: field.slug,
              proportions: [50, 50],
              active: false
            };
            return accum;
          }, {})
        };
        if (insured.business_relation.payroll.payroll_frequency_id === 2) {
          this.payrollConfiguration.fields = this.payload.proportionableFields.reduce(
            (accum, field) => {
              const insuredPayrollProportion = insured.business_relation.payroll.proportions.filter(
                proportion => proportion.proportionable_field_id === field.id
              )[0];
              accum[field.id] = {
                slug: field.slug,
                proportions: insuredPayrollProportion
                  ? JSON.parse(insuredPayrollProportion.proportions)
                  : [50, 50], // Quincenal only
                active: insuredPayrollProportion ? true : false
              };
              return accum;
            },
            {}
          );
        }
        this.payrollConfigurationMode = "view";
        this.fetchPaymentsHistoryPage();
      } else {
        this.payrollConfiguration = {
          base: "",
          bonuses: "",
          payroll_frequency_id: "",
          payroll_type_id: "",
          fields: this.payload.proportionableFields.reduce((accum, field) => {
            accum[field.id] = {
              slug: field.slug,
              proportions: [50, 50], // Quincenal only
              active: false
            };
            return accum;
          }, {})
        };
        this.payrollConfigurationMode = "edit";
      }
      if (insured.bank_account) {
        this.bankConfiguration = {
          bank_id: insured.bank_account ? insured.bank_account.bank_id : "",
          bank_account_type_id: insured.bank_account
            ? insured.bank_account.bank_account_type_id
            : "",
          account_number: insured.bank_account
            ? insured.bank_account.account_number
            : "",
          account_name: insured.bank_account
            ? insured.bank_account.account_name
            : ""
        };
        this.bankConfigurationMode = "view";
      } else {
        this.bankConfiguration = {
          bank_id: "",
          bank_account_type_id: "",
          account_number: "",
          account_name: ""
        };
        this.bankConfigurationMode = "edit";
      }
    },
    setPayrollConfigurationViewMode() {
      this.payrollConfigurationMode = "view";
    },
    setPayrollConfigurationEditMode() {
      this.payrollConfigurationMode = "edit";
    },
    setBankConfigurationViewMode() {
      this.bankConfigurationMode = "view";
    },
    setBankConfigurationEditMode() {
      this.bankConfigurationMode = "edit";
    },
    setPayment(payment) {
      this.activePayment = payment;
    },
    prevPage() {
      this.currentPage = this.currentPage - 1;
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
    },
    fetchPaymentsHistoryPage(url) {
      this.isLoadingHistory = true;
      if (url) {
        url = url + "&items=" + this.itemsPerPageHistory;
      }
      axios
        .get(
          url
            ? url
            : "/payroll/history-payments?insured_business_id=" +
                this.activeInsured.business_relation.id +
                "&items=" +
                this.itemsPerPageHistory.toString()
        )
        .then(({ data: { payments } }) => {
          this.activeInsuredPayrollHistory = payments;
          this.isLoadingHistory = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isLoadingHistory = false;
        });
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.insuredFilter && !url.includes("&query")) {
        url = url + "&query=" + this.insuredFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { insureds } }) => {
          this.insureds = insureds;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.insuredFilterTimer) {
        clearTimeout(this.insuredFilterTimer);
      }
      this.insuredFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.insuredFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.insureds.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { insureds } }) => {
          this.insureds = insureds;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    refetchPaymentsPage(callback) {
      this.isFetching = true;
      let path =
        "/payroll/history-payments" +
        "?insured_business_id=" +
        this.activeInsured.business_relation.id +
        "&page=" +
        this.activeInsuredPayrollHistory.current_page.toString() +
        "&items=" +
        this.itemsPerPage;
      axios
        .get(path)
        .then(({ data: { payments } }) => {
          this.activeInsuredPayrollHistory = payments;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    updatePayrollConfiguration() {
      this.isUpdating = true;
      axios
        .put("/payroll/general", {
          ...this.payrollConfiguration,
          payroll_id: this.activeInsured.business_relation.payroll.id,
          bank_account_id: this.activeInsured.bank_account
            ? this.activeInsured.bank_account.id
            : undefined
        })
        .then(({ data: { insured } }) => {
          this.insureds.data[this.activeInsuredIndex] = insured;
          this.activeInsured = insured;
          this.payrollConfigurationMode = "view";
          this.isUpdating = false;
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Configuración actualizada</div>",
            { status: "success", timeout: 3000 }
          );
          this.fetchPaymentsHistoryPage();
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdating = false;
        });
    },
    createPayrollConfiguration() {
      this.isUpdating = true;
      axios
        .post("/payroll/general", {
          ...this.payrollConfiguration,
          insured_business_id: this.activeInsured.business_relation.id
        })
        .then(({ data: { insured } }) => {
          this.insureds.data[this.activeInsuredIndex] = insured;
          this.activeInsured = insured;
          this.payrollConfigurationMode = "view";
          this.isUpdating = false;
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Configuración de pagos actualizada</div>",
            { status: "success", timeout: 3000 }
          );
          this.fetchPaymentsHistoryPage();
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdating = false;
        });
    },
    createBankConfiguration() {
      this.isUpdating = true;
      axios
        .post("/payroll/bank-accounts", {
          ...this.bankConfiguration,
          insured_id: this.activeInsured.id
        })
        .then(({ data: { bankAccount } }) => {
          this.insureds.data[
            this.activeInsuredIndex
          ].bank_account = bankAccount;
          this.activeInsured = this.insureds.data[this.activeInsuredIndex];
          this.bankConfigurationMode = "view";
          this.isUpdating = false;
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Configuración de cuenta bancaria actualizada</div>",
            { status: "success", timeout: 3000 }
          );
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdating = false;
        });
    },
    updateBankConfiguration() {
      this.isUpdating = true;
      axios
        .put(
          "/payroll/bank-accounts/" + this.activeInsured.bank_account.id,
          this.bankConfiguration
        )
        .then(({ data: { bankAccount } }) => {
          this.insureds.data[
            this.activeInsuredIndex
          ].bank_account = bankAccount;
          this.activeInsured = this.insureds.data[this.activeInsuredIndex];
          this.bankConfigurationMode = "view";
          this.isUpdating = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdating = false;
        });
    },
    showAssignBonusCard() {
      this.assignBonusCard = true;
      this.bonusModel.reason = "";
      this.bonusModel.amount = "";
      this.bonusModel.application_date = "";
      this.bonusModel.bonus_type_id = "";
    },
    hideAssignBonusCard() {
      this.assignBonusCard = false;
    },
    assignBonus() {
      this.isUpdating = true;
      axios
        .post("/payroll/bonuses", {
          ...this.bonusModel,
          insured_business_id: this.activeInsured.business_relation.id
        })
        .then(response => {
          this.assignBonusCard = false;
          this.bonusModel.reason = "";
          this.bonusModel.amount = "";
          this.bonusModel.application_date = "";
          this.bonusModel.bonus_type_id = "";
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Bono asignado</div>",
            { status: "success", timeout: 3000 }
          );
          this.isUpdating = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdating = false;
        });
    }
  },

  watch: {
    insuredFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    itemsPerPageHistory: function() {
      this.fetchPaymentsHistoryPage();
    }
  }
};
</script>

<style>
.v-select.searchable .dropdown-toggle {
  border-radius: 15px;
  background: white;
  border: none;
}

.v-select .selected-tag {
  border-radius: 15px;
  background-color: #2c5fa1;
  color: white;
  padding: 5px 10px;
}

.bottom-divider {
  border-bottom: solid 1px;
  padding: 10px 0;
}

.paid-check {
  color: green;
}

.tab1 {
  padding-left: 20px;
}

.tab2 {
  padding-left: 40px;
}

.rounded-avatar {
  display: block;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;
}

.user-logo {
  color: white;
  background: #2c5fa1;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  letter-spacing: -3px;
}

.light-weight {
  font-weight: 100;
}
</style>