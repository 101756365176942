<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Marcaje</div>
      <div>
        <a
          class="uk-button uk-border-rounded uk-button-primary"
          href="/time-tracking/general"
          >Regresar a mapa</a
        >
        <a
          class="uk-button uk-border-rounded uk-button-primary"
          :href="
            '/time-tracking/general/history/bulk?' +
              typeFilter
                .filter(type => type.selected)
                .map(type => '&types[]=' + type.slug)
                .join('') +
              '&from=' +
              checksFilterFrom +
              '&to=' +
              checksFilterTo
          "
          >Exportar asistencias en rango</a
        >
        <!-- <button dusk="add-check" class="uk-button uk-border-rounded uk-button-secondary" href="#edit-check-modal" uk-toggle @click="setNewMode">Añadir marcaje</button> -->
      </div>
    </div>

    <div class="uk-flex uk-flex-center">
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          id="start-date"
          class="uk-input uk-border-rounded uk-text-center"
          v-model="checksFilterFrom"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          id="end-date"
          class="uk-input uk-border-rounded uk-text-center"
          v-model="checksFilterTo"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
    </div>

    <div
      class="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-flex-center"
    >
      <label v-for="(type, index) in typeFilter" :key="index">
        <input
          class="uk-checkbox"
          type="checkbox"
          :disabled="isLoading"
          v-model="type.selected"
          @change="changeTypeFilter"
        />
        {{ type.name }}
      </label>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            name="search-check"
            class="uk-search-input"
            type="search"
            placeholder="Buscar marcaje..."
            v-model="checkFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="checks.prev_page_url"
          :is-fetching="isLoading"
          :current-page="checks.current_page"
          :last-page="checks.last_page"
          :next-page-url="checks.next_page_url"
        ></pagination-buttons>
      </div>
      <table
        class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s"
      >
        <thead>
          <tr>
            <th width="20%">Colaborador</th>
            <th width="10%">Tipo de marcaje</th>
            <th width="20%">Notas</th>
            <th width="10%">Ubicación</th>
            <th width="10">Constancia</th>
            <th width="20%">Fecha de aplicación</th>
            <th width="10%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(check, index) in checks.data" :key="check.id">
            <td>
              <a
                :id="'check-' + check.id"
                :dusk="'check-' + check.id"
                :href="
                  '/directory/general/' +
                    check.insured_business.insured.id.toString()
                "
                class="uk-text-bold uk-link-reset"
                >{{ check.insured_business.insured.first_name }}
                {{ check.insured_business.insured.last_name }}</a
              >
            </td>
            <td :id="'check-name-' + check.id" :dusk="'check-name-' + check.id">
              {{ check.type.name }}
            </td>
            <td
              :id="'check-notes-' + check.id"
              :dusk="'check-notes-' + check.id"
            >
              {{ check.notes }}
            </td>
            <td class="uk-inline">
              <button
                class="uk-button"
                type="button"
                uk-icon="location"
              ></button>
              <div uk-drop="boundary: body; pos: bottom-center">
                <GmapMap
                  class="uk-border-rounded uk-overflow-hidden"
                  :center="{
                    lat: parseFloat(check.lat),
                    lng: parseFloat(check.lng)
                  }"
                  :zoom="15"
                  map-type-id="roadmap"
                  style="width: 400px; height: 300px"
                >
                  <GmapMarker
                    :position="{
                      lat: parseFloat(check.lat),
                      lng: parseFloat(check.lng)
                    }"
                    :clickable="true"
                    :draggable="false"
                    @click="
                      center = {
                        lat: parseFloat(check.lat),
                        lng: parseFloat(check.lng)
                      }
                    "
                  />
                </GmapMap>
              </div>
            </td>
            <td class="uk-text-center">
              <a
                :id="'check-path-' + check.id"
                :dusk="'check-path-' + check.id"
                v-if="check.file"
                uk-icon="cloud-download"
                :href="check.file.path"
                target="_blank"
              ></a>
            </td>
            <td
              :id="'check-application-' + check.id"
              :dusk="'check-application-' + check.id"
            >
              {{ formatDatetime(check.created_at) }}
            </td>
            <td class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  :id="'check-edit-' + check.id"
                  :dusk="'check-edit-' + check.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                  href="#edit-check-modal"
                  uk-toggle
                  @click="setEditMode(check, index)"
                  uk-icon="pencil"
                ></button>
                <button
                  :id="'check-delete-' + check.id"
                  :dusk="'check-delete-' + check.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(check, index)"
                  uk-icon="trash"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="bottom-divider uk-hidden@s"
        v-for="(check, index) in checks.data"
        :key="check.id"
      >
        <div class="uk-flex uk-flex-between">
          <div>
            <a
              :id="'check-responsive-' + check.id"
              :href="
                '/directory/general/' +
                  check.insured_business.insured.id.toString()
              "
              class="uk-text-bold uk-link-reset"
              >{{ check.insured_business.insured.first_name }}
              {{ check.insured_business.insured.last_name }}</a
            >
          </div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div class="uk-text-bold" :id="'check-responsive-name-' + check.id">
            {{ check.type.name }}
          </div>
        </div>
        <div>{{ formatDatetime(check.created_at) }}</div>
        <div :id="'check-responsive-notes-' + check.id">{{ check.notes }}</div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
        >
          <button
            :id="'check-responsive-edit-' + check.id"
            :dusk="'check-responsive-edit-' + check.id"
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-check-modal"
            uk-toggle
            @click="setEditMode(check, index)"
            uk-icon="pencil"
          ></button>
          <a
            v-if="check.file"
            class="uk-button uk-width-1-2 uk-button-primary"
            :href="check.file.path"
            target="_blank"
            uk-icon="cloud-download"
          ></a>
          <button
            :id="'check-responsive-delete-' + check.id"
            :dusk="'check-responsive-delete-' + check.id"
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(check, index)"
            uk-icon="trash"
          ></button>
        </div>
      </div>

      <div
        v-if="checks.data && checks.data.length === 0"
        class="uk-text-center uk-margin-top"
      >
        No se han encontrado resultados con los filtros seleccionados.
      </div>
    </div>

    <div
      id="confirm-deletion-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span
            >¿Está seguro que desea eliminar la marcaje
            {{ this.model.name }}?</span
          >
        </div>
        <div class="uk-margin uk-text-center">
          <button
            id="check-delete-confirm"
            dusk="check-delete-confirm"
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteCheck"
            :disabled="isLoading"
          >
            Eliminar marcaje
          </button>
        </div>
      </div>
    </div>

    <div id="edit-check-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button
          dusk="check-close-modal"
          class="uk-modal-close-default"
          type="button"
          uk-close
        ></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          marcaje
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text"
            >Colaborador*</label
          >
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <v-select
                id="check-insured-select"
                v-model="model.insured_business_id"
                class="uk-input uk-border-rounded"
                :options="payload.insureds"
                :reduce="insured => insured.id"
                :getOptionLabel="
                  option =>
                    option.insured.first_name + ' ' + option.insured.last_name
                "
              ></v-select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text"
            >Tipo de marcaje*</label
          >
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select
                name="check-type-select"
                class="uk-select uk-border-rounded"
                v-model="model.check_type_id"
              >
                <option
                  v-for="type in payload.types"
                  :key="type.id"
                  :value="type.id"
                  >{{ type.name }}</option
                >
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text"
            >Constancia</label
          >
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1" uk-form-custom="target: true">
              <input
                name="check-file-holder"
                type="file"
                @change="handleFileChange($event.target.files[0])"
              />
              <input
                name="check-file-model"
                class="uk-input uk-border-rounded"
                type="text"
                disabled
                v-model="model.filename"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Notas</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <textarea
                name="check-notes-text"
                v-model="model.notes"
                rows="5"
                class="uk-textarea uk-border-rounded"
                type="text"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text"
            >Fecha de aplicación*</label
          >
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <flat-pickr
                id="check-apllication-date-input"
                class="uk-input uk-border-rounded"
                v-model="model.created_at"
                :config="datepickerConfig"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <div v-if="askForForce">
            <div class="uk-margin-bottom">
              Este colaborador ya tiene asignada una marcaje que coincide con el
              período seleccionado, desea continuar?
            </div>
            <button
              dusk="create-check"
              class="uk-button uk-border-rounded uk-button-danger"
              @click="
                model.force = true;
                store();
              "
              :disabled="isLoading || !checkIsValid"
              v-if="mode === 'new'"
            >
              <span v-if="isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Forzar creación de marcaje</span>
            </button>
            <button
              dusk="update-check"
              class="uk-button uk-border-rounded uk-button-danger"
              @click="
                model.force = true;
                update();
              "
              :disabled="isLoading || !checkIsValid"
              v-else
            >
              <span v-if="isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Forzar actualizar marcaje</span>
            </button>
            <button
              dusk="update-check"
              class="uk-button uk-border-rounded uk-button-secondary"
              @click="askForForce = false"
              :disabled="isLoading || !checkIsValid"
            >
              Cancelar
            </button>
          </div>
          <div v-else>
            <button
              dusk="create-check"
              class="uk-button uk-border-rounded uk-button-secondary"
              @click="store"
              :disabled="isLoading || !checkIsValid"
              v-if="mode === 'new'"
            >
              <span v-if="isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Crear marcaje</span>
            </button>
            <button
              dusk="update-check"
              class="uk-button uk-border-rounded uk-button-secondary"
              @click="update"
              :disabled="isLoading || !checkIsValid"
              v-else
            >
              <span v-if="isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Actualizar marcaje</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      id="import-export-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <a
            class="uk-button uk-border-rounded uk-button-secondary"
            href="/time-tracking/general/template"
            target="”_blank”"
            >Descargar plantilla de importación</a
          >
        </div>
        <div class="uk-margin uk-text-center">
          <div uk-form-custom>
            <form autocomplete="off">
              <input
                autocomplete="off"
                name="file"
                type="file"
                @change="handleFile($event)"
              />
            </form>
            <button
              class="uk-button uk-border-rounded uk-button-secondary"
              :disabled="isLoading || isLoading"
            >
              <span v-if="isLoading || isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Importar</span>
            </button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <a
            href="/time-tracking/general/bulk"
            class="uk-button uk-border-rounded uk-button-secondary"
            target="_blank"
            >Exportar</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import xlsx from "xlsx";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import moment from "moment";

export default {
  name: "DirectoryChecksHistory",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        insured_business_id: "",
        check_type_id: "",
        notes: "",
        file: "",
        filename: "",
        created_at: "",
        force: false
      },
      askForForce: false,
      checksFilterFrom: this.payload.checksFilterFrom,
      checksFilterTo: this.payload.checksFilterTo,
      itemsPerPage: 20,
      activeCheckId: "",
      payloadPosition: "",
      isLoading: false,
      isLoading: false,
      mode: "new",
      checkFilter: null,
      typeFilter: this.payload.types.map(type => ({
        ...type,
        selected: true
      })),
      checks: {},
      checkFilterTimer: null,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  computed: {
    insuredIsValid() {
      return this.model.insured_business_id !== "";
    },
    typeIsValid() {
      return this.model.check_type_id !== "";
    },
    applicationDateIsValid() {
      return this.model.created_at !== "";
    },
    checkIsValid() {
      const validations = [
        this.insuredIsValid,
        this.typeIsValid,
        this.applicationDateIsValid
      ];

      return validations.every(validation => validation);
    }
  },

  mounted() {
    this.checks = this.payload.checks;
  },

  methods: {
    changeTypeFilter() {
      if (this.checkFilterTimer) {
        clearTimeout(this.checkFilterTimer);
      }
      this.checkFilterTimer = setTimeout(() => {
        this.refetchPage(() => {
          this.isLoading = false;
        });

        this.checkFilterTimer = null;
      }, 700);
    },
    handleFileChange(file) {
      this.model.file = file;
      this.model.filename = file.name;
    },
    setNewMode() {
      this.mode = "new";
      this.model.insured_business_id = "";
      this.model.check_type_id = "";
      this.model.notes = "";
      this.model.created_at = "";
      this.model.force = false;
      this.askForForce = false;
    },
    setEditMode(check, index) {
      this.mode = "edit";
      this.activeCheckId = check.id;
      this.payloadPosition = index;
      this.model.insured_business_id = check.insured_business_id;
      this.model.check_type_id = check.check_type_id;
      this.model.notes = check.notes;
      this.model.created_at = check.created_at;
      this.model.force = false;
      this.askForForce = false;
    },
    fetchPage(url) {
      this.isLoading = true;
      if (this.checkFilter && !url.includes("&query")) {
        url = url + "&query=" + this.checkFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      url = url + "&to=" + this.checksFilterTo;
      url =
        url +
        this.typeFilter
          .filter(type => type.selected)
          .map(type => "&types[]=" + type.slug)
          .join("");
      url = url + "&from=" + this.checksFilterFrom;
      axios
        .get(url)
        .then(({ data: { checks } }) => {
          this.checks = checks;
          this.isLoading = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    search(query) {
      if (this.checkFilterTimer) {
        clearTimeout(this.checkFilterTimer);
      }
      this.checkFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.checkFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isLoading = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.checks.current_page.toString() +
            "&items=" +
            this.itemsPerPage +
            "&from=" +
            this.checksFilterFrom +
            "&to=" +
            this.checksFilterTo +
            this.typeFilter
              .filter(type => type.selected)
              .map(type => "&types[]=" + type.slug)
              .join("")
        )
        .then(({ data: { checks } }) => {
          this.checks = checks;
          this.isLoading = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando marcaje...",
        { status: "primary", timeout: 0 }
      );
      let formData = new FormData();
      for (const key in this.model) {
        if (this.model[key] !== "") {
          if (typeof this.model[key] === "boolean") {
            formData.append(key, this.model[key] === true ? 1 : 0);
          } else {
            formData.append(key, this.model[key]);
          }
        }
      }
      axios
        .post("/time-tracking/general", formData)
        .then(({ data: { model } }) => {
          // this.checks.data.push(model)
          this.refetchPage(() => {
            UIkit.modal("#edit-check-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='success' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Marcaje creado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          if (
            error.response.data &&
            error.response.data.message === "range_occupied"
          ) {
            this.askForForce = true;
          }
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando marcaje...",
        { status: "primary", timeout: 0 }
      );
      let formData = new FormData();
      for (const key in this.model) {
        if (this.model[key] !== "") {
          if (typeof this.model[key] === "boolean") {
            formData.append(key, this.model[key] === true ? 1 : 0);
          } else {
            formData.append(key, this.model[key]);
          }
        }
      }
      formData.append("_method", "PUT");
      axios
        .post(
          "/time-tracking/general/" + this.activeCheckId.toString(),
          formData
        )
        .then(({ data: { model } }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-check-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='updated' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Marcaje actualizado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          if (
            error.response.data &&
            error.response.data.message === "range_occupied"
          ) {
            this.askForForce = true;
          }
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteCheck() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando marcaje...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/time-tracking/general/" + this.activeCheckId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='deleted' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Marcaje eliminado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleFile(e) {
      this.isLoading = true;
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        let newChecks = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .slice(2);

        newChecks = newChecks.map(check => {
          check.created_at = moment(check.created_at).format("YYYY-MM-DD");
          return check;
        });

        axios
          .post("/time-tracking/general/bulk", newChecks)
          .then(({ data }) => {
            this.refetchPage(() => {
              UIkit.modal("#import-export-modal").hide();
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  data.createdCount +
                  " Marcaje creadas</div>",
                { status: "success", timeout: 3000 }
              );
              this.isLoading = false;
            });
          })
          .catch(error => {
            console.log("error", error);
            this.isLoading = false;
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        insured_business_id: "",
        check_type_id: "",
        notes: "",
        created_at: "",
        force: false
      };
      this.askForForce = false;
    }
  },

  watch: {
    checkFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    checksFilterFrom(value) {
      this.refetchPage(() => {});
    },
    checksFilterTo(value) {
      this.refetchPage(() => {});
    }
  }
};
</script>

<style>
.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}
</style>