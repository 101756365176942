<template>
  <div>
    <div class="uk-text-large">Constancias</div>

    <div class="uk-flex uk-flex-center">
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          class="uk-input uk-border-rounded uk-text-center"
          v-model="filterFrom"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          class="uk-input uk-border-rounded uk-text-center"
          v-model="filterTo"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
    </div>
    <div class="uk-text-center uk-margin-small-top">
      <select
        class="uk-select uk-width-medium uk-border-rounded"
        v-model="auditableType"
        :disabled="isFetching"
      >
        <option disabled>Tipo de constancia</option>
        <option value="bulks">Pagos y operaciones en bulto</option>
        <option value="bonuses">Bonos</option>
        <option value="discounts">Descuentos</option>
        <option value="overtimes">Horas extra</option>
        <option value="absences">Ausencias</option>
      </select>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-right">
        <pagination-buttons
          :prev-page-url="records.prev_page_url"
          :is-fetching="isFetching"
          :current-page="records.current_page"
          :last-page="records.last_page"
          :next-page-url="records.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr v-if="auditableType === 'bulks'">
            <th width="20%">Bulto</th>
            <th width="25%">Fecha y hora de generación</th>
            <th width="10%" class="uk-text-center">Constancias</th>
            <th width="25%">Autor</th>
            <th width="5%" class="uk-text-center">Constancia sin firmar</th>
            <th width="5%" class="uk-text-center">Constancia firmada</th>
          </tr>
          <tr v-if="auditableType === 'bonuses' || auditableType === 'discounts'">
            <th width="30%">Fecha y hora de generación</th>
            <th width="30%">Autor</th>
            <th width="30%">Colaborador</th>
            <th width="5%" class="uk-text-center">Constancia sin firmar</th>
            <th width="5%" class="uk-text-center">Constancia firmada</th>
          </tr>
          <tr v-if="auditableType === 'overtimes'">
            <th width="30%">Fecha y hora de generación</th>
            <th width="30%">Autor</th>
            <th width="30%">Colaborador</th>
            <th width="5%" class="uk-text-center">Constancia sin firmar</th>
            <th width="5%" class="uk-text-center">Constancia firmada</th>
          </tr>
          <tr v-if="auditableType === 'absences'">
            <th width="30%">Fecha y hora de generación</th>
            <th width="30%">Autor</th>
            <th width="30%">Colaborador</th>
            <th width="5%" class="uk-text-center">Constancia sin firmar</th>
            <th width="5%" class="uk-text-center">Constancia firmada</th>
          </tr>
        </thead>
        <tbody v-if="!isFetching">
          <tr v-for="record in records.data" :key="record.id" v-if="auditableType === 'bulks'">
            <td>{{ record.significantName }}</td>
            <td>{{ formatDatetime(record.created_at) }}</td>
            <td class="uk-text-center">
              <span v-if="record.auditable">{{ record.auditable.content.length }}</span>
            </td>
            <td>{{ record.user.user.first_name }} {{ record.user.user.last_name }}</td>
            <td class="uk-text-center">
              <a
                v-if="record.unsigned_record"
                :href="record.unsigned_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
            <td class="uk-text-center">
              <a
                v-if="record.signed_record"
                :href="record.signed_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
          </tr>
          <tr
            v-for="record in records.data"
            :key="record.id"
            v-if="auditableType === 'bonuses' || auditableType === 'discounts'"
          >
            <td>{{ formatDatetime(record.created_at) }}</td>
            <td>{{ record.user.user.first_name }} {{ record.user.user.last_name }}</td>
            <td>{{ record.significantName }}</td>
            <td class="uk-text-center">
              <a
                v-if="record.unsigned_record"
                :href="record.unsigned_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
            <td class="uk-text-center">
              <a
                v-if="record.signed_record"
                :href="record.signed_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
          </tr>
          <tr v-for="record in records.data" :key="record.id" v-if="auditableType === 'overtimes'">
            <td>{{ formatDatetime(record.created_at) }}</td>
            <td>{{ record.user.user.first_name }} {{ record.user.user.last_name }}</td>
            <td>{{ record.significantName }}</td>
            <td class="uk-text-center">
              <a
                v-if="record.unsigned_record"
                :href="record.unsigned_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
            <td class="uk-text-center">
              <a
                v-if="record.signed_record"
                :href="record.signed_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
          </tr>
          <tr v-for="record in records.data" :key="record.id" v-if="auditableType === 'absences'">
            <td>{{ formatDatetime(record.created_at) }}</td>
            <td>{{ record.user.user.first_name }} {{ record.user.user.last_name }}</td>
            <td>{{ record.significantName }}</td>
            <td class="uk-text-center">
              <a
                v-if="record.unsigned_record"
                :href="record.unsigned_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
            <td class="uk-text-center">
              <a
                v-if="record.signed_record"
                :href="record.signed_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="uk-text-center" v-if="isFetching">
        <span uk-spinner></span>
      </div>

      <div class="bottom-divider uk-hidden@s" v-for="record in records.data" :key="record.id">
        <div class="uk-text-center uk-text-bold">
          <span>{{ record.significantName }}</span>
        </div>
        <div>
          <div>Generación:</div>
          <div>{{ formatDatetime(record.created_at) }}</div>
        </div>
        <div>
          <div>Autor:</div>
          <div>{{ record.user.user.first_name }} {{ record.user.user.last_name }}</div>
        </div>
        <div class="uk-flex uk-flex-center">
          <div class="uk-text-center" v-if="record.unsigned_record">
            <div>Constancia sin firmar</div>
            <div>
              <a :href="record.unsigned_record.path" target="”_blank”" uk-icon="cloud-download"></a>
            </div>
          </div>
          <div class="uk-text-center" v-if="record.signed_record">
            <div>Constancia firmada</div>
            <div>
              <a :href="record.signed_record.path" target="”_blank”" uk-icon="cloud-download"></a>
            </div>
          </div>
        </div>
        <!--       <div class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top">
        <button class="uk-button uk-width-1-2 uk-button-primary" href="#edit-record-modal" uk-toggle @click="setEditMode(record, index)" uk-icon="pencil"></button>
        <button class="uk-button uk-width-1-2 uk-button-danger" href="#confirm-deletion-modal" uk-toggle @click="setEditMode(record, index)" uk-icon="trash"></button>
        </div>-->
      </div>
    </div>

    <div
      v-if="records.data && records.data.length === 0"
      class="uk-text-center uk-margin-top"
    >No se han encontrado resultados con los filtros seleccionados.</div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default {
  name: "DirectoryRecords",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      filterFrom: this.payload.filterFrom,
      filterTo: this.payload.filterTo,
      isLoading: false,
      isFetching: false,
      itemsPerPage: 20,
      records: {},
      auditableType: "bulks",
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  mounted() {
    this.records = this.payload.records;
  },

  methods: {
    fetchPage(url) {
      this.isFetching = true;
      url =
        url +
        "&items=" +
        this.itemsPerPage +
        "&auditableType=" +
        this.auditableType;
      axios
        .get(url)
        .then(({ data: { records } }) => {
          this.records = records;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    }
  },

  watch: {
    auditableType() {
      this.fetchPage(window.location.pathname + "?dataOnly=true");
    }
  }
};
</script>