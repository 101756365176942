<template>
<div>
  <div class="uk-flex uk-flex-wrap uk-flex-between">
    <div class="uk-text-large">Reportes</div>
    <div>
      <button class="uk-button uk-border-rounded uk-button-primary">Exportar PDF</button>
      <button class="uk-button uk-border-rounded uk-button-secondary">Exportar Excel</button>
    </div>
  </div>
  <div>Reportes content</div>
</div>
</template>

<script>
export default {
  name: 'PayrollReports'
}
</script>
