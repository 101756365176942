<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Cuentas</div>
      <div>
        <!-- <button class="uk-button uk-border-rounded uk-button-primary" href="#import-export-modal" uk-toggle>Importar / Exportar</button> -->
        <button
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-account-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-bank-account')"
        >Añadir cuenta</button>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input"
            type="search"
            placeholder="Buscar cuenta..."
            v-model="accountFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="accounts.prev_page_url"
          :is-fetching="isFetching"
          :current-page="accounts.current_page"
          :last-page="accounts.last_page"
          :next-page-url="accounts.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th width="20%">Banco</th>
            <th width="20%">Tipo de cuenta</th>
            <th width="20%">Nombre de cuenta</th>
            <th width="20%">Número de cuenta</th>
            <th width="20%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(account, index) in accounts.data" :key="account.id">
            <td>{{ account.bank.name }}</td>
            <td>{{ account.type.name }}</td>
            <td>{{ account.account_name }}</td>
            <td>{{ account.account_number }}</td>
            <td class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  class="uk-button uk-width-1-2 uk-button-primary"
                  href="#edit-account-modal"
                  uk-toggle
                  @click="setEditMode(account, index)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-bank-account')"
                ></button>
                <button
                  class="uk-button uk-width-1-2 uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(account, index)"
                  uk-icon="trash"
                  v-if="checkPermission('delete-bank-account')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="bottom-divider uk-hidden@s"
        v-for="(account, index) in accounts.data"
        :key="account.id"
      >
        <div class="uk-text-center uk-text-bold">
          <span>{{ account.account_name }}</span>
        </div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
        >
          <button
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-account-modal"
            uk-toggle
            @click="setEditMode(account, index)"
            uk-icon="pencil"
            v-if="checkPermission('edit-bank-account')"
          ></button>
          <button
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(account, index)"
            uk-icon="trash"
            v-if="checkPermission('delete-bank-account')"
          ></button>
        </div>
      </div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span>¿Está seguro que desea eliminar la cuenta {{ this.model.account_name }}?</span>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteBankAccount"
            :disabled="isLoading"
          >Eliminar cuenta</button>
        </div>
      </div>
    </div>

    <div id="edit-account-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          cuenta
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Banco*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': bankIdIsValid }"
                uk-icon="check"
              ></span>
              <select class="uk-select uk-border-rounded" v-model="model.bank_id">
                <option
                  v-for="bank in payload.banks"
                  :key="bank.id"
                  :value="bank.id"
                >{{ bank.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Tipo de cuenta*</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': bankAccountTypeIdIsValid }"
                uk-icon="check"
              ></span>
              <select class="uk-select uk-border-rounded" v-model="model.bank_account_type_id">
                <option
                  v-for="bankAccountType in payload.bankAccountTypes"
                  :key="bankAccountType.id"
                  :value="bankAccountType.id"
                >{{ bankAccountType.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre de la cuenta</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': accountNameIsValid }"
                uk-icon="check"
              ></span>
              <input class="uk-input uk-border-rounded" type="text" v-model="model.account_name" />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Número de cuenta</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': accountNumberIsValid }"
                uk-icon="check"
              ></span>
              <input class="uk-input uk-border-rounded" type="text" v-model="model.account_number" />
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !accountIsValid"
            v-if="mode === 'new'"
          >Crear cuenta</button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !accountIsValid"
            v-else
          >Actualizar cuenta</button>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <div uk-form-custom>
            <input name="file" type="file" @change="handleFile($event)" />
            <button class="uk-button uk-border-rounded uk-button-secondary">Importar</button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-border-rounded uk-button-secondary">Exportar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import xlsx from "xlsx";

export default {
  name: "ProfileBanks",

  props: ["payload"],

  data() {
    return {
      model: {
        account_name: "",
        account_number: "",
        bank_id: "",
        bank_account_type_id: ""
      },
      itemsPerPage: 20,
      activeAccountId: "",
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      mode: "new",
      accountFilter: "",
      accounts: {},
      accountFilterTimer: null
    };
  },

  mounted() {
    this.accounts = this.payload.accounts;
  },

  computed: {
    accountNameIsValid() {
      return this.model.account_name.length > 1;
    },
    accountNumberIsValid() {
      return this.model.account_number.length > 1;
    },
    bankIdIsValid() {
      return this.model.bank_id !== "";
    },
    bankAccountTypeIdIsValid() {
      return this.model.bank_account_type_id !== "";
    },
    accountIsValid() {
      const validations = [
        this.accountNameIsValid,
        this.accountNumberIsValid,
        this.bankIdIsValid,
        this.bankAccountTypeIdIsValid
      ];
      return validations.every(validation => validation === true);
    }
  },

  methods: {
    setNewMode() {
      this.mode = "new";
      this.model.account_name = "";
      this.model.account_number = "";
      this.model.bank_id = "";
      this.model.bank_account_type_id = "";
    },
    setEditMode(account, index) {
      this.mode = "edit";
      this.activeAccountId = account.id;
      this.payloadPosition = index;
      this.model.account_name = account.account_name;
      this.model.account_number = account.account_number;
      this.model.bank_id = account.bank_id;
      this.model.bank_account_type_id = account.bank_account_type_id;
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.accountFilter && !url.includes("&query")) {
        url = url + "&query=" + this.accountFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { accounts } }) => {
          this.accounts = accounts;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.accountFilterTimer) {
        clearTimeout(this.accountFilterTimer);
      }
      this.accountFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.accountFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.accounts.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { accounts } }) => {
          this.accounts = accounts;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando cuenta...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/profile/banks", this.model)
        .then(({ data: { model } }) => {
          // this.accounts.data.push(model)
          this.refetchPage(() => {
            UIkit.modal("#edit-account-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Cuenta creada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando cuenta...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/profile/banks/" + this.activeAccountId.toString(), this.model)
        .then(({ data: { model } }) => {
          // this.accounts.data[this.payloadPosition] = model
          this.refetchPage(() => {
            UIkit.modal("#edit-account-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Cuenta actualizada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteBankAccount() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando cuenta...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete("/profile/banks/" + this.activeAccountId.toString(), this.model)
        .then(({ response }) => {
          // this.accounts.data.splice(this.payloadPosition, 1)
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Cuenta eliminada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleFile(e) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        const newAccounts = xlsx.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 2 }
        );

        axios
          .post("/profile/banks/bulk", newAccounts)
          .then(({ data }) => {
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                data.count +
                " Cuentas creadas</div>",
              { status: "success", timeout: 3000 }
            );
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        account_name: "",
        account_number: "",
        bank_id: "",
        bank_account_type_id: ""
      };
    }
  },

  watch: {
    accountFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}
</style>