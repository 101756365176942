<template>
  <div>
    <div class="uk-card uk-card-default uk-border-rounded uk-card-body">
      <div class="uk-flex uk-flex-wrap uk-flex-around@s">
        <div class="uk-width-1-3@s uk-text-center">
          <div
            class="uk-inline uk-dark uk-visible-toggle uk-text-center uk-overflow-hidden avatar-placeholder"
          >
            <span v-if="isUpdatingAvatar" uk-spinner="ratio: 10" class="uk-width-1-1"></span>
            <div class="avatar-cropper" :class="{ 'uk-hidden': isUpdatingAvatar }">
              <div
                id="insured-avatar"
                class="rounded-avatar"
                :style="{ 'background-image': payload.insured.avatar ? 'url(' + payload.insured.avatar + ')' : '' }"
              ></div>
              <div uk-form-custom class="uk-display-block position-static">
                <input
                  name="avatar"
                  type="file"
                  accept="image/*"
                  @change="handleAvatar($event.target.files[0])"
                />
                <div
                  class="uk-overlay-default uk-position-cover uk-border-circle"
                  :class="{ 'uk-invisible-hover': payload.insured.avatar, 'overlay-adjust': payload.insured.avatar === null }"
                >
                  <div class="uk-position-center">
                    <span class="uk-link" uk-overlay-icon="icon: pencil; ratio: 3;"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-margin-top">
            <span class="uk-text-bold">Qüilo ID:</span>
            {{ formatQuiloId(payload.insured.quilo_id) }}
          </div>
        </div>
        <div class="uk-width-2-3 uk-visible@s">
          <div class="text-huge">{{ payload.insured.first_name }} {{ payload.insured.last_name }}</div>
          <div class="uk-margin-top">
            <a
              :href="'/directory/general?filtertags[0]=' + tag.name.es"
              v-for="tag in payload.insured.business_relation.tags"
              :key="tag.name.es"
            >
              <span
                class="uk-badge uk-padding-small uk-margin-small-left uk-margin-small-right"
              >#{{ tag.name.es }}</span>
            </a>
          </div>
          <div class="uk-flex uk-flex-wrap uk-flex-around uk-text-large uk-margin-top uk-text-bold">
            <div class="uk-width-1-2@l uk-padding-small">
              <div>
                Estado:
                <span class="text-light">{{ payload.insured.business_relation.status }}</span>
              </div>
              <div>
                División:
                <span
                  class="text-light"
                  v-if="payload.insured.division"
                >{{ payload.insured.division.name }}</span>
              </div>
              <div>
                Título:
                <span class="text-light">{{ payload.insured.business_relation.title }}</span>
              </div>
              <div>
                Tipo:
                <span class="text-light">{{ payload.insured.business_relation.type }}</span>
              </div>
              <div>
                Ubicación:
                <span class="text-light">{{ payload.insured.business_relation.location }}</span>
              </div>
              <div v-if="payload.insured.business_boss">
                <a
                  :href="'/directory/general/' + payload.insured.business_boss.id.toString()"
                  class="uk-link-reset"
                >
                  Responsable:
                  <span
                    class="text-light"
                  >{{ payload.insured.business_boss.first_name }} {{ payload.insured.business_boss.last_name }}</span>
                </a>
              </div>
              <div>
                Colaborador desde:
                <span
                  class="text-light"
                >{{ formatDate(payload.insured.business_relation.start_date) }}</span>
              </div>
            </div>
            <div class="uk-width-1-2@l uk-padding-small">
              <div>
                Teléfono:
                <span class="text-light">{{ payload.insured.phone }}</span>
              </div>
              <div>
                Email:
                <span class="text-light">{{ payload.insured.business_relation.email }}</span>
              </div>
              <div>
                Fecha de nacimiento:
                <span
                  class="text-light"
                >{{ formatDate(payload.insured.birthdate) }}</span>
              </div>
              <div>
                DPI:
                <span class="text-light">{{ payload.insured.dpi }}</span>
              </div>
              <div>
                Género:
                <span class="text-light">{{ payload.insured.gender }}</span>
              </div>
              <div>
                Estado civil:
                <span class="text-light">{{ payload.insured.civil_state }}</span>
              </div>
              <div>
                Número de afiliación del IGSS:
                <span
                  class="text-light"
                >{{ payload.insured.business_relation.igss }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-hidden@s uk-margin-top uk-width-1-1 uk-text-bold">
          <div
            class="uk-text-large uk-text-center"
          >{{ payload.insured.first_name }} {{ payload.insured.last_name }}</div>
          <div class="uk-margin-top uk-margin-bottom uk-text-center">
            <a
              :href="'/directory/general?filtertags[0]=' + tag.name.es"
              v-for="tag in payload.insured.business_relation.tags"
              :key="tag.name.es"
            >
              <span
                class="uk-badge uk-padding-small uk-margin-small-left uk-margin-small-right"
              >#{{ tag.name.es }}</span>
            </a>
          </div>
          <div>
            Estado:
            <span class="text-light">{{ payload.insured.business_relation.status }}</span>
          </div>
          <div>
            División:
            <span
              class="text-light"
              v-if="payload.insured.division"
            >{{ payload.insured.division.name }}</span>
          </div>
          <div>
            Título:
            <span class="text-light">{{ payload.insured.business_relation.title }}</span>
          </div>
          <div>
            Tipo:
            <span class="text-light">{{ payload.insured.business_relation.type }}</span>
          </div>
          <div>
            Ubicación:
            <span class="text-light">{{ payload.insured.business_relation.location }}</span>
          </div>
          <div v-if="payload.insured.business_boss">
            Responsable:
            <a
              :href="'/directory/general/' + payload.insured.business_boss.id.toString()"
              class="link-underline"
            >
              <span
                class="text-light"
              >{{ payload.insured.business_boss.first_name }} {{ payload.insured.business_boss.last_name }}</span>
            </a>
          </div>
          <div>
            Colaborador desde:
            <span
              class="text-light"
            >{{ formatDate(payload.insured.business_relation.start_date) }}</span>
          </div>
          <div>
            Teléfono:
            <span class="text-light">{{ payload.insured.phone }}</span>
          </div>
          <div>
            Email:
            <span class="text-light">{{ payload.insured.business_relation.email }}</span>
          </div>
          <div>
            Fecha de nacimiento:
            <span class="text-light">{{ formatDate(payload.insured.birthdate) }}</span>
          </div>
          <div>
            DPI:
            <span class="text-light">{{ payload.insured.dpi }}</span>
          </div>
          <div>
            Género:
            <span class="text-light">{{ payload.insured.gender }}</span>
          </div>
          <div>
            Estado civil:
            <span class="text-light">{{ payload.insured.civil_state }}</span>
          </div>
          <div>
            Número de afiliación del IGSS:
            <span
              class="text-light"
            >{{ payload.insured.business_relation.igss }}</span>
          </div>
        </div>
      </div>
      <div>
        <div
          class="uk-card uk-card-primary uk-card-body uk-border-rounded uk-width-1-2@s uk-width-1-3@m uk-margin-auto uk-margin-small-top uk-padding-small"
        >
          <div class="uk-text-large uk-text-center">Contacto de emergencia</div>
          <div>
            <span class="uk-text-bold">Nombre:</span>
            {{ payload.insured.business_relation.emergency_contact_name }}
          </div>
          <div>
            <span class="uk-text-bold">Teléfono:</span>
            {{ payload.insured.business_relation.emergency_contact_phone }}
          </div>
          <div>
            <span class="uk-text-bold">Parentesco:</span>
            {{ payload.insured.business_relation.emergency_contact_relationship }}
          </div>
        </div>
      </div>
      <div
        class="uk-margin-top uk-text-lead uk-text-bold"
        v-if="payload.insured.business_relation.business_plans.length !== 0"
      >Incluido en:</div>
      <div
        class="uk-flex uk-flex-wrap uk-flex-around"
        v-if="payload.insured.business_relation.business_plans.length !== 0"
      >
        <div
          v-for="plan in payload.insured.business_relation.business_plans"
          :key="plan.id"
          class="uk-card uk-card-primary uk-card-body uk-border-rounded uk-width-1-4@s uk-margin-small uk-padding-small"
        >
          <div class="uk-text-large uk-text-center">{{ plan.plan.name }}</div>
          <div class="uk-margin-small-top">{{ plan.plan.description }}</div>
        </div>
      </div>
      <hr class="uk-divider-icon" />
      <div class="uk-margin-top uk-text-large uk-text-bold">
        <span
          v-if="payload.insured.business_relation.payroll && payload.insured.business_relation.payroll.type.id === 1"
        >Vacaciones y ausencias:</span>
        <span v-else>Ausencias:</span>
      </div>
      <div
        class="uk-flex uk-flex-wrap uk-flex-around"
        v-if="payload.insured.business_relation.deleted_at === null && payload.insured.business_relation.payroll && payload.insured.business_relation.payroll.type.id === 1"
      >
        <div
          class="uk-card uk-card-primary uk-card-body uk-border-rounded uk-width-1-4@s uk-margin-small uk-padding-small"
        >
          <div class="text-huge uk-text-center">{{ payload.totalAvailableVacationDays }}</div>
          <div class="uk-margin-small-top uk-text-center">días de vacaciones disponibles</div>
          <div
            class="uk-margin-small-top uk-text-center"
          >{{ payload.totalVacationDays }} acumulados - {{ payload.totalUsedVacationDays }} gozados desde el {{ formatDate(payload.insured.business_relation.start_date) }}</div>
        </div>
      </div>
      <table
        class="uk-table uk-table-hover uk-table-middle uk-table-divider"
        v-if="payload.insured.business_relation.absences.length !== 0"
      >
        <thead>
          <tr>
            <th width="15%">Tipo de ausencia</th>
            <th width="20%">Razón</th>
            <th width="35%">Notas</th>
            <th width="5%">Constancia</th>
            <th width="15%">Fecha de aplicación</th>
            <th width="10%">Duración en días</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="absence in payload.insured.business_relation.absences" :key="absence.id">
            <td :id="'absence-' + absence.id + '-type-name'">{{ absence.type.name }}</td>
            <td :id="'absence-' + absence.id + '-reason-name'">{{ absence.reason.name }}</td>
            <td :id="'absence-' + absence.id + '-notes'">{{ absence.notes }}</td>
            <td class="uk-text-center">
              <a
                v-if="absence.file"
                uk-icon="cloud-download"
                :href="absence.file.path"
                target="_blank"
              ></a>
            </td>
            <td
              :id="'absence-' + absence.id + '-application-date'"
            >{{ formatDate(absence.application_date) }}</td>
            <td
              :id="'absence-' + absence.id + '-duration'"
              class="uk-text-center"
            >{{ absence.duration }}</td>
          </tr>
        </tbody>
      </table>
      <hr class="uk-divider-icon" v-if="payload.insured.business_relation.overtimes.length !== 0" />
      <div
        class="uk-margin-top uk-text-large uk-text-bold"
        v-if="payload.insured.business_relation.overtimes.length !== 0"
      >Horas extra:</div>
      <table
        class="uk-table uk-table-hover uk-table-middle uk-table-divider"
        v-if="payload.insured.business_relation.overtimes.length !== 0"
      >
        <thead>
          <tr>
            <th width="20%">Razón</th>
            <th width="40%">Notas</th>
            <th width="5%">Constancia</th>
            <th width="25%">Fecha de aplicación</th>
            <th width="10%">Cantidad de horas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="overtime in payload.insured.business_relation.overtimes" :key="overtime.id">
            <td :id="'overtime-' + overtime.id + '-reason-name'">{{ overtime.reason.name }}</td>
            <td :id="'overtime-' + overtime.id + '-notes'">{{ overtime.notes }}</td>
            <td class="uk-text-center">
              <a
                v-if="overtime.file"
                uk-icon="cloud-download"
                :href="overtime.file.path"
                target="_blank"
              ></a>
            </td>
            <td
              :id="'overtime-' + overtime.id + '-application-date'"
            >{{ formatDate(overtime.application_date) }}</td>
            <td
              :id="'overtime-' + overtime.id + '-hours'"
              class="uk-text-center"
            >{{ overtime.hours }}</td>
          </tr>
        </tbody>
      </table>
      <hr class="uk-divider-icon" />
      <div>
        <div
          class="uk-card uk-card-secondary uk-card-body uk-border-rounded uk-margin-top uk-padding-small"
        >
          <div class="uk-margin-small-top uk-text-large uk-text-center">Documentos:</div>
          <ul uk-tab class="uk-flex-center file-selector">
            <li v-for="fileType in payload.fileTypes" :key="fileType.id">
              <a href="#">{{ fileType.name }}</a>
            </li>
          </ul>

          <ul class="uk-switcher uk-margin">
            <li v-for="fileType in payload.fileTypes" :key="fileType.id">
              <div class="uk-text-center uk-margin-top">
                <a
                  v-if="fileType.id in filesByTypeId"
                  :href="filesByTypeId[fileType.id].path"
                  class="uk-link-reset"
                  target="_blank"
                >
                  <button class="uk-button uk-border-rounded uk-button-default uk-margin-small-top">
                    <span uk-icon="cloud-download"></span>
                    Descargar {{ fileType.name }}
                  </button>
                </a>
                <div uk-form-custom>
                  <input
                    name="file"
                    type="file"
                    @change="handleFile($event.target.files[0], fileType.id, index)"
                  />
                  <button
                    class="uk-button uk-border-rounded uk-button-default uk-margin-small-top"
                    :disabled="updatingFile === fileType.id"
                  >
                    <span v-if="updatingFile === fileType.id" uk-spinner="ratio: 0.6"></span>
                    <span v-else>
                      <span uk-icon="cloud-upload"></span>
                      Cargar {{ fileType.name }}
                    </span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <hr class="uk-divider-icon" />
      <div
        class="uk-margin-top uk-text-large uk-text-bold"
        v-if="payload.insured.business_relation.activities.length !== 0"
      >Actividad de la relación con el colaborador:</div>
      <table
        class="uk-table uk-table-hover uk-table-middle uk-table-divider"
        v-if="payload.insured.business_relation.activities.length !== 0"
      >
        <thead>
          <tr>
            <th width="20%">Tipo</th>
            <th width="50%">Notas</th>
            <th width="30%">Fecha</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="activity in payload.insured.business_relation.activities" :key="activity.id">
            <td :id="'activity-' + activity.id + '-name'">{{ activity.type.name }}</td>
            <td :id="'activity-' + activity.id + '-notes'">{{ activity.notes }}</td>
            <td
              :id="'activity-' + activity.id + '-date'"
            >{{ formatDate(activity.application_date) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="uk-text-center uk-margin-top">
      <a dusk="back-to-directory" href="/directory/general" class="uk-link-reset">
        <button
          class="uk-button uk-border-rounded uk-button-secondary"
        >Regresar a directorio general</button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DirectoryDetail",

  props: ["payload"],

  data() {
    let filesByTypeId = this.payload.insured.files.reduce((accum, file) => {
      accum[file.file_type_id] = file;
      return accum;
    }, {});

    filesByTypeId = this.payload.insured.business_relation.files.reduce(
      (accum, file) => {
        accum[file.file_type_id] = file;
        return accum;
      },
      filesByTypeId
    );

    return {
      isUpdatingAvatar: false,
      updatingFile: "",
      filesByTypeId
    };
  },

  methods: {
    handleAvatar(file) {
      this.isUpdatingAvatar = true;
      let formData = new FormData();
      formData.append("avatar", file);
      axios
        .post(
          "/directory/general/" +
            this.payload.insured.id.toString() +
            "/avatar",
          formData
        )
        .then(({ data: { url } }) => {
          document.getElementById("insured-avatar").style.backgroundImage =
            "url('" + url + "')";
          this.isUpdatingAvatar = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdatingAvatar = false;
        });
    },
    handleFile(file, fileTypeId, fileIndex) {
      this.updatingFile = fileTypeId;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("file_type_id", fileTypeId);
      formData.append("insured_id", this.payload.insured.id);
      axios
        .post(
          "/directory/general/" + this.payload.insured.id + "/file",
          formData
        )
        .then(({ data: { model } }) => {
          this.updatingFile = null;
          this.filesByTypeId[model.file_type_id] = model;
        })
        .catch(error => {
          console.log("error", error);
          this.updatingFile = null;
        });
    }
  }
};
</script>

<style scoped>
.overlay-adjust {
  margin: auto;
  height: 50vw;
  width: 50vw;
  max-height: 50vw;
  max-width: 50vw;
}

.avatar-cropper {
  max-width: 50vw;
  height: auto;
  position: relative;
  overflow: hidden;
}

.rounded-avatar {
  display: block;
  margin: 0 auto;
  height: 50vw;
  width: 50vw;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;
}

@media only screen and (min-width: 640px) {
  .overlay-adjust {
    height: 20vw;
    width: 20vw;
    max-height: 20vw;
    max-width: 20vw;
  }

  .avatar-cropper {
    max-width: 20vw;
  }

  .rounded-avatar {
    height: 20vw;
    width: 20vw;
  }
}

.avatar-placeholder {
  min-height: 200px;
}

.text-huge {
  font-size: 50px;
}

.text-light {
  font-weight: 100;
}

.position-static {
  position: static;
}
</style>
