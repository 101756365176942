<template>
  <vue-mathjax v-if="latexScript" :formula="latexScript"></vue-mathjax>
</template>

<script>
const operators = ["+", "-", "*", "/", "^"];
const postfixToLatex = (postfix, availableTokens) => {
  let stack = [],
    op1,
    op2;
  postfix.forEach((token, index) => {
    if (operators.includes(token)) {
      switch (token) {
        case "/":
          op2 = stack.pop();
          op1 = stack.pop();
          stack.push(
            (postfix.length !== index + 1 ? "\\left(" : "") +
              op1 +
              "\\over" +
              op2 +
              (postfix.length !== index + 1 ? "\\right)" : "")
          );
          break;
        default:
          op2 = stack.pop();
          op1 = stack.pop();
          stack.push(
            (postfix.length !== index + 1 ? "\\left(" : "") +
              op1 +
              token +
              op2 +
              (postfix.length !== index + 1 ? "\\right)" : "")
          );
          break;
      }
    } else {
      let tokenName = availableTokens.find(
        availableToken => availableToken.slug === token
      );
      tokenName = tokenName ? tokenName.name.replace(/ /g, "\\,") : token;
      stack.push(
        "\\require{color}{\\color{white}\\colorbox{#2c5fa1}{$" +
          tokenName +
          "$}}"
      );
    }
  });
  return "$$" + stack[0] + "$$";
};

export default {
  name: "Latex",

  props: ["postfix", "tokens"],

  computed: {
    latexScript() {
      return postfixToLatex(this.postfix, this.tokens);
    }
  }
};
</script>