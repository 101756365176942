<template>
<div>
  <div class="uk-text-large">Configuración de proporción de pagos</div>

  <div class="uk-text-center uk-margin-small-top">
    <select class="uk-select uk-width-medium uk-border-rounded" v-model="selectedDivision" :disabled="isLoading">
      <option disabled>Tipo de constancia</option>
      <option :value="null">Global</option>
      <option :value="division.id" v-for="division in payload.divisions" :key="division.id">{{ division.name }}</option>
    </select>
  </div>

  <div v-for="frequency in frequencies" class="uk-card uk-margin-top uk-card-default uk-border-rounded uk-padding">
    <div class="uk-text-large uk-text-center">{{ frequency.name }}</div>
    <div v-for="(field, fieldIndex) in frequency.fields" class="uk-margin-top">
      <hr class="uk-divider-icon" v-if="fieldIndex !== frequency.fields.length - 1">
      <div class="uk-flex uk-flex-middle uk-flex-between">
        <label><input class="uk-checkbox" type="checkbox" v-model="field.active"> Habilitar configuración de {{ proportionalFieldTranslations(field.slug) }}</label>
        <div class="uk-flex" v-if="field.active">
          <div v-for="(proportion, proportionIndex) in field.proportions" class="uk-margin-right">
            <div class="uk-text-center">Porcentaje en período {{ proportionIndex + 1 }}</div>
            <input class="uk-input uk-border-rounded uk-background-default uk-text-center" type="text" v-currencyOnly v-model="field.proportions[proportionIndex]">
          </div>
        </div>
        <div  v-if="field.active">
          <div>Total</div>
          <div
            :class="{
              'uk-text-danger': field.proportions.reduce((accum, proportion) => accum + parseFloat(proportion), 0) != 100,
              'uk-text-success': field.proportions.reduce((accum, proportion) => accum + parseFloat(proportion), 0) == 100
            }">
            {{ field.proportions.reduce((accum, proportion) => accum + parseFloat(proportion), 0) }}%
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="uk-text-center uk-margin-top">
    <button class="uk-button uk-border-rounded uk-button-primary" @click="update" :disabled="!allComplete || isLoading">
      <span v-if="isLoading" uk-spinner></span>
      <span v-else>Actualizar proporciones</span>
    </button>
  </div>
</div>
</template>

<script>
import UIkit from 'uikit'

export default {
  name: 'PaymentProportions',

  props: ['payload'],

  data() {
    return {
      isLoading: false,
      selectedDivision: null,
      frequencies: this.payload.frequencies
        .filter(frequency => frequency.id === 2) // Quincenal only
        .map(frequency => ({
          id: frequency.id,
          name: frequency.name,
          fields: this.payload.fields.reduce((accum, field) => {
            const businessPaymentProportion = frequency.business_payment_proportions.filter(proportion => proportion.field.id === field.id)[0]
            accum[field.id] = {
              slug: field.slug,
              proportions: businessPaymentProportion ? JSON.parse(businessPaymentProportion.proportions) : [50, 50], // Quincenal only
              active: businessPaymentProportion ? true : false
            }
            return accum
          }, {})
        }))
    }
  },

  computed: {
    allComplete() {
      return this.frequencies.map(frequency => {
        return Object.keys(frequency.fields)
          .map(key =>
            frequency
              .fields[key]
          )
          .filter(field => field.active)
          .map(field => field
            .proportions
            .reduce((accum, proportion) => accum + parseFloat(proportion), 0) == 100
          )
          .every(sum => sum)
      }).every(sum => sum)
    }
  },

  methods: {
    proportionalFieldTranslations(slug) {
      switch(slug) {
        case 'base':
          return 'Pago Base'
        case 'bonuses':
          return 'Bonos Fijos'
        case 'incentive':
          return 'Bono Incentivo'
        case 'igss':
          return 'IGSS Laboral'
        case 'isr':
          return 'Retención ISR'
        default:
          return ''
      }
    },
    update() {
      this.isLoading = true
      axios.put('/payroll/proportions', {
        division_id: this.selectedDivision,
        proportions: this.frequencies
      }).then(
        ({ data: { frequencies, divisions }}) => {
          this.payload.frequencies = frequencies
          this.payload.divisions = divisions
          this.isLoading = false
          const success = UIkit.notification("<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Proporciones actualizadas</div>", { status: 'success', timeout: 3000 })
        }
      ).catch(
        error => {
          console.log('error', error)
          this.isLoading = false
          const success = UIkit.notification("<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Ha ocurrido un error actualizando las proporciones.</div>", { status: 'warning', timeout: 3000 })
        }
      )
    }
  },

  watch: {
    selectedDivision(divisionId) {
      if (divisionId) {
        this.frequencies = [{
          id: 2,
          name: 'Quincenal',
          fields: this.payload.fields.reduce((accum, field) => {
            const divisionPaymentProportion = this.payload.divisions.filter(division => division.id === divisionId)[0].proportions.filter(proportion => proportion.proportionable_field_id === field.id)[0]
            accum[field.id] = {
              slug: field.slug,
              proportions: divisionPaymentProportion ? JSON.parse(divisionPaymentProportion.proportions) : [50, 50], // Quincenal only
              active: divisionPaymentProportion ? true : false
            }
            return accum
          }, {})
        }]
      } else {
        this.frequencies = this.payload.frequencies
          .filter(frequency => frequency.id === 2) // Quincenal only
          .map(frequency => ({
            id: frequency.id,
            name: frequency.name,
            fields: this.payload.fields.reduce((accum, field) => {
              const businessPaymentProportion = frequency.business_payment_proportions.filter(proportion => proportion.field.id === field.id)[0]
              accum[field.id] = {
                slug: field.slug,
                proportions: businessPaymentProportion ? JSON.parse(businessPaymentProportion.proportions) : [50, 50], // Quincenal only
                active: businessPaymentProportion ? true : false
              }
              return accum
            }, {})
          }))
      }
    }
  }
}
</script>