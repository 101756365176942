<template>
  <div>
    <div id="calendar"></div>

    <div id="create-event-modal" class="uk-flex-top uk-modal-container" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div
          class="uk-text-center uk-text-large"
          v-if="model.start"
        >{{ momentUTC(model.start).format('LL') }}</div>

        <div class="uk-flex uk-flex-row-reverse uk-flex-wrap uk-flex-around">
          <div
            class="uk-card uk-card-primary uk-border-rounded uk-padding-small uk-margin-top uk-width-2-5@s"
          >
            <div v-if="mode === 'new'">Nuevo evento</div>
            <div v-else>Editar evento</div>
            <flat-pickr
              class="uk-input uk-border-rounded uk-margin-small-top uk-text-center uk-text-emphasis"
              v-model="model.start"
              :config="datepickerConfig"
              placeholder="Fecha"
            ></flat-pickr>
            <div class="uk-margin-top uk-text-center">
              <label>
                <input class="uk-checkbox" type="checkbox" v-model="model.allDay" /> Todo el día
              </label>
            </div>
            <input
              name="title"
              class="uk-margin-top uk-border-rounded uk-input uk-text-center uk-text-emphasis"
              type="text"
              placeholder="Título"
              v-model="model.title"
            />
            <textarea
              class="uk-margin-top uk-border-rounded uk-textarea uk-text-emphasis"
              rows="3"
              placeholder="Notas"
              v-model="model.notes"
            ></textarea>
            <div class="uk-text-center" v-if="mode === 'new'">
              <button
                class="uk-margin-top uk-width-1-2 uk-button uk-border-rounded uk-button-secondary"
                @click="store"
                :disabled="isLoading"
              >
                <span v-if="isLoading" uk-spinner="ratio: 0.7"></span>
                <span v-else>Crear</span>
              </button>
            </div>
            <div v-else>
              <div class="uk-text-center">
                <button
                  class="uk-margin-top uk-width-1-2 uk-button uk-button-primary uk-border-rounded"
                  @click="update"
                  :disabled="isLoading"
                >
                  <span v-if="isLoading" uk-spinner="ratio: 0.7"></span>
                  <span v-else>Actualizar</span>
                </button>
              </div>
              <div class="uk-text-center">
                <button
                  class="uk-margin-top uk-width-1-2 uk-button uk-button-danger uk-border-rounded"
                  id="delete-confirmation-button"
                >Eliminar</button>
                <div
                  uk-drop="mode: click; toggle: #delete-confirmation-button; pos: top;"
                  class="uk-card uk-card-body uk-card-secondary uk-border-rounded uk-padding-small"
                >
                  Está seguro que desea eliminar el evento {{ model.title }}?
                  <div class="uk-text-center">
                    <button
                      class="uk-button uk-button-danger uk-border-rounded"
                      @click="dele"
                      :disabled="isLoading"
                    >
                      <span v-if="isLoading" uk-spinner="ratio: 0.7"></span>
                      <span v-else>Confirmar eliminación</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="uk-text-center">
                <button
                  class="uk-margin-top uk-width-1-2 uk-button uk-border-rounded uk-button-secondary"
                  @click="resetForm"
                  v-if="mode === 'edit'"
                >Cancelar</button>
              </div>
            </div>
          </div>

          <div class="uk-width-2-5@s" v-if="model.start.substr(0, 10) in events">
            <div
              class="uk-card uk-card-primary uk-border-rounded uk-padding-small uk-margin-top"
              v-for="(event, index) in events[model.start.substr(0, 10)]"
              :key="index"
              @click="editEvent(event, index)"
            >
              <div v-if="event.allDay === true">Todo el día</div>
              <div v-else>{{ momentUTC(event.start).format('h:mm:ss a') }}</div>
              <div>{{ event.title }}</div>
              <div>{{ event.notes }}</div>
              <div
                v-if="event.creator"
              >Creado por {{ event.creator.first_name }} {{ event.creator.last_name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import esLocale from "@fullcalendar/core/locales/es";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import moment from "moment";
import gapi from "gapi-client";

export default {
  name: "DirectoryCalendar",

  data() {
    return {
      calendar: "",
      events: {},
      model: {
        start: "",
        title: "",
        notes: "",
        allDay: false
      },
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        enableTime: true,
        altInput: true,
        altFormat: "j \\de F \\de Y, h:i K"
      },
      mode: "new",
      currentIndex: null,
      currentId: null,
      isLoading: false
    };
  },

  props: ["payload"],

  components: {
    flatPickr
  },

  mounted() {
    this.events = this.payload.insureds.reduce((dates, insured) => {
      if (
        moment(insured.birthdate)
          .set("year", moment().get("year"))
          .toISOString()
          .substr(0, 10) in dates
      ) {
        dates[
          moment(insured.birthdate)
            .set("year", moment().get("year"))
            .toISOString()
            .substr(0, 10)
        ].push({
          title:
            "Cumpleaños de " + insured.first_name + " " + insured.last_name,
          notes: "",
          start: moment(insured.birthdate)
            .set("year", moment().get("year"))
            .toISOString(),
          allDay: true,
          editable: false
        });
      } else {
        dates[
          moment(insured.birthdate)
            .set("year", moment().get("year"))
            .toISOString()
            .substr(0, 10)
        ] = [
          {
            title:
              "Cumpleaños de " + insured.first_name + " " + insured.last_name,
            notes: "",
            start: moment(insured.birthdate)
              .set("year", moment().get("year"))
              .toISOString(),
            allDay: true,
            editable: false
          }
        ];
      }
      return dates;
    }, {});

    this.events = this.payload.events.reduce((dates, event) => {
      if (event.date.substr(0, 10) in dates) {
        dates[event.date.substr(0, 10)].push({
          ...event,
          allDay: event.all_day,
          start: moment.utc(event.date).toISOString(),
          editable: true
        });
      } else {
        dates[event.date.substr(0, 10)] = [
          {
            ...event,
            allDay: event.all_day,
            start: moment.utc(event.date).toISOString(),
            editable: true
          }
        ];
      }
      return dates;
    }, this.events);

    this.calendar = new Calendar(document.getElementById("calendar"), {
      plugins: [dayGridPlugin, interactionPlugin, googleCalendarPlugin],
      googleCalendarApiKey: "AIzaSyCRbzGDfk3XMwMS_TNsKWvYY0GqKJohArw",
      height: 600,
      locale: esLocale,
      timeZone: "America/Guatemala",
      nowIndicator: true,
      eventSources: [
        {
          googleCalendarId:
            "5dudo46mk6il68u0ovsd4lf88g@group.calendar.google.com"
        },
        (info, successCallback, failureCallback) => {
          return successCallback(
            Object.keys(this.events).reduce((plainEvents, date) => {
              plainEvents.push(...this.events[date]);
              return plainEvents;
            }, [])
          );
        }
      ],
      dateClick: info => {
        this.model.start = info.dateStr;
        this.mode = "new";
        this.resetForm();
        UIkit.modal("#create-event-modal").show();
      },
      eventClick: info => {
        this.mode = "new";
        this.resetForm();
        this.model.start = info.event.start.toISOString().substr(0, 10);
        UIkit.modal("#create-event-modal").show();
      }
    });

    this.calendar.render();

    // Client ID and API key from the Developer Console
    var CLIENT_ID =
      "1068258189902-4djge2gph21ppgvb4m0u4oip0dqi2hkc.apps.googleusercontent.com";
    var API_KEY = "oTWfmMt_r3CHmYutFfJJ_FUh";

    // Array of API discovery doc URLs for APIs used by the quickstart
    var DISCOVERY_DOCS = [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    var SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

    var authorizeButton = document.getElementById("authorize_button");
    var signoutButton = document.getElementById("signout_button");

    /**
     *  On load, called to load the auth2 library and API client library.
     */
    function handleClientLoad() {
      gapi.load("client:auth2", initClient);
    }

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    function initClient() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        })
        .then(
          function() {
            // Listen for sign-in state changes.
            gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

            // Handle the initial sign-in state.
            updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
            authorizeButton.onclick = handleAuthClick;
            signoutButton.onclick = handleSignoutClick;
          },
          function(error) {
            appendPre(JSON.stringify(error, null, 2));
          }
        );
    }

    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */
    function updateSigninStatus(isSignedIn) {
      if (isSignedIn) {
        authorizeButton.style.display = "none";
        signoutButton.style.display = "block";
        listUpcomingEvents();
      } else {
        authorizeButton.style.display = "block";
        signoutButton.style.display = "none";
      }
    }

    /**
     *  Sign in the user upon button click.
     */
    function handleAuthClick(event) {
      gapi.auth2.getAuthInstance().signIn();
    }

    /**
     *  Sign out the user upon button click.
     */
    function handleSignoutClick(event) {
      gapi.auth2.getAuthInstance().signOut();
    }

    /**
     * Append a pre element to the body containing the given message
     * as its text node. Used to display the results of the API call.
     *
     * @param {string} message Text to be placed in pre element.
     */
    function appendPre(message) {
      var pre = document.getElementById("content");
      var textContent = document.createTextNode(message + "\n");
      pre.appendChild(textContent);
    }

    /**
     * Print the summary and start datetime/date of the next ten events in
     * the authorized user's calendar. If no events are found an
     * appropriate message is printed.
     */
    function listUpcomingEvents() {
      gapi.client.calendar.events
        .list({
          calendarId: "primary",
          timeMin: new Date().toISOString(),
          showDeleted: false,
          singleEvents: true,
          maxResults: 10,
          orderBy: "startTime"
        })
        .then(function(response) {
          var events = response.result.items;
          appendPre("Upcoming events:");

          if (events.length > 0) {
            for (i = 0; i < events.length; i++) {
              var event = events[i];
              var when = event.start.dateTime;
              if (!when) {
                when = event.start.date;
              }
              appendPre(event.summary + " (" + when + ")");
            }
          } else {
            appendPre("No upcoming events found.");
          }
        });
    }

    handleClientLoad();
  },

  methods: {
    moment,
    momentUTC: date => moment.utc(date),
    store() {
      this.isLoading = true;
      axios
        .post("/directory/calendar", {
          ...this.model,
          date: this.model.start,
          all_day: this.model.allDay ? 1 : 0
        })
        .then(({ data: { model } }) => {
          if (model.date.substr(0, 10) in this.events) {
            this.events[model.date.substr(0, 10)].push({
              ...model,
              allDay: model.all_day,
              start: model.date,
              editable: true
            });
          } else {
            this.events[model.date.substr(0, 10)] = [
              {
                ...model,
                allDay: model.all_day,
                start: model.date,
                editable: true
              }
            ];
          }
          this.isLoading = false;
          this.resetForm();
          this.calendar.refetchEvents();
        })
        .catch(error => {
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      axios
        .put("/directory/calendar/" + this.currentId.toString(), {
          ...this.model,
          date: this.model.start,
          all_day: this.model.allDay ? 1 : 0
        })
        .then(({ data: { model } }) => {
          this.events[model.date.substr(0, 10)][this.currentIndex] = {
            ...model,
            allDay: model.all_day,
            start: model.date,
            editable: true
          };
          this.isLoading = false;
          this.calendar.refetchEvents();
          this.resetForm();
        })
        .catch(error => {
          this.isLoading = false;
        });
    },
    dele() {
      this.isLoading = true;
      axios
        .delete("/directory/calendar/" + this.currentId.toString())
        .then(response => {
          this.events[this.model.start.substr(0, 10)].splice(
            this.currentIndex,
            1
          );
          this.resetForm();
          this.calendar.refetchEvents();
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
        });
    },
    editEvent(event, index) {
      if (event.editable === true) {
        this.model.start = event.start;
        this.model.title = event.title;
        this.model.notes = event.notes;
        this.model.allDay = event.allDay;
        this.currentIndex = index;
        this.currentId = event.id;
        this.mode = "edit";
      }
    },
    resetForm() {
      this.model.title = "";
      this.model.notes = "";
      this.model.allDay = false;
      this.mode = "new";
    }
  }
};
</script>

<style>
.fc-button {
  border-radius: 15px;
  background-color: #2c5fa1;
  border-color: #2c5fa1;
}

.fc-unthemed td.fc-today {
  background: #aed0fc;
}
</style>