<template>
  <div>
    <div v-for="module in modules" :key="module.id" class="uk-margin-small-bottom">
      <div>
        <span class="uk-text-bold">{{ module.name }}</span>
        <span
          v-if="module.actions.length !== 0"
        >- {{ module.actions.map(action => action.name).join(', ') }}</span>
      </div>
      <modules-description :modules="module.children" class="uk-margin-left" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModulesDescription",

  props: ["modules"]
};
</script>