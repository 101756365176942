<template>
  <div>
    <div class="uk-text-large">Historial</div>

    <div class="uk-flex uk-flex-center">
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          class="uk-input uk-border-rounded uk-text-center"
          v-model="filterFrom"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
      <div class="uk-padding-small uk-width-medium">
        <flat-pickr
          class="uk-input uk-border-rounded uk-text-center"
          v-model="filterTo"
          :config="datepickerConfig"
          :disabled="isLoading"
        ></flat-pickr>
      </div>
    </div>
    <div class="uk-text-center uk-margin-small-top">
      <select
        class="uk-select uk-width-medium uk-border-rounded"
        v-model="selectedUser"
        :disabled="isFetching"
      >
        <option disabled>Usuario</option>
        <option :value="null">Todos los usuarios</option>
        <option
          v-for="user in payload.users"
          :key="user.id"
          :value="user.id"
        >{{ user.first_name }} {{ user.last_name }}</option>
      </select>
    </div>
    <div class="uk-text-center uk-margin-small-top">
      <select
        class="uk-select uk-width-medium uk-border-rounded"
        v-model="selectedEntity"
        :disabled="isFetching"
      >
        <option disabled>Entidades</option>
        <option :value="null">Todas las entidades</option>
        <option
          v-for="entity in payload.entities"
          :key="entity"
          :value="entity"
        >{{ translate(entity) }}</option>
      </select>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <div>
          <!-- <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input search-input-animation"
            type="search"
            placeholder="Buscar registro..."
            v-model="auditFilter"
          />
          </form>-->
        </div>
        <pagination-buttons
          :prev-page-url="audits.prev_page_url"
          :is-fetching="isFetching"
          :current-page="audits.current_page"
          :last-page="audits.last_page"
          :next-page-url="audits.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th>Usuario</th>
            <th class="uk-text-center">Acción</th>
            <th>Entidad</th>
            <th>Cambios</th>
            <th class="uk-text-center">Constancia sin firmar</th>
            <th class="uk-text-center">Constancia firmada</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="audit in audits.data" :key="audit.id">
            <td width="15%">
              <div v-if="audit.user_type === 'App\\Models\\UserRole'">
                <div>Administrador:</div>
                <div>{{ audit.user.user.first_name }} {{ audit.user.user.last_name }}</div>
              </div>
              <div v-if="audit.user_type === 'App\\Models\\User'">
                <div>Colaborador:</div>
                <div>{{ audit.user.first_name }} {{ audit.user.last_name }}</div>
              </div>
            </td>
            <td
              width="10%"
              :class="{
            'uk-text-primary': audit.event === 'updated',
            'uk-text-secondary': audit.event === 'created',
            'uk-text-danger': audit.event === 'deleted',
            'uk-text-warning': audit.event === 'restored'
          }"
            >
              <div class="uk-text-center">
                <span uk-icon="pencil" v-if="audit.event === 'updated'"></span>
                <span uk-icon="plus" v-if="audit.event === 'created'"></span>
                <span uk-icon="close" v-if="audit.event === 'deleted'"></span>
                <span uk-icon="history" v-if="audit.event === 'restored'"></span>
              </div>
              <div class="uk-text-center">{{ translate(audit.event) }}</div>
              <div v-if="audit.batch">({{ audit.batch.name }})</div>
            </td>
            <td width="15%">{{ translate(audit.auditable_type) }}: {{ audit.significantName }}</td>
            <td width="40%">
              <div v-if="audit.event === 'updated'">
                <div
                  v-for="field in Object.keys(audit.new_values).filter(field => field !== 'updated_at' && field !== 'created_at' && field !== 'id')"
                  :key="field"
                  class="uk-flex"
                >
                  <div class="uk-width-1-6">{{ translate(field) }}</div>
                  <div
                    class="uk-width-1-3 uk-text-right"
                  >{{ formatField(field, audit, audit.old_values[field]) }}</div>
                  <div class="uk-width-1-6 uk-text-center">></div>
                  <div class="uk-width-1-3">{{ formatField(field, audit, audit.new_values[field]) }}</div>
                </div>
              </div>
              <div v-if="audit.event === 'created'">
                <div
                  v-for="field in Object.keys(audit.new_values).filter(field => field !== 'updated_at' && field !== 'created_at' && field !== 'id')"
                  :key="field"
                  class="uk-flex"
                >
                  <div class="uk-width-1-2">{{ translate(field) }}</div>
                  <div class="uk-width-1-2">{{ formatField(field, audit, audit.new_values[field]) }}</div>
                </div>
              </div>
              <div v-if="audit.event === 'deleted'">-</div>
            </td>
            <td width="5%" class="uk-text-center">
              <a
                v-if="audit.unsigned_record"
                :href="audit.unsigned_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
            <td width="5%" class="uk-text-center">
              <a
                v-if="audit.signed_record"
                :href="audit.signed_record.path"
                target="”_blank”"
                uk-icon="cloud-download"
              ></a>
            </td>
            <td width="10%">{{ formatDatetime(audit.created_at) }}</td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="bottom-divider uk-hidden@s" v-for="audit in audits.data" :key="audit.id">
      <div class="uk-flex uk-flex-between">
        <div>
          <a :href="'/directory/general/' + audit.id.toString()" class="link-underline"><span class="uk-text-bold">{{ audit.business_relation.insured.first_name }} {{ audit.business_relation.insured.last_name }}</span></a>
          <span v-if="audit.business_relation">- {{ audit.business_relation.title }}</span>
        </div>
        <div>{{ toCurrency(audit.amount) }}</div>
      </div>
      <div class="uk-flex uk-flex-between">
        <div>
          <span v-if="audit.type">{{ audit.type.name }}</span>
        </div>
        <div>
          <span v-if="audit.application_date">{{ formatDate(audit.application_date) }}</span>
        </div>
      </div>
      <div>
        {{ audit.reason }}
      </div>
      <div class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top">
        <button class="uk-button uk-width-1-2 uk-button-primary" href="#edit-audit-modal" uk-toggle @click="setEditMode(audit)" uk-icon="pencil"></button>
        <button class="uk-button uk-width-1-2 uk-button-danger" href="#confirm-deletion-modal" uk-toggle @click="setEditMode(audit)" uk-icon="trash"></button>
      </div>
      </div>-->
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <a
            class="uk-button uk-border-rounded uk-button-secondary"
            href="/payroll/audits/template"
            target="”_blank”"
          >Descargar plantilla de importación</a>
        </div>
        <div class="uk-margin uk-text-center">
          <div uk-form-custom>
            <input name="file" type="file" @change="handleImportFile($event)" />
            <button class="uk-button uk-border-rounded uk-button-secondary">Importar</button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <a
            href="/payroll/audits/bulk"
            class="uk-button uk-border-rounded uk-button-secondary"
          >Exportar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import xlsx from "xlsx";
import translations from "../../translations";

export default {
  name: "PayrollAudits",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        insured_business_id: "",
        reason: "",
        amount: "",
        application_date: "",
        audit_type_id: "",
        file: "",
        filename: "",
        recurrent: false,
        recurrent_quantity: "",
        recurrent_frequency: ""
      },
      itemsPerPage: 20,
      activeAuditId: "",
      activeAudit: "",
      isLoading: false,
      isUpdating: false,
      isFetching: false,
      mode: "new",
      auditFilter: "",
      audits: {},
      auditFilterTimer: null,
      selectedUser: null,
      selectedEntity: null,
      filterFrom: this.payload.filterFrom,
      filterTo: this.payload.filterTo,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  mounted() {
    this.audits = this.payload.audits;
    this.audits.data = this.audits.data.map(audit => ({
      ...audit,
      new_values: JSON.parse(audit.new_values),
      old_values: JSON.parse(audit.old_values)
    }));
  },

  methods: {
    moment,
    formatField(field, audit, value) {
      if (field in translations) {
        switch (translations[field].type) {
          case "boolean":
            return value === 1 ? "Sí" : "No";
          case "date":
            return this.formatDate(value);
          case "significantName":
            return audit.significantName;
          default:
            return value;
        }
      } else {
        return value;
      }
    },
    setNewMode() {
      this.mode = "new";
      this.model.insured_business_id = "";
      this.model.reason = "";
      this.model.amount = "";
      this.model.application_date = "";
      this.model.audit_type_id = "";
      this.model.file = "";
      this.model.filename = "";
    },
    setEditMode(audit) {
      this.mode = "edit";
      this.activeAudit = audit;
      this.activeAuditId = audit.id;
      this.model.insured_business_id = audit.insured_business_id;
      this.model.reason = audit.reason;
      this.model.amount = audit.amount;
      this.model.application_date = audit.application_date;
      this.model.audit_type_id = audit.audit_type_id;
      this.model.file = "";
      this.model.filename = "";
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.auditFilter && !url.includes("&query")) {
        url = url + "&query=" + this.auditFilter;
      }
      url =
        url +
        "&items=" +
        this.itemsPerPage +
        "&entity=" +
        this.selectedEntity +
        "&user=" +
        this.selectedUser +
        "&from=" +
        this.filterFrom +
        "&to=" +
        this.filterTo;
      axios
        .get(url)
        .then(({ data: { audits } }) => {
          audits.data = audits.data.map(audit => ({
            ...audit,
            new_values: JSON.parse(audit.new_values),
            old_values: JSON.parse(audit.old_values)
          }));
          this.audits = audits;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.auditFilterTimer) {
        clearTimeout(this.auditFilterTimer);
      }
      this.auditFilterTimer = setTimeout(() => {
        let urlSearchString = new URL(window.location.href).search;

        this.fetchPage(
          window.location.pathname +
            urlSearchString +
            (urlSearchString == "" ? "?" : "&") +
            "dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.auditFilterTimer = null;
      }, 500);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.audits.current_page.toString() +
            "&items=" +
            this.itemsPerPage +
            "&entity=" +
            this.selectedEntity +
            "&user=" +
            this.selectedUser +
            "&from=" +
            this.filterFrom +
            "&to=" +
            this.filterTo
        )
        .then(({ data: { audits } }) => {
          audits.data = audits.data.map(audit => ({
            ...audit,
            new_values: JSON.parse(audit.new_values),
            old_values: JSON.parse(audit.old_values)
          }));
          this.audits = audits;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      let formData = new FormData();
      for (const key in this.model) {
        if (this.model[key] !== "") {
          if (typeof this.model[key] === "boolean") {
            formData.append(key, this.model[key] === true ? 1 : 0);
          } else {
            formData.append(key, this.model[key]);
          }
        }
      }
      axios
        .post("/payroll/audits", formData)
        .then(response => {
          this.refetchPage(() => {
            this.assignAuditCard = false;
            this.model.reason = "";
            this.model.amount = "";
            this.model.application_date = "";
            this.model.audit_type_id = "";
            this.model.file = "";
            this.model.filename = "";
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Registro asignado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
            UIkit.modal("#edit-audit-modal").hide();
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      let formData = new FormData();
      for (const key in this.model) {
        if (this.model[key] !== "") {
          if (typeof this.model[key] === "boolean") {
            formData.append(key, this.model[key] === true ? 1 : 0);
          } else {
            formData.append(key, this.model[key]);
          }
        }
      }
      formData.append("_method", "PUT");
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando registro...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/payroll/audits/" + this.activeAuditId.toString(), formData)
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-audit-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Registro actualizado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteAudit() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando registro...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete("/payroll/audits/" + this.activeAuditId.toString(), this.model)
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Registro eliminado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteAuditGroup() {
      axios
        .delete("/payroll/audits/bulk/" + this.activeAudit.recurrent_group)
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Registros eliminado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleImportFile(e) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        let newAudits = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .slice(3);

        newAudits = newAudits.map(audit => {
          audit.application_date = moment(audit.application_date).format(
            "YYYY-MM-DD"
          );
          return audit;
        });

        axios
          .post("/payroll/audits/bulk", newAudits)
          .then(({ data }) => {
            this.refetchPage(() => {
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  data.count +
                  " registros creados</div>",
                { status: "success", timeout: 3000 }
              );
            });
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      reader.readAsBinaryString(f);
    },

    handleFileChange(file) {
      this.model.file = file;
      this.model.filename = file.name;
    },

    resetForm() {
      this.model.insured_business_id = "";
      this.model.reason = "";
      this.model.amount = "";
      this.model.application_date = "";
      this.model.audit_type_id = "";
    }
  },

  watch: {
    auditFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    selectedUser: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    selectedEntity: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    filterFrom: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    filterTo: function() {
      this.isLoading = true;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.user-logo {
  color: white;
  background: #2c5fa1;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  letter-spacing: -3px;
}

.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}

.avatar {
  width: 80px;
  max-width: 80px;
}

.uk-badge {
  margin: 1px;
}

.v-select.searchable .dropdown-toggle {
  border-radius: 15px;
  background: white;
  border: none;
}

.v-select .selected-tag {
  border-radius: 15px;
  background-color: #2c5fa1;
  color: white;
  padding: 5px 10px;
}

.bottom-divider {
  border-bottom: solid 1px;
  padding: 10px 0;
}
</style>