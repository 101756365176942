<template>
  <div>
    <div class="uk-flex uk-flex-middle uk-flex-between">
      <div>
        <label v-if="payrollConfigurationMode === 'edit'">
          <input :name="duskSelect" class="uk-checkbox" type="checkbox" v-model="field.active" />
          Habilitar configuración de {{ proportionalFieldTranslations(field.slug) }}
        </label>
        <div v-else>{{ proportionalFieldTranslations(field.slug) }}</div>
        <div>
          Proporciones de la división:
          <span
            class="light-weight"
          >{{ activeInsuredDivisionProportions(fieldIndex) ? JSON.parse(activeInsuredDivisionProportions(fieldIndex).proportions).map(percentage => percentage + '%').join('-') : 'Sin configurar' }}</span>
        </div>
        <div>
          Proporciones de la empresa:
          <span
            class="light-weight"
          >{{ activeInsuredBusinessProportions(fieldIndex) ? JSON.parse(activeInsuredBusinessProportions(fieldIndex).proportions).map(percentage => percentage + '%').join('-') : 'Sin configurar' }}</span>
        </div>
        <div>
          Proporciones estándar:
          <span class="light-weight">50%-50%</span>
        </div>
      </div>
      <div class="uk-flex" v-if="field.active">
        <div
          v-for="(proportion, proportionIndex) in field.proportions"
          class="uk-margin-right"
          :key="proportionIndex"
        >
          <div class="uk-text-center">Porcentaje en período {{ proportionIndex + 1 }}</div>
          <input
            v-if="payrollConfigurationMode === 'edit'"
            class="uk-input uk-border-rounded uk-background-muted uk-text-center"
            type="text"
            v-currencyOnly
            :name="duskSelect + '-' + proportionIndex"
            v-model="field.proportions[proportionIndex]"
          />
          <div v-else class="uk-text-center light-weight">
            {{
            activeInsured
            .business_relation
            .payroll
            .proportions
            .filter(proportion =>
            proportion.payroll_frequency_id === 2 &&
            proportion.proportionable_field_id == fieldIndex
            )[0] ?
            JSON.parse(
            activeInsured
            .business_relation
            .payroll
            .proportions
            .filter(proportion =>
            proportion.payroll_frequency_id === 2 &&
            proportion.proportionable_field_id == fieldIndex
            )[0]
            .proportions
            )[proportionIndex] + '%' :
            'Sin configurar'
            }}
          </div>
        </div>
      </div>
      <div>
        <div>Total</div>
        <div
          :class="{
          'uk-text-danger': field.proportions.reduce((accum, proportion) => accum + parseFloat(proportion), 0) != 100,
          'uk-text-success': field.proportions.reduce((accum, proportion) => accum + parseFloat(proportion), 0) == 100
        }"
          v-if="payrollConfigurationMode === 'edit'"
        >{{ field.proportions.reduce((accum, proportion) => accum + parseFloat(proportion), 0) }}%</div>
        <div v-else class="uk-text-success">100%</div>
      </div>
    </div>
    <hr class="uk-divider-icon" v-if="fieldIndex !== payrollConfiguration.fields.length - 1" />
  </div>
</template>

<script>
export default {
  name: "FieldProportions",

  props: [
    "fieldIndex",
    "field",
    "activeInsured",
    "payrollConfigurationMode",
    "payrollConfiguration",
    "duskSelect"
  ],

  methods: {
    proportionalFieldTranslations(slug) {
      switch (slug) {
        case "base":
          return "Pago Base";
        case "bonuses":
          return "Bonos Fijos";
        case "incentive":
          return "Bono Incentivo";
        case "igss":
          return "IGSS Laboral";
        case "isr":
          return "Retención ISR";
        default:
          return "";
      }
    },
    activeInsuredDivisionProportions(fieldId) {
      return this.activeInsured.division.proportions.filter(
        proportion => proportion.proportionable_field_id === parseInt(fieldId)
      )[0];
    },
    activeInsuredBusinessProportions(fieldId) {
      return this.activeInsured.business_relation.business.proportions.filter(
        proportion => proportion.proportionable_field_id === parseInt(fieldId)
      )[0];
    }
  }
};
</script>