<template>
  <div class="uk-margin-bottom">
    <div class="uk-text-center uk-margin-large-top">
      <img src="/images/logo.png" width="300px" alt />
    </div>

    <div class="uk-text-center uk-margin-top uk-text-large">Seleccione la empresa</div>

    <div class="uk-container uk-flex uk-flex-around@s uk-flex-center uk-flex-wrap uk-margin-top">
      <a
        :dusk="'business-id-' + business.id"
        class="uk-width-2-5@s uk-width-4-5 uk-link-reset uk-margin-small-top"
        :href="'/auth/set-business-id?businessId=' + business.id.toString()"
        v-for="business in businesses"
        :key="business.id"
      >
        <div
          :id="'business-' + business.commercial_name + '-' + business.legal_name"
          class="uk-card uk-card-default uk-card-body uk-border-rounded business-card uk-margin-remove uk-width-1-1"
        >
          <div class="center-cell">
            <div class="uk-text-center" v-if="business.avatar">
              <img
                :id="'business-image-'+ business.commercial_name + '-' + business.legal_name"
                :name="'business-image-' + business.commercial_name + '-' + business.legal_name"
                :src="business.avatar"
                alt
                width="100px"
              />
            </div>
            <div>{{ business.commercial_name }}</div>
          </div>
        </div>
      </a>
    </div>

    <div class="uk-text-center uk-margin-top">
      <button
        dusk="create"
        class="uk-button uk-border-rounded uk-button-secondary"
        href="#new-business-modal"
        uk-toggle
      >Crear empresa</button>
    </div>
    <div class="uk-text-center">
      <a
        dusk="close-session-select-business"
        id="close-session-select-business"
        class="uk-button uk-link-reset uk-border-rounded uk-button-muted"
        href="/logout"
      >Cerrar sesión</a>
    </div>

    <div id="new-business-modal" class="uk-flex-top" uk-modal>
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button dusk="close-modal" class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">Crear empresa</div>
        <div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">Nombre comercial</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span
                  class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                  :class="{ 'valid-check': commercialNameIsValid }"
                  uk-icon="check"
                ></span>
                <input
                  name="commercial_name"
                  class="uk-input uk-border-rounded"
                  
                  type="text"
                  v-model="model.commercial_name"
                />
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">Nombre legal</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span
                  class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                  :class="{ 'valid-check': legalNameIsValid }"
                  uk-icon="check"
                ></span>
                <input
                  name="legal_name"
                  class="uk-input uk-border-rounded"
                  
                  type="text"
                  v-model="model.legal_name"
                />
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">Industria</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span
                  class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                  :class="{ 'valid-check': businessIndustryIsValid }"
                  uk-icon="check"
                ></span>
                <select
                  name="industry_id"
                  class="uk-select uk-border-rounded"
                  v-model="model.industry_id"
                >
                  <option
                    v-for="industry in payload.industries"
                    :key="industry.id"
                    :value="industry.id"
                  >{{ industry.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">Giro del negocio</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span
                  class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                  :class="{ 'valid-check': businessCoreIsValid }"
                  uk-icon="check"
                ></span>
                <input
                  name="business_core"
                  class="uk-input uk-border-rounded"
                  
                  type="text"
                  v-model="model.business_core"
                />
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">Dirección</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span
                  class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                  :class="{ 'valid-check': addressIsValid }"
                  uk-icon="check"
                ></span>
                <input
                  name="address"
                  class="uk-input uk-border-rounded"
                  
                  type="text"
                  v-model="model.address"
                />
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">NIT</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span
                  class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                  :class="{ 'valid-check': nitIsValid }"
                  uk-icon="check"
                ></span>
                <input
                  name="nit"
                  class="uk-input uk-border-rounded"
                  
                  type="text"
                  v-model="model.nit"
                />
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">Sitio web</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span
                  class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                  :class="{ 'valid-check': websiteIsValid }"
                  uk-icon="check"
                ></span>
                <input
                  name="website"
                  class="uk-input uk-border-rounded"
                  
                  type="text"
                  v-model="model.website"
                />
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <label
              class="uk-form-label stablish_text"
              for="form-stacked-text"
            >Fecha de establecimiento</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span
                  class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                  :class="{ 'valid-check': establishedAtIsValid }"
                  uk-icon="check"
                ></span>
                <flat-pickr
                  id="established_at"
                  name="established_at"
                  class="uk-input uk-border-rounded"
                  v-model="model.established_at"
                  :config="datepickerConfig"
                ></flat-pickr>
              </div>
            </div>
          </div>
          <div class="uk-margin uk-text-center">
            <button
              dusk="create-enterprise"
              class="uk-button uk-border-rounded uk-button-secondary"
              :disabled="!businessIsValid"
              @click="createBusiness"
            >Crear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default {
  name: "AuthSelectBusiness",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      businesses: [],
      isLoading: false,
      model: {
        commercial_name: "",
        legal_name: "",
        business_core: "",
        industry_id: "",
        address: "",
        nit: "",
        website: "",
        established_at: ""
      },
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  mounted() {
    this.businesses = this.payload.businesses;
  },

  computed: {
    commercialNameIsValid() {
      return this.model.commercial_name.length > 1;
    },
    legalNameIsValid() {
      return this.model.legal_name.length > 1;
    },
    businessCoreIsValid() {
      return this.model.business_core.length > 1;
    },
    businessIndustryIsValid() {
      return this.model.industry_id !== "";
    },
    addressIsValid() {
      return this.model.address.length > 1;
    },
    nitIsValid() {
      let nd,
        add = 0;
      if ((nd = /^(\d+)\-?([\dk])$/i.exec(this.model.nit))) {
        nd[2] = nd[2].toLowerCase() == "k" ? 10 : parseInt(nd[2]);
        for (let i = 0; i < nd[1].length; i++) {
          add += ((i - nd[1].length) * -1 + 1) * nd[1][i];
        }
        return (11 - (add % 11)) % 11 == nd[2];
      } else {
        return false;
      }
    },
    websiteIsValid() {
      return this.model.website.length > 1;
    },
    establishedAtIsValid() {
      return this.model.established_at.length > 1;
    },
    businessIsValid() {
      const validations = [
        this.commercialNameIsValid,
        this.legalNameIsValid,
        this.businessCoreIsValid,
        this.businessIndustryIsValid,
        this.addressIsValid,
        this.nitIsValid,
        this.websiteIsValid,
        this.establishedAtIsValid
      ];

      return validations.every(field => field === true);
    }
  },

  methods: {
    createBusiness() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando empresa...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/profile", this.model)
        .then(({ data: { businesses } }) => {
          loader.close();
          const success = UIkit.notification(
            "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Empresa creada</div>",
            { status: "success", timeout: 3000 }
          );
          this.isLoading = false;
          UIkit.modal("#new-business-modal").hide();
          this.businesses = businesses;
          this.model = {
            commercial_name: "",
            legal_name: "",
            business_core: "",
            industry_id: "",
            address: "",
            nit: "",
            website: "",
            established_at: ""
          };
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.border-bottom {
  border-bottom: solid 1px;
}

.business-card {
  display: table;
  font-size: 35px;
  font-weight: normal;
  height: 150px;
}

.center-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .business-card {
    height: 300px;
  }
}
</style>