<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle">
      <div class="uk-text-large">
        Directorio General
        <span v-if="payload.filtertags">
          <a
            :href="removeTagLink(filtertag)"
            v-for="(filtertag, index) in payload.filtertags"
            :key="index"
          >
            <span class="uk-badge uk-padding-small" uk-close>#{{ filtertag }}</span>
          </a>
        </span>
      </div>
      <div class="uk-text-center">
        <button
          :id="'actions-colaborator'"
          :dusk="'actions-colaborator'"
          class="uk-button uk-border-rounded uk-button-primary"
          href="#import-export-modal"
          uk-toggle
          @click="resetImportInput"
        >Importar / Exportar</button>
        <button
          :id="'add-colaborator'"
          :dusk="'add-colaborator'"
          class="uk-button uk-border-rounded uk-button-secondary uk-margin"
          href="#edit-insured-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-insured')"
        >Añadir colaborador</button>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <form autocomplete="off">
            <input
              autocomplete="off"
              class="uk-search-input search-input-animation"
              type="search"
              placeholder="Buscar colaborador..."
              v-model="insuredFilter"
            />
          </form>
        </form>
        <pagination-buttons
          :prev-page-url="insureds.prev_page_url"
          :is-fetching="isFetching"
          :current-page="insureds.current_page"
          :last-page="insureds.last_page"
          :next-page-url="insureds.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th></th>
            <!-- <th @click="setSort('first_name')">Nombre <span uk-icon="triangle-down" v-if="sortBy === 'first_name' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'first_name' && sortDirection === 'desc'"></span></th>
          <th @click="setSort('type')">Contratado por <span uk-icon="triangle-down" v-if="sortBy === 'type' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'type' && sortDirection === 'desc'"></span></th>
          <th @click="setSort('status')">Estado <span uk-icon="triangle-down" v-if="sortBy === 'status' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'status' && sortDirection === 'desc'"></span></th>
          <th @click="setSort('title')">Título <span uk-icon="triangle-down" v-if="sortBy === 'title' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'title' && sortDirection === 'desc'"></span></th>
          <th @click="setSort('tags')">Tags <span uk-icon="triangle-down" v-if="sortBy === 'tags' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'tags' && sortDirection === 'desc'"></span></th>
          <th @click="setSort('businessRelation.division.name')">División <span uk-icon="triangle-down" v-if="sortBy === 'division' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'division' && sortDirection === 'desc'"></span></th>
          <th @click="setSort('location')">Localidad <span uk-icon="triangle-down" v-if="sortBy === 'location' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'location' && sortDirection === 'desc'"></span></th>
          <th @click="setSort('boss')">Responsable <span uk-icon="triangle-down" v-if="sortBy === 'boss' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'boss' && sortDirection === 'desc'"></span></th>
            <th @click="setSort('start_date')">Fecha de inicio <span uk-icon="triangle-down" v-if="sortBy === 'start_date' && sortDirection === 'asc'"></span> <span uk-icon="triangle-up" v-if="sortBy === 'start_date' && sortDirection === 'desc'"></span></th>-->
            <th>Nombre</th>
            <th>Contratado por</th>
            <th>Estado</th>
            <th>Título</th>
            <th>Tags</th>
            <th>División</th>
            <th>Localidad</th>
            <th>Responsable</th>
            <th>Fecha de inicio</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="insured in insureds.data" :key="insured.id">
            <td width="10%">
              <a
                :dusk="'insured-' + insured.id"
                :id="'insured-' + insured.id"
                :href="'/directory/general/' + insured.id.toString()"
                class="uk-link-reset"
              >
                <div
                  v-if="insured.avatar"
                  class="rounded-avatar"
                  :style="{ 'background-image': 'url(' + insured.avatar + ')' }"
                ></div>
                <div
                  class="user-logo uk-border-circle uk-padding-small uk-text-center"
                  v-else
                >{{ insured.first_name.substr(0, 1) }} {{ insured.last_name.substr(0, 1) }}</div>
              </a>
            </td>
            <td width="10%">
              <a
                :href="'/directory/general/' + insured.id.toString()"
                class="uk-text-bold uk-link-reset"
                :id="'insured-name-'+insured.id"
              >{{ insured.first_name }} {{ insured.last_name }}</a>
            </td>
            <td width="10%">
              <span
                v-if="insured.business_relation"
                :id="'insured-type-'+insured.id"
              >{{ insured.business_relation.type }}</span>
            </td>
            <td width="5%">
              <span
                v-if="insured.business_relation"
                :id="'insured-status-'+insured.id"
              >{{ insured.business_relation.status }}</span>
            </td>
            <td width="5%">
              <span
                v-if="insured.business_relation"
                :id="'insured-title-'+insured.id"
              >{{ insured.business_relation.title }}</span>
            </td>
            <td width="15%">
              <span v-if="insured.business_relation">
                <a :href="tagLink(tag)" v-for="tag in insured.business_relation.tags" :key="tag.id">
                  <span
                    :id="'tag-insured-' + insured.id + '-name-' + tag.id"
                    class="uk-badge uk-padding-small uk-margin-small-left uk-margin-small-right"
                  >#{{ tag.name.es }}</span>
                </a>
              </span>
            </td>
            <td width="5%">
              <span
                v-if="insured.division"
                :id="'insured-division-'+insured.id"
              >{{ insured.division.name }}</span>
            </td>
            <td width="5%">
              <span
                v-if="insured.business_relation"
                :id="'insured-location-'+insured.id"
              >{{ insured.business_relation.location }}</span>
            </td>
            <td width="10%">
              <span v-if="insured.business_boss">
                <a
                  :dusk="'insured-'+insured.id+'-boss-'+insured.business_boss.id"
                  :id="'insured-boss-name-'+insured.id"
                  :href="'/directory/general/' + insured.business_boss.id.toString()"
                  class="link-underline"
                >{{ insured.business_boss.first_name }} {{ insured.business_boss.last_name }}</a>
              </span>
            </td>
            <td width="15%">
              <span
                :id="'insured-start-date-'+insured.id"
                v-if="insured.business_relation"
              >{{ formatDate(insured.business_relation.start_date) }}</span>
            </td>
            <td width="10%" class="uk-text-right">
              <!-- <button class="uk-padding-remove uk-button uk-border-rounded uk-button-primary" href="#edit-insured-modal" uk-toggle @click="setEditMode(insured)" uk-icon="pencil"></button>
              <button class="uk-padding-remove uk-button uk-border-rounded uk-button-danger" href="#confirm-deletion-modal" uk-toggle @click="setEditMode(insured); fetchPendingBonuses(insured.business_relation.id)" uk-icon="minus-circle"></button>-->
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  :id="'edit-insured-' + insured.id"
                  :dusk="'edit-insured-' + insured.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                  href="#edit-insured-modal"
                  uk-toggle
                  @click="setEditMode(insured)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-insured')"
                ></button>
                <button
                  :id="'delete-insured-' + insured.id"
                  :dusk="'delete-insured-' + insured.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(insured); fetchPendingBonuses(insured.business_relation.id)"
                  uk-icon="minus-circle"
                  v-if="checkPermission('layoff-insured')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="insureds.data && insureds.data.length === 0"
        class="uk-text-center uk-margin-top"
      >No se han encontrado resultados con los filtros seleccionados.</div>

      <div class="bottom-divider uk-hidden@s" v-for="insured in insureds.data" :key="insured.id">
        <div class="uk-flex uk-flex-between">
          <div>
            <a
              :dusk="'responsive-insured-' + insured.id"
              :href="'/directory/general/' + insured.id.toString()"
              class="link-underline"
            >
              <span
                :id="'responsive-insured-name-' + insured.id"
                class="uk-text-bold"
              >{{ insured.first_name }} {{ insured.last_name }}</span>
            </a>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-title-' + insured.id"
            >- {{ insured.business_relation.title }}</span>
          </div>
          <div>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-status-' + insured.id"
            >{{ insured.business_relation.status }}</span>
          </div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span
              v-if="insured.division"
              :id="'responsive-insured-division-' + insured.id"
            >{{ insured.division.name }}</span>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-location-' + insured.id"
            >- {{ insured.business_relation.location }}</span>
          </div>
          <div>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-type-' + insured.id"
            >{{ insured.business_relation.type }}</span>
          </div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span
              v-if="insured.business_boss"
              :id="'responsive-insured-boss-name-' + insured.id"
            >Responsable: {{ insured.business_boss.first_name }} {{ insured.business_boss.last_name }}</span>
          </div>
          <div>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-start-date-' + insured.id"
            >{{ moment(insured.business_relation.start_date).format('DD/MM/YY') }}</span>
          </div>
        </div>
        <div class="uk-text-center uk-margin-small-top">
          <span v-if="insured.business_relation">
            <a
              :id="'responsive-tag-insured-' + insured.id + '-name-' + tag.id"
              :href="tagLink(tag)"
              v-for="tag in insured.business_relation.tags"
              :key="tag.id"
            >
              <span
                class="uk-badge uk-padding-small uk-margin-small-left uk-margin-small-right"
              >#{{ tag.name.es }}</span>
            </a>
          </span>
        </div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
        >
          <button
            :id="'responsive-insured-edit-' + insured.id"
            :dusk="'responsive-insured-edit-' + insured.id"
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-insured-modal"
            uk-toggle
            @click="setEditMode(insured)"
            uk-icon="pencil"
            v-if="checkPermission('edit-insured')"
          ></button>
          <button
            :id="'responsive-insured-delete-' + insured.id"
            :dusk="'responsive-insured-delete-' + insured.id"
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(insured); fetchPendingBonuses(insured.business_relation.id)"
            uk-icon="minus-circle"
            v-if="checkPermission('layoff-insured')"
          ></button>
        </div>
      </div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button dusk="close-delete-modal" class="uk-modal-close-default" type="button" uk-close></button>
        <div
          class="uk-text-large"
        >¿Está seguro que desea dar de baja al colaborador {{ this.model.first_name }} {{ this.model.last_name }}?</div>
        <div class="uk-margin">
          <label
            class="uk-form-label"
            for="form-stacked-text"
          >Seleccione el último día laborado para el proceso de baja del colaborador</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                id="termination-date-insured-validation"
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': terminationDateIsValid }"
                uk-icon="check"
              ></span>
              <flat-pickr
                id="termination-date-insured"
                class="uk-input uk-border-rounded"
                v-model="deleteModel.termination_date"
                :config="datepickerConfigTermination"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Selecciona el motivo de baja</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <v-select
                id="termination-motive-insured"
                v-model="deleteModel.type"
                class="uk-input uk-border-rounded"
                :options="payload.activityTypes"
                :reduce="type => type.id"
                :getOptionLabel="option => option.name"
              ></v-select>
            </div>
          </div>
        </div>
        <div v-if="insuredBusinessDependents.length !== 0">
          <div>{{ this.model.first_name }} {{ this.model.last_name }} actualmente es responsable de</div>
          <ul class="uk-list uk-list-bullet">
            <li
              v-for="(dependent, index) in insuredBusinessDependents"
              :key="index"
            >{{ dependent.insured.first_name }} {{ dependent.insured.last_name }}</li>
          </ul>
          <div class="uk-margin">
            <label
              class="uk-form-label"
              for="form-stacked-text"
            >Selecciona el colaborador al que deseas transferir la responsabilidad</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <v-select
                  id="responsible-delegation"
                  v-model="deleteModel.boss_transfer"
                  class="uk-input uk-border-rounded"
                  :options="transferableInsureds"
                  :reduce="insured => insured.id"
                  :getOptionLabel="option => option.dpi + ' - ' + option.first_name + ' ' + option.last_name"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Notas</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <textarea
                name="delete-notes"
                v-model="deleteModel.notes"
                rows="5"
                class="uk-textarea uk-border-rounded"
                type="text"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="uk-text-center">
          <div uk-spinner v-if="isLoading"></div>
        </div>
        <div v-if="!isLoading && pendingBonuses.length !== 0">
          <div>Consolidar bonos y descuentos pendientes en pago de liquidación, los bonos no seleccionados serán eliminados.</div>
          <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
            <thead>
              <tr>
                <th>
                  <input class="uk-checkbox" type="checkbox" v-model="allPendingBonusesSelected" />
                </th>
                <th>Tipo de bono</th>
                <th>Razón</th>
                <th>Monto</th>
                <th>Fecha de aplicación</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="bonus in pendingBonuses" :key="bonus.id">
                <td width="5%">
                  <label>
                    <input class="uk-checkbox" type="checkbox" v-model="bonus.selected" />
                  </label>
                </td>
                <td width="35%">
                  <span v-if="bonus.type">{{ bonus.type.name }}</span>
                </td>
                <td width="20%">
                  <span v-if="bonus.reason">{{ bonus.reason }}</span>
                </td>
                <td width="20%">
                  <span v-if="bonus.amount">{{ toCurrency(bonus.amount) }}</span>
                </td>
                <td width="20%">
                  <span v-if="bonus.application_date">{{ formatDate(bonus.application_date) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            dusk="delete-insured-button"
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteInsured"
            :disabled="isLoading || !layOffIsValid"
          >Dar de baja al colaborador</button>
        </div>
      </div>
    </div>

    <div id="edit-insured-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button dusk="close-edit-modal" class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          colaborador
        </div>
        <ul
          class="uk-subnav uk-subnav-pill uk-flex-center"
          id="add-employee-switcher"
          uk-switcher="animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium"
        >
          <li>
            <a
              dusk="personal-info-toogle"
              href="#"
              id="personalInfoToogle"
              class="uk-border-rounded"
            >Información personal</a>
          </li>
          <li>
            <a
              dusk="laboral-info-toogle"
              href="#"
              id="laboralInfoToogle"
              class="uk-border-rounded"
            >Información laboral</a>
          </li>
        </ul>
        <div class="uk-text-center">Los campos marcados con asterisco (*) son requeridos.</div>
        <ul class="uk-switcher uk-margin">
          <li>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Nombres*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="name-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': firstNameIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="name-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.first_name"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Apellidos*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="last-name-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': lastNameIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="last-name-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.last_name"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Teléfono*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="phone-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': phoneIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="phone-insured"
                      autocomplete="off"
                      v-numericOnly
                      maxlength="8"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.phone"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Género*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="genre-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': genderIsValid }"
                    uk-icon="check"
                  ></span>
                  <select
                    name="genre-insured"
                    class="uk-select uk-border-rounded"
                    v-model="model.gender"
                  >
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Fecha de nacimiento*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="birthdate-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': birthdateIsValid }"
                    uk-icon="check"
                  ></span>
                  <flat-pickr
                    id="birthdate-insured"
                    class="uk-input uk-border-rounded"
                    v-model="model.birthdate"
                    :config="datepickerConfig"
                  ></flat-pickr>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">DPI*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="dpi-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': dpiIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="dpi-insured"
                      autocomplete="off"
                      v-numericOnly
                      maxlength="13"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.dpi"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">NIT</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="nit-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': nitIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="nit-insured"
                      autocomplete="off"
                      v-numericOnly
                      maxlength="13"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.nit"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Estado civil*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="civil-state-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': civilStateIsValid }"
                    uk-icon="check"
                  ></span>
                  <select
                    name="civil-state-insured"
                    class="uk-select uk-border-rounded"
                    v-model="model.civil_state"
                  >
                    <option value="S">Soltero</option>
                    <option value="M">Casado</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Nombre de contacto de emergencia</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="emergency-contact-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': emergencyContactNameIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="emergency-contact-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.emergency_contact_name"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label
                class="uk-form-label"
                for="form-stacked-text"
              >Teléfono de contacto de emergencia</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="emergency-phone-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': emergencyContactPhoneIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="emergency-phone-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.emergency_contact_phone"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label
                class="uk-form-label"
                for="form-stacked-text"
              >Parentesco de contacto de emergencia</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="emergency-relation-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': emergencyContactRelationshipIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="emergency-relation-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.emergency_contact_relationship"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="next">
                <button class="uk-button uk-border-rounded uk-button-secondary">Siguiente</button>
              </a>
            </div>
          </li>
          <li>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Email</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="email-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': emailIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="email-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.email"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Contratado por*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="job-type-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': typeIsValid }"
                    uk-icon="check"
                  ></span>
                  <select
                    name="job-type-insured"
                    class="uk-select uk-border-rounded"
                    id="typeSelector"
                    v-model="model.type"
                  >
                    <option>Tiempo completo</option>
                    <option>Medio tiempo</option>
                    <option>Por día</option>
                    <option>Por trabajo específico</option>
                    <option>Por servicios</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Estado*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="status-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': statusIsValid }"
                    uk-icon="check"
                  ></span>
                  <select
                    name="status-insured"
                    class="uk-select uk-border-rounded"
                    v-model="model.status"
                  >
                    <option>Activo</option>
                    <option>Inactivo</option>
                    <option>Suspendido</option>
                    <option>En prueba</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Título*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="title-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': titleIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="title-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.title"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Tags</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <v-select
                    id="tag-select"
                    class="uk-border-rounded"
                    multiple
                    taggable
                    v-model="model.tags"
                    :options="payload.tags.map(tag => tag.name.es)"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">División*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="division-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': businessDivisionIdIsValid }"
                    uk-icon="check"
                  ></span>
                  <select
                    name="division-insured"
                    id="divisionSelect"
                    class="uk-select uk-border-rounded"
                    v-model="model.business_division_id"
                  >
                    <option
                      v-for="division in payload.divisions"
                      :key="division.id"
                      :value="division.id"
                    >{{ division.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Localidad*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="location-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': locationIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="location-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.location"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Responsable</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <v-select
                    id="responsibleSelect"
                    v-model="model.boss"
                    :key="model.boss"
                    class="uk-input uk-border-rounded"
                    :options="allInsureds.filter(insured => insured.id !== activeInsuredId)"
                    :reduce="insured => insured.id"
                    :getOptionLabel="option => option.dpi + ' - ' + option.first_name + ' ' + option.last_name"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Número de afiliación del IGSS</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="iggs-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': igssIsValid }"
                    uk-icon="check"
                  ></span>
                  <form autocomplete="off">
                    <input
                      name="iggs-insured"
                      autocomplete="off"
                      class="uk-input uk-border-rounded"
                      type="text"
                      v-model="model.igss"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text">Fecha de inicio*</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <span
                    id="startdate-insured-validation"
                    class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                    :class="{ 'valid-check': startDateIsValid }"
                    uk-icon="check"
                  ></span>
                  <flat-pickr
                    id="startdate-insured"
                    class="uk-input uk-border-rounded"
                    v-model="model.start_date"
                    :config="datepickerConfig"
                  ></flat-pickr>
                </div>
              </div>
            </div>
            <div class="uk-margin uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="previous">
                <button class="uk-button uk-border-rounded uk-button-secondary">Atrás</button>
              </a>
              <button
                dusk="create-insured"
                class="uk-button uk-border-rounded uk-button-secondary"
                @click="store"
                :disabled="isLoading || !formIsValid"
                v-if="mode === 'new'"
              >Crear colaborador</button>
              <button
                dusk="update-insured"
                class="uk-button uk-border-rounded uk-button-secondary"
                @click="update"
                :disabled="isLoading || !formIsValid"
                v-else
              >Actualizar colaborador</button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <a
            class="uk-button uk-border-rounded uk-button-secondary"
            href="/directory/general/template"
            target="”_blank”"
          >Descargar plantilla de importación</a>
        </div>
        <div class="uk-margin uk-text-center" v-if="checkPermission('import-insureds')">
          <div uk-form-custom>
            <form autocomplete="off">
              <input
                id="import-file"
                autocomplete="off"
                name="file"
                type="file"
                @change="handleFile($event)"
              />
            </form>
            <button
              class="uk-button uk-border-rounded uk-button-secondary"
              :disabled="isFetching || isLoading"
            >
              <span v-if="isFetching || isLoading" uk-spinner="ratio: 1"></span>
              <span v-else>Importar</span>
            </button>
          </div>

          <div v-if="bulkError" class="uk-text-left uk-margin-small-top">
            La plantilla importada no ha sido procesada por los siguientes errores:
            <div
              v-for="(error, index) in Object.keys(bulkError)"
              :key="index"
              class="uk-margin-small"
            >
              <span uk-icon="icon: warning; ratio: 0.8"></span>
              Fila: {{ parseInt(error.split(".")[0]) + 4 }}, Columna: {{ error.split(".")[1] }}
              <div
                v-for="(errorDetail, index) in bulkError[error]"
                :key="index"
                class="uk-margin-left"
              >{{ errorDetail.replace(new RegExp(error.split(".")[0] + '.', 'g'), (parseInt(error.split(".")[0]) + 4).toString() + '.') }}</div>
            </div>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center" v-if="checkPermission('export-insureds')">
          <a
            href="/directory/general/bulk"
            class="uk-button uk-border-rounded uk-button-secondary"
          >Exportar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import xlsx from "xlsx";

export default {
  name: "DirectoryGeneral",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    const today = new Date();
    const formattedToday =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    return {
      model: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        gender: "",
        birthdate: "",
        dpi: "",
        nit: "",
        civil_state: "",
        type: "",
        status: "",
        title: "",
        tags: "",
        business_division_id: "",
        location: "",
        boss: "",
        igss: "",
        start_date: "",
        emergency_contact_name: "",
        emergency_contact_phone: "",
        emergency_contact_relationship: ""
      },
      deleteModel: {
        termination_date: formattedToday,
        boss_transfer: "",
        type: "",
        notes: ""
      },
      allInsureds: [],
      pendingBonuses: [],
      allPendingBonusesSelected: true,
      itemsPerPage: 20,
      activeInsuredId: null,
      insuredBusinessDependents: [],
      isLoading: false,
      isFetching: false,
      mode: "new",
      insuredFilter: "",
      sortBy: "first_name",
      sortDirection: "asc",
      insureds: {},
      insuredFilterTimer: null,
      bulkError: null,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      },
      datepickerConfigTermination: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y",
        maxDate: moment()
          .add(11, "months")
          .format("YYYY-MM-DD")
      }
    };
  },

  mounted() {
    this.insureds = this.payload.insureds;
    this.allInsureds = [
      {
        first_name: "No asignar",
        last_name: "",
        dpi: "",
        id: ""
      },
      ...this.payload.allInsureds
    ];
  },

  computed: {
    firstNameIsValid() {
      return this.model.first_name.length > 1;
    },
    lastNameIsValid() {
      return this.model.last_name.length > 1;
    },
    phoneIsValid() {
      return this.model.phone.length === 8;
    },
    emailIsValid() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.model.email) || this.model.email === "";
    },
    genderIsValid() {
      return this.model.gender;
    },
    birthdateIsValid() {
      return this.model.birthdate;
    },
    dpiIsValid() {
      return this.cuiIsValid(this.model.dpi);
    },
    nitIsValid() {
      if (this.model.nit === "") {
        return true;
      } else {
        let nd,
          add = 0;
        if ((nd = /^(\d+)\-?([\dk])$/i.exec(this.model.nit))) {
          nd[2] = nd[2].toLowerCase() == "k" ? 10 : parseInt(nd[2]);
          for (let i = 0; i < nd[1].length; i++) {
            add += ((i - nd[1].length) * -1 + 1) * nd[1][i];
          }
          return (11 - (add % 11)) % 11 == nd[2];
        } else {
          return false;
        }
      }
    },
    civilStateIsValid() {
      return this.model.civil_state;
    },
    typeIsValid() {
      return this.model.type;
    },
    statusIsValid() {
      return this.model.status;
    },
    titleIsValid() {
      return this.model.title;
    },
    businessDivisionIdIsValid() {
      return this.model.business_division_id;
    },
    locationIsValid() {
      return this.model.location;
    },
    bossIsValid() {
      return this.model.boss || this.model.boss === "";
    },
    igssIsValid() {
      return this.model.igss === "" || this.model.igss.length > 1;
    },
    startDateIsValid() {
      return this.model.start_date;
    },
    emergencyContactNameIsValid() {
      return (
        this.model.emergency_contact_name === "" ||
        this.model.emergency_contact_name === null ||
        this.model.emergency_contact_name.length > 1
      );
    },
    emergencyContactPhoneIsValid() {
      return (
        this.model.emergency_contact_phone === "" ||
        this.model.emergency_contact_phone === null ||
        this.model.emergency_contact_phone.length === 8
      );
    },
    emergencyContactRelationshipIsValid() {
      return true;
    },
    formIsValid() {
      const fields = [
        this.firstNameIsValid,
        this.lastNameIsValid,
        this.phoneIsValid,
        this.emailIsValid,
        this.genderIsValid,
        this.birthdateIsValid,
        this.dpiIsValid,
        this.nitIsValid,
        this.cuiIsValid,
        this.civilStateIsValid,
        this.typeIsValid,
        this.statusIsValid,
        this.titleIsValid,
        this.businessDivisionIdIsValid,
        this.locationIsValid,
        this.bossIsValid,
        this.igssIsValid,
        this.startDateIsValid,
        this.emergencyContactNameIsValid,
        this.emergencyContactPhoneIsValid,
        this.emergencyContactRelationshipIsValid
      ];

      return fields.every(field => field);
    },

    terminationDateIsValid() {
      return this.deleteModel.termination_date !== "";
    },
    layOffTypeIsValid() {
      return this.deleteModel.type !== "";
    },
    bossTransferIsValid() {
      return true;
    },
    layOffIsValid() {
      const fields = [
        this.terminationDateIsValid,
        this.layOffTypeIsValid,
        this.bossTransferIsValid
      ];

      return fields.every(field => field);
    },
    untransferableInsureds() {
      if (this.activeInsuredId) {
        let untransferableInsureds = [];
        const secondDependents = this.allInsureds
          .filter(insured => insured.id === this.activeInsuredId)[0]
          .all_business_dependents.map(
            insuredBusiness => insuredBusiness.insured.all_business_dependents
          );

        const accumUntransferableInsureds = dependents => {
          untransferableInsureds.push(
            ...dependents.map(dependent => dependent.insured.id)
          );
          dependents
            .map(dependent => dependent.insured.all_business_dependents)
            .map(dependents =>
              dependents.length !== 0
                ? accumUntransferableInsureds(dependents)
                : null
            );
        };

        secondDependents.map(secondDependent =>
          accumUntransferableInsureds(secondDependent)
        );

        return untransferableInsureds;
      } else {
        return [];
      }
    },
    transferableInsureds() {
      if (this.activeInsuredId) {
        return this.allInsureds.filter(
          insured =>
            insured.id !== this.activeInsuredId &&
            !this.untransferableInsureds.includes(insured.id)
        );
      } else {
        return [];
      }
    }
  },

  methods: {
    moment,
    resetImportInput() {
      let importFileInput = document.getElementById("import-file");
      if (importFileInput) {
        importFileInput.value = "";
      }
    },
    setSort(column) {
      if (column === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = column;
    },
    tagLink(tag) {
      if (
        this.payload.filtertags &&
        this.payload.filtertags.includes(tag.name.es)
      ) {
        return "#";
      } else {
        if (this.payload.filtertags && this.payload.filtertags) {
          return (
            "/directory/general?" +
            this.payload.filtertags
              .map(
                (filtertag, index) =>
                  "filtertags[" + index.toString() + "]=" + filtertag
              )
              .join("&") +
            "&filtertags[" +
            this.payload.filtertags.length.toString() +
            "]=" +
            tag.name.es
          );
        } else {
          return "/directory/general?filtertags[]=" + tag.name.es;
        }
      }
    },
    removeTagLink(tag) {
      let remainingTags = this.payload.filtertags.map(tag => tag);
      remainingTags.splice(remainingTags.indexOf(tag), 1);
      if (remainingTags.length !== 0) {
        return (
          "/directory/general?" +
          remainingTags
            .map(
              (filtertag, index) =>
                "filtertags[" + index.toString() + "]=" + filtertag
            )
            .join("&")
        );
      } else {
        return "/directory/general";
      }
    },
    setNewMode() {
      this.mode = "new";
      UIkit.switcher("#add-employee-switcher").show(0);
      this.model.first_name = "";
      this.model.last_name = "";
      this.model.phone = "";
      this.model.gender = "";
      this.model.birthdate = "";
      this.model.dpi = "";
      this.model.nit = "";
      this.model.civil_state = "";
      this.model.email = "";
      this.model.type = "";
      this.model.status = "";
      this.model.title = "";
      this.model.tags = "";
      this.model.business_division_id = "";
      this.model.location = "";
      this.model.boss = "";
      this.model.igss = "";
      this.model.start_date = "";
      this.model.emergency_contact_name = "";
      this.model.emergency_contact_phone = "";
      this.model.emergency_contact_relationship = "";
      this.activeInsuredId = null;
    },
    setEditMode(insured) {
      this.mode = "edit";
      UIkit.switcher("#add-employee-switcher").show(0);
      this.activeInsuredId = insured.id;
      this.insuredBusinessDependents = insured.business_dependents;
      this.model.first_name = insured.first_name;
      this.model.last_name = insured.last_name;
      this.model.phone = insured.phone;
      this.model.gender = insured.gender;
      this.model.birthdate = insured.birthdate;
      this.model.dpi = insured.dpi;
      this.model.nit = insured.nit ? insured.nit : "";
      this.model.civil_state = insured.civil_state;
      this.model.email = insured.business_relation.email
        ? insured.business_relation.email
        : "";
      this.model.type = insured.business_relation.type;
      this.model.status = insured.business_relation.status;
      this.model.title = insured.business_relation.title;
      this.model.tags = insured.business_relation.tags.map(tag => tag.name.es);
      this.model.business_division_id =
        insured.business_relation.business_division_id;
      this.model.location = insured.business_relation.location;
      this.model.boss = insured.business_boss ? insured.business_boss.id : "";
      this.model.igss = insured.business_relation.igss
        ? insured.business_relation.igss
        : "";
      this.model.start_date = insured.business_relation.start_date;
      this.model.emergency_contact_name =
        insured.business_relation.emergency_contact_name;
      this.model.emergency_contact_phone =
        insured.business_relation.emergency_contact_phone;
      this.model.emergency_contact_relationship =
        insured.business_relation.emergency_contact_relationship;
      this.boss_transfer = "";
      this.allPendingBonusesSelected = true;
    },
    fetchPendingBonuses(insuredBusinessId) {
      this.pendingBonuses = [];
      this.isLoading = true;
      axios
        .get("/payroll/bonuses/pending-bonuses/" + insuredBusinessId.toString())
        .then(({ data: { bonuses } }) => {
          this.pendingBonuses = bonuses.map(bonus => ({
            ...bonus,
            selected: true
          }));
        })
        .catch(error => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.insuredFilter && !url.includes("&query")) {
        url = url + "&query=" + this.insuredFilter;
      }
      url =
        url +
        "&items=" +
        this.itemsPerPage +
        "&sortBy=" +
        this.sortBy +
        "&sortDirection=" +
        this.sortDirection;
      axios
        .get(url)
        .then(({ data: { insureds } }) => {
          this.insureds = insureds;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.insuredFilterTimer) {
        clearTimeout(this.insuredFilterTimer);
      }
      this.insuredFilterTimer = setTimeout(() => {
        let urlSearchString = new URL(window.location.href).search;

        this.fetchPage(
          window.location.pathname +
            urlSearchString +
            (urlSearchString == "" ? "?" : "&") +
            "dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage +
            "&sortBy=" +
            this.sortBy +
            "&sortDirection=" +
            this.sortDirection
        );
        this.insuredFilterTimer = null;
      }, 500);
    },
    refetchPage(callback) {
      this.isFetching = true;
      let path =
        window.location.pathname +
        "?dataOnly=true&page=" +
        this.insureds.current_page.toString() +
        "&items=" +
        this.itemsPerPage +
        "&sortBy=" +
        this.sortBy +
        "&sortDirection=" +
        this.sortDirection;
      if (this.insuredFilter && !path.includes("&query")) {
        path = path + "&query=" + this.insuredFilter;
      }
      axios
        .get(path)
        .then(({ data: { insureds, allInsureds } }) => {
          this.insureds = insureds;
          this.allInsureds = [
            {
              first_name: "No asignar",
              last_name: "",
              dpi: "",
              id: ""
            },
            ...allInsureds
          ];
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando colaborador...",
        { status: "primary", timeout: 0 }
      );
      const payload = Object.keys(this.model).reduce((accum, key) => {
        if (this.model[key] !== "") {
          accum[key] = this.model[key];
        }
        return accum;
      }, {});
      axios
        .post("/directory/general", payload)
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-insured-modal").hide();
            UIkit.switcher("#add-employee-switcher").show(0);
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Colaborador creado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando colaborador...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put(
          "/directory/general/" + this.activeInsuredId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-insured-modal").hide();
            UIkit.switcher("#add-employee-switcher").show(0);
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Colaborador actualizado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteInsured() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando colaborador...",
        { status: "primary", timeout: 0 }
      );
      const payload = Object.keys(this.deleteModel).reduce((accum, key) => {
        if (this.deleteModel[key] !== "") {
          accum[key] = this.deleteModel[key];
        }
        return accum;
      }, {});
      payload["shifted_bonuses"] = this.pendingBonuses
        .filter(bonus => bonus.selected)
        .map(bonus => bonus.id);
      axios
        .delete("/directory/general/" + this.activeInsuredId.toString(), {
          data: payload
        })
        .then(({ response }) => {
          this.activeInsuredId = null;
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Colaborador dado de baja</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleFile(e) {
      this.bulkError = null;
      this.isLoading = true;
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        let newInsureds = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .slice(2);

        newInsureds = newInsureds.map(insured => {
          insured.start_date = moment(insured.start_date).format("YYYY-MM-DD");
          insured.birthdate = moment(insured.birthdate).format("YYYY-MM-DD");
          insured.civil_state = insured.civil_state.substr(0, 1);
          insured.gender = insured.gender.substr(0, 1);
          return insured;
        });

        axios
          .post("/directory/general/bulk", newInsureds)
          .then(({ data }) => {
            this.refetchPage(() => {
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  data.createdCount +
                  " Colaboradores creados, " +
                  data.updatedCount +
                  " actualizados.</div>",
                { status: "success", timeout: 3000 }
              );
              this.isLoading = false;
              UIkit.modal("#import-export-modal").hide();
            });
          })
          .catch(error => {
            if (error.response && error.response.status === 422) {
              this.bulkError = error.response.data.errors;
            }
            console.log("error", error);
            this.isLoading = false;
          });
        this.resetImportInput();
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        gender: "",
        birthdate: "",
        dpi: "",
        nit: "",
        civil_state: "",
        type: "",
        status: "",
        title: "",
        tags: "",
        business_division_id: "",
        location: "",
        boss: "",
        igss: "",
        start_date: "",
        emergency_contact_name: "",
        emergency_contact_phone: "",
        emergency_contact_relationship: ""
      };
    },

    cuiIsValid(cui) {
      // var console = window.console;
      if (!cui) {
        // console.log("CUI vacío");
        return false;
      }

      var cuiRegExp = /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/;

      if (!cuiRegExp.test(cui)) {
        // console.log("CUI con formato inválido");
        return false;
      }

      cui = cui.replace(/\s/, "");
      var depto = parseInt(cui.substring(9, 11), 10);
      var muni = parseInt(cui.substring(11, 13));
      var numero = cui.substring(0, 8);
      var verificador = parseInt(cui.substring(8, 9));

      // Se asume que la codificación de Municipios y
      // departamentos es la misma que esta publicada en
      // http://goo.gl/EsxN1a

      // Listado de municipios actualizado segun:
      // http://goo.gl/QLNglm

      // Este listado contiene la cantidad de municipios
      // existentes en cada departamento para poder
      // determinar el código máximo aceptado por cada
      // uno de los departamentos.
      var munisPorDepto = [
        /* 01 - Guatemala tiene:      */ 17 /* municipios. */,
        /* 02 - El Progreso tiene:    */ 8 /* municipios. */,
        /* 03 - Sacatepéquez tiene:   */ 16 /* municipios. */,
        /* 04 - Chimaltenango tiene:  */ 16 /* municipios. */,
        /* 05 - Escuintla tiene:      */ 13 /* municipios. */,
        /* 06 - Santa Rosa tiene:     */ 14 /* municipios. */,
        /* 07 - Sololá tiene:         */ 19 /* municipios. */,
        /* 08 - Totonicapán tiene:    */ 8 /* municipios. */,
        /* 09 - Quetzaltenango tiene: */ 24 /* municipios. */,
        /* 10 - Suchitepéquez tiene:  */ 21 /* municipios. */,
        /* 11 - Retalhuleu tiene:     */ 9 /* municipios. */,
        /* 12 - San Marcos tiene:     */ 30 /* municipios. */,
        /* 13 - Huehuetenango tiene:  */ 32 /* municipios. */,
        /* 14 - Quiché tiene:         */ 21 /* municipios. */,
        /* 15 - Baja Verapaz tiene:   */ 8 /* municipios. */,
        /* 16 - Alta Verapaz tiene:   */ 17 /* municipios. */,
        /* 17 - Petén tiene:          */ 14 /* municipios. */,
        /* 18 - Izabal tiene:         */ 5 /* municipios. */,
        /* 19 - Zacapa tiene:         */ 11 /* municipios. */,
        /* 20 - Chiquimula tiene:     */ 11 /* municipios. */,
        /* 21 - Jalapa tiene:         */ 7 /* municipios. */,
        /* 22 - Jutiapa tiene:        */ 17 /* municipios. */
      ];

      if (depto === 0 || muni === 0) {
        // console.log("CUI con código de municipio o departamento inválido.");
        return false;
      }

      if (depto > munisPorDepto.length) {
        // console.log("CUI con código de departamento inválido.");
        return false;
      }

      if (muni > munisPorDepto[depto - 1]) {
        // console.log("CUI con código de municipio inválido.");
        return false;
      }

      // Se verifica el correlativo con base
      // en el algoritmo del complemento 11.
      var total = 0;

      for (var i = 0; i < numero.length; i++) {
        total += numero[i] * (i + 2);
      }

      var modulo = total % 11;

      // console.log("CUI con módulo: " + modulo);
      return modulo === verificador;
    }
  },

  watch: {
    allPendingBonusesSelected(value) {
      this.pendingBonuses = this.pendingBonuses.map(bonus => ({
        ...bonus,
        selected: value
      }));
    },
    insuredFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    sortBy: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    sortDirection: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped>
.user-logo {
  color: white;
  background: #2c5fa1;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  letter-spacing: -3px;
}

.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}

.rounded-avatar {
  display: block;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;
}

.uk-badge {
  margin: 1px;
}
</style>

<style>
.v-select.searchable .dropdown-toggle {
  border-radius: 15px;
  background: white;
  border: none;
}

.v-select .selected-tag {
  border-radius: 15px;
  background-color: #2c5fa1;
  color: white;
  padding: 5px 10px;
}

.bottom-divider {
  border-bottom: solid 1px;
  padding: 10px 0;
}

.uk-search-default .uk-search-input {
  padding-left: 40px !important;
}
</style>