/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

import moment from 'moment'
moment.locale('es')

import translations from './translations'

import VueSelect from 'vue-select'
Vue.component('v-select', VueSelect)

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCq7aHTKV601i0b1fosJWMDZcgZizakR1A',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
})

Vue.mixin({
    methods: {
        toCurrency: amount => (amount < 0 ? '-' : '') + 'Q' + Math.abs(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        formatDate: date => moment(date).format('LL'),
        formatDatetime: datetime => moment(datetime).format('LL hh:mm:ss A'),
        formatQuiloId: quiloId => quiloId.substr(0, 2) + '-' + quiloId.substr(2, 4) + '-' + quiloId.substr(6),
        checkPermission: function(slug) {
            return this.$parent.routePermissions ? this.$parent.routePermissions.includes(slug) : false
        },
        translate(string) {
            if (string in translations) {
                return translations[string].translation;
            } else {
                return string;
            }
        },
    }
})

const numericFilter = value => {
    return /^\d*$/.test(value);
}
Vue.directive('numericOnly', {
    bind(el) {
        ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
            el.addEventListener(event, function() {
                if (numericFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                }
            });
        });
    }
})

const currencyFilter = value => {
    return /^\d*[.]?(\d\d?)?$/.test(value);
}
Vue.directive('currencyOnly', {
    bind(el) {
        ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
            el.addEventListener(event, function() {
                if (currencyFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                }
            });
        });
    }
})

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('pagination-buttons', require('./components/Utils/PaginationButtons').default);
Vue.component('payment-detail', require('./components/Utils/PaymentDetail').default);
Vue.component('service-tree-level', require('./components/Utils/ServiceTreeLevel').default);
Vue.component('field-proportions', require('./components/Utils/FieldProportions').default);
Vue.component('modules-description', require('./components/Utils/ModulesDescription').default);
Vue.component('modules-selection', require('./components/Utils/ModulesSelection').default);
Vue.component('formula-editor', require('./components/Utils/FormulaEditor').default);
Vue.component('latex', require('./components/Utils/Latex').default);

Vue.component('line-chart', require('./components/Utils/Charts/LineChart').default);
Vue.component('pie-chart', require('./components/Utils/Charts/PieChart').default);
Vue.component('bar-chart', require('./components/Utils/Charts/BarChart').default);
Vue.component('generic-chart', require('./components/Utils/Charts/GenericChart').default);

Vue.component('landing', require('./components/Landing').default);
Vue.component('auth-login', require('./components/Auth/Login').default);
Vue.component('auth-register', require('./components/Auth/Register').default);
Vue.component('auth-passwords-reset', require('./components/Auth/PasswordReset').default);
Vue.component('auth-passwords-email', require('./components/Auth/PasswordEmail').default);
Vue.component('auth-select-business', require('./components/Auth/SelectBusiness').default);
Vue.component('auth-verify', require('./components/Auth/Verify').default);
Vue.component('navigation', require('./components/Navigation').default);
Vue.component('profile-general', require('./components/Profile/General').default);
Vue.component('profile-banks', require('./components/Profile/Banks').default);
Vue.component('profile-documents', require('./components/Profile/Documents').default);
Vue.component('profile-audits', require('./components/Profile/Audits').default);
Vue.component('profile-security', require('./components/Profile/Security').default);
Vue.component('profile-suggestions', require('./components/Profile/Suggestions').default);
Vue.component('profile-roles', require('./components/Profile/Roles').default);
Vue.component('profile-users', require('./components/Profile/Users').default);
Vue.component('reports-index', require('./components/Reports/General').default);
Vue.component('reports-series', require('./components/Reports/Series').default);
Vue.component('reports-scheduling', require('./components/Reports/Scheduling').default);
Vue.component('directory-general', require('./components/Directory/General').default);
Vue.component('directory-detail', require('./components/Directory/Detail').default);
Vue.component('time-tracking-index', require('./components/TimeTracking/Index').default);
Vue.component('time-tracking-history', require('./components/TimeTracking/History').default);
Vue.component('time-tracking-activities', require('./components/TimeTracking/Activities').default);
Vue.component('time-tracking-shifts', require('./components/TimeTracking/Shifts').default);
Vue.component('communications-index', require('./components/Communication/Index').default);
Vue.component('directory-organization-chart', require('./components/Directory/OrganizationChart').default);
Vue.component('directory-divisions', require('./components/Directory/Divisions').default);
Vue.component('directory-tags', require('./components/Directory/Tags').default);
Vue.component('directory-absences', require('./components/Directory/Absences').default);
Vue.component('directory-calendar', require('./components/Directory/Calendar').default);
Vue.component('directory-records', require('./components/Directory/Records').default);
Vue.component('directory-overtime', require('./components/Directory/Overtime').default);
Vue.component('directory-laid-offs', require('./components/Directory/LaidOffs').default);
Vue.component('directory-requests', require('./components/Directory/Requests').default);
Vue.component('payroll-general', require('./components/Payroll/General').default);
Vue.component('payroll-bonuses', require('./components/Payroll/Bonuses').default);
Vue.component('payroll-discounts', require('./components/Payroll/Discounts').default);
Vue.component('payroll-bonus-types', require('./components/Payroll/BonusTypes').default);
Vue.component('payroll-discount-types', require('./components/Payroll/DiscountTypes').default);
Vue.component('payroll-benefits', require('./components/Payroll/Benefits').default);
Vue.component('payroll-payments', require('./components/Payroll/Payments').default);
Vue.component('payroll-reports', require('./components/Payroll/Reports').default);
Vue.component('payroll-proportions', require('./components/Payroll/Proportions').default);
Vue.component('benefits-general', require('./components/Benefits/General').default);
Vue.component('benefits-insurances', require('./components/Benefits/Insurances').default);
Vue.component('support-contact', require('./components/Support/Contact').default);
Vue.component('services-explore', require('./components/Services/Explore').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});