<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Reportes</div>
      <div>
        <!-- <button class="uk-button uk-border-rounded uk-button-primary" href="#import-export-modal" uk-toggle>Importar / Exportar</button> -->
        <button
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-report-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-report')"
        >Añadir reporte</button>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-padding-small uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input"
            type="search"
            placeholder="Buscar reporte..."
            v-model="reportFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="reports.prev_page_url"
          :is-fetching="isFetching"
          :current-page="reports.current_page"
          :last-page="reports.last_page"
          :next-page-url="reports.next_page_url"
        ></pagination-buttons>
      </div>
    </div>
    <div class="uk-margin-small-top uk-child-width-1-2@s" uk-grid>
      <div v-for="report in reports.data" :key="report.id">
        <div class="uk-card uk-card-default uk-padding-small uk-border-rounded">
          <div class="uk-text-large uk-text-center">{{ report.name }}</div>
          <div class="uk-margin-small-top uk-child-width-1-2@s" uk-grid>
            <div class="uk-width-1-1">
              <label class="uk-form-label" for="form-stacked-text">Rango</label>
              <div class="uk-form-controls">
                <flat-pickr
                  class="uk-input uk-border-rounded uk-text-center"
                  :config="{ ...rangeDatepickerConfig, defaultDate: report.range }"
                  value
                  :disabled="isLoading"
                  @on-change="updateRange($event, report)"
                ></flat-pickr>
              </div>
            </div>
          </div>

          <div class="uk-margin-top">
            <generic-chart :configuration="report.chartConfiguration"></generic-chart>
          </div>

          <div class="uk-text-center uk-margin-top">
            <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
              <button
                :id="'edit-insured-' + report.id"
                :dusk="'edit-report-' + report.id"
                class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                href="#edit-report-modal"
                uk-toggle
                @click="setEditMode(report)"
                uk-icon="pencil"
                v-if="checkPermission('edit-report')"
              ></button>
              <button
                :id="'delete-report-' + report.id"
                :dusk="'delete-report-' + report.id"
                class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                href="#confirm-deletion-modal"
                uk-toggle
                @click="setEditMode(report)"
                uk-icon="minus-circle"
                v-if="checkPermission('delete-report')"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span>¿Está seguro que desea eliminar el reporte {{ this.model.name }}?</span>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteReport"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Eliminar reporte</span>
          </button>
        </div>
      </div>
    </div>

    <div id="edit-report-modal" class="uk-flex-top uk-modal-container" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          reporte
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre de el reporte</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': reportNameIsValid }"
                uk-icon="check"
              ></span>
              <input class="uk-input uk-border-rounded" type="text" v-model="model.name" />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Rango predeterminado</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select class="uk-select uk-border-rounded" v-model="model.range_days">
                <option value="7">Última semana</option>
                <option value="14">Últimas dos semanas</option>
                <option value="15">Últimos 15 días</option>
                <option value="30">Últimos 30 días</option>
              </select>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Tipo de gráfica</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <select class="uk-select uk-border-rounded" v-model="model.graph_id">
                <option
                  v-for="graph in payload.graphs"
                  :key="graph.id"
                  :value="graph.id"
                >{{ graph.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div>Contenido</div>
        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
          <thead>
            <tr>
              <th width="20%">Métrica</th>
              <th width="30%">Tipo de objetivo</th>
              <th width="30%">Objetivo</th>
              <th width="20%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in model.content" :key="index">
              <td>{{ getReportableName(item.reportable_type, item.reportable_id) }}</td>
              <td>
                <v-select
                  class="uk-input uk-border-rounded uk-width-medium"
                  :options="[{name: 'Empresa', value: 'App\\Models\\Business'}, {name: 'División', value: 'App\\Models\\Division'}, {name: 'Colaborador', value: 'App\\Models\\InsuredBusiness'}]"
                  :reduce="targetType => targetType.value"
                  :getOptionLabel="targetType => targetType.name"
                  v-model="item.targetable_type"
                  @input="clearTargetableId(index)"
                ></v-select>
              </td>
              <td>
                <v-select
                  v-if="item.targetable_type === 'App\\Models\\Division'"
                  class="uk-input uk-border-rounded uk-width-1-1"
                  :options="payload.divisions"
                  :reduce="division => division.id"
                  :getOptionLabel="division => division.name"
                  v-model="item.targetable_id"
                ></v-select>
                <v-select
                  v-if="item.targetable_type === 'App\\Models\\InsuredBusiness'"
                  class="uk-input uk-border-rounded uk-width-1-1"
                  :options="payload.insuredBusinesses"
                  :reduce="insuredBusiness => insuredBusiness.id"
                  :getOptionLabel="insuredBusiness => insuredBusiness.insured.first_name + ' ' + insuredBusiness.insured.last_name"
                  v-model="item.targetable_id"
                ></v-select>
              </td>
              <td>
                <div class="uk-text-right">
                  <button
                    class="uk-button uk-border-rounded uk-padding-remove uk-button-danger"
                    uk-toggle
                    @click="removeContent(index)"
                    uk-icon="minus-circle"
                  ></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="model.content.length === 0"
          class="uk-text-center"
        >Agrega métricas utilizando los siguientes buscadores</div>
        <div class="uk-margin-left">
          <div class="uk-margin-small-top">Buscar series</div>
          <v-select
            class="uk-input uk-border-rounded"
            :options="payload.series"
            :reduce="serie => ({ reportable_type: 'App\\Models\\Serie', reportable_id: serie.id })"
            :getOptionLabel="option => option.name"
            v-model="serieSelector"
            @input="selectSerie"
          >
            <template slot="option" slot-scope="option">
              <div>{{ option.name }}</div>
              <latex :postfix="option.postfix" :tokens="payload.snapshotables" />
            </template>
          </v-select>
          <div class="uk-margin-small-top">Buscar variables</div>
          <v-select
            class="uk-input uk-border-rounded"
            :options="payload.snapshotables"
            :reduce="snapshotable => ({ reportable_type: 'App\\Models\\Snapshotable', reportable_id: snapshotable.id })"
            :getOptionLabel="snapshotable => snapshotable.name"
            v-model="snapshotableSelector"
            @input="selectSnapshotable"
          ></v-select>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !reportIsValid"
            v-if="mode === 'new'"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Crear reporte</span>
          </button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !reportIsValid"
            v-else
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Actualizar reporte</span>
          </button>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <div uk-form-custom>
            <input name="file" type="file" @change="handleFile($event)" />
            <button class="uk-button uk-border-rounded uk-button-secondary">Importar</button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-border-rounded uk-button-secondary">Exportar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import xlsx from "xlsx";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import moment from "moment";

export default {
  name: "ReportsGeneral",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      model: {
        name: null,
        graph_id: null,
        range_days: null,
        content: []
      },
      itemsPerPage: 20,
      activeReportId: "",
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      serieSelector: null,
      snapshotableSelector: null,
      mode: "new",
      reportFilter: "",
      reports: this.payload.reports,
      reportFilterTimer: null,
      rangeDatepickerConfig: {
        mode: "range",
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  computed: {
    reportNameIsValid() {
      return this.model.name && this.model.name.length > 1;
    },
    contentIsValid() {
      return (
        this.model.content.length !== 0 &&
        this.model.content.every(content => {
          return (
            content.targetable_type !== null &&
            (content.targetable_type === "App\\Models\\Business" ||
              content.targetable_id !== null)
          );
        })
      );
    },
    reportIsValid() {
      const validations = [
        this.reportNameIsValid,
        this.model.range_days !== null,
        this.model.graph_id !== null,
        this.contentIsValid
      ];
      return validations.every(validation => validation === true);
    },
    selectedContent() {
      return this.model.content.map(item => {
        if (item.reportable_type === "App\\Models\\Serie") {
          return this.payload.series.find(
            serie => serie.id === item.reportable_id
          ).name;
        }
        if (item.reportable_type === "App\\Models\\Snapshotable") {
          return this.payload.snapshotables.find(
            snapshotable => snapshotable.id === item.reportable_id
          ).name;
        }
        return null;
      });
    }
  },

  methods: {
    getReportableName(reportable_type, reportable_id) {
      if (reportable_type === "App\\Models\\Serie") {
        return this.payload.series.find(serie => serie.id === reportable_id)
          .name;
      }
      if (reportable_type === "App\\Models\\Snapshotable") {
        return this.payload.snapshotables.find(
          snapshotable => snapshotable.id === reportable_id
        ).name;
      }
      return null;
    },
    clearTargetableId(index) {
      this.model.content[index].targetable_id = null;
    },
    addContent(content) {
      this.model.content.push(content);
    },
    removeContent(index) {
      this.model.content.splice(index, 1);
    },
    selectSerie(serie) {
      if (serie) {
        this.addContent({
          ...serie,
          targetable_type: null,
          targetable_id: null
        });
        this.$nextTick(() => {
          this.serieSelector = null;
        });
      }
    },
    selectSnapshotable(snapshotable) {
      if (snapshotable) {
        this.addContent({
          ...snapshotable,
          targetable_type: null,
          targetable_id: null
        });
        this.$nextTick(() => {
          this.snapshotableSelector = null;
        });
      }
    },
    updateRange(range, report) {
      report.range = range.map(date =>
        moment(date)
          .toISOString()
          .substr(0, 10)
      );
    },
    setNewMode() {
      this.mode = "new";
      this.resetForm();
    },
    setEditMode(report, index) {
      this.mode = "edit";
      this.activeReportId = report.id;
      this.payloadPosition = index;
      this.model.name = report.name;
      this.model.graph_id = report.graph_id;
      this.model.range_days = report.range_days;
      this.model.content = report.content.map(item => ({
        reportable_type: item.reportable_type,
        reportable_id: item.reportable_id,
        targetable_type: item.targetable_type,
        targetable_id: item.targetable_id
      }));
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.reportFilter && !url.includes("&query")) {
        url = url + "&query=" + this.reportFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { reports } }) => {
          this.reports = reports;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.reportFilterTimer) {
        clearTimeout(this.reportFilterTimer);
      }
      this.reportFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.reportFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.reports.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { reports } }) => {
          this.reports = reports;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      axios
        .post("/reports/general", this.model)
        .then(({ data: { model } }) => {
          // this.reports.data.push(model)
          this.refetchPage(() => {
            UIkit.modal("#edit-report-modal").hide();
            this.resetForm();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Reporte creado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      axios
        .put("/reports/general/" + this.activeReportId.toString(), this.model)
        .then(({ data: { model } }) => {
          // this.reports.data[this.payloadPosition] = model
          this.refetchPage(() => {
            UIkit.modal("#edit-report-modal").hide();
            this.resetForm();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Reporte actualizado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
    deleteReport() {
      this.isLoading = true;
      axios
        .delete(
          "/reports/general/" + this.activeReportId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Reporte eliminado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
        });
    },

    handleFile(e) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        const newAccounts = xlsx.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 2 }
        );

        axios
          .post("/reports/general/bulk", newAccounts)
          .then(({ data }) => {
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                data.count +
                " Reportes creadas</div>",
              { status: "success", timeout: 3000 }
            );
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        name: null,
        graph_id: null,
        range_days: null,
        content: []
      };
    }
  },

  watch: {
    reportFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}

.vs__dropdown-menu {
  max-height: 270px !important;
}
</style>