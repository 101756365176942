<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">
        Bajas
        <span v-if="payload.filtertags">
          <a
            :href="removeTagLink(filtertag)"
            v-for="(filtertag, index) in payload.filtertags"
            :key="index"
          >
            <span class="uk-badge uk-padding-small" uk-close>#{{ filtertag }}</span>
          </a>
        </span>
      </div>
      <!-- <div class="uk-text-center">
      <button class="uk-button uk-border-rounded uk-button-primary" href="#import-export-modal" uk-toggle>Importar / Exportar</button>
      <button class="uk-button uk-border-rounded uk-button-secondary uk-margin" href="#edit-insured-modal" uk-toggle @click="setNewMode">Añadir colaborador</button>
      </div>-->
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <form autocomplete="off">
            <input
              autocomplete="off"
              class="uk-search-input search-input-animation"
              type="search"
              placeholder="Buscar colaborador..."
              v-model="insuredFilter"
            />
          </form>
        </form>
        <pagination-buttons
          :prev-page-url="insureds.prev_page_url"
          :is-fetching="isFetching"
          :current-page="insureds.current_page"
          :last-page="insureds.last_page"
          :next-page-url="insureds.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Contratado por</th>
            <th>Título</th>
            <th>Tags</th>
            <th>Localidad</th>
            <th>Responsable</th>
            <th>Período laborado</th>
            <th>Tipo de baja</th>
            <th>Comentarios de baja</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(insured, index) in insureds.data" :key="insured.id">
            <td width="10%">
              <a
                :dusk="'insured-' + insured.id"
                :id="'insured-' + insured.id"
                :href="'/directory/general/' + insured.id.toString()"
                class="uk-link-reset"
              >
                <div
                  v-if="insured.avatar"
                  class="rounded-avatar"
                  :style="{ 'background-image': 'url(' + insured.avatar + ')' }"
                ></div>
                <div
                  class="user-logo uk-border-circle uk-padding-small uk-text-center"
                  v-else
                >{{ insured.first_name.substr(0, 1) }} {{ insured.last_name.substr(0, 1) }}</div>
              </a>
            </td>
            <td width="10%">
              <div>
                <a
                  :id="'insured-name-' + insured.id"
                  :href="'/directory/general/' + insured.id.toString()"
                  class="uk-text-bold uk-link-reset"
                >{{ insured.first_name }} {{ insured.last_name }}</a>
              </div>
              <div>{{ insured.business_relation.division.name }}</div>
            </td>
            <td width="10%">
              <span
                :id="'insured-type-' + insured.id"
                v-if="insured.business_relation"
              >{{ insured.business_relation.type }}</span>
            </td>
            <td width="5%">
              <span
                :id="'insured-title-' + insured.id"
                v-if="insured.business_relation"
              >{{ insured.business_relation.title }}</span>
            </td>
            <td width="10%">
              <span v-if="insured.business_relation">
                <a :href="tagLink(tag)" v-for="tag in insured.business_relation.tags" :key="tag.id">
                  <span
                    :id="'tag-insured-' + insured.id + '-name-' + tag.id"
                    class="uk-badge uk-padding-small uk-margin-small-left uk-margin-small-right"
                  >#{{ tag.name.es }}</span>
                </a>
              </span>
            </td>
            <td width="5%">
              <span
                :id="'insured-location-' + insured.id"
                v-if="insured.business_relation"
              >{{ insured.business_relation.location }}</span>
            </td>
            <td width="10%">
              <span v-if="insured.business_relation.boss_model">
                <a
                  :id="'insured-boss-name-' + insured.id"
                  :href="'/directory/general/' + insured.business_relation.boss_model.id.toString()"
                  class="link-underline"
                >{{ insured.business_relation.boss_model.first_name }} {{ insured.business_relation.boss_model.last_name }}</a>
              </span>
            </td>
            <td width="15%">
              <div
                :id="'insured-start-date-' + insured.id"
                v-if="insured.business_relation"
              >{{ formatDate(insured.business_relation.start_date) }}</div>
              <div
                :id="'insured-end-date-' + insured.id"
                v-if="insured.business_relation && insured.business_relation.end_date"
              >{{ formatDate(insured.business_relation.end_date) }}</div>
            </td>
            <td
              width="10%"
            >{{ insured.business_relation.end_activity ? insured.business_relation.end_activity.type.name : '' }}</td>
            <td
              width="15%"
            >{{ insured.business_relation.end_activity ? insured.business_relation.end_activity.notes : '' }}</td>
            <td>
              <button
                class="uk-button uk-button-secondary uk-border-rounded"
                uk-icon="history"
                href="#insured-history-modal"
                uk-toggle
                @click="setEditMode(insured, index)"
              ></button>
            </td>
            <!-- <td width="10%" class="uk-text-right">
            <div class="uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top">
              <button class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary" href="#edit-insured-modal" uk-toggle @click="setEditMode(insured)" uk-icon="pencil"></button>
              <button class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger" href="#confirm-deletion-modal" uk-toggle @click="setEditMode(insured)" uk-icon="trash"></button>
            </div>
            </td>-->
          </tr>
        </tbody>
      </table>

      <div class="bottom-divider uk-hidden@s" v-for="insured in insureds.data" :key="insured.id">
        <div class="uk-flex uk-flex-between">
          <div>
            <span
              class="uk-text-bold"
              :id="'responsive-insured-name-' + insured.id"
            >{{ insured.first_name }} {{ insured.last_name }}</span>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-title-' + insured.id"
            >- {{ insured.business_relation.title }}</span>
          </div>
          <div>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-status-' + insured.id"
            >{{ insured.business_relation.status }}</span>
          </div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span
              v-if="insured.business_relation.division"
              :id="'responsive-insured-division-' + insured.id"
            >{{ insured.business_relation.division.name }}</span>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-location-' + insured.id"
            >- {{ insured.business_relation.location }}</span>
          </div>
          <div>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-type-' + insured.id"
            >{{ insured.business_relation.type }}</span>
          </div>
        </div>
        <div class="uk-flex uk-flex-between">
          <div>
            <span
              v-if="insured.business_relation.boss_model"
              :id="'responsive-insured-boss-name-' + insured.id"
            >Responsable: {{ insured.business_relation.boss_model.first_name }} {{ insured.business_relation.boss_model.last_name }}</span>
          </div>
          <div>
            <span
              v-if="insured.business_relation"
              :id="'responsive-insured-start-date-' + insured.id"
            >{{ moment(insured.business_relation.start_date).format('D/MM/YY') }}</span>
          </div>
        </div>
        <div class="uk-text-center uk-margin-small-top">
          <span v-if="insured.business_relation">
            <a :href="tagLink(tag)" v-for="tag in insured.business_relation.tags" :key="tag.id">
              <span
                class="uk-badge uk-padding-small uk-margin-small-left uk-margin-small-right"
                :id="'responsive-tag-insured-' + insured.id + '-name-' + tag.id"
              >#{{ tag.name.es }}</span>
            </a>
          </span>
        </div>
        <!-- <div class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top">
        <button class="uk-button uk-width-1-2 uk-button-primary" href="#edit-insured-modal" uk-toggle @click="setEditMode(insured)" uk-icon="pencil"></button>
        <button class="uk-button uk-width-1-2 uk-button-danger" href="#confirm-deletion-modal" uk-toggle @click="setEditMode(insured)" uk-icon="trash"></button>
        </div>-->
      </div>

      <div
        v-if="insureds.data && insureds.data.length === 0"
        class="uk-text-center uk-margin-top"
      >No se han encontrado resultados con los filtros seleccionados.</div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div
          class="uk-text-large"
        >¿Está seguro que desea eliminar al colaborador {{ this.model.first_name }} {{ this.model.last_name }}?</div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteInsured"
            :disabled="isLoading"
          >Eliminar colaborador</button>
        </div>
      </div>
    </div>

    <div
      id="insured-history-modal"
      class="uk-flex-top uk-modal-container"
      uk-modal="bg-close: false"
    >
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <ul
          id="payroll-modal-switcher"
          uk-switcher
          class="uk-hidden"
          swipping="False"
          animation="uk-animation-slide-right, uk-animation-slide-left"
        >
          <li>
            <a href="#"></a>
          </li>
          <li>
            <a href="#"></a>
          </li>
        </ul>

        <!-- PAGOS PROGRAMADOS -->
        <ul class="uk-switcher" v-if="activeInsured">
          <li>
            <div class="uk-text-large">{{ activeInsured.first_name }} {{ activeInsured.last_name }}</div>
            <div class="uk-text-center">
              <div
                class="uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
                v-if="insuredAction === null"
              >
                <button
                  v-if="checkPermission('revert-layoff')"
                  :disabled="isLoading"
                  @click="confirmRevert"
                  class="uk-button uk-button-danger"
                  uk-tooltip="Al revertir una baja se eliminarán los pagos de liquidación asignados al colaborador, regresándolo al estado de contratado y con pagos programados a futuro."
                >
                  <span v-if="isLoading" uk-spinner></span>
                  <span v-else>Revertir baja</span>
                </button>
                <button
                  v-if="checkPermission('recontract-layoff')"
                  :disabled="isLoading"
                  @click="confirmRecontract"
                  class="uk-button uk-button-primary"
                  uk-tooltip="Al recontratar a un colaborador se iniciará una nueva relación laboral, iniciando en cero todos los cálculos de prestaciones correspondientes."
                >
                  <span v-if="isLoading" uk-spinner></span>
                  <span v-else>Recontratar</span>
                </button>
              </div>
              <div
                class="uk-card uk-padding-small uk-border-rounded uk-width-large uk-card-primary uk-margin-auto"
                v-if="insuredAction === 'confirm-revert'"
              >
                <div uk-icon="icon: question; ratio: 4"></div>
                <div
                  class="uk-text-large"
                >Confirmar reversión de baja de {{ activeInsured.first_name }} {{ activeInsured.last_name }}</div>

                <div>Al revertir una baja se eliminarán los pagos de liquidación asignados al colaborador, regresándolo al estado de contratado y con pagos programados a futuro.</div>

                <div
                  class="uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
                >
                  <button @click="cancelInsuredAction" class="uk-button uk-button-primary">
                    <span v-if="isLoading" uk-spinner></span>
                    <span v-else>Cancelar</span>
                  </button>
                  <button
                    :disabled="isLoading"
                    @click="revertLayOff"
                    class="uk-button uk-button-danger"
                  >
                    <span v-if="isLoading" uk-spinner></span>
                    <span v-else>Confirmar reversión</span>
                  </button>
                </div>
              </div>
              <div
                class="uk-card uk-padding-small uk-border-rounded uk-width-large uk-card-primary uk-margin-auto"
                v-if="insuredAction === 'confirm-recontract'"
              >
                <div uk-icon="icon: question; ratio: 4"></div>
                <div
                  class="uk-text-large"
                >Confirmar recontratación de {{ activeInsured.first_name }} {{ activeInsured.last_name }}</div>

                <div>Al recontratar a un colaborador se iniciará una nueva relación laboral, iniciando en cero todos los cálculos de prestaciones correspondientes.</div>

                <div class="uk-margin-top">Fecha de recontratación</div>
                <div class="uk-padding-small uk-width-medium uk-margin-auto">
                  <flat-pickr
                    id="start-date"
                    class="uk-input uk-border-rounded uk-text-center"
                    v-model="recontractDate"
                    :config="datepickerConfig"
                    :disabled="isLoading"
                  ></flat-pickr>
                </div>

                <div
                  class="uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
                >
                  <button @click="cancelInsuredAction" class="uk-button uk-button-primary">
                    <span v-if="isLoading" uk-spinner></span>
                    <span v-else>Cancelar</span>
                  </button>
                  <button
                    :disabled="isLoading || recontractDate === null"
                    @click="recontract"
                    class="uk-button uk-button-secondary"
                  >
                    <span v-if="isLoading" uk-spinner></span>
                    <span v-else>Recontratar</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="checkPermission('layoff-payments-history')">
              <div class="uk-text-large">Historial de pagos</div>
              <div v-if="activeInsuredPayrollHistory" class="uk-margin-top">
                <div class="uk-flex uk-flex-right">
                  <pagination-buttons
                    :prev-page-url="activeInsuredPayrollHistory.prev_page_url"
                    :is-fetching="isLoadingHistory"
                    prevItemsBtnId="detailPaymentsPrev"
                    nextItemsBtnId="detailPaymentsNext"
                    :current-page="activeInsuredPayrollHistory.current_page"
                    :last-page="activeInsuredPayrollHistory.last_page"
                    :next-page-url="activeInsuredPayrollHistory.next_page_url"
                    method-name="fetchPaymentsHistoryPage"
                    items-per-page="itemsPerPageHistory"
                  ></pagination-buttons>
                </div>
                <table
                  class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s"
                >
                  <thead>
                    <tr>
                      <th>Período</th>
                      <th>Base</th>
                      <th>Prestaciones</th>
                      <th>Bonos de productividad</th>
                      <th>Otros bonos</th>
                      <th>Total</th>
                      <th class="uk-text-center">Pagado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="record in activeInsuredPayrollHistory.data" :key="record.id">
                      <td
                        :id="'modal-insured-payroll-dates-' + record.id"
                      >{{ formatDate(record.start_date) }} - {{ formatDate(record.end_date) }}</td>
                      <td
                        :id="'modal-insured-payroll-base-' + record.id"
                      >{{ toCurrency(record.base) }}</td>
                      <td
                        :id="'modal-insured-payroll-benefits-' + record.id"
                      >{{ toCurrency(record.benefits) }}</td>
                      <td
                        :id="'modal-insured-payroll-fixed-bonuses-' + record.id"
                      >{{ toCurrency(record.fixedBonuses) }}</td>
                      <td
                        :id="'modal-insured-payroll-other-bonuses' + record.id"
                      >{{ toCurrency(record.otherBonusesAmount) }}</td>
                      <td
                        :id="'modal-insured-payroll-total-' + record.id"
                      >{{ toCurrency(record.totalAmount) }}</td>
                      <td class="uk-text-center">
                        <span
                          class="uk-badge"
                          v-if="record.paid === 1"
                          uk-icon="icon: check; ratio: 1;"
                        ></span>
                      </td>
                      <td class="uk-text-right">
                        <a
                          class="uk-link-reset"
                          href="#"
                          uk-switcher-item="next"
                          @click="setPayment(record)"
                        >
                          <button
                            class="uk-button uk-border-rounded uk-button-secondary"
                            uk-icon="chevron-right"
                          ></button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="uk-hidden@s">
                  <div
                    v-for="record in activeInsuredPayrollHistory.data"
                    :key="record.id"
                    class="bottom-divider"
                  >
                    <div class="uk-flex uk-flex-between">
                      <div
                        :id="'responsive-modal-insured-payroll-dates-' + activeInsured.id"
                      >{{ formatDate(record.start_date) }} - {{ formatDate(record.end_date) }}</div>
                      <div>
                        <span class="uk-badge" v-if="record.paid === 1">
                          <span uk-icon="icon: check; ratio: 1;"></span>Pagado
                        </span>
                      </div>
                    </div>
                    <div class="uk-flex uk-flex-between">
                      <div class="uk-width-expand" uk-leader>Base</div>
                      <div
                        :id="'responsive-modal-insured-payroll-base-' + activeInsured.id"
                      >{{ toCurrency(record.base) }}</div>
                    </div>
                    <div class="uk-flex uk-flex-between">
                      <div class="uk-width-expand" uk-leader>Prestaciones</div>
                      <div
                        :id="'responsive-modal-insured-payroll-benefits-' + activeInsured.id"
                      >{{ toCurrency(record.benefits) }}</div>
                    </div>
                    <div class="uk-flex uk-flex-between">
                      <div class="uk-width-expand" uk-leader>+ Bonos de productividad</div>
                      <div
                        :id="'responsive-modal-insured-payroll-fixed-bonuses-' + activeInsured.id"
                      >{{ toCurrency(record.fixedBonuses) }}</div>
                    </div>
                    <div class="uk-flex uk-flex-between" v-if="record.other_bonuses.length !== 0">
                      <div class="uk-width-expand" uk-leader>+ Otros bonos</div>
                      <div
                        :id="'responsive-modal-insured-payroll-other-bonuses' + activeInsured.id"
                      >{{ toCurrency(record.otherBonusesAmount) }}</div>
                    </div>
                    <div class="uk-flex uk-flex-between">
                      <div class="uk-width-expand" uk-leader>Total</div>
                      <div
                        :id="'responsive-modal-insured-payroll-total-' + activeInsured.id"
                      >{{ toCurrency(record.totalAmount) }}</div>
                    </div>
                    <div class="uk-margin-small-top uk-text-center">
                      <a
                        class="uk-link-reset"
                        href="#"
                        uk-switcher-item="next"
                        @click="setPayment(record)"
                      >
                        <button
                          class="uk-width-1-1 uk-button uk-border-rounded uk-button-secondary"
                          uk-icon="chevron-right"
                        >Desglose</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="activeInsuredPayrollHistory === null && !isLoadingHistory"
                class="uk-text-center"
              >No hay pagos registrados.</div>
            </div>
          </li>

          <li>
            <payment-detail :payment="activePayment" />
            <div class="uk-margin-small-top uk-text-center">
              <a class="uk-link-reset" href="#" uk-switcher-item="previous">
                <button class="uk-button uk-border-rounded uk-button-secondary">
                  <span uk-icon="chevron-left"></span> Regresar
                </button>
              </a>
            </div>
          </li>
        </ul>
        <div class="uk-text-center uk-margin-top" v-if="isLoadingHistory">
          <span uk-spinner></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import xlsx from "xlsx";

export default {
  name: "DirectoryLaidOffs",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      },
      model: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        gender: "",
        birthdate: "",
        dpi: "",
        nit: "",
        civil_state: "",
        type: "",
        status: "",
        title: "",
        tags: "",
        business_division_id: "",
        location: "",
        boss: "",
        igss: "",
        start_date: "",
        emergency_contact_name: "",
        emergency_contact_phone: "",
        emergency_contact_relationship: ""
      },
      recontractDate: null,
      insuredAction: null,
      itemsPerPage: 20,
      itemsPerPageHistory: 20,
      activeInsuredId: "",
      activeInsured: "",
      activeInsuredPayrollHistory: {},
      activePayment: "",
      isLoading: false,
      isLoadingHistory: false,
      isFetching: false,
      mode: "new",
      insuredFilter: "",
      insureds: {},
      insuredFilterTimer: null,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  mounted() {
    this.insureds = this.payload.insureds;
  },

  computed: {
    firstNameIsValid() {
      return this.model.first_name.length > 1;
    },
    lastNameIsValid() {
      return this.model.last_name.length > 1;
    },
    phoneIsValid() {
      return this.model.phone.length === 8;
    },
    emailIsValid() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.model.email) || this.model.email === "";
    },
    genderIsValid() {
      return this.model.gender;
    },
    birthdateIsValid() {
      return this.model.birthdate;
    },
    dpiIsValid() {
      return this.cuiIsValid(this.model.dpi);
    },
    nitIsValid() {
      if (this.model.nit === "") {
        return true;
      } else {
        let nd,
          add = 0;
        if ((nd = /^(\d+)\-?([\dk])$/i.exec(this.model.nit))) {
          nd[2] = nd[2].toLowerCase() == "k" ? 10 : parseInt(nd[2]);
          for (let i = 0; i < nd[1].length; i++) {
            add += ((i - nd[1].length) * -1 + 1) * nd[1][i];
          }
          return (11 - (add % 11)) % 11 == nd[2];
        } else {
          return false;
        }
      }
    },
    civilStateIsValid() {
      return this.model.civil_state;
    },
    typeIsValid() {
      return this.model.type;
    },
    statusIsValid() {
      return this.model.status;
    },
    titleIsValid() {
      return this.model.title;
    },
    businessDivisionIdIsValid() {
      return this.model.business_division_id;
    },
    locationIsValid() {
      return this.model.location;
    },
    bossIsValid() {
      return this.model.boss || this.model.boss === "";
    },
    igssIsValid() {
      return this.model.igss === "" || this.model.igss.length > 1;
    },
    startDateIsValid() {
      return this.model.start_date;
    },
    emergencyContactNameIsValid() {
      return (
        this.model.emergency_contact_name === "" ||
        this.model.emergency_contact_name === null ||
        this.model.emergency_contact_name.length > 1
      );
    },
    emergencyContactPhoneIsValid() {
      return (
        this.model.emergency_contact_phone === "" ||
        this.model.emergency_contact_phone === null ||
        this.model.emergency_contact_phone.length === 8
      );
    },
    emergencyContactRelationshipIsValid() {
      return true;
    },
    formIsValid() {
      const fields = [
        this.firstNameIsValid,
        this.lastNameIsValid,
        this.phoneIsValid,
        this.emailIsValid,
        this.genderIsValid,
        this.birthdateIsValid,
        this.dpiIsValid,
        this.nitIsValid,
        this.cuiIsValid,
        this.civilStateIsValid,
        this.typeIsValid,
        this.statusIsValid,
        this.titleIsValid,
        this.businessDivisionIdIsValid,
        this.locationIsValid,
        this.bossIsValid,
        this.igssIsValid,
        this.startDateIsValid,
        this.emergencyContactNameIsValid,
        this.emergencyContactPhoneIsValid,
        this.emergencyContactRelationshipIsValid
      ];

      return fields.every(field => field);
    }
  },

  methods: {
    moment,
    confirmRevert() {
      this.insuredAction = "confirm-revert";
    },
    confirmRecontract() {
      this.insuredAction = "confirm-recontract";
    },
    cancelInsuredAction() {
      this.insuredAction = null;
    },
    revertLayOff() {
      this.isLoading = true;
      axios
        .post("/directory/laid-offs/revert", {
          insured_business_id: this.activeInsured.business_relation.id
        })
        .then(response => {
          this.refetchPage(() => {
            UIkit.modal("#insured-history-modal").hide();
            this.isLoading = false;
          });
        })
        .catch(error => {
          if (
            error.response.data &&
            error.response.data.message === "layoff_payment_paid"
          ) {
            UIkit.notification(
              "<div class='uk-border-rounded uk-background-muted uk-padding-small'>No es posible revertir a este colaborador ya que su pago de liquidación se ha marcado como pagado.</div>",
              { status: "warning", timeout: 5000 }
            );
          }
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    recontract() {
      this.isLoading = true;
      axios
        .post("/directory/laid-offs/recontract", {
          insured_business_id: this.activeInsured.business_relation.id,
          application_date: "2020-01-08"
        })
        .then(response => {
          this.refetchPage(() => {
            UIkit.modal("#insured-history-modal").hide();
            this.isLoading = false;
          });
        })
        .catch(error => {
          if (
            error.response.data &&
            error.response.data.message === "layoff_payment_paid"
          ) {
            UIkit.notification(
              "<div class='uk-border-rounded uk-background-muted uk-padding-small'>No es posible revertir a este colaborador ya que su pago de liquidación se ha marcado como pagado.</div>",
              { status: "warning", timeout: 5000 }
            );
          }
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    tagLink(tag) {
      if (
        this.payload.filtertags &&
        this.payload.filtertags.includes(tag.name.es)
      ) {
        return "#";
      } else {
        if (this.payload.filtertags && this.payload.filtertags) {
          return (
            "/directory/general?" +
            this.payload.filtertags
              .map(
                (filtertag, index) =>
                  "filtertags[" + index.toString() + "]=" + filtertag
              )
              .join("&") +
            "&filtertags[" +
            this.payload.filtertags.length.toString() +
            "]=" +
            tag.name.es
          );
        } else {
          return "/directory/general?filtertags[]=" + tag.name.es;
        }
      }
    },
    removeTagLink(tag) {
      let remainingTags = this.payload.filtertags.map(tag => tag);
      remainingTags.splice(remainingTags.indexOf(tag), 1);
      if (remainingTags.length !== 0) {
        return (
          "/directory/general?" +
          remainingTags
            .map(
              (filtertag, index) =>
                "filtertags[" + index.toString() + "]=" + filtertag
            )
            .join("&")
        );
      } else {
        return "/directory/general";
      }
    },
    setNewMode() {
      this.mode = "new";
      UIkit.switcher("#add-employee-switcher").show(0);
      this.model.first_name = "";
      this.model.last_name = "";
      this.model.phone = "";
      this.model.gender = "";
      this.model.birthdate = "";
      this.model.dpi = "";
      this.model.nit = "";
      this.model.civil_state = "";
      this.model.email = "";
      this.model.type = "";
      this.model.status = "";
      this.model.title = "";
      this.model.tags = "";
      this.model.business_division_id = "";
      this.model.location = "";
      this.model.boss = "";
      this.model.igss = "";
      this.model.start_date = "";
      this.model.emergency_contact_name = "";
      this.model.emergency_contact_phone = "";
      this.model.emergency_contact_relationship = "";
    },
    setEditMode(insured, index) {
      this.insuredAction = null;
      UIkit.switcher("#payroll-modal-switcher").show(0);
      this.activeInsured = insured;
      this.activeInsuredPayrollHistory = null;
      this.activeInsuredIndex = index;
      if (insured.business_relation.payroll_history_count !== 0) {
        this.payrollConfigurationMode = "view";
        this.fetchPaymentsHistoryPage();
      }
    },
    setPayment(payment) {
      this.activePayment = payment;
    },
    fetchPaymentsHistoryPage(url) {
      this.isLoadingHistory = true;
      if (url) {
        url = url + "&items=" + this.itemsPerPageHistory;
      }
      axios
        .get(
          url
            ? url
            : "/payroll/history-payments?insured_business_id=" +
                this.activeInsured.business_relation.id +
                "&items=" +
                this.itemsPerPageHistory.toString()
        )
        .then(({ data: { payments } }) => {
          this.activeInsuredPayrollHistory = payments;
          this.isLoadingHistory = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isLoadingHistory = false;
        });
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.insuredFilter && !url.includes("&query")) {
        url = url + "&query=" + this.insuredFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { insureds } }) => {
          this.insureds = insureds;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.insuredFilterTimer) {
        clearTimeout(this.insuredFilterTimer);
      }
      this.insuredFilterTimer = setTimeout(() => {
        let urlSearchString = new URL(window.location.href).search;

        this.fetchPage(
          window.location.pathname +
            urlSearchString +
            (urlSearchString == "" ? "?" : "&") +
            "dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.insuredFilterTimer = null;
      }, 500);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.insureds.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { insureds } }) => {
          this.insureds = insureds;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando colaborador...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/directory/general", {
          ...this.model,
          tags: ["gerencia", "administración"]
        })
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-insured-modal").hide();
            UIkit.switcher("#add-employee-switcher").show(0);
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Colaborador creado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando colaborador...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put(
          "/directory/general/" + this.activeInsuredId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-insured-modal").hide();
            UIkit.switcher("#add-employee-switcher").show(0);
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Colaborador actualizado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteInsured() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando colaborador...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/directory/general/" + this.activeInsuredId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Colaborador eliminado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleFile(e) {
      this.isLoading = true;
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        let newInsureds = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .slice(2);

        newInsureds = newInsureds.map(insured => {
          insured.start_date = moment(insured.start_date).format("YYYY-MM-DD");
          insured.birthdate = moment(insured.birthdate).format("YYYY-MM-DD");
          insured.civil_state = insured.civil_state.substr(0, 1);
          insured.gender = insured.gender.substr(0, 1);
          return insured;
        });

        axios
          .post("/directory/general/bulk", newInsureds)
          .then(({ data }) => {
            this.refetchPage(() => {
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  data.createdCount +
                  " Colaboradores creados, " +
                  data.updatedCount +
                  " actualizados.</div>",
                { status: "success", timeout: 3000 }
              );
              this.isLoading = false;
              UIkit.modal("#import-export-modal").hide();
            });
          })
          .catch(error => {
            console.log("error", error);
            this.isLoading = false;
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        gender: "",
        birthdate: "",
        dpi: "",
        nit: "",
        civil_state: "",
        type: "",
        status: "",
        title: "",
        tags: "",
        business_division_id: "",
        location: "",
        boss: "",
        igss: "",
        start_date: "",
        emergency_contact_name: "",
        emergency_contact_phone: "",
        emergency_contact_relationship: ""
      };
    },

    cuiIsValid(cui) {
      // var console = window.console;
      if (!cui) {
        // console.log("CUI vacío");
        return false;
      }

      var cuiRegExp = /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/;

      if (!cuiRegExp.test(cui)) {
        // console.log("CUI con formato inválido");
        return false;
      }

      cui = cui.replace(/\s/, "");
      var depto = parseInt(cui.substring(9, 11), 10);
      var muni = parseInt(cui.substring(11, 13));
      var numero = cui.substring(0, 8);
      var verificador = parseInt(cui.substring(8, 9));

      // Se asume que la codificación de Municipios y
      // departamentos es la misma que esta publicada en
      // http://goo.gl/EsxN1a

      // Listado de municipios actualizado segun:
      // http://goo.gl/QLNglm

      // Este listado contiene la cantidad de municipios
      // existentes en cada departamento para poder
      // determinar el código máximo aceptado por cada
      // uno de los departamentos.
      var munisPorDepto = [
        /* 01 - Guatemala tiene:      */ 17 /* municipios. */,
        /* 02 - El Progreso tiene:    */ 8 /* municipios. */,
        /* 03 - Sacatepéquez tiene:   */ 16 /* municipios. */,
        /* 04 - Chimaltenango tiene:  */ 16 /* municipios. */,
        /* 05 - Escuintla tiene:      */ 13 /* municipios. */,
        /* 06 - Santa Rosa tiene:     */ 14 /* municipios. */,
        /* 07 - Sololá tiene:         */ 19 /* municipios. */,
        /* 08 - Totonicapán tiene:    */ 8 /* municipios. */,
        /* 09 - Quetzaltenango tiene: */ 24 /* municipios. */,
        /* 10 - Suchitepéquez tiene:  */ 21 /* municipios. */,
        /* 11 - Retalhuleu tiene:     */ 9 /* municipios. */,
        /* 12 - San Marcos tiene:     */ 30 /* municipios. */,
        /* 13 - Huehuetenango tiene:  */ 32 /* municipios. */,
        /* 14 - Quiché tiene:         */ 21 /* municipios. */,
        /* 15 - Baja Verapaz tiene:   */ 8 /* municipios. */,
        /* 16 - Alta Verapaz tiene:   */ 17 /* municipios. */,
        /* 17 - Petén tiene:          */ 14 /* municipios. */,
        /* 18 - Izabal tiene:         */ 5 /* municipios. */,
        /* 19 - Zacapa tiene:         */ 11 /* municipios. */,
        /* 20 - Chiquimula tiene:     */ 11 /* municipios. */,
        /* 21 - Jalapa tiene:         */ 7 /* municipios. */,
        /* 22 - Jutiapa tiene:        */ 17 /* municipios. */
      ];

      if (depto === 0 || muni === 0) {
        // console.log("CUI con código de municipio o departamento inválido.");
        return false;
      }

      if (depto > munisPorDepto.length) {
        // console.log("CUI con código de departamento inválido.");
        return false;
      }

      if (muni > munisPorDepto[depto - 1]) {
        // console.log("CUI con código de municipio inválido.");
        return false;
      }

      // Se verifica el correlativo con base
      // en el algoritmo del complemento 11.
      var total = 0;

      for (var i = 0; i < numero.length; i++) {
        total += numero[i] * (i + 2);
      }

      var modulo = total % 11;

      // console.log("CUI con módulo: " + modulo);
      return modulo === verificador;
    }
  },

  watch: {
    insuredFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    itemsPerPageHistory: function() {
      this.fetchPaymentsHistoryPage();
    }
  }
};
</script>

<style scoped>
.user-logo {
  color: white;
  background: #2c5fa1;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  letter-spacing: -3px;
}

.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}

.rounded-avatar {
  display: block;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;
}

.uk-badge {
  margin: 1px;
}
</style>

<style>
.v-select.searchable .dropdown-toggle {
  border-radius: 15px;
  background: white;
  border: none;
}

.v-select .selected-tag {
  border-radius: 15px;
  background-color: #2c5fa1;
  color: white;
  padding: 5px 10px;
}

.bottom-divider {
  border-bottom: solid 1px;
  padding: 10px 0;
}
</style>