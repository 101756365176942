<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Tipos de descuentos</div>
      <div class="uk-text-center">
        <!-- <button class="uk-button uk-border-rounded uk-button-primary" href="#import-export-modal" uk-toggle>Importar / Exportar</button> -->
        <button
          class="uk-button uk-border-rounded uk-button-secondary uk-margin"
          href="#edit-discount-type-modal"
          uk-toggle
          @click="setNewMode"
        >Crear tipo de descuento</button>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input search-input-animation"
            type="search"
            placeholder="Buscar tipo de descuento..."
            v-model="discountFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="types.prev_page_url"
          :is-fetching="isFetching"
          :current-page="types.current_page"
          :last-page="types.last_page"
          :next-page-url="types.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Prestaciones asociadas</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="discount in types.data" :key="discount.id">
            <td>{{ discount.name }}</td>
            <td>{{ discount.benefits.map(benefit => benefit.name).join(', ') }}</td>
            <td width="10%" class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <!-- <button
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                  href="#edit-discount-type-modal"
                  uk-toggle
                  @click="setEditMode(discount)"
                  uk-icon="pencil"
                ></button>-->
                <button
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(discount)"
                  uk-icon="trash"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="bottom-divider uk-hidden@s" v-for="discount in types.data" :key="discount.id">
        <div>{{ discount.name }}</div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
        >
          <!-- <button
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-discount-type-modal"
            uk-toggle
            @click="setEditMode(discount)"
            uk-icon="pencil"
          ></button>-->
          <button
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(discount)"
            uk-icon="trash"
          ></button>
        </div>
      </div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div
          class="uk-text-large"
        >¿Está seguro que desea eliminar el tipo de descuento {{ this.model.name }}?</div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteDiscount"
            :disabled="isLoading"
          >Eliminar tipo de descuento</button>
        </div>
      </div>
    </div>

    <div id="edit-discount-type-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          tipo de descuento
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <input v-model="model.name" class="uk-input uk-border-rounded" type="text" />
            </div>
          </div>
        </div>
        <label class="uk-form-label" for="form-stacked-text">Prestaciones asociadas</label>
        <div class="uk-margin" v-for="benefit in payload.benefits" :key="benefit.id">
          <label>
            <input class="uk-checkbox" type="checkbox" v-model="model.benefits[benefit.id]" />
            {{ benefit.name }}
          </label>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !formIsValid"
            v-if="mode === 'new'"
          >Asignar tipo de descuento</button>
          <button
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !formIsValid"
            v-else
          >Actualizar tipo de descuento</button>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <a
            class="uk-button uk-border-rounded uk-button-secondary"
            :href="payload.uploadTemplateUrl"
            target="”_blank”"
          >Descargar plantilla de importación</a>
        </div>
        <div class="uk-margin uk-text-center">
          <div uk-form-custom>
            <input name="file" type="file" @change="handleImportFile($event)" />
            <button class="uk-button uk-border-rounded uk-button-secondary">Importar</button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <a
            href="/payroll/discount-types/bulk"
            class="uk-button uk-border-rounded uk-button-secondary"
          >Exportar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import moment from "moment";
import xlsx from "xlsx";

export default {
  name: "PayrollBonusTypes",

  props: ["payload"],

  data() {
    return {
      model: {
        name: "",
        benefits: {}
      },
      itemsPerPage: 20,
      activeBonusTypeId: "",
      isLoading: false,
      isUpdating: false,
      isFetching: false,
      mode: "new",
      discountFilter: "",
      types: {},
      discountFilterTimer: null
    };
  },

  mounted() {
    this.types = this.payload.types;
  },

  computed: {
    nameIsValid() {
      return this.model.name !== "";
    },
    formIsValid() {
      const fields = [this.nameIsValid];

      return fields.every(field => field);
    }
  },

  methods: {
    moment,
    setNewMode() {
      this.mode = "new";
      this.model.name = "";
      this.model.benefits = {};
    },
    setEditMode(discount) {
      this.mode = "edit";
      this.activeBonusTypeId = discount.id;
      this.model.name = discount.name;
      this.model.benefits = this.payload.benefits.reduce((accum, benefit) => {
        accum[benefit.id] = discount.benefits.find(
          discountBenefit => discountBenefit.id === benefit.id
        )
          ? true
          : false;
        return accum;
      }, {});
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.discountFilter && !url.includes("&query")) {
        url = url + "&query=" + this.discountFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { types } }) => {
          this.types = types;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.discountFilterTimer) {
        clearTimeout(this.discountFilterTimer);
      }
      this.discountFilterTimer = setTimeout(() => {
        let urlSearchString = new URL(window.location.href).search;

        this.fetchPage(
          window.location.pathname +
            urlSearchString +
            (urlSearchString == "" ? "?" : "&") +
            "dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.discountFilterTimer = null;
      }, 500);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.types.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { types } }) => {
          this.types = types;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isUpdating = true;
      axios
        .post("/payroll/discount-types", {
          ...this.model,
          benefits: this.payload.benefits
            .filter(benefit => this.model.benefits[benefit.id])
            .map(benefit => benefit.id)
        })
        .then(response => {
          this.refetchPage(() => {
            this.resetForm();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Tipo de descuento creado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isUpdating = false;
            UIkit.modal("#edit-discount-type-modal").hide();
          });
        })
        .catch(error => {
          console.log("error", error);
          this.isUpdating = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando tipo de descuento...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put("/payroll/discount-types/" + this.activeBonusTypeId.toString(), {
          ...this.model,
          benefits: this.payload.benefits
            .filter(benefit => this.model.benefits[benefit.id])
            .map(benefit => benefit.id)
        })
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-discount-type-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Tipo de descuento actualizado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteDiscount() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando tipo de descuento...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/payroll/discount-types/" + this.activeBonusTypeId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>Bono eliminado</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleImportFile(e) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        let newBonuses = xlsx.utils
          .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 2 })
          .slice(3);

        newBonuses = newBonuses.map(discount => {
          discount.application_date = moment(discount.application_date).format(
            "YYYY-MM-DD"
          );
          return discount;
        });

        axios
          .post("/payroll/discount-types/bulk", newBonuses)
          .then(({ data }) => {
            this.refetchPage(() => {
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  data.count +
                  " descuentos creados</div>",
                { status: "success", timeout: 3000 }
              );
            });
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      reader.readAsBinaryString(f);
    },

    handleFileChange(file) {
      this.model.file = file;
      this.model.filename = file.name;
    },

    resetForm() {
      this.model.name = "";
      this.model.benefits = {};
    }
  },

  watch: {
    discountFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.user-logo {
  color: white;
  background: #2c5fa1;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  letter-spacing: -3px;
}

.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}

.avatar {
  width: 80px;
  max-width: 80px;
}

.uk-badge {
  margin: 1px;
}

.v-select.searchable .dropdown-toggle {
  border-radius: 15px;
  background: white;
  border: none;
}

.v-select .selected-tag {
  border-radius: 15px;
  background-color: #2c5fa1;
  color: white;
  padding: 5px 10px;
}

.bottom-divider {
  border-bottom: solid 1px;
  padding: 10px 0;
}
</style>