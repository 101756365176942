<template>
<div class="uk-margin-bottom">
  <div class="uk-text-center uk-margin-large-top">
    <img src="/images/logo.png" width="300px" alt="">
  </div>
  <form method="POST" action="/password/reset" class="uk-width-medium uk-margin-auto uk-text-center">
    <input type="hidden" :value="csrf" name="_token">
    <input type="text" name="token" hidden :value="token">
    <div class="uk-margin-large-top uk-text-large border-bottom">Recuperar contraseña</div>
    <input name="email" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="text" placeholder="Email">
    <div class="uk-text-danger" v-if="errors.email">{{ errors.email[0] }}</div>
    <input name="password" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="password" placeholder="Contraseña">
    <input name="password_confirmation" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="password" placeholder="Confirmación de contraseña">
    <div class="uk-text-danger" v-if="errors.password">{{ errors.password[0] }}</div>
    <button class="uk-margin-top uk-width-1-1 uk-button uk-border-rounded uk-button-secondary" type="submit">Actualizar contraseña</button>
    <a href="/login" class="uk-margin-top uk-width-1-1 uk-button uk-border-rounded uk-button-primary">Iniciar sesión</a>
  </form>
</div>
</template>

<script>
export default {
  name: 'AuthPasswordReset',

  props: ['csrf', 'token', 'errors']
}
</script>

<style>
.border-bottom {
  border-bottom: solid 1px;
}
</style>