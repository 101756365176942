<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Divisiones</div>
      <div>
        <!-- <button class="uk-button uk-border-rounded uk-button-primary" href="#import-export-modal" uk-toggle>Importar / Exportar</button> -->
        <button
          dusk="add-division"
          class="uk-button uk-border-rounded uk-button-secondary"
          href="#edit-division-modal"
          uk-toggle
          @click="setNewMode"
          v-if="checkPermission('create-division')"
        >Añadir división</button>
      </div>
    </div>

    <div
      class="uk-card uk-card-default uk-card-body uk-border-rounded uk-margin-top uk-overflow-auto"
    >
      <div class="uk-flex uk-flex-wrap uk-flex-between">
        <form class="uk-search uk-search-default uk-width-1-2@s">
          <span uk-search-icon></span>
          <input
            class="uk-search-input search-input-animation"
            type="search"
            placeholder="Buscar división..."
            v-model="divisionFilter"
          />
        </form>
        <pagination-buttons
          :prev-page-url="divisions.prev_page_url"
          :is-fetching="isFetching"
          :current-page="divisions.current_page"
          :last-page="divisions.last_page"
          :next-page-url="divisions.next_page_url"
        ></pagination-buttons>
      </div>
      <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-visible@s">
        <thead>
          <tr>
            <th width="80%">Nombre</th>
            <th width="20%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(division, index) in divisions.data" :key="division.id">
            <td>{{ division.name }}</td>
            <td class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  :dusk="'edit-division-button-show-' + division.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-primary"
                  href="#edit-division-modal"
                  uk-toggle
                  @click="setEditMode(division, index)"
                  uk-icon="pencil"
                  v-if="checkPermission('edit-division')"
                ></button>
                <button
                  :dusk="'delete-division-button-show-' + division.id"
                  class="uk-button uk-width-1-2 uk-padding-remove uk-button-danger"
                  href="#confirm-deletion-modal"
                  uk-toggle
                  @click="setEditMode(division, index)"
                  uk-icon="trash"
                  v-if="checkPermission('delete-division')"
                ></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="bottom-divider uk-hidden@s"
        v-for="(division, index) in divisions.data"
        :key="division.id"
      >
        <div class="uk-text-center uk-text-bold">
          <span>{{ division.name }}</span>
        </div>
        <div
          class="uk-width-1-1 uk-button-group uk-border-rounded uk-overflow-hidden uk-margin-small-top"
        >
          <button
            class="uk-button uk-width-1-2 uk-button-primary"
            href="#edit-division-modal"
            uk-toggle
            @click="setEditMode(division, index)"
            uk-icon="pencil"
            v-if="checkPermission('edit-division')"
          ></button>
          <button
            class="uk-button uk-width-1-2 uk-button-danger"
            href="#confirm-deletion-modal"
            uk-toggle
            @click="setEditMode(division, index)"
            uk-icon="trash"
            v-if="checkPermission('delete-division')"
          ></button>
        </div>
      </div>

      <div
        v-if="divisions.data && divisions.data.length === 0"
        class="uk-text-center uk-margin-top"
      >No se han encontrado resultados con los filtros seleccionados.</div>
    </div>

    <div id="confirm-deletion-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span>¿Está seguro que desea eliminar la división {{ this.model.name }}?</span>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            dusk="delete-division-accept"
            class="uk-button uk-border-rounded uk-button-danger"
            @click="deleteDivision"
            :disabled="isLoading"
          >Eliminar división</button>
        </div>
      </div>
    </div>

    <div id="edit-division-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Añadir</span>
          <span v-else>Editar</span>
          división
        </div>
        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-text">Nombre</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span
                id="division-name-check"
                class="uk-form-icon uk-form-icon-flip uk-border-circle validation-check"
                :class="{ 'valid-check': nameIsValid }"
                uk-icon="check"
              ></span>
              <input
                name="new-division-name"
                class="uk-input uk-border-rounded"
                type="text"
                v-model="model.name"
              />
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            dusk="create-division"
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="store"
            :disabled="isLoading || !nameIsValid"
            v-if="mode === 'new'"
          >Crear división</button>
          <button
            dusk="update-division"
            class="uk-button uk-border-rounded uk-button-secondary"
            @click="update"
            :disabled="isLoading || !nameIsValid"
            v-else
          >Actualizar división</button>
        </div>
      </div>
    </div>

    <div id="import-export-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-margin uk-text-center">
          <div uk-form-custom>
            <input name="file" type="file" @change="handleFile($event)" />
            <button class="uk-button uk-border-rounded uk-button-secondary">Importar</button>
          </div>
        </div>
        <hr class="uk-divider-icon" />
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-border-rounded uk-button-secondary">Exportar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import xlsx from "xlsx";

export default {
  name: "DirectoryDivision",

  props: ["payload"],

  data() {
    return {
      model: {
        name: ""
      },
      itemsPerPage: 20,
      activeDivisionId: "",
      payloadPosition: "",
      isLoading: false,
      isFetching: false,
      mode: "new",
      divisionFilter: "",
      divisions: {},
      divisionFilterTimer: null
    };
  },

  mounted() {
    this.divisions = this.payload.divisions;
  },

  computed: {
    nameIsValid() {
      return this.model.name.length;
    }
  },

  methods: {
    setNewMode() {
      this.mode = "new";
      this.model.name = "";
    },
    setEditMode(division, index) {
      this.mode = "edit";
      this.activeDivisionId = division.id;
      this.payloadPosition = index;
      this.model.name = division.name;
    },
    fetchPage(url) {
      this.isFetching = true;
      if (this.divisionFilter && !url.includes("&query")) {
        url = url + "&query=" + this.divisionFilter;
      }
      url = url + "&items=" + this.itemsPerPage;
      axios
        .get(url)
        .then(({ data: { divisions } }) => {
          this.divisions = divisions;
          this.isFetching = false;
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
        });
    },
    search(query) {
      if (this.divisionFilterTimer) {
        clearTimeout(this.divisionFilterTimer);
      }
      this.divisionFilterTimer = setTimeout(() => {
        this.fetchPage(
          window.location.pathname +
            "?dataOnly=true&query=" +
            query +
            "&items=" +
            this.itemsPerPage
        );
        this.divisionFilterTimer = null;
      }, 1000);
    },
    refetchPage(callback) {
      this.isFetching = true;
      axios
        .get(
          window.location.pathname +
            "?dataOnly=true&page=" +
            this.divisions.current_page.toString() +
            "&items=" +
            this.itemsPerPage
        )
        .then(({ data: { divisions } }) => {
          this.divisions = divisions;
          this.isFetching = false;
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isFetching = false;
          callback();
        });
    },
    store() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Creando división...",
        { status: "primary", timeout: 0 }
      );
      axios
        .post("/directory/divisions", this.model)
        .then(({ data: { model } }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-division-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='successfull-store' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>División creada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Actualizando división...",
        { status: "primary", timeout: 0 }
      );
      axios
        .put(
          "/directory/divisions/" + this.activeDivisionId.toString(),
          this.model
        )
        .then(({ data: { model } }) => {
          this.refetchPage(() => {
            UIkit.modal("#edit-division-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='successfull-edit' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>División actualizada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },
    deleteDivision() {
      this.isLoading = true;
      const loader = UIkit.notification(
        "<span uk-spinner='ratio: 1'></span> Eliminando división...",
        { status: "primary", timeout: 0 }
      );
      axios
        .delete(
          "/directory/divisions/" + this.activeDivisionId.toString(),
          this.model
        )
        .then(({ response }) => {
          this.refetchPage(() => {
            UIkit.modal("#confirm-deletion-modal").hide();
            this.resetForm();
            loader.close();
            const success = UIkit.notification(
              "<div id='successdull-delete' class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>División eliminada</div>",
              { status: "success", timeout: 3000 }
            );
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log("error", error);
          loader.close();
          this.isLoading = false;
        });
    },

    handleFile(e) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: "binary",
          cellDates: true
        });

        const newDivisions = xlsx.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 2 }
        );

        axios
          .post("/directory/divisions/bulk", newDivisions)
          .then(({ data }) => {
            this.refetchPage(() => {
              UIkit.modal("#import-export-modal").hide();
              const success = UIkit.notification(
                "<div class='uk-border-rounded uk-padding-small uk-background-secondary text-white'>" +
                  data.count +
                  " Divisiones creadas</div>",
                { status: "success", timeout: 3000 }
              );
            });
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      reader.readAsBinaryString(f);
    },

    resetForm() {
      this.model = {
        name: ""
      };
    }
  },

  watch: {
    divisionFilter: function(value) {
      this.search(value);
    },
    itemsPerPage: function() {
      this.refetchPage(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
.uk-form-icon-flip {
  right: 15px;
}

.validation-check {
  transition: background 0.3s;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.valid-check {
  background: rgb(78, 194, 163);
}
</style>