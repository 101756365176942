<template>
  <div>
    <div class="uk-flex uk-flex-wrap uk-flex-between">
      <div class="uk-text-large">Asistencia del día</div>
      <div class="uk-flex uk-flex-center">
        <div class="uk-padding-small uk-width-medium">
          <flat-pickr
            id="start-date"
            class="uk-input uk-border-rounded uk-text-center"
            v-model="currentDate"
            :config="datepickerConfig"
            :disabled="isLoading"
          ></flat-pickr>
        </div>
      </div>
      <div>
        <a
          class="uk-button uk-border-rounded uk-button-primary"
          href="/time-tracking/general/history"
          >Historial</a
        >
        <a
          class="uk-button uk-border-rounded uk-button-primary"
          :href="
            '/time-tracking/general/bulk?' +
              typeFilter
                .filter(type => type.selected)
                .map(type => '&types[]=' + type.slug)
                .join('') +
              '&date=' +
              currentDate
          "
          >Exportar asistencias en rango</a
        >
      </div>
    </div>

    <div class="uk-grid-small uk-child-width-auto uk-grid uk-flex-center">
      <label v-for="(type, index) in typeFilter" :key="index">
        <input
          class="uk-checkbox"
          type="checkbox"
          :disabled="isLoading"
          v-model="type.selected"
          @change="changeTypeFilter"
        />
        {{ type.name }}
      </label>
    </div>

    <div class="uk-flex uk-flex-between">
      <div class="uk-width-2-5@s uk-padding-small">
        <form
          class="uk-search uk-search-default uk-width-1-1 uk-margin-small-top"
        >
          <span uk-search-icon></span>
          <form autocomplete="off">
            <input
              autocomplete="off"
              class="uk-search-input search-input-animation uk-border-rounded"
              type="search"
              placeholder="Buscar colaborador..."
              v-model="checksFilter"
            />
          </form>
        </form>

        <div class="uk-text-center uk-margin-top" v-if="isLoading">
          <span uk-spinner></span>
        </div>

        <div class="uk-margin-top uk-height-max-large uk-overflow-auto" v-else>
          <ul class="uk-list uk-list-divider">
            <li
              v-for="check in filteredChecks"
              :key="check.id"
              @click="setActiveCheck(check)"
              :class="{
                'uk-background-primary text-white':
                  activeCheck && check.id === activeCheck.id && info.open
              }"
              class="cursor-pointer uk-margin-remove uk-padding-small uk-border-rounded"
            >
              <div>
                {{
                  check.insured_business.insured.first_name +
                    " " +
                    check.insured_business.insured.last_name
                }}
              </div>
              <div class="text-light">{{ check.type.name }}</div>
              <div class="text-light">
                {{ formatDatetime(check.created_at) }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <GmapMap
        ref="checksMap"
        class="uk-border-rounded uk-overflow-hidden uk-margin-top uk-width-3-5@s"
        :center="center"
        :zoom="15"
        map-type-id="roadmap"
        style="width: 100%; height: 550px"
      >
        <GmapInfoWindow
          ref="checksMapInfoWindow"
          :options="info.options"
          :position="info.position"
          :opened="info.open"
          @closeclick="info.open = false"
        ></GmapInfoWindow>
        <GmapMarker
          v-for="check in filteredChecks"
          :key="check.id"
          :label="{
            text:
              check.insured_business.insured.first_name +
              ' ' +
              check.insured_business.insured.last_name,
            color: 'black'
          }"
          :position="{ lat: parseFloat(check.lat), lng: parseFloat(check.lng) }"
          :icon="check.icon"
          :clickable="true"
          :draggable="false"
          @click="setActiveCheck(check)"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { gmapApi } from "vue2-google-maps";
import Fuse from "fuse.js";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    "insured_business.insured.first_name",
    "insured_business.insured.last_name"
  ]
};

export default {
  name: "TimeTrackingIndex",

  props: ["payload"],

  components: {
    flatPickr
  },

  data() {
    return {
      center: {
        lat: 14.622283,
        lng: -90.513883
      },
      info: {
        open: false,
        position: null,
        options: {
          content: "",
          pixelOffset: {
            width: 0,
            height: -35
          }
        }
      },
      checks: [],
      activeCheck: null,
      checksFilter: null,
      typeFilter: this.payload.types.map(type => ({
        ...type,
        selected: true
      })),
      filterTimer: null,
      currentDate: this.payload.today,
      isLoading: false,
      datepickerConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: "j \\de F \\de Y"
      }
    };
  },

  mounted() {
    this.checks = this.payload.checks.map(check => ({
      ...check,
      icon: {
        url: "/images/checkin.png",
        scaledSize: {
          width: 40,
          height: 40
        },
        labelOrigin: {
          x: 0,
          y: 0
        }
      }
    }));
    if (this.checks.length !== 0) {
      this.$gmapApiPromiseLazy().then(() => {
        this.fitBounds();
      });
    }
  },

  computed: {
    google: gmapApi,
    filteredChecks: function() {
      let results;
      if (this.checksFilter && this.checksFilter !== "") {
        const fuse = new Fuse(this.checks, fuseOptions);
        results = fuse.search(this.checksFilter);
      } else {
        results = this.checks;
      }
      return results;
    }
  },

  methods: {
    changeTypeFilter() {
      if (this.filterTimer) {
        clearTimeout(this.filterTimer);
      }
      this.filterTimer = setTimeout(() => {
        this.refetchPage(() => {
          this.isLoading = false;
        });

        this.filterTimer = null;
      }, 500);
    },
    fitBounds() {
      const bounds = new google.maps.LatLngBounds();
      this.checks.map(check => {
        bounds.extend({
          lat: parseFloat(check.lat),
          lng: parseFloat(check.lng)
        });
      });
      this.$refs.checksMap.fitBounds(bounds);
    },
    setActiveCheck(check) {
      if (this.activeCheck ? check.id !== this.activeCheck.id : true) {
        this.activeCheck = check;
        this.center = {
          lat: parseFloat(check.lat),
          lng: parseFloat(check.lng)
        };
        this.info.options.content =
          "<div>" +
          (check.file
            ? "<div>" +
              "<img src='" +
              check.file.path +
              "' width='200px' class='uk-border-rounded'></img>" +
              "</div>"
            : "") +
          "<div class='uk-margin-small-top uk-text-bold'>" +
          check.insured_business.insured.first_name +
          " " +
          check.insured_business.insured.last_name +
          "</div>" +
          "<div>" +
          check.type.name +
          "</div>" +
          "<div>" +
          this.formatDatetime(check.created_at) +
          "</div>" +
          "</div>";
        this.info.position = this.center;
        this.info.open = true;
      } else {
        this.info.open = false;
        this.activeCheck = null;
      }
    },
    refetchPage(callback) {
      this.isLoading = true;
      let path =
        window.location.pathname +
        "?dataOnly=true" +
        "&date=" +
        this.currentDate +
        this.typeFilter
          .filter(type => type.selected)
          .map(type => "&types[]=" + type.slug)
          .join("");
      if (this.checksFilter && !path.includes("&query")) {
        path = path + "&query=" + this.checksFilter;
      }
      axios
        .get(path)
        .then(({ data: { checks } }) => {
          this.checks = checks.map(check => ({
            ...check,
            icon: {
              url: "/images/checkin.png",
              scaledSize: {
                width: 40,
                height: 40
              },
              labelOrigin: {
                x: 0,
                y: 0
              }
            }
          }));
          this.isLoading = false;
          if (checks.length !== 0) {
            this.fitBounds();
          }
          callback();
        })
        .catch(error => {
          console.log("error", error);
          this.isLoading = false;
          callback();
        });
    }
  },

  watch: {
    currentDate(value) {
      this.info.open = false;
      this.refetchPage(() => {
        this.isLoading = false;
      });
    },
    checksFilter() {
      this.info.open = false;
    }
  }
};
</script>