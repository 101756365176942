export default {
    updated: {
        translation: "Actualización",
        type: ""
    },
    created: {
        translation: "Creación",
        type: ""
    },
    deleted: {
        translation: "Eliminación",
        type: ""
    },
    restored: {
        translation: "Restauración",
        type: ""
    },
    id: {
        translation: "Identificador",
        type: ""
    },
    name: {
        translation: "Nombre",
        type: ""
    },
    slug: {
        translation: "Slug",
        type: ""
    },
    absence_type_id: {
        translation: "Identificador de tipo de ausencia",
        type: ""
    },
    created_at: {
        translation: "Fecha de creación",
        type: "date"
    },
    updated_at: {
        translation: "Fecha de actualización",
        type: "date"
    },
    address: {
        translation: "Dirección",
        type: ""
    },
    city: {
        translation: "Ciudad",
        type: ""
    },
    zip: {
        translation: "Código postal",
        type: ""
    },
    country_id: {
        translation: "Identificador de país",
        type: ""
    },
    addressable_type: {
        translation: "",
        type: ""
    },
    addressable_id: {
        translation: "",
        type: ""
    },
    user_id: {
        translation: "",
        type: ""
    },
    insurer_id: {
        translation: "",
        type: ""
    },
    policy_number: {
        translation: "Número de póliza",
        type: ""
    },
    signature: {
        translation: "Firma",
        type: ""
    },
    status: {
        translation: "Estado",
        type: ""
    },
    type: {
        translation: "Tipo",
        type: ""
    },
    action: {
        translation: "Acción",
        type: ""
    },
    parent_menu_id: {
        translation: "",
        type: ""
    },
    user_type: {
        translation: "Tipo de usuario",
        type: ""
    },
    event: {
        translation: "Evento",
        type: ""
    },
    auditable_type: {
        translation: "",
        type: ""
    },
    auditable_id: {
        translation: "",
        type: ""
    },
    old_values: {
        translation: "Valores anteriores",
        type: ""
    },
    new_values: {
        translation: "Valores nuevos",
        type: ""
    },
    url: {
        translation: "URL",
        type: ""
    },
    ip_address: {
        translation: "Dirección IP",
        type: ""
    },
    user_agent: {
        translation: "",
        type: ""
    },
    tags: {
        translation: "Tags",
        type: ""
    },
    bank_id: {
        translation: "Identificador de banco",
        type: ""
    },
    account_name: {
        translation: "Nombre de cuenta",
        type: ""
    },
    account_number: {
        translation: "Número de cuenta",
        type: ""
    },
    bank_account_type_id: {
        translation: "Identificador de tipo de cuenta bancaria",
        type: ""
    },
    bank_accountable_id: {
        translation: "",
        type: ""
    },
    bank_accountable_type: {
        translation: "",
        type: ""
    },
    bonus_type_id: {
        translation: "Identificador de tipo de bono",
        type: ""
    },
    benefit_id: {
        translation: "Identificador de prestación",
        type: ""
    },
    operation: {
        translation: "Operación",
        type: ""
    },
    business_id: {
        translation: "Empresa",
        type: ""
    },
    reason: {
        translation: "Razón",
        type: ""
    },
    amount: {
        translation: "Monto",
        type: ""
    },
    application_date: {
        translation: "Fecha de aplicación",
        type: "date"
    },
    paid: {
        translation: "Pagado",
        type: "boolean"
    },
    insured_business_id: {
        translation: "Identificador de relación empresa-colaborador",
        type: ""
    },
    recurrent_group: {
        translation: "Grupo de recurrencia",
        type: ""
    },
    new_business: {
        translation: "Nueva empresa",
        type: ""
    },
    business_plan_id: {
        translation: "",
        type: ""
    },
    coverage_option_id: {
        translation: "",
        type: ""
    },
    plan_id: {
        translation: "",
        type: ""
    },
    requirement_id: {
        translation: "",
        type: ""
    },
    file: {
        translation: "Archivo",
        type: ""
    },
    commercial_name: {
        translation: "Nombre comercial",
        type: ""
    },
    legal_name: {
        translation: "Nombre legal",
        type: ""
    },
    business_core: {
        translation: "Giro de negocio",
        type: ""
    },
    industry_id: {
        translation: "Identificador de industria",
        type: ""
    },
    nit: {
        translation: "NIT",
        type: ""
    },
    admin_user_id: {
        translation: "Identificador de usuario administrador",
        type: ""
    },
    hr_user_id: {
        translation: "Identificador de usuario de recursos humanos",
        type: ""
    },
    website: {
        translation: "Sitio web",
        type: ""
    },
    established_at: {
        translation: "Establecida en",
        type: ""
    },
    avatar: {
        translation: "Avatar",
        type: ""
    },
    english: {
        translation: "Inglés",
        type: ""
    },
    spanish: {
        translation: "Español",
        type: ""
    },
    iso2: {
        translation: "iso2",
        type: ""
    },
    iso3: {
        translation: "iso3",
        type: ""
    },
    phone_code: {
        translation: "Código de teléfono",
        type: ""
    },
    region_id: {
        translation: "Identificador de región",
        type: ""
    },
    description: {
        translation: "Descripción",
        type: ""
    },
    detail: {
        translation: "Detalle",
        type: ""
    },
    price: {
        translation: "Precio",
        type: ""
    },
    allows_dependents: {
        translation: "Permite dependientes",
        type: ""
    },
    coverage_optionable_id: {
        translation: "",
        type: ""
    },
    coverage_optionable_type: {
        translation: "",
        type: ""
    },
    group: {
        translation: "Grupo",
        type: ""
    },
    parent: {
        translation: "",
        type: ""
    },
    cardholder_name: {
        translation: "Nombre de tarjetahabiente",
        type: ""
    },
    last_digits: {
        translation: "Últimos dígitos",
        type: ""
    },
    expiration_month: {
        translation: "Mes de expiración",
        type: ""
    },
    expiration_year: {
        translation: "Año de expiración",
        type: ""
    },
    token_card: {
        translation: "Token de tarjeta",
        type: ""
    },
    deleted_at: {
        translation: "Eliminado en",
        type: ""
    },
    credit_id: {
        translation: "",
        type: ""
    },
    payment_id: {
        translation: "",
        type: ""
    },
    available: {
        translation: "Disponible",
        type: ""
    },
    creditable_id: {
        translation: "",
        type: ""
    },
    creditable_type: {
        translation: "",
        type: ""
    },
    first_name: {
        translation: "Nombres",
        type: ""
    },
    last_name: {
        translation: "Apellidos",
        type: ""
    },
    relationship: {
        translation: "Parentesco",
        type: ""
    },
    birthdate: {
        translation: "Fecha de nacimiento",
        type: "date"
    },
    insured_plan_id: {
        translation: "",
        type: ""
    },
    discount_code_id: {
        translation: "",
        type: ""
    },
    remuneration_type_id: {
        translation: "",
        type: ""
    },
    code: {
        translation: "Código",
        type: ""
    },
    expiration_date: {
        translation: "Fecha de expiración",
        type: "date"
    },
    percentaje: {
        translation: "Porcentaje",
        type: ""
    },
    percentaje_comission: {
        translation: "Porcentaje de comisión",
        type: ""
    },
    fixed_comission: {
        translation: "Comisión fija",
        type: ""
    },
    used: {
        translation: "Usado",
        type: ""
    },
    is_reusable: {
        translation: "Reusable",
        type: ""
    },
    application_delay: {
        translation: "Espera de aplicación",
        type: ""
    },
    recurrency: {
        translation: "Recurrencia",
        type: ""
    },
    phone: {
        translation: "Teléfono",
        type: ""
    },
    contactable_type: {
        translation: "",
        type: ""
    },
    contactable_id: {
        translation: "",
        type: ""
    },
    title: {
        translation: "Título",
        type: ""
    },
    notes: {
        translation: "Notas",
        type: ""
    },
    all_day: {
        translation: "Todo el día",
        type: ""
    },
    date: {
        translation: "Fecha",
        type: ""
    },
    question: {
        translation: "Pregunta",
        type: ""
    },
    category: {
        translation: "Categoría",
        type: ""
    },
    answer: {
        translation: "Respuesta",
        type: ""
    },
    file_type_id: {
        translation: "",
        type: ""
    },
    file_status_id: {
        translation: "",
        type: ""
    },
    path: {
        translation: "Ruta",
        type: ""
    },
    fileable_id: {
        translation: "",
        type: ""
    },
    fileable_type: {
        translation: "",
        type: ""
    },
    value: {
        translation: "Valor",
        type: ""
    },
    fieldable_type: {
        translation: "",
        type: ""
    },
    fieldable_id: {
        translation: "",
        type: ""
    },
    formable_id: {
        translation: "",
        type: ""
    },
    formable_type: {
        translation: "",
        type: ""
    },
    insured_id: {
        translation: "Colaborador",
        type: ""
    },
    email: {
        translation: "Correo electrónico",
        type: ""
    },
    igss: {
        translation: "IGSS",
        type: ""
    },
    business_division_id: {
        translation: "División",
        type: ""
    },
    location: {
        translation: "Ubicación",
        type: ""
    },
    boss: {
        translation: "Responsable",
        type: ""
    },
    start_date: {
        translation: "Fecha de inicio",
        type: "date"
    },
    emergency_contact_name: {
        translation: "Nombre de contacto de emergencia",
        type: ""
    },
    emergency_contact_phone: {
        translation: "Teléfono de contacto de emergencia",
        type: ""
    },
    emergency_contact_relationship: {
        translation: "Relación con contacto de emergencia",
        type: ""
    },
    absence_reason_id: {
        translation: "Identificador de razón de ausencia",
        type: ""
    },
    duration: {
        translation: "Duración",
        type: ""
    },
    overtime_reason_id: {
        translation: "Identificador de razón de horas extra",
        type: ""
    },
    hours: {
        translation: "Horas",
        type: ""
    },
    payment_multiplier: {
        translation: "Multiplicador de pago",
        type: ""
    },
    base: {
        translation: "Base",
        type: ""
    },
    bonuses: {
        translation: "Bonos de productividad",
        type: ""
    },
    payroll_type_id: {
        translation: "Identificador de tipo de paga",
        type: ""
    },
    payroll_frequency_id: {
        translation: "Identificador de frecuencia de pagos",
        type: ""
    },
    adjustments: {
        translation: "Ajustes",
        type: ""
    },
    end_date: {
        translation: "Fecha de finalización",
        type: "date"
    },
    valid: {
        translation: "Válido",
        type: ""
    },
    total_comission: {
        translation: "Comisión total",
        type: ""
    },
    paid_at: {
        translation: "Pagado en",
        type: ""
    },
    dpi: {
        translation: "DPI",
        type: ""
    },
    gender: {
        translation: "Género",
        type: ""
    },
    civil_state: {
        translation: "Estado civil",
        type: ""
    },
    logo: {
        translation: "Logo",
        type: ""
    },
    migration: {
        translation: "Migración",
        type: ""
    },
    batch: {
        translation: "Paquete",
        type: ""
    },
    client_id: {
        translation: "Identificador de cliente",
        type: ""
    },
    scopes: {
        translation: "",
        type: ""
    },
    revoked: {
        translation: "",
        type: ""
    },
    expires_at: {
        translation: "",
        type: ""
    },
    secret: {
        translation: "",
        type: ""
    },
    redirect: {
        translation: "",
        type: ""
    },
    personal_access_client: {
        translation: "",
        type: ""
    },
    password_client: {
        translation: "",
        type: ""
    },
    access_token_id: {
        translation: "",
        type: ""
    },
    token: {
        translation: "",
        type: ""
    },
    transaction_id: {
        translation: "",
        type: ""
    },
    tries: {
        translation: "",
        type: ""
    },
    next_date: {
        translation: "",
        type: "date"
    },
    surcharge: {
        translation: "",
        type: ""
    },
    pay_commission: {
        translation: "",
        type: ""
    },
    carbon_string: {
        translation: "",
        type: ""
    },
    new_plan: {
        translation: "",
        type: ""
    },
    feature: {
        translation: "",
        type: ""
    },
    max_insureds: {
        translation: "",
        type: ""
    },
    max_days: {
        translation: "",
        type: ""
    },
    promotional_price: {
        translation: "",
        type: ""
    },
    line_id: {
        translation: "",
        type: ""
    },
    file_name: {
        translation: "",
        type: ""
    },
    coverage_id: {
        translation: "",
        type: ""
    },
    coverageable_type: {
        translation: "",
        type: ""
    },
    coverageable_id: {
        translation: "",
        type: ""
    },
    minimum: {
        translation: "",
        type: ""
    },
    mandatory: {
        translation: "",
        type: ""
    },
    exclusion: {
        translation: "",
        type: ""
    },
    required_coverage_id: {
        translation: "",
        type: ""
    },
    geolocatable_type: {
        translation: "",
        type: ""
    },
    geolocatable_id: {
        translation: "",
        type: ""
    },
    placeable_type: {
        translation: "",
        type: ""
    },
    placeable_id: {
        translation: "",
        type: ""
    },
    tag_id: {
        translation: "",
        type: ""
    },
    taggable_id: {
        translation: "",
        type: ""
    },
    taggable_type: {
        translation: "",
        type: ""
    },
    order_column: {
        translation: "",
        type: ""
    },
    sequence: {
        translation: "",
        type: ""
    },
    uuid: {
        translation: "",
        type: ""
    },
    batch_id: {
        translation: "",
        type: ""
    },
    family_hash: {
        translation: "",
        type: ""
    },
    should_display_on_index: {
        translation: "",
        type: ""
    },
    content: {
        translation: "",
        type: ""
    },
    entry_uuid: {
        translation: "",
        type: ""
    },
    tag: {
        translation: "",
        type: ""
    },
    transaction: {
        translation: "",
        type: ""
    },
    credit_card_id: {
        translation: "",
        type: ""
    },
    start: {
        translation: "",
        type: ""
    },
    end: {
        translation: "",
        type: ""
    },
    role_id: {
        translation: "",
        type: ""
    },
    thumbnail: {
        translation: "",
        type: ""
    },
    password: {
        translation: "",
        type: ""
    },
    accepted_terms: {
        translation: "",
        type: ""
    },
    origin: {
        translation: "",
        type: ""
    },
    origin_token: {
        translation: "",
        type: ""
    },
    origin_id: {
        translation: "",
        type: ""
    },
    department_id: {
        translation: "",
        type: ""
    },
    remember_token: {
        translation: "",
        type: ""
    },
    email_verified_at: {
        translation: "",
        type: ""
    },
    "App\\Models\\AbsenceDuration": {
        translation: "Duración de ausencia",
        type: ""
    },
    "App\\Models\\AbsenceReason": {
        translation: "Razón de ausencia",
        type: ""
    },
    "App\\Models\\AbsenceType": {
        translation: "Tipo de ausencia",
        type: ""
    },
    "App\\Models\\AffiliationRequest": {
        translation: "",
        type: ""
    },
    "App\\Models\\AppMenu": {
        translation: "",
        type: ""
    },
    "App\\Models\\Audit": {
        translation: "",
        type: ""
    },
    "App\\Models\\Bank": {
        translation: "Banco",
        type: ""
    },
    "App\\Models\\BankAccount": {
        translation: "Cuenta bancaria",
        type: ""
    },
    "App\\Models\\BankAccountType": {
        translation: "Tipo de cuenta bancaria",
        type: ""
    },
    "App\\Models\\Batch": {
        translation: "Operación en bulto",
        type: ""
    },
    "App\\Models\\BaseModel": {
        translation: "",
        type: ""
    },
    "App\\Models\\Benefit": {
        translation: "Prestación",
        type: ""
    },
    "App\\Models\\Bonus": {
        translation: "Bono/Descuento",
        type: ""
    },
    "App\\Models\\BonusType": {
        translation: "Tipo de bono/descuento",
        type: ""
    },
    "App\\Models\\Business": {
        translation: "Empresa",
        type: ""
    },
    "App\\Models\\BusinessChange": {
        translation: "Cambios a información de empresa",
        type: ""
    },
    "App\\Models\\BusinessCoverageOption": {
        translation: "",
        type: ""
    },
    "App\\Models\\BusinessPlan": {
        translation: "Relación empresa-plan",
        type: ""
    },
    "App\\Models\\BusinessRequest": {
        translation: "Solicitud a empresa",
        type: ""
    },
    "App\\Models\\BusinessSuggestion": {
        translation: "Sugerencia interna",
        type: ""
    },
    "App\\Models\\BusinessRequirement": {
        translation: "",
        type: ""
    },
    "App\\Models\\Country": {
        translation: "País",
        type: ""
    },
    "App\\Models\\CountryRegion": {
        translation: "Relación país-región",
        type: ""
    },
    "App\\Models\\Coverage": {
        translation: "Cobertura",
        type: ""
    },
    "App\\Models\\CoverageOption": {
        translation: "",
        type: ""
    },
    "App\\Models\\Credit": {
        translation: "Crédito",
        type: ""
    },
    "App\\Models\\CreditCard": {
        translation: "Tarjeta de crédito",
        type: ""
    },
    "App\\Models\\Department": {
        translation: "Departamento",
        type: ""
    },
    "App\\Models\\Dependent": {
        translation: "Dependiente",
        type: ""
    },
    "App\\Models\\DiscountCode": {
        translation: "Código de descuento",
        type: ""
    },
    "App\\Models\\Division": {
        translation: "División",
        type: ""
    },
    "App\\Models\\Event": {
        translation: "Evento",
        type: ""
    },
    "App\\Models\\Faq": {
        translation: "Pregunta frecuente",
        type: ""
    },
    "App\\Models\\File": {
        translation: "Archivo",
        type: ""
    },
    "App\\Models\\FileStatus": {
        translation: "Estado de archivo",
        type: ""
    },
    "App\\Models\\FileType": {
        translation: "Tipo de archivo",
        type: ""
    },
    "App\\Models\\Form": {
        translation: "Formulario",
        type: ""
    },
    "App\\Models\\Industry": {
        translation: "Industria",
        type: ""
    },
    "App\\Models\\Insured": {
        translation: "Colaborador",
        type: ""
    },
    "App\\Models\\InsuredBusiness": {
        translation: "Relación empresa-colaborador",
        type: ""
    },
    "App\\Models\\InsuredBusinessAbsence": {
        translation: "Ausencia",
        type: ""
    },
    "App\\Models\\InsuredBusinessCheck": {
        translation: "Asistencia",
        type: ""
    },
    "App\\Models\\InsuredBusinessActivity": {
        translation: "Actividad de colaborador",
        type: ""
    },
    "App\\Models\\InsuredBusinessOvertime": {
        translation: "Horas extra",
        type: ""
    },
    "App\\Models\\InsuredBusinessPlan": {
        translation: "",
        type: ""
    },
    "App\\Models\\InsuredCoverageOption": {
        translation: "",
        type: ""
    },
    "App\\Models\\InsuredPayroll": {
        translation: "Configuración de pago",
        type: ""
    },
    "App\\Models\\InsuredPayrollRecord": {
        translation: "Pago",
        type: ""
    },
    "App\\Models\\InsuredPlan": {
        translation: "Relación asegurado-plan",
        type: ""
    },
    "App\\Models\\InsuredPlanDiscountCode": {
        translation: "",
        type: ""
    },
    "App\\Models\\Insurer": {
        translation: "Aseguradora",
        type: ""
    },
    "App\\Models\\Line": {
        translation: "Línea",
        type: ""
    },
    "App\\Models\\LumenUser": {
        translation: "Usuario API",
        type: ""
    },
    "App\\Models\\OvertimeReason": {
        translation: "Razón de horas extra",
        type: ""
    },
    "App\\Models\\Payment": {
        translation: "Pago",
        type: ""
    },
    "App\\Models\\PaymentProportion": {
        translation: "Proporción de pagos",
        type: ""
    },
    "App\\Models\\PayrollFrequency": {
        translation: "Frecuencia de paga",
        type: ""
    },
    "App\\Models\\PayrollType": {
        translation: "Tipo de paga",
        type: ""
    },
    "App\\Models\\Plan": {
        translation: "Plan",
        type: ""
    },
    "App\\Models\\PlanChange": {
        translation: "Cambios a plan",
        type: ""
    },
    "App\\Models\\PlanDetail": {
        translation: "Detalle de plan",
        type: ""
    },
    "App\\Models\\PlanDocument": {
        translation: "Documento de plan",
        type: ""
    },
    "App\\Models\\PlanPrice": {
        translation: "Precio de Plan",
        type: ""
    },
    "App\\Models\\QuiloForm": {
        translation: "Formulario Qüilo",
        type: ""
    },
    "App\\Models\\Region": {
        translation: "Región",
        type: ""
    },
    "App\\Models\\RelatedCoverage": {
        translation: "Cobertura relacionada",
        type: ""
    },
    "App\\Models\\RelatedPlace": {
        translation: "Lugar relacionado",
        type: ""
    },
    "App\\Models\\RemunerationType": {
        translation: "Tipo de remuneración",
        type: ""
    },
    "App\\Models\\Requirement": {
        translation: "Requerimientos",
        type: ""
    },
    "App\\Models\\Role": {
        translation: "Rol",
        type: ""
    },
    "App\\Models\\Tag": {
        translation: "Tag",
        type: ""
    },
    "App\\Models\\Transaction": {
        translation: "Transacción",
        type: ""
    },
    "App\\Models\\User": {
        translation: "Usuario",
        type: ""
    },
    "App\\Models\\UserRole": {
        translation: "Relación usuario-rol",
        type: ""
    }
};