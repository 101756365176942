<template>
<div class="uk-margin-bottom">
  <div class="uk-text-center uk-margin-large-top">
    <img src="/images/logo.png" width="300px" alt="">
  </div>
  <form method="POST" action="/login" class="uk-width-medium uk-margin-auto uk-text-center">
    <input type="hidden" :value="csrf" name="_token">
    <div class="uk-margin-large-top uk-text-large border-bottom">Inicia sesión</div>
    <input name="email" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="text" placeholder="Email">
    <div id="emailMissing" class="uk-text-danger" v-if="errors.email">{{ errors.email[0] }}</div>
    <input name="password" class="uk-margin-top uk-border-rounded uk-input uk-text-center" type="password" placeholder="Contraseña">
    <div id="passwordMissing" class="uk-text-danger" v-if="errors.password">{{ errors.password[0] }}</div>
    <div class="uk-margin-top">
      <label><input class="uk-checkbox" name="remember" type="checkbox" checked> Recuérdame</label>
    </div>
    <button dusk="login" class="uk-margin-top uk-width-1-1 uk-button uk-border-rounded uk-button-secondary" type="submit">Iniciar sesión</button>
    <!-- <a href="/register" class="uk-margin-top uk-width-1-1 uk-button uk-border-rounded uk-button-primary">Crear una cuenta</a> -->
    <div class="uk-margin-top">
      <a class="uk-link-muted" href="/password/reset">¿Olvidaste tu contraseña?</a>
    </div>
  </form>
</div>
</template>

<script>
export default {
  name: 'AuthLogin',

  props: ['csrf', 'errors']
}
</script>

<style>
.border-bottom {
  border-bottom: solid 1px;
}
</style>